import React from "react";
import { Route, Routes } from "react-router";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsSmCounterManagement from "./tms-sm-counter-management";
import TmsSmCounterManagementManageShifts from "./tms-sm-counter-management-shifts";
const TmsSmCounterManagementHome = () => {
  return (
    <div>
      <Routes>
        <Route
          exact
          path={"/manage-shift"}
          element={<TmsSmCounterManagementManageShifts />}
        />
        <Route
          exact
          path={tmsRouteMap.serviceManagement_counter_management.replace(
            "/service-management/counter-management",
            ""
          )}
          element={<TmsSmCounterManagement />}
        />
      </Routes>
    </div>
  );
};
export default withRouter(TmsSmCounterManagementHome);
