import {Checkbox, Col, Row} from "antd";
import React from "react";
const TmsCheckBoxList = ({
  data,
  selectedData,
  name,
  updateItems,
  gutter,
  style,
}) => {
  return (
    <>
      <Row justify="start">{name}</Row>
      {data ? (
        <Row gutter={gutter} justify="start" style={style}>
          {data?.map((item, i) => {
            return (
              <Col
                key={"role-dataa" + i}
                style={{width: "25%", textTransform: "lowercase"}}
              >
                <Checkbox
                  checked={selectedData?.includes(item)}
                  onChange={() => {
                   
                    updateItems(item);
                  }}
                >
                  {item}
                </Checkbox>
              </Col>
            );
          })}
        </Row>
      ) : (
        <Row>fetching Roles ....</Row>
      )}
    </>
  );
};
export default TmsCheckBoxList;
