import { Col, Select } from "antd";
import React from "react";
import { useContext } from "react";
import { BaseContext } from "../../contexts/BaseContext";

export default function TmsCalenderTithi({
  paksham,
  labelStyles,
  setTithi,
  tithi,
}) {
  const { tithiMapData } = useContext(BaseContext);
  return (
    <Col style={{ width: "48%", justifyContent: "start" }}>
      <label style={labelStyles}>
        Tithi
        <span style={{ color: "red", marginLeft: "3px" }}>*</span>
      </label>
      <br />
      <Select
        size="medium"
        style={{ width: "100%" }}
        placeholder="Select a Tithi"
        optionFilterProp="children"
        value={tithi}
        onChange={(e) => {
          setTithi(e);
        }}
      >
        {tithiMapData.map((item) => {
          return (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          );
        })}

        <Select.Option
          key={paksham === "Shukla" ? "Purnima" : "Amavasya"}
          value={paksham === "Shukla" ? "Purnima" : "Amavasya"}
        >
          {paksham === "Shukla" ? "Purnima" : "Amavasya"}
        </Select.Option>
      </Select>
    </Col>
  );
}
