import { Button, Col, Row } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import TmsAccommodationService from "../../../services/tms-accommodation.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsPosAccommodationScan from "../../../views/tms-pos/tms-pos-pages/tms-pos-accommodation-scan";
import TmsModal from "../../tms-customs/tms-modal";

const TmsAccDashboardCheckOutRenew = ({
  router,
  flag,
  roomId,
  setCheckOutRenewModal,
}) => {
  const [checkOutRenewDatas, setCheckOutRenewDatas] = useState();
  const [loading, setLoading] = useState(false);
  const [roomUpdateData, setRoomUpdateData] = useState();
  const { ticketDetailsModal, setTicketDetailsModal } = useContext(BaseContext);

  useEffect(() => {
    if (flag === "checkOut") {
      fetchCheckOut();
    } else if (flag === "renew") {
      fetchRenew();
    }
  }, [flag, roomId]);

  const fetchCheckOut = async () => {
    setLoading(true);
    try {
      const resp = await TmsAccommodationService.checkOutStats(roomId);
      setRoomUpdateData({
        func: "checkOut",
        roomBookingId: resp?.roomBookingId,
        outstandingAmount: resp?.outstandingAmount,
      });
      setCheckOutRenewDatas([
        {
          title: "Room Details",
          value: `Rs. ${resp?.roomPrice}.00 - ${resp?.roomType} - ${resp?.blockName} - ${resp?.roomNumber}`,
        },
        {
          title: "Checkin Time",
          value: moment(resp?.checkInTime).format("DD-MM-YYYY hh:mm A"),
        },
        {
          title: "Actual Checkout Time",
          value: moment(resp?.currentCheckOutTime).format("DD-MM-YYYY hh:mm A"),
        },
        {
          title: "New Checkout Time",
          value: moment(resp?.newCheckOutTime).format("DD-MM-YYYY hh:mm A"),
        },
        {
          title: "Unpaid Days",
          value: resp?.unPaidDays,
        },

        {
          title: "Service Amount",
          value: `Rs. ${resp?.serviceAmount ?? 0}.00`,
        },
        {
          title: "Deposit Amount",
          value: `Rs. ${resp?.depositAmount ?? 0}.00`,
        },

        {
          title: "GST Amount",
          value: `Rs. ${resp?.gstAmount ?? 0}.00`,
        },
        {
          title: "Outstanding Amount",
          value: `Rs. ${resp?.outstandingAmount ?? 0}.00`,
        },
      ]);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const fetchRenew = async () => {
    setLoading(true);
    try {
      const resp = await TmsAccommodationService.renewStats(roomId);
      setRoomUpdateData({
        func: "renew",
        roomBookingId: resp?.roomBookingId,
        outstandingAmount: resp?.outstandingAmount,
      });
      setCheckOutRenewDatas([
        {
          title: "New Checkout Time",
          value: moment(resp?.newCheckOutTime).format("DD-MM-YYYY hh:mm A"),
        },
        {
          title: "Previous Renewal Count",
          value: resp?.previousRenewalCount,
        },
        {
          title: "Unpaid Days",
          value: resp?.unPaidDays,
        },
        {
          title: "Deposit Amount",
          value: `Rs. ${resp?.depositAmount}.00`,
        },
        {
          title: "Service Amount",
          value: `Rs. ${resp?.serviceAmount}.00`,
        },
        {
          title: "GST Amount",
          value: `Rs. ${resp?.gstAmount ?? 0}.00`,
        },
        {
          title: "Outstanding Amount",
          value: `Rs. ${resp?.outstandingAmount ?? 0}.00`,
        },
      ]);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  return (
    <>
      <Row>
        <b
          style={{
            justifyContent: "center",
            margin: "auto",
            display: "flex",
            paddingBottom: "10px",
          }}
        >
          {flag === "checkOut" ? "Checkout" : "Renew"} Details
        </b>
        {checkOutRenewDatas?.map((item, i) => {
          return (
            <Row
              style={{ marginBottom: "5px", width: "100%" }}
              key={"ticketdetails" + i}
            >
              <Col className="ticket_label">{item.title}:</Col>
              <Col className="ticket_data">{item?.value}</Col>
            </Row>
          );
        })}
      </Row>
      <Row style={{ paddingTop: "10px", justifyContent: "end" }}>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            setTicketDetailsModal(true);
            setCheckOutRenewModal(false);
          }}
        >
          {flag === "checkOut" ? "Checkout" : "Renew"}
        </Button>
      </Row>
      <TmsModal
        visible={ticketDetailsModal}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPosAccommodationScan
          from="accDashboard"
          dataFromAccDash={roomUpdateData}
        />
      </TmsModal>
    </>
  );
};

export default TmsAccDashboardCheckOutRenew;
