import { Button, Col, Input, notification, Row } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import TmsDonationService from "../../../../services/tms-donation.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsCounterService from "../../../../services/tms-counter.service";
import TmsShiftService from "../../../../services/tms-shift.service";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";

const TmsReportDonationReport = ({ router }) => {
  const [loading, setLoading] = useState();
  const [bookingDetails, setBookingDetails] = useState([]);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingType, setBookingType] = useState("pos");
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [sortType, setSortType] = useState("DESC");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [donationType, setDonationType] = useState("");
  const [donationList, setDonationList] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);

  const [counter, setCounter] = useState("");
  const [counterList, setCounterList] = useState([]);

  const [shift, setShift] = useState("");
  const [shiftList, setShiftList] = useState([]);

  const API = {
    online: TmsDonationService.donationOnlineReport,
    pos: TmsDonationService.donationReport,
  };

  const fetchBookingDetails = async () => {
    setLoading(true);
    try {
      const data = await API[bookingType](
        "json",
        Number(tableCurrentPage),
        Number(tablePageSize),
        bookingFromDate,
        bookingToDate,
        sortType,
        counter,
        shift,
        donationType
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setBookingDetails(
        data?.items?.map((item) => {
          return { key: item?.bookingId, ...item };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdDate") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  const fetchDarshanamList = async () => {
    try {
      let res = await TmsDonationService.getDonations(1, 100);
      setDonationList(res.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchCounterList = async () => {
    try {
      let res = await TmsCounterService.getAllCounter();
      setCounterList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchShiftList = async () => {
    try {
      let res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      let data = await API[bookingType](
        "json",
        Number(tableCurrentPage),
        50000,
        bookingFromDate,
        bookingToDate,
        sortType,
        counter,
        shift,
        donationType
      );
      data = data?.items?.reverse();
      let compiledDataOffile = [];
      let compiledDataOnline = [];
      if (bookingType === "online") {
        compiledDataOnline = data?.map((item, i) => {
          return {
            "S.no": i + 1,
            Donation: item?.donationName,
            "Booked on": moment(item?.createdDate).format("DD-MM-YYYY HH:mm A"),
            "Booking phone": item?.contact,
            Amount: item?.amount,
            "Date type": item?.calendarType,
            "Performance Date":
              item?.calendarType === "english"
                ? item.bookingDate &&
                  moment(item.bookingDate).format("DD-MM-YYYY")
                : item?.masam
                ? `${item?.masam} ${item?.paksham} ${item?.tithi}`
                : "-",
            "razorpay ID": item?.razorPayOrderId,
            "Devotee Name": item?.devoteeName ?? "-",
            Gender: item?.gender ?? "-",
            Gothram: item?.gothram ?? "-",
            "ID Proof Type": item?.documentType ?? "-",
            "ID Proof Number": item?.documentId ?? "-",
            "Date of Birth": moment(item?.dob).format("DD-MM-YYYY"),
            Address: `${item?.address} ${item?.address2}`,
            city: item?.city,
            state: item?.state,
            country: item?.country,
            Occasion: item?.occasion ?? "-",
          };
        });
      } else {
        compiledDataOffile = data?.map((item, i) => {
          return {
            "S.no": i + 1,
            "POS Name": item.counterName,
            "Shift ID": item.counterShiftTimingId,
            "Transaction Id": item.categoryTransactionSequence
              ?.split("-")
              ?.pop(),
            "Serial No": item.dailyCategoryTransactionSequence
              ?.split("-")
              ?.pop(),
            "Booking Id": item.bookingId,
            "Donation Name": item.donationName,
            "Devotee Name": item.devoteeName,
            Address: `${item.address ? `${item.address},` : ""}${
              item.address2 ? `${item.address2},` : ""
            }${item.city ? `${item.city},` : ""}${
              item.state ? `${item.state},` : ""
            }${item.country ? `${item.country},` : ""}${
              item.pincode ? `${item.pinCode},` : ""
            }`,
            "In Behalf of": item.inTheNameOf ?? "-",
            Amount: item.amount,
            Gothram: item.gothram ?? "-",
            "ID Proof Type": item?.documentType ?? "-",
            "ID Proof Number": item?.documentId ?? "-",
            Ocassion: item.occasion ?? "-",
            "Booked on": moment(item?.createdDate).format("DD-MM-YYYY HH:mm A"),
            "Performance Date":
              item?.calendarType === "english"
                ? item.bookingDate &&
                  moment(item.bookingDate).format("DD-MM-YYYY")
                : `${item?.masam} ${item?.paksham} ${item?.tithi}`,
            Phone: item.contact ?? "-",
            Email: item?.email ?? "-",
          };
        });
      }
      exportExcel(
        bookingType === "online" ? compiledDataOnline : compiledDataOffile,
        `donation-report--${bookingType}-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };

  useEffect(() => {
    fetchDarshanamList();
    fetchCounterList();
    fetchShiftList();
  }, []);
  useEffect(() => {
    fetchBookingDetails();
  }, [
    bookingFromDate,
    bookingToDate,
    sortType,
    tableCurrentPage,
    tablePageSize,
    counter,
    shift,
    donationType,
    bookingType,
  ]);

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "POS Name",
      dataIndex: "counterName",
      key: "counterName",
    },

    {
      title: "Txn Id",
      dataIndex: "categoryTransactionSequence",
      render: (text) => text?.split("-")?.pop(),
    },
    {
      title: "S No",
      dataIndex: "dailyCategoryTransactionSequence",
      render: (text) => text?.split("-")?.pop(),
    },
    {
      title: "Donation Type",
      dataIndex: "donationName",
      key: "donationName",
    },
    {
      title: "Devotee Name",
      dataIndex: "devoteeName",
      key: "devoteeName",
      ...getColumnSearchProps("devoteeName"),
      width: "100px",
    },
    {
      title: "In Behalf of",
      dataIndex: "inTheNameOf",
      key: "inTheNameOf",
      ...getColumnSearchProps("inTheNameOf"),
      width: "100px",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        return `Rs. ${text}`;
      },
      sorter: (a, b) => a.amount > b.amount,
    },
    {
      title: "Booked On",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY HH:mm A");
      },
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.createdDate > b.createdDate,
    },
    {
      title: "Performance Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      render: (record) => {
        return record ? moment(record).format("DD-MM-YYYY") : "-";
      },
      sorter: (a, b) => a.bookingDate > b.bookingDate,
    },
    {
      title: "Phone",
      dataIndex: "contact",
      key: "contact",
      render: (text) => text ?? "-",
    },
  ];

  const onlineColumns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Donation Type",
      dataIndex: "donationName",
      key: "donationName",
    },
    {
      title: "Devotee Name",
      dataIndex: "devoteeName",
      key: "devoteeName",
      ...getColumnSearchProps("devoteeName"),
      width: "100px",
    },
    // {
    //   title: "In Behalf of",
    //   dataIndex: "inTheNameOf",
    //   key: "inTheNameOf",
    //   ...getColumnSearchProps("inTheNameOf"),
    //   width: "100px",
    // },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        return `Rs. ${text}`;
      },
      sorter: (a, b) => a.amount > b.amount,
    },
    {
      title: "Performance Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      render: (record) => {
        return record ? moment(record).format("DD-MM-YYYY") : "-";
      },
      sorter: (a, b) => a.bookingDate > b.bookingDate,
    },
    {
      title: "Booked On",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY HH:mm A");
      },
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdDate > b.createdDate,
    },
    {
      title: "Phone",
      dataIndex: "contact",
      key: "contact",
      render: (text) => text ?? "-",
    },
  ];

  return (
    <>
      <Row gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "pos" },
            { id: "online", name: "online" },
          ]}
          optional={true}
        />
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Donation Type"
          setValue={(e) => {
            setDonationType(e);
          }}
          value={donationType}
          data={donationList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="POS"
          setValue={setCounter}
          value={counter}
          data={counterList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Shift"
          setValue={setShift}
          value={shift}
          data={shiftList}
        />
        <Col span={6}>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
        <TmsExcelButton
          loading={excelLoading}
          handleExcelExport={handleExcelExport}
          top={true}
        />
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={bookingType === "online" ? onlineColumns : columns}
          dataSource={bookingDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
          expandable={{
            expandedRowRender: (record) => (
              <Row>
                <Col className="mr-10">
                  {bookingType !== "online" && (
                    <>
                      <b>Shift ID:</b> {record.counterShiftTimingId} |
                    </>
                  )}
                  <b>Booking ID:</b> {record.key}
                </Col>
                |
                <Col className="mr-10">
                  <b> Address</b> : {record.address ?? ""}
                  {record.address2 ?? ""}
                  {record.city ?? ""}
                  {record.state ?? ""}
                  {record.country ?? ""}
                  {record.pincode ?? ""}
                </Col>
                |
                <Col className="mr-10">
                  <b> Gothram</b>: {record.gothram ?? "-"}
                </Col>
                |
                <Col className="mr-10">
                  <b> Ocassion</b>: {record.occasion ?? "-"}
                </Col>
              </Row>
            ),
          }}
        ></TmsTable>
      </Row>
    </>
  );
};

export default withRouter(TmsReportDonationReport);
