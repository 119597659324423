import { Upload, Button, message, notification } from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";

import "../../assets/css/tms-custom-elements/tms-custom-elements.scss";
import TmsCommonService from "../../services/tms-common.service";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
const TmsImageUpload = ({
  fileList,
  setFileList,
  className,
  selectedClass,
  accept,
  router,
  type,
}) => {
  const [uploading, setUploading] = useState(false);

  const handleChange = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      setFileList([]);
      return false;
    }
    setFileList([file]);
    return true;
  };

  const props = {
    customRequest: async ({ file, onSuccess, onError }) => {
      setUploading(true);
      let formData = new FormData();
      formData.append("file", file);
      try {
        let res = await TmsCommonService.uploadFile(
          formData,
          type ? type : "public",
          "welcome"
        );
        setFileList([
          {
            url: res.fileUrl,
            status: "done",
            name: "file",
          },
        ]);
        setUploading(false);
        onSuccess("done");
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please login again.", router);
        setUploading(false);
        onError("err");
        notification.error({ message: e?.message });
        setFileList([]);
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    beforeUpload: handleChange,
    multiple: false,
    listType: "picture",
    accept: accept ? "" : "image/png",
    onRemove: () => setFileList([]),
    className: "w-100",
    style: `width:100%`,
  };

  return (
    <Upload
      {...props}
      maxCount={1}
      fileList={fileList}
      className={selectedClass ?? ""}
      style={{ width: className === "w-100" ? "100%" : "250px" }}
    >
      <Button
        loading={uploading}
        disabled={uploading}
        icon={<UploadOutlined />}
        style={{ width: className === "w-100" ? "100%" : "250px" }}
      >
        Upload {accept ? "" : "png only"}
      </Button>
    </Upload>
  );
};

export default withRouter(TmsImageUpload);
