import React, { createContext, useState } from "react";
export const BaseContext = createContext();
export const BaseContextProvider = (props) => {
  const [userDetails, setUserDetails] = useState(null);
  const [shiftData, setShiftData] = useState(null);
  const [version, setVersion] = useState("");
  const [restart, setRestart] = useState(false);
  const clearData = () => {
    setUserDetails(null);
    setShiftData(null);
  };
  const clearLocalStorage = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("templeId");
    localStorage.removeItem("templeDetails");
  };
  const [details, setDetails] = useState(null);
  const [ticketDetailsModal, setTicketDetailsModal] = useState();
  const [roomsModal, setRoomsModal] = useState(false);
  const [rebookingConfi, setReBookingConfi] = useState(false);
  const posMap = [
    "ACCOMMODATION_COUNTER",
    "SEVA_COUNTER",
    "DARSHANAM_COUNTER",
    "PRASADAM_COUNTER",
    "KALYANAKATTA_COUNTER",
    "DONATION_COUNTER",
    "DONATION_PETTY_COUNTER",
    "PUBLICATION_COUNTER",
    "TOLLGATE_COUNTER",
    "TICKET_DETAILS_SCAN",
    "TOLLGATE_TICKET_SCAN",
    "PRASADAM_TICKET_SCAN",
    "DARSHANAM_TICKET_SCAN",
    "SEVA_TICKET_PERFORMANCE_SCAN",
    "ACCOMMODATION_TICKET_SCAN",
    "THULABHARAM_COUNTER",
  ];
  const roomMapData = [
    { name: "AC", id: "AC" },
    { name: "NON-AC", id: "NON_AC" },
    { name: "SUITE", id: "SUITE" },
    { name: "COTTAGE", id: "COTTAGE" },
    { name: "DORMITORY", id: "DORMITORY" },
    { name: "SINGLE ROOM NON A/C", id: "SINGLE_ROOM_NON_AC" },
    { name: "DOUBLE ROOM NON A/C", id: "DOUBLE_ROOM_NON_AC" },
    { name: "SINGLE ROOM A/C", id: "SINGLE_ROOM_AC" },
    { name: "DOUBLE ROOM A/C", id: "DOUBLE_ROOM_AC" },
    { name: "HALL", id: "HALL" },
    { name: "KALYANA MANDAPAM", id: "KALYANA_MANDAPAM" },
    { name: "OTHERS", id: "OTHERS" },
  ];
  const tithiMapData = [
    "Prathama",
    "Dwitiya",
    "Tritiya",
    "Chaturthi",
    "Panchami",
    "Shashthi",
    "Saptami",
    "Ashtami",
    "Navami",
    "Dasami",
    "Ekadasi",
    "Dvadasi",
    "Trayodasi",
    "Chaturdashi",
  ];
  return (
    <BaseContext.Provider
      value={{
        posMap,
        userDetails,
        setUserDetails,
        shiftData,
        setShiftData,
        clearData,
        clearLocalStorage,
        version,
        setVersion,
        restart,
        setRestart,
        details,
        setDetails,
        ticketDetailsModal,
        setTicketDetailsModal,
        roomMapData,
        rebookingConfi,
        setReBookingConfi,
        roomsModal,
        setRoomsModal,
        tithiMapData,
      }}
    >
      {props.children}
    </BaseContext.Provider>
  );
};
