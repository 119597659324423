import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";
const TmsCommonService = {
  uploadFile: async (file, access, fileFor) => {
    const res = await Axios.post(
      `v1/file-upload/restricted/upload/${access}?fileFor=${fileFor}`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return res.data;
  },
  getConsolidateReport: async (format, productId, from, to, counter, shift) => {
    let query = `v1/dashboard/consolidated-report/counter?bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (counter) {
      query += `&counterId=${counter}`;
    }
    if (shift) {
      query += `&counterShiftTimingId=${shift}`;
    }

    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
  getOnlineConsolidateReport: async (format, productId, from, to) => {
    let query = `v1/dashboard/consolidated-report/online?bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }

    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
};
export default TmsCommonService;
