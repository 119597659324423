import {Row} from "antd";
import React from "react";
const TmsRowVertical = ({data}) => {
  return (
    <>
      <Row justify={data.justify}>{data.one}</Row>
      <Row justify={data.justify} style={data.style}>
        {data.two}
      </Row>
    </>
  );
};
export default TmsRowVertical;
