import { Button, Card, Drawer, Row } from "antd";
import React, { useEffect, useState } from "react";
import AddBook from "../../../../components/tms-service-management/tms-sm-manual-entry/add-book";
import TmsManualEntryService from "../../../../services/tms-manual-entry.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsManualEntryAction from "../../../../components/tms-service-management/tms-sm-manual-entry/tms-manual-entry-action";

import EditBook from "../../../../components/tms-service-management/tms-sm-manual-entry/edit-book";

const TmsManualEntry = ({ router, setCategoryData }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [viewSlider, setViewSlider] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateflag, setUpdateFlag] = useState();
  const [editData, setEditData] = useState([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Counter",
      dataIndex: "counter",
      key: "counter",
      //   ...getColumnSearchProps("darshanam_name"),
      render: (text) => {
        return text?.name;
      },
    },
    {
      title: "Series",
      children: [
        {
          title: "From",
          dataIndex: "seriesStart",
          key: "seriesStart",
        },
        {
          title: "To",
          dataIndex: "seriesEnd",
          key: "seriesEnd",
        },
      ],
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <TmsManualEntryAction
            record={record}
            setLoading={setLoading}
            setUpdateFlag={setUpdateFlag}
            setCategoryData={setCategoryData}
            setViewSlider={setViewSlider}
            setEditData={setEditData}
          />
        );
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, [tableCurrentPage, tablePageSize, updateflag]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await TmsManualEntryService.getAll(
        tableCurrentPage,
        tablePageSize
      );
      setTableData(resp?.items);
      setTabelTotalSize(resp?.meta?.totalItems);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  return (
    <div>
      <Row justify="start">
        <Button
          type="primary"
          onClick={() => setViewSlider(true)}
          loading={loading}
          style={{ color: "white !important" }}
        >
          Add Book
        </Button>
      </Row>
      <br />
      <Row style={{ width: "100%" }}>
        <Card style={{ width: "100%" }}>
          <TmsTable
            columns={columns}
            dataSource={tableData}
            handleTableChange={handleTableChange}
            pagination={{
              total: tableTotalSize,
              current: tableCurrentPage,
              pageSize: 10,
            }}
          />
        </Card>
      </Row>
      <Drawer
        visible={viewSlider}
        onClose={() => {
          setViewSlider(false);
          setEditData([]);
        }}
        width={800}
        title={editData?.id > 0 ? "Edit Book" : "Add Book"}
        closable={false}
      >
        {editData?.id > 0 ? (
          <EditBook
            editData={editData}
            setViewSlider={setViewSlider}
            setUpdateFlag={setUpdateFlag}
            setEditData={setEditData}
          />
        ) : (
          <AddBook
            setViewSlider={setViewSlider}
            setUpdateFlag={setUpdateFlag}
          />
        )}
      </Drawer>
    </div>
  );
};

export default withRouter(TmsManualEntry);
