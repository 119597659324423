import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import { Button, Col, Drawer, Switch } from "antd";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
// import TmsDInnerPagesAnd from "./tms-d-inner-pages-and";
import TmsAccinnerActions from "../../../../../components/tms-service-management/tms-sm-accommodation/TmsAccInnerActions";
import TmsAccommodationService from "../../../../../services/tms-accommodation.service";
import TmsAccInnerPagesAdd from "./tms-acc-add";
import TextArea from "antd/lib/input/TextArea";

const TmsAccInnerPagesAll = ({ router }) => {
  const [updateFlag, setUpdateFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accommodationData, setAccommodationData] = useState([]);
  const [tmsModalVisible, settmsModalVisible] = useState(false);
  const [viewAccommodationData, setViewAccommodationData] = useState();
  const [editFlag, setEditFlag] = useState();
  const [productId, setProductId] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [termsData, setTermsData] = useState([]);

  const tableColums = [
    ["Name", viewAccommodationData?.name],
    ["Description", viewAccommodationData?.description],
    ["Min Online Hours", Number(viewAccommodationData?.minOnlineHours)],
    ["Max Online Hours", Number(viewAccommodationData?.maxOnlineHours)],
    ["Min POS Hours", viewAccommodationData?.minCounterHours],
    ["Max POS Hours", viewAccommodationData?.maxCounterHours],
    [
      "POS-(Status)",
      viewAccommodationData?.counterEnabled ? "enabled" : "disabled",
    ],
    [
      "Online-(Status)",
      viewAccommodationData?.onlineEnabled ? "enabled" : "disabled",
    ],
  ];

  const switchTrigger = async (key, status, from) => {
    setLoading(true);
    try {
      const darData = await TmsAccommodationService.getBlockById(key);
      setViewAccommodationData(darData);
      settmsModalVisible(true);
      setEditFlag(true);
      setProductId(key);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    // try {
    //   const accData = await TmsAccommodationService.getBlockById(key);
    //   let body = {
    //     name: accData.name,
    //     description: accData.description,
    //     counterEnabled: from === "counter" ? status : accData?.counterEnabled,
    //     onlineEnabled: from === "online" ? status : accData?.onlineEnabled,
    //     price: accData.price,
    //     imageUrl: accData.imageUrl,
    //     minOnlineHours: accData.minOnlineHours,
    //     maxOnlineHours: accData.maxOnlineHours,
    //     minCounterHours: accData.minCounterHours,
    //     maxCounterHours: accData.maxCounterHours,
    //   };
    //   await TmsAccommodationService.editBlock(key, body);
    //   setUpdateFlag(Math.random() + 1);
    // } catch (error) {
    //   catchErrorFunction(
    //     error,
    //     "Token Expired, redirecting to login page in 5 seconds",
    //     router
    //   );
    // }
    setLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    if (router.location?.pathname === "/service-management/accommodation") {
      getAccommadation();
      if (!editFlag) {
        settmsModalVisible(false);
      }
    }
  }, [
    updateFlag,
    editFlag,
    tableCurrentPage,
    tablePageSize,
    router.location?.pathname,
  ]);

  const getAccommadation = async () => {
    setLoading(true);
    try {
      setAccommodationData([]);
      const data = await TmsAccommodationService.getAllBlocks(
        tableCurrentPage,
        tablePageSize
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setAccommodationData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
    setLoading(false);
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "Block Name",
      dataIndex: "name",
      key: "name",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "POS",
      children: [
        {
          title: "Min Hours",
          dataIndex: "minCounterHours",
          key: "minCounterHours",
          sorter: (a, b) => a.minCounterHours > b.minCounterHours,
          render: (text) => {
            return <>{Number(text)}</>;
          },
        },
        {
          title: "Max Hours",
          dataIndex: "maxCounterHours",
          key: "maxCounterHours",
          sorter: (a, b) => a.maxCounterHours > b.maxCounterHours,
          render: (text) => {
            return <>{Number(text)}</>;
          },
        },
        {
          title: "Status",
          dataIndex: "counterEnabled",
          key: "counterEnabled",
          render: (text, record) => {
            return (
              <Switch
                size={"small"}
                checked={text}
                style={{
                  background: text ? "#4CBB17 " : "EFEFEF ",
                }}
                onClick={() => {
                  switchTrigger(record?.key, !text, "counter");
                }}
              />
            );
          },
        },
      ],
    },
    {
      title: "Online",
      children: [
        {
          title: "Min Hours",
          dataIndex: "minOnlineHours",
          key: "minOnlineHours",
          sorter: (a, b) => a.minOnlineHours > b.minOnlineHours,
          render: (text) => {
            return <>{Number(text)}</>;
          },
        },
        {
          title: "Max Hours",
          dataIndex: "maxOnlineHours",
          key: "maxOnlineHours",
          sorter: (a, b) => a.maxOnlineHours > b.maxOnlineHours,
          render: (text) => {
            return <>{Number(text)}</>;
          },
        },
        {
          title: "Status",
          dataIndex: "onlineEnabled",
          key: "onlineEnabled",
          render: (text, record) => {
            return (
              <Switch
                size={"small"}
                checked={text}
                style={{
                  background: text ? "#4CBB17 " : "EFEFEF ",
                }}
                onClick={() => {
                  switchTrigger(record?.key, !text, "online");
                }}
              />
            );
          },
        },
      ],
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <TmsAccinnerActions
            record={record}
            setLoading={setLoading}
            setViewAccommodationData={setViewAccommodationData}
            settmsModalVisible={settmsModalVisible}
            setUpdateFlag={setUpdateFlag}
            setEditFlag={setEditFlag}
            setProductId={setProductId}
            setShowDrawer={setShowDrawer}
            setTermsData={setTermsData}
          />
        );
      },
    },
  ];

  return (
    <>
      {" "}
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={accommodationData}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      />
      <Drawer
        className="DetailsModal"
        // closable={true}
        width={editFlag ? "800px" : "600px"}
        visible={tmsModalVisible}
        onClose={() => {
          settmsModalVisible(false);
          setEditFlag(false);
          setViewAccommodationData(null);
        }}
        title={editFlag ? "Edit Accommodation" : "Acommodation Details"}
      >
        {editFlag ? (
          <TmsAccInnerPagesAdd
            viewAccommodationData={viewAccommodationData}
            editFlag={editFlag}
            productId={productId}
            setEditFlag={setEditFlag}
            settmsModalVisible={settmsModalVisible}
          />
        ) : (
          <div className="viewDetails">
            <Col style={{ width: "100%" }}>
              <table className="DetailsTable">
                {tableColums.map((item) => {
                  return (
                    <tr key={"table" + item[0]}>
                      <th>{item[0]}</th>
                      <td>{item[1]}</td>
                    </tr>
                  );
                })}
              </table>
            </Col>
            <br />
            <Col className="ImgCol">
              <img src={viewAccommodationData?.imageUrl} alt="Image" />
            </Col>
          </div>
        )}
      </Drawer>
      <Drawer
        className="TermsDrawer"
        visible={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setTermsData([]);
        }}
        title="Terms & Conditions"
        extra={
          <Button
            type="primary"
            onClick={async () => {
              setLoading(true);
              try {
                await TmsAccommodationService.updateTerms(
                  productId,
                  termsData?.toString()?.replaceAll(",", "\n")?.split("\n")
                );
                setUpdateFlag(Math.random() + 1);
                setShowDrawer(false);
                setTermsData([]);
              } catch (error) {
                catchErrorFunction(
                  error,
                  "Token Expired, redirecting to login page in 5 seconds",
                  router
                );
              }
              setLoading(false);
            }}
          >
            Submit
          </Button>
        }
      >
        <TextArea
          rows={4}
          value={termsData?.toString()?.replaceAll(",", "\n")}
          onChange={(e) => {
            setTermsData(e.target.value);
          }}
        />
      </Drawer>
    </>
  );
};

export default withRouter(TmsAccInnerPagesAll);
