import React from "react";
import { Route, Routes as SwitchRouter } from "react-router";
import TmsBookings from "../views/tms-bookings/tms-bookings";
import TmsCalender from "../views/tms-calender/tms-calender";
import TmsDevotees from "../views/tms-devotees/tms-devotees";
import TmsHome from "../views/tms-home/tms-home";
import TmsOrders from "../views/tms-orders/tms-orders";
import TmsPos from "../views/tms-pos/tms-pos";
import TmsReports from "../views/tms-reports/tms-reports";
import TmsServiceManagement from "../views/tms-service-management/tms-service-management";
import TmsSupport from "../views/tms-support/tms-support";
import TmsTempleManagement from "../views/tms-temple-management/tms-temple-management";
import TmsTempleSettings from "../views/tms-temple-settings/tms-temple-settings";
import TmsUserManagement from "../views/tms-user-management/tms-user-management";
import TmsWelcome from "../views/tms-welcome/tms-welcome";

const TmsRoutes = () => {
  return (
    <SwitchRouter>
      <Route path="/welcome" element={<TmsWelcome />} />

      <Route path="/home" element={<TmsHome />} />
      <Route exact path="/devotees/*" element={<TmsDevotees />} />
      <Route exact path="/pos/*" element={<TmsPos />} />
      <Route
        exact
        path="/service-management/*"
        element={<TmsServiceManagement />}
      />
      <Route exact path="/bookings/*" element={<TmsBookings />} />
      <Route
        exact
        path="/temple-management/*"
        element={<TmsTempleManagement />}
      />
      <Route exact path="/temple-settings/*" element={<TmsTempleSettings />} />
      <Route exact path="/orders/*" element={<TmsOrders />} />
      <Route exact path="/reports/*" element={<TmsReports />} />
      <Route exact path="/user-management/*" element={<TmsUserManagement />} />
      <Route exact path="/support/*" element={<TmsSupport />} />
      <Route path="/calender/*" element={<TmsCalender />} />
      <Route path="/user-management/*" element={<TmsUserManagement />} />
    </SwitchRouter>
  );
};
export default TmsRoutes;
