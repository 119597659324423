import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
// import TmsCmsCounters from "../../tms-common-manage-slots/tms-cms-counters";
import TmsSevasInnerPagesAll from "./tms-sm-sevas-inner-pages/tms-sevas-inner-pages-all";
import TmsSevasInnerPagesAnd from "./tms-sm-sevas-inner-pages/tms-sevas-inner-pages-and";
import TmsCmsSlots from "../../../tms-common-manage-slots/tms-cms-slots";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsSevasInnerPagesDisablesevas from "./tms-sm-sevas-inner-pages/tms-sevas-inner-pages-disablesevas";
import TmsCalender from "../../../../components/tms-customs/tms-calender";
// import TmsSevasInnerPagesDbd from "./tms-sm-sevas-inner-pages/tms-sevas-inner-pages-dbd";
const TmsSmSevas = ({ router }) => {
  const [active, setActive] = useState("all");
  const [sevaType, setSevaType] = useState("All");
  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.serviceManagement_sevas) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sms_add_new_seva)) {
      setActive("Add New Pooja");
    } else if (location.includes(tmsRouteMap.sms_seva_disable_dates)) {
      setActive("Pooja Disable Dates");
    } else if (location.includes(tmsRouteMap.sms_sevas_manage_slots)) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sms_sevas_manage_counters)) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sms_sevas_manage_quota)) {
      setActive("all");
      return;
    }
  }, [router?.location?.pathname]);
  const location = router?.location?.pathname;
  const components = [
    {
      name: location.includes(tmsRouteMap.sms_sevas_manage_counters)
        ? "(Slots)-Manage Counter"
        : location.includes(tmsRouteMap.sms_sevas_manage_slots)
        ? "Manage Slots"
        : location.includes(tmsRouteMap.sms_sevas_manage_quota)
        ? "Manage Online Quota"
        : "All",
      Element: location.includes(tmsRouteMap.sms_sevas_manage_quota) ? (
        <TmsCalender />
      ) : location.includes(tmsRouteMap.sms_sevas_manage_counters) ? (
        <TmsCmsSlots type="seva-counters" sevaType={sevaType} />
      ) : location.includes(tmsRouteMap.sms_sevas_manage_slots) ? (
        <TmsCmsSlots type="seva-slots" sevaType={sevaType} />
      ) : (
        <TmsSevasInnerPagesAll sevaType={sevaType} setSevaType={setSevaType} />
      ),
      key: "all",
      route: tmsRouteMap.serviceManagement_sevas,
    },
    {
      name: "Add New Pooja",
      Element: <TmsSevasInnerPagesAnd />,
      key: "Add New Pooja",
      route: tmsRouteMap.sms_add_new_seva,
    },
    {
      name: "Pooja Disable Dates",
      Element: <TmsSevasInnerPagesDisablesevas />,
      key: "Pooja Disable Dates",
      route: tmsRouteMap.sms_seva_disable_dates,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsSmSevas);
