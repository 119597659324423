import { Col, Row, Space } from "antd";
import React from "react";
const TmsSingleWrapper = (props) => {
  return (
    <Row
      style={props?.style ? props?.style : { zIndex: "auto" }}
      className={props.className ? props.className : ""}
    >
      {props.type === "col" ? (
        <Col> {props.children}</Col>
      ) : props.type === "space" ? (
        <Space> {props.children}</Space>
      ) : props.type === "row" ? (
        <Row>{props.children}</Row>
      ) : (
        ""
      )}
    </Row>
  );
};
export default TmsSingleWrapper;
