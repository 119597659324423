import React, { useEffect, useState } from "react";
// import {Route} from "react-router";
import TmsTabsLayout from "../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../constants/tms-route.map";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
// import {withRouter} from "../../utils/tms-with-router/tms-with-router";
import TmsUmInnerPagesUsers from "./tms-um-inner-pages/tms-um-inner-pages-users";
import TmsUmInnerPagesUsersDetails from "./tms-um-inner-pages/tms-um-inner-pages-users-details";
// import TmsUMInnerPagesUserManagement from "./tms-um-inner-pages/tms-um-inner-pages-users-management";

const TmsUserManagement = ({ router }) => {
  const [active, setActive] = useState("Users");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === "/user-management") {
      setActive("Users");
      return;
    } else if (location.includes("/user-management/user-details")) {
      setActive("User Details");
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "User Mangement",
      Element: <TmsUmInnerPagesUsers />,
      key: "Users",
      route: tmsRouteMap.userManagement,
    },

    {
      name: "User Details",
      Element: <TmsUmInnerPagesUsersDetails />,
      key: "User Details",
      route: tmsRouteMap.userManagement_user_details,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsUserManagement);
