import {
  Button,
  Col,
  Divider,
  Input,
  notification,
  Row,
  Select,
  Tabs,
} from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import TmsImageUpload from "../../components/tms-customs/tms-image-upload";
// import TmsPhoneNumberBasic from "../../components/tms-customs/tms-phone-number-basic";
import TmsTempleService from "../../services/tms-temple.service";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import moment from "moment";

const { TabPane } = Tabs;

const TmsTempleSettings = ({ router }) => {
  const [feeType, setFeeType] = useState("percentage");
  const [feeValue, setFeeValue] = useState("");
  const [conditionOne, setConditionOne] = useState("");
  const [conditionOneValue, setConditionOneValue] = useState("");
  const [liveURL, setLiveURL] = useState();
  const [conditionTwo, setConditionTwo] = useState("");
  const [conditionTwoValue, setConditionTwoValue] = useState("");
  const [payementGateway, setPaymentGateway] = useState("");

  // const [darshanamType, setDarshanamType] = useState(
  //   templeDetails?.configuration?.darshanamMultiTickets
  // );
  const [gopuramImage, setGopuramImage] = useState([]);
  const [loading, setLoading] = useState(false);

  const [active, setActive] = useState("temple-profile");
  const [razorpayKey, setRazorPayKey] = useState("");
  const [razorpaySecret, setRazorPaySecret] = useState("");
  const [razorpayMerchant, setRazorpayMerchant] = useState("");
  const [onlineData, setOnlineData] = useState("");
  const [fileList, setFileList] = useState([]);
  const [banner, setBanner] = useState([]);
  const [time, setTime] = useState("");

  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState();
  const [email, setEmail] = useState("");
  const [village, setVillage] = useState("");
  const [mandal, setMandal] = useState("");
  const [district, setDistrict] = useState("");
  const [domain, setDomain] = useState("");

  const handleOnlineSubmit = async () => {
    setLoading(true);

    if (conditionTwo !== "" || conditionTwo) {
      if (conditionTwoValue === "") {
        notification.error({ message: "Please provide condition two value" });
        setLoading(false);
        return;
      }
    }
    if (payementGateway === "") {
      notification.error({ message: "Please select a payment gateway" });
      setLoading(false);
      return;
    }
    if (razorpayKey === "" || !razorpayKey) {
      notification.error({
        message: `Please provide valid ${
          payementGateway === "razorpay key" ? "CC avnue Access Code" : ""
        }`,
      });
      setLoading(false);
      return;
    } else {
      if (razorpaySecret === "" || !razorpaySecret) {
        notification.error({
          message: `Please provide valid ${
            payementGateway === "razorpay"
              ? "razorpay key"
              : "CC Avenue Working key"
          } `,
        });
        setLoading(false);
        return;
      }
      if (razorpayMerchant === "" || !razorpayMerchant) {
        notification.error({
          message: "Please provide valid Merchant ID",
        });
        setLoading(false);
        return;
      }
      // }
    }

    try {
      let body = {
        feeType,
        feeValue,
        conditionOne,
        conditionOneValue,
        conditionTwo,
        conditionTwoValue,
        payementGateway,
      };

      body.razorpayKey = razorpayKey;
      body.razorpaySecret = razorpaySecret;
      body.merchantId = razorpayMerchant;

      if (!onlineData || onlineData === "") {
        await TmsTempleService.modifyTempleOnlineSettings(body);
      } else {
        await TmsTempleService.updateTempleOnlineSettings(body);
      }
      await handleOnlineTempleData();

      notification.success({ message: "Updated temple online configuration" });
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token has been expired!. Please login again.",
        router
      );
      setLoading(false);
    }
  };

  const handleOnlineTempleData = async () => {
    try {
      let res = await TmsTempleService.getOnlineTempleSettings();
      setOnlineData(res);
      if (res.configuration) {
        res.configuration.razorpay
          ? setPaymentGateway("razorpay")
          : setPaymentGateway("ccavenue");
        res.configuration.razorpay
          ? resetValues("razorpay", res.configuration.razorpay)
          : resetValues("ccavenue", res.configuration.ccavenue);

        let fee = res?.configuration?.fee[0];
        if (fee) {
          setFeeType(fee.type ?? "");
          fee.type
            ? fee.type === "fixed" && setFeeType(fee?.fixedValue)
            : setFeeType(fee?.percentageValue);
          setConditionOne(fee.condition ?? "");
          setConditionTwo(fee.condition2 ?? "");
          setConditionOneValue(fee.conditionValue ?? "");
          setConditionTwoValue(fee.condition2Value ?? "");
        }
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token has been expired!. Please login again.",
        router
      );
    }
  };

  const stateVariables = (data) => {
    if (data?.idolImage === "") {
      setFileList([]);
    } else {
      setFileList([{ url: data?.idolImage, status: "done" }]);
    }
    if (data?.gopuramImage === "") {
      setGopuramImage([]);
    }
    if (data?.templeBannerImage === "") {
      setBanner([]);
    } else {
      setBanner([{ url: data?.templeBannerImage, status: "done" }]);
    }
    setMandal(data?.mandal ?? "");
    setVillage(data?.village ?? "");
    setDistrict(data?.district ?? "");
    setLiveURL(data?.parokshaLiveStreamUrl ?? "");
    setTime(data?.templeTime);
    setAddress(data?.address);
    setCity(data?.city);
    setState(data?.state);
    setCountry(data?.country);
    setZip(data?.pinCode);
    setEmail(data?.email);
    setPhone(data?.contact?.replaceAll("+91", ""));
    setDomain(data?.devoteePortalDomain);
  };

  const getTempleDetails = async () => {
    setLoading(true);
    try {
      let res = await TmsTempleService.getTempleDetails(
        localStorage.getItem("templeId")
      );
      stateVariables(res?.devoteePortalConfiguration);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token has been expired!. Please login again.",
        router
      );
      setLoading(false);
    }
  };

  const handleTempleProfile = async () => {
    setLoading(true);
    if (phone === "") {
      notification.error({ message: "Enter Phone No." });
      setLoading(false);
      return;
    }
    if (phone.length !== 10) {
      notification.error({ message: "Phone number must be 10 digits" });
      // clearLoadings();
      setLoading(false);
      return;
    }
    if (address === "") {
      notification.error({ message: "Enter Address" });
      setLoading(false);
      return;
    }
    if (zip === "") {
      notification.error({ message: "Enter Pincode" });
      setLoading(false);
      return;
    }
    if (city === "") {
      notification.error({ message: "Enter City" });
      setLoading(false);
      return;
    }
    if (village === "") {
      notification.error({ message: "Enter Village" });
      setLoading(false);
      return;
    }
    if (mandal === "") {
      notification.error({ message: "Enter Mandal" });
      setLoading(false);
      return;
    }
    if (district === "") {
      notification.error({ message: "Enter District" });
      setLoading(false);
      return;
    }
    if (!state) {
      notification.error({ message: "Select State" });
      setLoading(false);
      return;
    }
    if (!country) {
      notification.error({ message: "Select Country" });
      setLoading(false);
      return;
    }
    if (email !== "") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase())) {
        notification.error({ message: "Invalid Email." });
        setLoading(false);
        // clearLoadings();
        return;
      }
    }

    let body = {
      idolImage: fileList[0].url,
      gopuramImage: gopuramImage[0].url,
      templeBannerImage: banner[0].url,
      templeTime: `${moment(time[0]).format("hh:mm A")} - ${moment(
        time[1]
      ).format("hh:mm A")}`,
      address: address,
      address2: "string",
      city: city,
      state: state,
      country: country,
      district: district,
      mandal: mandal,
      village: village,
      contact: `+91${phone}`,
      email: email,
      pinCode: Number(zip),
      devoteePortalDomain: domain,
      parokshaLiveStreamUrl: liveURL,
    };

    try {
      await TmsTempleService.templeProfile(body);
      notification.success({ message: "Updated temple profile" });
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token has been expired!. Please login again.",
        router
      );
      setLoading(false);
    }
  };

  const resetValues = (e, values) => {
    if (e === "razorpay") {
      setRazorPayKey(
        values ? values?.key : onlineData?.configuration?.razorpay?.key ?? ""
      );
      setRazorpayMerchant(
        values
          ? values?.merchantId
          : onlineData?.configuration?.razorpay?.merchantId ?? ""
      );
      setRazorPaySecret("");
    } else if (e === "ccavenue") {
      setRazorPayKey(
        values
          ? values?.workingKey
          : onlineData?.configuration?.ccavenue?.workingKey ?? ""
      );
      setRazorpayMerchant(
        values
          ? values?.merchantId
          : onlineData?.configuration?.ccavenue?.merchantId ?? ""
      );
      setRazorPaySecret("");
    } else {
      setRazorPayKey("");
      setRazorpayMerchant("");
      setRazorPaySecret("");
    }
  };

  useEffect(() => {
    active === "payment-settings" && handleOnlineTempleData();
    active === "temple-profile" && getTempleDetails();
  }, [active]);

  return (
    <div className="card-container">
      <Tabs
        type="card"
        activeKey={active}
        onChange={(e) => {
          setActive(e);
        }}
      >
        <TabPane tab={"Temple Profile"} key={"temple-profile"}>
          <Row gutter={[20, 20]}>
            <Col style={{ textAlign: "left" }}>
              <lable>Idol Image</lable>
              <br />
              <TmsImageUpload fileList={fileList} setFileList={setFileList} />
            </Col>
            <Col style={{ textAlign: "left" }}>
              <lable>Gopuram Image</lable>
              <br />
              <TmsImageUpload
                fileList={gopuramImage}
                setFileList={setGopuramImage}
              />
            </Col>
            <Col style={{ textAlign: "left" }}>
              <lable>Temple Banner</lable>
              <br />
              <TmsImageUpload fileList={banner} setFileList={setBanner} />
            </Col>

            <Col span={5} style={{ textAlign: "left" }}>
              <lable>Temple Time</lable>
              <br />

              <Input
                type="text"
                placeholder="Start Time - End TIme"
                value={time}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
              />
            </Col>
            {/* <Col span={5} style={{ textAlign: "left" }}>
              <lable>Devotee Domain Portal</lable>
              <br />
              <Input
                type="text"
                placeholder="Domain"
                value={domain}
                onChange={(e) => {
                  setDomain(e.target.value);
                }}
              />
            </Col> */}
            {/* <Col span={5} style={{ textAlign: "left" }}>
              <lable>Live Seva URL</lable>
              <br />
              <Input
                type="text"
                placeholder="Live Seva URL"
                value={liveURL}
                onChange={(e) => {
                  setLiveURL(e.target.value);
                }}
              />
            </Col> */}
          </Row>
          <br />
          <h3>Temple Address</h3>
          <Divider />
          <Row gutter={[20, 20]} style={{ textAlign: "left" }}>
            <Col span={6}>
              <label>Address</label>
              <Input
                type="text"
                placeholder="Enter address"
                value={address}
                onKeyDown={(e) =>
                  ["#", "+"].includes(e.key) && e.preventDefault()
                }
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>City</label>
              <Input
                type="text"
                placeholder="Enter City"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>Village</label>
              <Input
                type="text"
                placeholder="Enter Village"
                value={village}
                onChange={(e) => {
                  setVillage(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>Mandal</label>
              <Input
                type="text"
                placeholder="Enter Mandal"
                value={mandal}
                onChange={(e) => {
                  setMandal(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>District</label>
              <Input
                type="text"
                placeholder="Enter District"
                value={district}
                onChange={(e) => {
                  setDistrict(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>State</label>
              <RegionDropdown
                className="CountryRegionDropDown"
                style={{
                  border: "1px solid #d9d9d9",
                  height: "32px",
                  fontSize: "14px",
                  padding: "0px 8px",
                  width: "100%",
                }}
                country={country}
                value={state}
                showDefaultOption={true}
                blankOptionLabel="Select a State"
                onChange={(val) => {
                  setState(val);
                }}
              />
            </Col>
            <Col span={6}>
              <label>Country</label>
              <CountryDropdown
                className="CountryRegionDropDown"
                style={{
                  border: "1px solid #d9d9d9",
                  height: "32px",
                  fontSize: "14px",
                  padding: "0px 8px",
                  width: "100%",
                }}
                value={country}
                onChange={(val) => {
                  setCountry(val);
                }}
              />
            </Col>
            <Col span={6}>
              <label>Pincode</label>
              <Input
                type="number"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                placeholder="Enter Pincode"
                value={zip}
                onChange={(e) => {
                  if (e.target.value.length > 6) {
                    return;
                  }
                  setZip(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>Email</label>
              <Input
                type="email"
                placeholder="Enter Email ID"
                maxLength="30"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>Contact</label>
              <Input
                size="medium"
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
                maxLength={10}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                addonBefore={"+91"}
                placeholder="Please Enter Mobile Number"
                style={{ width: "100%" }}
                value={phone}
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                    return;
                  }
                  setPhone(`${e.target.value}`);
                }}
              />
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Button
              type="primary"
              style={{ width: "100%" }}
              loading={loading}
              onClick={() => handleTempleProfile()}
            >
              Submit
            </Button>
          </Row>
        </TabPane>
        <TabPane tab={"Payment settings"} key={"payment-settings"}>
          <h3>Payment settings</h3>
          <Row justify="start" gutter={[20, 20]}>
            <Col span={6}>
              <Row justify="start">Payment Gateway</Row>
              <Row>
                <Select
                  className="formInput"
                  onChange={(e) => {
                    setPaymentGateway(e);
                    resetValues(e);
                  }}
                  value={payementGateway}
                  defaultValue={payementGateway}
                >
                  <Select.Option value="ccavenue">CC Avenue</Select.Option>
                  <Select.Option value="razorpay">Razorpay</Select.Option>
                </Select>
              </Row>
            </Col>
          </Row>
          <br />
          {payementGateway === "" ? (
            ""
          ) : (
            <Row justify="start" gutter={[20, 20]}>
              <Col span={6}>
                <Row justify="start">
                  {payementGateway === "razorpay"
                    ? "Razorpay Key"
                    : "CC Avenue Access Code"}{" "}
                </Row>
                <Row>
                  <Input
                    placeholder={
                      payementGateway === "razorpay"
                        ? "Razorpay Key"
                        : "CC Avenue Access Code"
                    }
                    value={razorpayKey}
                    onChange={(e) => {
                      setRazorPayKey(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">
                  {payementGateway === "razorpay"
                    ? "Razorpay Merchant ID"
                    : "CC Avenue Merchant ID"}{" "}
                </Row>
                <Row>
                  <Input
                    placeholder={
                      payementGateway === "razorpay"
                        ? "Razorpay Merchant ID"
                        : "CC Avenue Merchant ID"
                    }
                    value={razorpayMerchant}
                    onChange={(e) => {
                      setRazorpayMerchant(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">
                  {payementGateway === "razorpay"
                    ? "Razorpay Secret"
                    : "CC avenue Working Key"}
                </Row>
                <Row>
                  <Input
                    placeholder={
                      payementGateway === "razorpay"
                        ? "Razorpay Secret"
                        : "CC avenue Working Key"
                    }
                    value={razorpaySecret}
                    onChange={(e) => {
                      setRazorPaySecret(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </Row>
              </Col>
            </Row>
          )}
          <Divider />
          <h3>Online ticket/Handling Fee</h3>
          <Row>
            <Col span={6}>
              <Row>Handling Fee Type</Row>
              <Row>
                <Select
                  className="formInput"
                  onChange={(e) => {
                    setFeeType(e);
                  }}
                  value={feeType}
                  defaultValue={feeType}
                  // disabled={editFlag ? true : false}
                >
                  <Select.Option value="percentage">Percentage</Select.Option>
                  <Select.Option value="fixed">Fixed</Select.Option>
                </Select>
              </Row>
            </Col>
            {feeType && (
              <Col span={6}>
                <Row justify="start" style={{ textTransform: "capitalize" }}>
                  {feeType} Value
                </Row>
                <Row>
                  <Input
                    placeholder={`Enter ${feeType} Value`}
                    value={feeValue}
                    onChange={(e) => {
                      setFeeValue(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </Row>
              </Col>
            )}
          </Row>
          {feeType && (
            <>
              <Divider />
              <h3>Fee conditions/limits</h3>
              <Row>
                <>
                  <Col span={6}>
                    <Row>Condition-1 Type</Row>
                    <Row>
                      <Select
                        className="formInput"
                        onChange={(e) => {
                          setConditionOne(e);
                        }}
                        value={conditionOne}
                        defaultValue={conditionOne}
                        // disabled={editFlag ? true : false}
                      >
                        <Select.Option value="greaterThan">
                          greater Than{`(>)`}
                        </Select.Option>
                        <Select.Option value="lessThan">
                          Less Than {`(<)`}
                        </Select.Option>
                        <Select.Option value="greaterThanOrEqual">
                          GreaterThan Or Equal{`(>=)`}
                        </Select.Option>
                        <Select.Option value="lessThanOrEqual ">
                          LessThan Or Equal {`(<=)`}{" "}
                        </Select.Option>
                      </Select>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row justify="start"> Condition One value</Row>
                    <Row>
                      <Input
                        placeholder={`Condition Value`}
                        value={conditionOneValue}
                        onChange={(e) => {
                          setConditionOneValue(e.target.value);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Row>
                  </Col>
                </>
              </Row>
              <br />
              <Row>
                <>
                  <Col span={6}>
                    <Row>Condition-2 Type</Row>
                    <Row>
                      <Select
                        className="formInput"
                        onChange={(e) => {
                          setConditionTwo(e);
                        }}
                        value={conditionTwo}
                        defaultValue={conditionTwo}
                        // disabled={editFlag ? true : false}
                      >
                        <Select.Option value="greaterThan">
                          Greater Than{`(>)`}
                        </Select.Option>
                        <Select.Option value="lessThan">
                          Less Than {`(<)`}
                        </Select.Option>
                        <Select.Option value="greaterThanOrEqual">
                          GreaterThan Or Equal{`(>=)`}
                        </Select.Option>
                        <Select.Option value="lessThanOrEqual ">
                          LessThan Or Equal {`(<=)`}{" "}
                        </Select.Option>
                      </Select>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row justify="start">Condition One value</Row>
                    <Row>
                      <Input
                        placeholder={`Condition Value`}
                        value={conditionTwoValue}
                        onChange={(e) => {
                          setConditionTwoValue(e.target.value);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Row>
                  </Col>
                </>
              </Row>{" "}
            </>
          )}
          <Row>
            {" "}
            <Col span={4}>
              <Button
                loading={loading}
                type="primary"
                style={{ width: "100%", marginTop: "22px" }}
                onClick={handleOnlineSubmit}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </TabPane>
      </Tabs>{" "}
    </div>
  );
};
export default withRouter(TmsTempleSettings);
