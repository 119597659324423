import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";

const TmsThulabharamService = {
  getThulabharam: async (page, limit) => {
    const resp = await Axios.get(
      `v1/thulabharam/all?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createThulabharam: async (body) => {
    const resp = await Axios.post(`v1/thulabharam/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  deleteThulabharam: async (productId) => {
    const resp = await Axios.delete(`v1/thulabharam/delete/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  viewThulabharam: async (productId) => {
    const resp = await Axios.get(`v1/thulabharam/by-id/${productId}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editThulabharam: async (productId, body) => {
    const resp = await Axios.put(`v1/thulabharam/update/${productId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  updateTerms: async (productId, termsData) => {
    const resp = await Axios.patch(
      `v1/thulabharam/metadata/${productId}`,
      {
        termsAndConditions: termsData,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //darshanam-counters
  getThulabharamCounters: async (id) => {
    const resp = await Axios.get(`v1/thulabharam-counter/by-product/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  createThulabharamCounters: async (body) => {
    const resp = await Axios.post(`v1/thulabharam-counter/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editThulabharamCounters: async (body, slotId) => {
    const resp = await Axios.put(
      `v1/thulabharam-counter/update/${slotId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteThulabharamCounters: async (slotId) => {
    const resp = await Axios.delete(`v1/thulabharam-counter/delete/${slotId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  //Thulabharam booking

  fetchThulabharamBookingSlots: async () => {
    const resp = await Axios.get(
      `v1/thulabharam-counter-booking/valid-counter-slots`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  thulabharamBooking: async (body) => {
    const resp = await Axios.post(
      `v1/counter-cart/new`,
      {
        thulabharamBookings: body?.thulabharamItems,
        multiTicketsLinked: true,
        paymentType: "CASH",
        cashRemarks: "string",
        cashReference: "string",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  thulabharamBookingDetails: async (
    format,
    fromDate,
    toDate,
    dateType,
    sortType,
    page,
    limit,
    selectedThulabharamId
  ) => {
    let url = `v1/thulabharam-counter-booking/reports/${format}?page=${page}&limit=${limit}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${sortType}`;
    if (selectedThulabharamId && selectedThulabharamId !== "") {
      url += `&productId=${Number(selectedThulabharamId)}`;
    }
    const resp = await ReportsAxios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  thulabharamReport: async (
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    counter,
    shift,
    prasadamType
  ) => {
    let query = `v1/thulabharam-counter-report/booking/${format}?page=${tableCurrentPage}&limit=${tablePageSize}&bookingFromDate=${fromDate}&bookingToDate=${toDate}`;

    if (counter && counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }

    if (prasadamType && prasadamType !== "") {
      query += `&productId=${prasadamType}`;
    }
    const resp = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getConsolidateReport: async (format, productId, from, to) => {
    let query = `v1/thulabharam-counter-report/consolidated-ticket-collection-for-product/${format}?bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }

    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
  getOnlineConsolidateReport: async (format, productId, from, to) => {
    let query = `v1/thulabharam-online-report/consolidated-ticket-collection-for-product/${format}?bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }

    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
};

export default TmsThulabharamService;
