import React, { useState, useEffect, useRef } from "react";
import {
  DatePicker,
  Row,
  Divider,
  Col,
  Card,
  Button,
  Modal,
  Radio,
  Input,
  notification,
} from "antd";
import moment from "moment";
import "../../../assets/css/tms-darshanam/tms-pos-darshanam.scss";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import { RightOutlined } from "@ant-design/icons";
import TmsDarshanamService from "../../../services/tms-darshanam/tms-darshanam.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import TmsStringUtils from "../../../utils/tms-string-utils/tms-string-utils";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import {
  dragEnter,
  dragStart,
  drop,
  updateData,
} from "../../../utils/tms-card-dragger/tms-card-dragger";

const TmsPosDarshanam = ({ router }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [bookingDate, setBookingDate] = useState(moment());
  const [darshanamList, setDarshanamList] = useState([]);
  const [persons, setPersons] = useState("");
  const [price, setPrice] = useState();
  const [ticketPrice, setTicketPrice] = useState();
  const [darshanamId, setDarshanamId] = useState();
  const [amountpaid, setAmountPaid] = useState(0);
  const [externalPrint, setExternalPrint] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [array, setArray] = useState([]);
  const [updateFlag, setUpdateFlag] = useState();
  const [ids, setIds] = useState([]);

  const ref = useRef(null);

  const dragItem = useRef();
  const dragOverItem = useRef();

  const darshanamData = (data) => {
    let schemaBody = [];
    data?.cartItems?.darshanam?.forEach((item) => {
      const generalBody = [
        [
          "Date",
          moment(item?.darshanamBooking?.bookingDate).format("DD-MM-YYYY"),
          { margin: "0" },
        ],
        [
          "Time Slot",
          `${moment(
            item?.productDetails?.productSlotStartTime,
            "HH:mm:ss"
          ).format("hh:mm A")} -
          ${moment(item?.productDetails?.productSlotEndTime, "HH:mm:ss").format(
            "hh:mm A"
          )}`,
          { margin: "0" },
        ],
        [
          "Devotee Details",
          item?.darshanamBooking?.refererDetails
            ? JSON.parse(item?.darshanamBooking?.refererDetails).map((item) => {
                return `${item.devoteeName}-${
                  item.idProofType
                }-${item.idProofNumber?.substring(
                  item.idProofNumber?.length - 4,
                  item.idProofNumber?.length
                )}`;
              })
            : "null",
        ],
        [
          "Ticket Count",
          item?.darshanamBooking?.noOfTickets,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "Ticket Price",
          `Rs. ${Number(item?.productDetails?.productActualPrice)}.00`,
          { margin: "0" },
        ],
        [
          "Total Amount",
          `Rs. ${item?.darshanamBooking?.price}.00 `,
          { margin: "0", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(item?.darshanamBooking?.price)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          item?.productDetails?.productName,
          `<p style="margin:0">TI No:${item?.counterCartItem?.categoryTransactionSequence
            ?.split("-")
            ?.pop()}</p><p style="margin:0">S No:${item?.counterCartItem?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop()}</p><p style="margin:0">${moment(
            item?.darshanamBooking?.bookingDate
          ).format(
            "DD-MM-YYYY"
          )}</p><p style="font-weight:bold;font-size:22px;margin:0">Rs. ${
            item?.darshanamBooking?.price
          }.00</p> `,
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.counterCartItem?.createdAt,
        ],
      ];
      const miniTicketBody = [
        [
          "Total Amount",
          `Rs. ${item?.darshanamBooking?.price}.00 `,
          { margin: "0", fontWeight: "800" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          null,
          item?.counterCartItem?.createdAt,
        ],
      ];
      const basicBody = [
        [
          "header",
          item?.counterCartItem?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.counterCartItem?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop(),
          item?.counterCartItem?.ticketId,
        ],
        [
          "Ticket Id",
          item?.productDetails?.productMetadata?.ticketType === "mini_ticket"
            ? item?.counterCartItem?.ticketId?.split("-")?.pop()
            : item?.counterCartItem?.ticketId,
          { margin: "0" },
        ],
        [
          "Darshanam",
          item?.productDetails?.productName,
          { margin: "0", fontWeight: "bold" },
        ],
      ];
      if (item?.productDetails?.productMetadata?.ticketType === "mini_ticket") {
        schemaBody = [...schemaBody, ...basicBody, ...miniTicketBody];
      } else {
        schemaBody = [...schemaBody, ...basicBody, ...generalBody];
      }
    });
    setArray([...schemaBody]);
  };

  const fetchDarshanam = async () => {
    document.getElementById("searchable")?.focus();

    if (persons) {
      setButtonLoading(true);
      try {
        setDarshanamList([]);
        let id = [];
        let dragList = [];
        let res = await TmsDarshanamService.fetchDarshanamBookingSlots(persons);
        res?.map((item) => {
          id = [...id, item?.product.id];
          dragList = [
            ...dragList,
            {
              id: item?.product?.id,
              availableBookingCount: item?.availableBookingCount,
              productSlotId: item?.productSlotId,
              startTime: item?.startTime,
              endTime: item?.endTime,
              name: item?.product?.name,
              price: item?.product?.price,
            },
          ];
        });

        setIds(id);
        updateData(dragList, setDarshanamList, "darshanamList");
        selectSlot(
          JSON.parse(localStorage.getItem("darshanamList"))[0]?.price,
          JSON.parse(localStorage.getItem("darshanamList"))[0]?.productSlotId
        );
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
      }
      setButtonLoading(false);
    }
  };

  const darshanamBooking = async () => {
    if (buttonLoading) {
      return;
    }
    setButtonLoading(true);
    if (persons === "") {
      notification.info({
        message: "No of Persons cannot be empty!!",
      });
      setButtonLoading(false);
      return;
    }
    try {
      let body = [];
      for (var i = 1; i <= persons; i++) {
        body.push({ darshanamSlotId: darshanamId, noOfTickets: Number(1) });
      }

      const data = await TmsDarshanamService.darshanamBooking(body);
      darshanamData(data);
      setIsModalVisible(true);
      setExternalPrint(true);
      setPersons("");
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    fetchDarshanam();
    if (persons && ticketPrice) {
      setPrice();
    }
  }, [bookingDate, persons, updateFlag]);

  const selectSlot = (ticketPrice, darshanamSlotId) => {
    console.log(ticketPrice, darshanamSlotId);
    setDarshanamId(darshanamSlotId);
    setTicketPrice(ticketPrice);
    setPrice(ticketPrice * persons);
    ref?.current?.focus();
    ref?.current?.focus();
  };

  return (
    <div className="darshanamBody">
      <Card className="darshanamBodyCard">
        {" "}
        <Row justify="center" className="darshanamTitle">
          Darshanam
        </Row>
        <Divider />
        <Row gutter={(20, 20)} style={{ justifyContent: "center" }}>
          <Col style={{ width: "30%" }}>
            <Row className="darshanamBookingDate">Booking Date</Row>
            <Row style={{ marginBottom: "20px" }}>
              <DatePicker
                disabled={true}
                size="medium"
                allowClear
                defaultPickerValue={moment()}
                format="DD-MM-YYYY"
                value={bookingDate}
                onChange={(e) => {
                  setBookingDate(e);
                  ref?.current?.focus();
                }}
                disabledDate={(d) =>
                  !d || d.isBefore(TmsStringUtils.addDays(new Date(), -1))
                }
                style={{ width: "100%" }}
              />
            </Row>
          </Col>
          <Col style={{ width: "30%" }}>
            <Row className="darshanamTickets">
              No. of Tickets <span style={{ color: "red" }}>*</span>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Input
                autoFocus
                placeholder="Enter No. of Persons"
                value={persons}
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) => {
                  if (e.target.value > 10) {
                    return;
                  } else {
                    setPersons(e.target.value);
                  }
                }}
                style={{ width: "100%", textAlign: "left" }}
                size="medium"
                type="number"
                min="0"
                max="10"
                maxLength="1"
                onInput={(object) => {
                  if (object.target.value.length > object.target.maxLength) {
                    object.target.value = object.target.value.slice(
                      0,
                      object.target.maxLength
                    );
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    buttonLoading === false && darshanamBooking();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault();
                }}
              />
            </Row>
          </Col>
          {/* <Col span={6}>
            <Row className="darshanamBookingDate">Referer Name</Row>
            <Row style={{ marginBottom: "20px" }}>
              {" "}
              <Input
                size="medium"
                type="text"
                placeholder="Enter referer name"
                style={{ width: "100%" }}
                value={referer}
                onChange={(e) => {
                  setReferer(`${e.target.value}`);
                }}
              />
            </Row>
          </Col>
          <Col span={6}>
            <Row className="darshanamBookingDate">Referer Department</Row>

            <Row style={{ marginBottom: "20px" }}>
              {" "}
              <Input
                size="medium"
                type="text"
                placeholder="Enter referer department"
                style={{ width: "100%" }}
                value={refererDepartment}
                onChange={(e) => {
                  setRefererDepatment(`${e.target.value}`);
                }}
              />
            </Row>
          </Col> */}
        </Row>
        <Divider />
        {/* {slotName?.toLowerCase()?.replaceAll(" ", "") ===
          "vipbreakdarshanam" && (
          <>
            <Row justify="center">
              <h3>Devotee Details</h3>
            </Row>
            <br />
            {devoteeData?.map((item, i) => {
              return (
                <>
                  <Row gutter={(20, 20)} style={{ justifyContent: "center" }}>
                    <Col span={8}>
                      <Row className="darshanamBookingDate">
                        {i + 1}.Devotee Name{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Row>

                      <Row style={{ marginBottom: "20px" }}>
                        {" "}
                        <Input
                          size="medium"
                          type="text"
                          placeholder="Devotee Name"
                          style={{ width: "100%" }}
                          value={item.devoteeName}
                          onChange={(e) => {
                            handleChange(i, e, "devoteeName");
                          }}
                        />
                      </Row>
                    </Col>

                    <Col span={8}>
                      <Row className="darshanamBookingDate">
                        {i + 1}.Select ID Proof
                        <span style={{ color: "red" }}>*</span>
                      </Row>
                      <Row>
                        {" "}
                        <Select
                          size="medium"
                          style={{ width: "100%" }}
                          placeholder="Select a ID"
                          optionFilterProp="children"
                          value={item.idProofType}
                          onChange={(e) => {
                            handleChange(i, e, "idProofType");
                          }}
                        >
                          <Select.Option key="pan" value="pan">
                            PAN
                          </Select.Option>
                          <Select.Option key="aadhar" value="aadhar">
                            AADHAR
                          </Select.Option>

                          <Select.Option key="voter" value="voter">
                            Voter ID
                          </Select.Option>
                        </Select>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row className="darshanamBookingDate">
                        {i + 1}.ID Proof Number
                        <span style={{ color: "red" }}>*</span>
                      </Row>

                      <Row style={{ marginBottom: "20px" }}>
                        {" "}
                        <Input
                          size="medium"
                          type="text"
                          placeholder="Enter ID Proof"
                          style={{ width: "100%" }}
                          value={item.idProofNumber}
                          onChange={(e) => {
                            handleChange(i, e, "idProofNumber");
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                </>
              );
            })}
            <Divider />
          </>
        )} */}
        <Row>
          <Col xs={24} sm={24} md={12} lg={16} xl={16} xsl={16}>
            {darshanamList?.length !== 0 && persons ? (
              <div style={{ marginBottom: "20px" }}>
                <Row>
                  <h1 className="resultsTitle">Results</h1>
                </Row>
                <Row gutter={[20, 20]} className="resultsRow">
                  {darshanamList
                    ?.filter((item) => ids?.includes?.(item?.id))
                    ?.map((item, i) => {
                      if (1 > item?.availableBookingCount) {
                        // eslint-disable-next-line array-callback-return
                        return;
                      } else
                        return (
                          <Col
                            style={{
                              float: "left",
                            }}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xsl={12}
                          >
                            <Card
                              draggable
                              onDragStart={(e) => dragStart(e, i, dragItem)}
                              onDragEnter={(e) => dragEnter(e, i, dragOverItem)}
                              onDragEnd={(e) =>
                                drop(
                                  e,
                                  setUpdateFlag,
                                  dragItem,
                                  dragOverItem,
                                  "darshanamList",
                                  ids
                                )
                              }
                              className="darshanamCard"
                              title={
                                <Row>
                                  <Col
                                    style={{ textAlign: "left", width: "80%" }}
                                  >
                                    {item?.name}
                                  </Col>
                                  <Col
                                    style={{ textAlign: "right", width: "20%" }}
                                  >
                                    <Radio
                                      defaultChecked={false}
                                      value={item?.productSlotId}
                                      checked={
                                        item?.productSlotId === darshanamId
                                          ? true
                                          : false
                                      }
                                      id={item?.productSlotId}
                                      onClick={() => {
                                        if (1 > item?.availableBookingCount) {
                                          return;
                                        }
                                        selectSlot(
                                          item?.price,
                                          item?.productSlotId
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              }
                              hoverable
                              onClick={() =>
                                selectSlot(item?.price, item?.productSlotId)
                              }
                            >
                              <Row>
                                <Col
                                  style={{
                                    width: "70%",
                                    borderRight: "2px solid #f0f2f5",
                                  }}
                                >
                                  <Row className="timeStamp">
                                    {moment(item?.startTime, "HH:mm:ss").format(
                                      "hh:mm A"
                                    )}{" "}
                                    -{" "}
                                    {moment(item?.endTime, "HH:mm:ss").format(
                                      "hh:mm A"
                                    )}
                                  </Row>
                                  <Row style={{ fontSize: "15px" }}>
                                    <b>Rs. {item?.price}</b>
                                  </Row>
                                </Col>
                                <Col className="ticketCount">
                                  {item?.availableBookingCount ?? 0}
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        );
                    })}
                </Row>
              </div>
            ) : (
              <>
                {bookingDate && !buttonLoading ? (
                  <>
                    <div
                      style={{
                        position: "relative",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {persons
                        ? `No Slots available on ${moment(bookingDate).format(
                            "DD-MM-YYYY"
                          )}`
                        : "Enter No. of Tickets"}
                    </div>
                  </>
                ) : (
                  bookingDate && (
                    <>
                      <div style={{ position: "relative", textAlign: "left" }}>
                        Fetching slots...Please wait{" "}
                      </div>
                    </>
                  )
                )}
              </>
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xsl={8}>
            <Divider type="vertical" />
            {price >= 0 && persons > 0 && (
              <>
                <Row>
                  <h1 className="paymentTitle">Payment Details</h1>
                </Row>
                <Row>
                  <Col
                    style={{
                      width: "25%",
                      textAlign: "left",
                    }}
                  >
                    Price :
                  </Col>
                  <Col style={{ width: "75%", textAlign: "right" }}>
                    Rs. {ticketPrice}
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      width: "25%",
                      textAlign: "left",
                    }}
                  >
                    No. of Tickets
                  </Col>
                  <Col style={{ width: "75%", textAlign: "right" }}>
                    {persons}
                  </Col>
                </Row>
                <Row>
                  <Col className="totalAmountTitle">Total Amount :</Col>
                  <Col className="totalAmount">Rs. {price}</Col>
                </Row>
                <Divider />
                <Row>
                  <Col
                    style={{
                      width: "25%",
                      textAlign: "left",
                    }}
                  >
                    Amount Paid:
                  </Col>
                  <Col
                    style={{
                      width: "75%",
                      textAlign: "right",
                      fontWeight: "600",
                    }}
                  >
                    <Input
                      style={{ width: "150px", textAlign: "right" }}
                      type="number"
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        setAmountPaid(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px", marginBottom: "50px" }}>
                  <Col
                    style={{
                      width: "25%",
                      textAlign: "left",
                    }}
                  >
                    Amount to be Returned:
                  </Col>
                  <Col
                    style={{
                      width: "75%",
                      textAlign: "right",
                      fontSize: "22px",
                      fontWeight: "600",
                    }}
                  >
                    Rs. {amountpaid - price > 0 ? amountpaid - price : 0}
                  </Col>
                </Row>
                <br />
                <Row gutter={[10, 10]} className="paymentDetailsButton">
                  <Col>
                    <Button
                      ref={ref}
                      type="primary"
                      onClick={() => {
                        buttonLoading === false && darshanamBooking();
                      }}
                      loading={buttonLoading}
                    >
                      Book Now <RightOutlined />
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        <Divider />
        <Row gutter={(20, 20)} style={{ justifyContent: "center" }}></Row>
        <Row>
          <Modal
            visible={isModalVisible}
            width={450}
            footer={null}
            closable={false}
            centered
            maskClosable={false}
          >
            <TmsPrintTicket
              data={array}
              setData={setArray}
              type="darshanam"
              from="Counter"
              externalPrint={externalPrint}
              setExternalPrint={setExternalPrint}
              setButtonLoading={setButtonLoading}
              setPrintTicketModal={setIsModalVisible}
            />
          </Modal>
        </Row>
      </Card>
    </div>
  );
};

export default withRouter(TmsPosDarshanam);
