import Axios from "../../utils/tms-axios/tms-axios";

const TmsUserService = {
  getUsers: async () => {
    const resp = await Axios.get(`v1/user-permission/get-users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  manageUserRole: async (role, phone) => {
    phone = "+91" + phone;
    const resp = await Axios.post(
      `v1/user/managment/user/role`,
      {
        role,
        phone,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  getUserPermissionsById: async (userId) => {
    const resp = await Axios.get(`v1/user-permission/by-admin/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  updatePermissions: async (userRolesData, userIdorPhone, isPhone) => {
    const body = { permissions: userRolesData };
    if (isPhone) {
      body.phone = userIdorPhone;
    } else {
      body.userId = userIdorPhone;
    }
    const resp = await Axios.post(`v1/user-permission/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  userDetails: async (queryBy, value) => {
    const params = {};
    params[queryBy] = value;
    const resp = await Axios.get(`v1/user/admin/user/details?`, {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  adminCreateUser: async (phone, displayName, password, templeId) => {
    phone = "+91" + phone;
    const resp = await Axios.post(
      `v1/user/admin/create`,
      {
        phone,
        displayName,
        password,
        templeId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  userOrderStats: async (userId) => {
    const resp = await Axios.get(
      `v1/user-report/order/stats?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  userPaymentStats: async (userId) => {
    const resp = await Axios.get(`v1/user-report/payments?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  userAuthLogs: async (phone, page, pageSize) => {
    const resp = await Axios.get(`v1/user-report/logs/auth`, {
      params: {
        phone,
        page,
        limit: pageSize,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  userActionLogs: async (userId, page, pageSize) => {
    const resp = await Axios.get(
      `v1/user-report/logs/actions?userId=${userId}&page=${page}&limit=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  userPermissions: async () => {
    const resp = await Axios.get(
      `v1/user-permission/get-available-permissions`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  deleteUser: async (userId) => {
    const resp = await Axios.delete(`v1/user-permission/delete/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  forgotPassword: async (phone, otp, password) => {
    phone = "+91" + phone;
    const resp = await Axios.post(
      `v1/user/password/forgot`,
      {
        otp: Number(otp),
        phone: phone,
        password: password,
        templeId: 1,
      },
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  checkUserRegistered: async (phone) => {
    let res = Axios.get(
      `https://d-api.9and9.supercharged.dev/api/v1/user/is-registered?phone=%2B91${phone}`,
      {
        headers: {
          "X-Temple-Id": 1,
        },
      }
    );
    return res;
  },
};

export default TmsUserService;
