import { Button, Card, Checkbox, Col, Input, notification, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import TmsKalayanakattaService from "../../../../../services/tms-kalayanakatta.service";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import { BaseContext } from "../../../../../contexts/BaseContext";
import TmsImageUpload from "../../../../../components/tms-customs/tms-image-upload";
// import TmsImageUpload from "../../../../../components/tms-customs/tms-image-upload";

const TmsKalayanakattaInnerPagesAND = ({
  viewKalyanakattaData,
  editFlag,
  productId,
  setEditFlag,
  settmsModalVisible,
  router,
}) => {
  const { userDetails } = useContext(BaseContext);
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setdescription] = useState("");
  const [counterEnabled, setcounterEnabled] = useState(false);
  const [onlineEnabled, setonlineEnabled] = useState(false);
  const [price, setprice] = useState("");
  const [changeRequestFile, setChangeRequestFile] = useState([]);
  const [changeRequestDesc, setChangeRequestDesc] = useState("");
  // const [fileList, setFileList] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    if (
      name === "" ||
      description === "" ||
      counterEnabled === "" ||
      onlineEnabled === "" ||
      price === ""
    ) {
      notification.error({ message: "Please enter all the details" });
      setLoading(false);
      return;
    }
    if (editFlag) {
      if (changeRequestFile?.length === 0 || changeRequestDesc === "") {
        notification.error({ message: "Please enter all the details" });
        setLoading(false);

        return;
      }
    }
    let body = {
      name: name,
      description: description,
      counterEnabled: counterEnabled,
      onlineEnabled: onlineEnabled,
      price: price,
    };
    if (editFlag) {
      body.changeRequestPdfUrl = changeRequestFile[0].url;
      body.changeDescription = changeRequestDesc;
    }
    try {
      if (editFlag) {
        await TmsKalayanakattaService.editKalyanakatta(productId, body);
        notification.success({ message: "Kalyanakatta Edited Successful" });
        setEditFlag(false);
      } else {
        await TmsKalayanakattaService.createKalayanakatta(body);
        notification.success({ message: "Kalyanakatta Created Successful" });
        handleFuction();
      }
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleFuction = () => {
    setName("");
    setdescription("");
    setcounterEnabled(false);
    setonlineEnabled(false);
    setprice();
    setChangeRequestFile([]);
    setChangeRequestDesc("");
  };

  useEffect(() => {
    if (editFlag) {
      setName(viewKalyanakattaData?.name);
      setdescription(viewKalyanakattaData?.description);
      setcounterEnabled(viewKalyanakattaData?.counterEnabled);
      setonlineEnabled(viewKalyanakattaData?.onlineEnabled);
      setprice(viewKalyanakattaData?.price);
    }
  }, [viewKalyanakattaData]);

  return (
    <Row className="formRow" style={{ width: "100%" }}>
      <Card style={{ width: "100%" }}>
        <Row className="formRow">
          <label className="formLabel">Kalyanakatta Name</label>
          <Input
            className="formInput"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Row>

        <Row className="formRow">
          <label className="formLabel">Description</label>
          <TextArea
            className="formInput"
            rows={4}
            value={description}
            onChange={(e) => setdescription(e.target.value)}
          />
        </Row>

        <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel">Price</label>
          <Col className="formCols-2">
            <Input
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={price}
              onChange={(e) => setprice(Number(e.target.value))}
            />
          </Col>
          <Col className="formCols-2 contantCenter">
            <label style={{ paddingRight: "10px" }}>Enable POS</label>
            <Checkbox
              style={{ paddingRight: "10px" }}
              checked={counterEnabled}
              onChange={(e) => setcounterEnabled(e.target.checked)}
            />
            <label style={{ paddingRight: "10px" }}>Enable Online</label>
            <Checkbox
              checked={onlineEnabled}
              onChange={(e) => setonlineEnabled(e.target.checked)}
            />
          </Col>
        </Row>
        {editFlag && (
          <>
            <Row gutter={[20, 20]} className="formRow">
              <label className="formLabel">Request Copy</label>
              <Col className="formInput ">
                <TmsImageUpload
                  fileList={changeRequestFile}
                  setFileList={setChangeRequestFile}
                  router={router}
                  type="private"
                  accept={true}
                  className="w-100"
                />
              </Col>
            </Row>
            <Row className="formRow">
              <label className="formLabel">Edit Description</label>
              <Input
                className="formInput"
                value={changeRequestDesc}
                onChange={(e) => setChangeRequestDesc(e.target.value)}
              />
            </Row>
          </>
        )}
        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              ghost
              loading={loading}
              onClick={() => {
                editFlag ? settmsModalVisible(false) : handleFuction();
              }}
            >
              {editFlag ? "Return" : "Clear"}
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              loading={loading}
              onClick={() => {
                fetchData();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsKalayanakattaInnerPagesAND);
