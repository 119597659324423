import { Row } from "antd";
import React, { useContext } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import checkPermissions from "../../../utils/tms-permissions/tms-permissions";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import "../../../assets/css/tms-custom-elements/tms-custom-elements.scss";
import TmsServiceCardMap from "../../../constants/tms-service.map";
import TmsDisplayCard from "../../../components/tms-customs/tms-cards";
const TmsServiceManagementHome = ({ router }) => {
  const { userDetails } = useContext(BaseContext);

  return (
    <Row gutter={[20, 20]}>
      {TmsServiceCardMap.map((card, i) => {
        return checkPermissions(userDetails, card) ? (
          <TmsDisplayCard
            label="service-management"
            card={card}
            router={router}
            i={i + `sm`}
          />
        ) : (
          ""
        );
      })}
    </Row>
  );
};
export default withRouter(TmsServiceManagementHome);
