import { DatePicker, Row } from "antd";
import React from "react";
import moment from "moment";
const TmsDatePickerRange = ({
  className,
  label,
  to,
  from,
  setFrom,
  setTo,
  disabled,
}) => {
  return (
    <Row justify="start" style={{ flexDirection: "column" }}>
      {label !== "" && (
        <div
          style={{
            marginBottom: label === "Select Batch Dates" ? "3px" : "5px",
            textAlign: "left",
          }}
        >
          {label}
        </div>
      )}

      <DatePicker.RangePicker
        className={className}
        format="DD-MM-YYYY"
        onChange={(e) => {
          if (e?.[0] && e?.[1]) {
            setFrom(moment(e[0]).format(`YYYY-MM-DD`));
            setTo(moment(e[1]).format(`YYYY-MM-DD`));
          } else {
            setFrom(undefined);
            setTo(undefined);
          }
        }}
        ranges={{
          Today: [moment(), moment()],
          "Last 7 Days": [moment().subtract(7, "days"), moment()],
          "Last 15 Days": [moment().subtract(15, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
        }}
        defaultValue={[moment(from), moment(to)]}
        defaultPickerValue={[moment(from), moment(to)]}
        value={[moment(from), moment(to)]}
        disabled={disabled ?? false}
      />
    </Row>
  );
};
export default TmsDatePickerRange;
