import tmsRouteMap from "./tms-route.map";
import {
  CrownOutlined,
  BarChartOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
  PartitionOutlined,
  SettingOutlined,
  CalendarOutlined,
  ReadOutlined,
  UnorderedListOutlined,
  LaptopOutlined,
  EditOutlined,
} from "@ant-design/icons";
export const tmsSiderMap = [
  {
    route: tmsRouteMap.home,
    icon: HomeOutlined,
    inLayout: true,
    name: "Dashboard",
    accessedByKey: [
      "DASHBOARD_READ",
      "ACCOMMODATION_ADMIN_READ",
      "ADMIN",
      "SUPER_ADMIN",
    ],
  },
  // {
  //   route: tmsRouteMap.devotees,
  //   icon: UsergroupAddOutlined,
  //   inLayout: true,
  //   name: "Devotees",
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  // },
  // {
  //   route: tmsRouteMap.pos,
  //   icon: PartitionOutlined,
  //   inLayout: true,
  //   name: "POS",
  //   accessedByKey: [
  //     "ACCOMMODATION_COUNTER",
  //     "SEVA_COUNTER",
  //     "DARSHANAM_COUNTER",
  //     "PRASADAM_COUNTER",
  //     "KALYANAKATTA_COUNTER",
  //     "DONATION_COUNTER",
  //     "DONATION_PETTY_COUNTER",
  //     "PUBLICATION_COUNTER",
  //     "TOLLGATE_COUNTER",
  //     "TICKET_DETAILS_SCAN",
  //     "TOLLGATE_TICKET_SCAN",
  //     "PRASADAM_TICKET_SCAN",
  //     "DARSHANAM_TICKET_SCAN",
  //     "SEVA_TICKET_PERFORMANCE_SCAN",
  //     "ACCOMMODATION_TICKET_SCAN",
  //     "THULABHARAM_COUNTER",
  //     "ADMIN",
  //     "SUPER_ADMIN",
  //   ],
  // },
  {
    route: tmsRouteMap.serviceManagement,
    icon: BarChartOutlined,
    inLayout: true,
    name: "Service Management",
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "PRASAD_INVENTORY_MANAGEMENT"],
  },
  // {
  //   route: tmsRouteMap.orders,
  //   icon: UnorderedListOutlined,
  //   inLayout: true,
  //   name: "Orders",
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  // },
  {
    route: tmsRouteMap.reports,
    icon: ReadOutlined,
    inLayout: true,
    name: "Reports",
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "ACCOMMODATION_REPORT",
      "SEVA_REPORT",
      "DARSHANAM_REPORT",
      "PRASADAM_REPORT",
      "KALYANAKATTA_REPORT",
      "DONATION_REPORT",
      "PUBLICATION_REPORT",
      "TOLLGATE_REPORT",
      "SHIFT_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
      "THULABHARAM_REPORT",
    ],
  },
  {
    route: tmsRouteMap.userManagement,
    icon: SettingOutlined,
    inLayout: true,
    name: "User Management",
    accessedByKey: ["SUPER_ADMIN", "ADMIN"],
  },
  // {
  //   route: tmsRouteMap.support,
  //   icon: LaptopOutlined,
  //   inLayout: true,
  //   name: "Support",
  //   accessedByKey: ["CUSTOMER_SUPPORT", "ADMIN", "SUPER_ADMIN"],
  // },
  // {
  //   route: tmsRouteMap.calender,
  //   icon: CalendarOutlined,
  //   inLayout: true,
  //   name: "Calender",
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  // },
  {
    route: tmsRouteMap.temple_management,
    icon: CrownOutlined,
    inLayout: true,
    name: "Temple Management",
    accessedByKey: ["SUPER_ADMIN"],
  },
  {
    route: tmsRouteMap.temple_settings,
    icon: EditOutlined,
    inLayout: true,
    name: "Temple Settings",
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  },
];
