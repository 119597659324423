import { Col, Input, Row, Select } from "antd";
import React from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";

const TmsPosUserForm = ({
  setDevoteeName,
  devoteeName,
  setGender,
  gender,
  setAge,
  age,
  setPhone,
  phone,
  setEmail,
  email,
  setAddress,
  address,
  setZip,
  zip,
  setCity,
  city,
  setState,
  state,
  setCountry,
  country,
}) => {
  const labelStyles = { float: "left", fontSize: "17px", marginBottom: "5px" };
  return (
    <>
      <Row
        style={{
          fontWeight: "400",
          justifyContent: "center",
          fontSize: "20px",
        }}
      >
        Devotee Details
      </Row>
      <br />
      <Row style={{ width: "100%", justifyContent: "space-between" }}>
        <Col style={{ width: "49%" }}>
          <label style={labelStyles}>
            Devotee Name{" "}
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </label>
          <br />{" "}
          <Input
            onChange={(e) => {
              if (
                /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                e.target.value === ""
              ) {
                setDevoteeName(e.target.value);
              }
            }}
            type="text"
            placeholder="Enter Name"
            maxLength="30"
            value={devoteeName}
          />
        </Col>
        <Col style={{ width: "49%" }}>
          <label style={labelStyles}>
            Age <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </label>
          <br />{" "}
          <Input
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onChange={(e) => {
              if (e.target.value > 120) {
                return;
              }
              setAge(e.target.value);
            }}
            type="number"
            placeholder="Enter Age"
            value={age}
            onWheel={(event) => event.currentTarget.blur()}
          />
        </Col>
      </Row>
      <br />
      <Row style={{ width: "100%", justifyContent: "space-between" }}>
        <Col style={{ width: "49%" }}>
          <label style={labelStyles}>
            Gender <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </label>
          <br />{" "}
          <Select
            onChange={(e) => setGender(e)}
            placeholder="Select Gender"
            style={{ width: "100%" }}
            value={gender}
            allowClear
          >
            <Select.Option key="male" value="male">
              Male
            </Select.Option>
            <Select.Option key="female" value="female">
              Female
            </Select.Option>
            <Select.Option key="other" value="other">
              Other
            </Select.Option>
          </Select>
        </Col>
        <Col style={{ width: "49%" }}>
          <label style={labelStyles}>
            Mobile Number{" "}
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </label>
          <br />{" "}
          <Input
            onChange={(e) => {
              if (e.target.value.length > 10) {
                return;
              }
              setPhone(e.target.value);
            }}
            type="number"
            prefix="+91"
            onWheel={(event) => event.currentTarget.blur()}
            maxLength={10}
            value={phone}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
      </Row>
      <br />
      <Row style={{ width: "100%", justifyContent: "space-between" }}>
        <Col style={{ width: "49%" }}>
          <label style={labelStyles}>Email ID </label>
          <br />{" "}
          <Input
            type="email"
            placeholder="Enter Email ID"
            maxLength="30"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Col>
        <Col style={{ width: "49%" }}>
          <label style={labelStyles}>
            Address <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </label>
          <br />{" "}
          <Input
            type="text"
            placeholder="Enter Address"
            maxLength="50"
            onKeyDown={(e) => ["#", "+"].includes(e.key) && e.preventDefault()}
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Col>
      </Row>
      <br />
      <Row style={{ width: "100%", justifyContent: "space-between" }}>
        <Col style={{ width: "49%" }}>
          <label style={labelStyles}>
            Pincode
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </label>
          <br />{" "}
          <Input
            type="number"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            placeholder="Enter Pincode"
            value={zip}
            onChange={(e) => {
              if (e.target.value.length > 6) {
                return;
              }
              setZip(e.target.value);
            }}
          />
        </Col>
        <Col style={{ width: "49%" }}>
          <label style={labelStyles}>
            City <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </label>
          <br />{" "}
          <Input
            type="text"
            value={city}
            placeholder="Enter City"
            maxLength="30"
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
        </Col>
      </Row>
      <br />
      <Row style={{ width: "100%", justifyContent: "space-between" }}>
        <Col style={{ width: "49%" }}>
          <label style={labelStyles}>
            State
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>{" "}
          </label>
          <br />
          <RegionDropdown
            className="CountryRegionDropDown"
            country={country}
            value={state}
            showDefaultOption={true}
            blankOptionLabel="Select State"
            onChange={(val) => {
              setState(val);
            }}
            placeholder="Select State"
          />
        </Col>
        <Col style={{ width: "49%" }}>
          <label style={labelStyles}>
            Country <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </label>
          <br />{" "}
          <CountryDropdown
            className="CountryRegionDropDown"
            value={country}
            onChange={(val) => {
              setCountry(val);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(TmsPosUserForm);
