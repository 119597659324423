import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  notification,
  Row,
  Select,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import TmsDonationService from "../../../../../services/tms-donation.service";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import TmsImageUpload from "../../../../../components/tms-customs/tms-image-upload";
import { BaseContext } from "../../../../../contexts/BaseContext";

const TmsDonationInnerPagesAND = ({
  viewDonationData,
  editFlag,
  productId,
  setEditFlag,
  settmsModalVisible,
  router,
}) => {
  const { TextArea } = Input;
  const { userDetails } = useContext(BaseContext);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setdescription] = useState("");
  const [counterEnabled, setcounterEnabled] = useState(false);
  const [onlineEnabled, setonlineEnabled] = useState(false);
  const [price, setprice] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [donationType, setDonationType] = useState("general");
  const [fileList, setFileList] = useState([]);

  const fetchData = async () => {
    if (
      name === "" ||
      description === "" ||
      price === "" ||
      maxAmount === "" ||
      fileList.length === 0
    ) {
      notification.error({ message: "Please Enter all fields." });
      return;
    } else if (price === maxAmount || price > maxAmount) {
      notification.error({
        message: "Minimun Amount must be less than Maximum Amount.",
      });
      return;
    }
    setLoading(true);
    let body = {
      name,
      description,
      counterEnabled,
      onlineEnabled,
      price,
      imageUrl: fileList[0].url,
      maxAmount,
      donationType,
    };
    try {
      if (editFlag) {
        await TmsDonationService.editDonation(productId, body);
        // setDarshanamData(darData);
        notification.success({ message: "Donation Edited Successful" });
        setEditFlag(false);
        setLoading(false);
      } else {
        await TmsDonationService.createDonation(body);
        // setDarshanamData(data?.items);
        notification.success({ message: "Donation Created Successful" });
        handleFuction();
        setLoading(false);
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleFuction = () => {
    // setTargetDate(moment());
    // setTargetAmount("");
    setName("");
    setdescription("");
    setcounterEnabled(false);
    setonlineEnabled(false);
    setprice();
    setFileList([]);
    setDonationType("general");
  };

  useEffect(() => {
    if (editFlag) {
      // setTargetDate(moment(viewDonationData.targetDate, "YYYY-MM-DD"));
      // setTargetAmount(viewDonationData.targetAmount);
      setName(viewDonationData?.name);
      setdescription(viewDonationData?.description);
      setcounterEnabled(viewDonationData?.counterEnabled);
      setonlineEnabled(viewDonationData?.onlineEnabled);
      setprice(viewDonationData?.price);
      setFileList([{ url: viewDonationData?.imageUrl, status: "done" }]);
      setDonationType(viewDonationData?.donationType);
      setMaxAmount(viewDonationData?.maxAmount);
    }
  }, [viewDonationData]);

  return (
    <Row className="formRow" style={{ width: "100%" }}>
      <Card style={{ width: "100%" }}>
        <Row style={{ marginBottom: "10px" }}>
          <label className="formLabel">Donation Type</label>

          <Select
            className="formInput"
            onChange={(e) => {
              setDonationType(e);
            }}
            value={donationType}
            defaultValue={donationType}
          >
            <Select.Option value="general">General Donation</Select.Option>
            <Select.Option value="petty">Petty Donation</Select.Option>
          </Select>
        </Row>
        <Row className="formRow">
          <label className="formLabel">Donation Name</label>
          <Input
            className="formInput"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel">Image Url</label>
          <TmsImageUpload fileList={fileList} setFileList={setFileList} />
        </Row>
        <Row className="formRow">
          <label className="formLabel">Description</label>
          <TextArea
            className="formInput"
            rows={4}
            value={description}
            onChange={(e) => setdescription(e.target.value)}
          />
        </Row>
        {/* <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel">Target Amount</label>
          <InputNumber
            defaultValue={targetAmount}
            value={targetAmount}
            style={{ width: "78%", marginLeft: "5px" }}
            min={0}
            placeholder="Target Amount"
            onChange={(e) => {
              setTargetAmount(e);
            }}
          ></InputNumber>
        </Row> */}
        {/* <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel">Target Date</label>

          <DatePicker
            allowClear
            defaultPickerValue={targetDate}
            format="DD-MM-YYYY"
            value={targetDate}
            onChange={(e) => {
              setTargetDate(e);
            }}
            style={{ width: "78%", marginLeft: "5px" }}
          />
        </Row> */}

        <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel"> Donation Amount (Min- Max) </label>
          <Col className="formCols-2">
            <Input
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={price}
              placeholder="Minimum Donation Amount"
              onChange={(e) => setprice(Number(e.target.value))}
            />
          </Col>
          <Col className="formCols-2">
            <Input
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              value={maxAmount}
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              placeholder="Maxmimum Donation Amount"
              onChange={(e) => {
                setMaxAmount(Number(e.target.value));
              }}
            />
          </Col>
        </Row>
        <Row gutter={[20, 20]} className="formRow" justify="center">
          <Col className="formCols-2 contantCenter">
            <label style={{ paddingRight: "10px" }}>Enable Online</label>
            <Checkbox
              style={{ paddingRight: "10px" }}
              checked={onlineEnabled}
              onChange={(e) => setonlineEnabled(e.target.checked)}
            />
            <label style={{ paddingRight: "10px" }}>Enable POS</label>
            <Checkbox
              checked={counterEnabled}
              onChange={(e) => setcounterEnabled(e.target.checked)}
            />
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              ghost
              loading={loading}
              onClick={() => {
                editFlag ? settmsModalVisible(false) : handleFuction();
              }}
            >
              {editFlag ? "Return" : "Clear"}
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              loading={loading}
              onClick={() => {
                fetchData();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsDonationInnerPagesAND);
