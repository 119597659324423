import { Input, Row } from "antd";
import React from "react";
import "../../assets/css/tms-custom-elements/tms-custom-elements.scss";
import { ExclamationCircleFilled } from "@ant-design/icons";
const InputPassword = ({
  size,
  setState,
  value,
  onKeyDown,
  error,
  setError,
  label,
}) => {
  return (
    <Row className="d-flex-c">
      <label>{label}</label>
      <Input.Password
        size={size}
        className="mobile-field"
        placeholder="Enter your Password"
        onChange={(e) => {
          setState(e.target.value);
          if (error !== "") {
            setError("");
          }
        }}
        value={value}
        type="password"
        onKeyDown={onKeyDown}
        style={{ margin: "5px 0px 10px 0px" }}
      />
      {error !== "" ? (
        <ExclamationCircleFilled className="tms-error-exclamation" />
      ) : (
        ""
      )}
    </Row>
  );
};
export default InputPassword;
