import { Checkbox, Row, Space } from "antd";
import React from "react";
const TmsCheckbox = ({ label, value, setValue }) => {
  return (
    <Row justify="center" style={{ width: "100%", padding: "10px 0px" }}>
      <Space size="middle">
        {label}
        <Checkbox
          checked={value}
          onChange={(e) => {
            setValue(e.target.checked);
          }}
        />
      </Space>
    </Row>
  );
};
export default TmsCheckbox;
