import {
  tms_icon_accommodation,
  tms_icon_check_in_scan,
  tms_icon_coupon_management,
  tms_icon_darshanam,
  tms_icon_donation,
  tms_icon_kalyanakatta,
  tms_icon_prasadam,
  tms_icon_pratyaksha_Seva,
  tms_icon_publication,
  tms_icon_shift_managament,
  tms_icon_thulabharam,
  tms_icon_tollgate,
} from "../assets/images/tms-image-list";
import tmsRouteMap from "./tms-route.map";

const TmsReportsMap = [
  // {
  //   title: "Shift Report",
  //   route: tmsRouteMap.reports_shift_report,
  //   accessedByKey: [
  //     "SHIFT_LOGIN",
  //     "ADMIN",
  //     "SUPER_ADMIN",
  //     "ACCOMMODATION_REPORT",
  //     "SEVA_REPORT",
  //     "DARSHANAM_REPORT",
  //     "PRASADAM_REPORT",
  //     "KALYANAKATTA_REPORT",
  //     "DONATION_REPORT",
  //     "PUBLICATION_REPORT",
  //     "TOLLGATE_REPORT",
  //     "SHIFT_REPORT",
  //     "GST_REPORT",
  //     "SCAN_LOG_REPORT",
  //     "TRANSACTIONS_REPORT",
  //     "THULABHARAM_REPORT",
  //     "SHOP_PRODUCT_REPORT",
  //   ],
  //   color: "#FEF6D7",
  //   logo: tms_icon_shift_managament,
  //   textColor: "#EF7843",
  // },
  // {
  //   title: "Shift Transactions",
  //   route: tmsRouteMap.reports_view_shift_transactions,
  //   accessedByKey: [
  //     "ADMIN",
  //     "SUPER_ADMIN",
  //     "SHIFT_LOGIN",
  //     "ACCOMMODATION_REPORT",
  //     "SEVA_REPORT",
  //     "DARSHANAM_REPORT",
  //     "PRASADAM_REPORT",
  //     "KALYANAKATTA_REPORT",
  //     "DONATION_REPORT",
  //     "PUBLICATION_REPORT",
  //     "TOLLGATE_REPORT",
  //     "SHIFT_REPORT",
  //     "GST_REPORT",
  //     "SCAN_LOG_REPORT",
  //     "TRANSACTIONS_REPORT",
  //     "THULABHARAM_REPORT",
  //     "SHOP_PRODUCT_REPORT",
  //   ],
  //   color: "#EEEBFF",
  //   logo: tms_icon_check_in_scan,
  //   textColor: "#573BFF",
  // },
  // {
  //   title: "Scan Logs",
  //   route: tmsRouteMap.reports_scan_logs,
  //   accessedByKey: [
  //     "ADMIN",
  //     "SUPER_ADMIN",
  //     "ACCOMMODATION_REPORT",
  //     "SEVA_REPORT",
  //     "DARSHANAM_REPORT",
  //     "PRASADAM_REPORT",
  //     "KALYANAKATTA_REPORT",
  //     "DONATION_REPORT",
  //     "PUBLICATION_REPORT",
  //     "SHOP_PRODUCT_REPORT",

  //     "TOLLGATE_REPORT",
  //     "SHIFT_REPORT",
  //     "GST_REPORT",
  //     "SCAN_LOG_REPORT",
  //     "TRANSACTIONS_REPORT",
  //     "THULABHARAM_REPORT",
  //   ],
  //   color: "#F0F9FF",
  //   logo: tms_icon_darshanam,
  //   textColor: "#3399CC",
  // },
  // {
  //   title: "Accommodation Reports",
  //   route: tmsRouteMap.reports_accommodation,
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN", "ACCOMMODATION_REPORT"],
  //   color: "#E6F8FD",
  //   logo: tms_icon_accommodation,
  //   textColor: "#0EB5E4",
  // },
  // {
  //   title: "Darshanam Reports",
  //   route: tmsRouteMap.reports_darshanam,
  //   accessedByKey: [
  //     "ADMIN",
  //     "SUPER_ADMIN",
  //     "SHIFT_REPORT",
  //     "GST_REPORT",
  //     "SCAN_LOG_REPORT",
  //     "TRANSACTIONS_REPORT",
  //     "DARSHANAM_REPORT",
  //   ],
  //   color: "#F0F9FF",
  //   logo: tms_icon_darshanam,
  //   textColor: "#3399CC",
  // },
  {
    title: "Seva Reports",
    route: tmsRouteMap.reports_seva,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "SEVA_REPORT",
      "SHIFT_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
      "SEVA_REPORT",
    ],
    color: "#EEF7EE",
    logo: tms_icon_pratyaksha_Seva,
    textColor: "#53B04A",
  },
  {
    title: "Donation Report",
    route: tmsRouteMap.reports_donation,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "DONATION_REPORT",
      "SHIFT_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
      "DONATION_REPORT",
    ],
    color: "#FFF6FB",
    logo: tms_icon_donation,
    textColor: "#CC3399",
  },
  // {
  //   title: "Prasadam Report",
  //   route: tmsRouteMap.reports_prasadam,
  //   accessedByKey: [
  //     "ADMIN",
  //     "SUPER_ADMIN",
  //     "PRASADAM_REPORT",
  //     "SHIFT_REPORT",
  //     "GST_REPORT",
  //     "SCAN_LOG_REPORT",
  //     "TRANSACTIONS_REPORT",
  //     "PRASADAM_REPORT",
  //   ],
  //   color: "#F0F7D8",
  //   logo: tms_icon_prasadam,
  //   textColor: "#999966",
  // },
  // {
  //   title: "Thulabharam Report",
  //   route: tmsRouteMap.reports_thulabharam,
  //   accessedByKey: [
  //     "ADMIN",
  //     "SUPER_ADMIN",
  //     "THULABHARAM_REPORT",
  //     "SHIFT_REPORT",
  //     "GST_REPORT",
  //     "SCAN_LOG_REPORT",
  //     "TRANSACTIONS_REPORT",
  //   ],
  //   color: "#D6F8FF",
  //   logo: tms_icon_thulabharam,
  //   textColor: "#669999",
  // },
  // {
  //   title: "Toll Gate Report",
  //   route: tmsRouteMap.reports_tollgate,
  //   accessedByKey: [
  //     "ADMIN",
  //     "SUPER_ADMIN",
  //     "TOLLGATE_REPORT",
  //     "SHIFT_REPORT",
  //     "GST_REPORT",
  //     "SCAN_LOG_REPORT",
  //     "TRANSACTIONS_REPORT",
  //   ],
  //   color: "#F9F8FD",
  //   logo: tms_icon_tollgate,
  //   textColor: "#666699",
  // },
  {
    title: "Products Report",
    route: tmsRouteMap.home,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "SHOP_PRODUCT_REPORT",
      "SHIFT_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
    ],
    color: "#D9F7E0",
    logo: tms_icon_kalyanakatta,
    textColor: "#669966",
  },
  // {
  //   title: "Kalyanakatta Report",
  //   route: tmsRouteMap.reports_kalyanakatta,
  //   accessedByKey: [
  //     "ADMIN",
  //     "SUPER_ADMIN",
  //     "KALYANAKATTA_REPORT",
  //     "SHIFT_REPORT",
  //     "GST_REPORT",
  //     "SCAN_LOG_REPORT",
  //     "TRANSACTIONS_REPORT",
  //   ],
  //   color: "#D9F7E0",
  //   logo: tms_icon_kalyanakatta,
  //   textColor: "#669966",
  // },
  {
    title: "Consolidated Report",
    route: tmsRouteMap.reports_consolidated_reports,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "SHIFT_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
    ],
    color: "#FFE2E5",
    logo: tms_icon_coupon_management,
    textColor: "#848355",
  },
  // {
  //   title: "Manual Entry",
  //   route: tmsRouteMap.report_manual_entry,
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  //   color: "#FEFBEC",
  //   logo: tms_icon_publication,
  //   textColor: "#CC9933",
  // },
];
export default TmsReportsMap;
