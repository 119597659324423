import { notification } from "antd";
import React, { useContext, useState } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import InputPhoneNumber from "../tms-customs/tms-input-phone-number";
import InputPassword from "../tms-customs/tms-input-password";
import CustomButton from "../tms-customs/tms-custom-button";
import TmsAuthService from "../../services/tms-user/tms-auth.service";
import { BaseContext } from "../../contexts/BaseContext";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import tmsRouteMap from "../../constants/tms-route.map";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import TmsTempleService from "../../services/tms-temple.service";

const TmsLoginStepOne = ({
  userName,
  setUsername,
  password,
  setPassword,
  setPage,
  router,
}) => {
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { setUserDetails } = useContext(BaseContext);

  const loginClick = async () => {
    setLoading(true);
    if (userName === "" || userName.length !== 10 || password === "") {
      if (userName === "" || userName.length !== 10) {
        setPhoneError("Enter a valid Phone Number");
      }
      if (password === "") setPasswordError("Enter a valid Password");
      setLoading(false);
      return;
    }

    try {
      const token = await TmsAuthService.login(userName, password);
      setLoading(false);
      // let flag =
      //   token.userPermission.includes("SUPER_ADMIN") ||
      //   token.userPermission.includes("DASHBOARD_READ");
      // if (!flag) {
      //   notification.error({
      //     message:
      //       "This login is only for Super Admins and Dashboard Read Users. To access, Login through your respective Application.",

      //   });
      //   localStorage.clear();
      //   return;
      // }
      if (token.userPermission?.length === 0 || !token.userPermission) {
        setLoading(false);
        notification.error({
          message:
            "User Permissions are not assigned to this account. Please contact your administrator.",
        });
      } else {
        localStorage.setItem("accessToken", token.accessToken);
        localStorage.setItem("refreshToken", token.refreshToken);
        localStorage.setItem("templeId", token.user.templeId);
        let res = await TmsTempleService.getTempleDetails(token.user.templeId);
        if (!res.configuration || res.configuration === {}) {
          if (res) {
            await TmsTempleService.updateTempleSettings(false, "A4");
            let res2 = await TmsTempleService.getTempleDetails(
              token.user.templeId
            );
            localStorage.setItem("templeDetails", JSON.stringify(res2));
          }
        } else {
          localStorage.setItem("templeDetails", JSON.stringify(res));
        }

        setUserDetails({
          user: token.user,
          permissions: token.userPermission,
          // permissions: ["SUPER_ADMIN"],
        });
        {
          router.navigate(tmsRouteMap.welcome);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      catchErrorFunction(error, "phone or password wrong", router);
    }
  };

  return (
    <div className="tms-login-step-one">
      <div className="tms-input">
        <InputPhoneNumber
          size="large"
          setState={setUsername}
          value={userName}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              loginClick();
            } else if (["e", "E", "+", "-", "."].includes(e.key)) {
              e.preventDefault();
            }
          }}
          error={phoneError}
          setError={setPhoneError}
        />
        <p className="tms-error-p">{phoneError !== "" ? phoneError : ""}</p>
      </div>
      <div className="tms-input">
        <InputPassword
          size="large"
          label={"Password"}
          setState={setPassword}
          value={password}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              loginClick();
            }
          }}
          error={passwordError}
          setError={setPasswordError}
        />
        <p className="tms-error-p">
          {passwordError !== "" ? passwordError : ""}
        </p>
      </div>
      <p
        className="cursor"
        style={{ color: "#407dd0" }}
        onClick={() => {
          setPage(2);
        }}
      >
        Forgot Password ?
      </p>
      <CustomButton
        type={"danger"}
        size="large"
        loading={loading}
        onClickFunction={loginClick}
        icon={
          <ArrowRightOutlined
            style={{ marginRight: "-5px", fontSize: "12px" }}
          />
        }
      />
    </div>
  );
};
export default withRouter(TmsLoginStepOne);
