import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import { Button, Col, notification, Popconfirm, Row, Space } from "antd";
import { BaseContext } from "../../../../contexts/BaseContext";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsCMNewCouponDrawer from "../../../../components/tms-service-management/tms-sm-coupon-management/tms-sm-new-coupon-drawer";
import TmsCouponService from "../../../../services/tms-coupon-service";
import moment from "moment";

const TmsSmCounponManagement = ({ router }) => {
  const [loading, setLoading] = useState();
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [editData, setEditData] = useState(null);
  const [updateFlag, setUpdateFlag] = useState();
  const [data, setData] = useState([]);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const { userDetails } = useContext(BaseContext);

  const handleModify = async (record) => {
    setEditData(record);
    setEditFlag(true);
    setShowCouponModal(true);
  };

  useEffect(() => {
    getAllCoupons();
  }, [updateFlag]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const getAllCoupons = async () => {
    try {
      setLoading(true);
      const shifts = await TmsCouponService.getAllCoupons(
        tableCurrentPage,
        tablePageSize
      );
      setData(
        shifts?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setTabelTotalSize(shifts?.meta?.totalItems);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token is expired. You wll be navigated to login page",
        router
      );
      setLoading(false);
    }
  };

  const columns = [
    { title: "id", dataIndex: "id", key: "id" },
    {
      title: "Coupon Code",
      dataIndex: "couponCode",
      key: "couponCode",
    },
    {
      title: "Counter Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Discount (%)",
      dataIndex: "maxOfferPercentage",
      key: "maxOfferPercentage",
      render: (text) => {
        return `${text} %`;
      },
    },
    {
      title: "Max offer amount",
      dataIndex: "maxOfferAmount",
      key: "maxOfferAmount",
      // render: FormatNumberINRCurrency,
    },
    {
      title: "Status",
      dataIndex: "used",
      render: (record) => {
        return <Space size="middle">{!record ? "Available" : "Used"}</Space>;
      },
    },

    {
      title: "Valid from ",
      dataIndex: "validFrom",
      key: "validFrom",
      render: (record) => moment(record).format("DD-MM-YYYY"),
    },

    {
      title: "Valid To",
      dataIndex: "validTo",
      key: "validTo",
      render: (record) => moment(record).format("DD-MM-YYYY"),
    },

    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await TmsCouponService.deleteCoupon(record.key);
                  notification.success({
                    message: `Shift has been deleted`,
                  });
                  getAllCoupons();
                } catch (error) {
                  catchErrorFunction(
                    error,
                    "Token Expired!! Please Login",
                    router
                  );
                }

                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>{" "}
            <a
              onClick={(e) => {
                e.preventDefault();
                handleModify(record);
              }}
            >
              Edit
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Row style={{ width: "100%", marginBottom: "15px" }} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowCouponModal(true);
            }}
            disabled={
              userDetails?.permissions?.includes("SUPER_ADMIN") ||
              userDetails?.permissions?.includes("ADMIN")
                ? false
                : true
            }
          >
            Add New Coupon
          </Button>
        </Col>
      </Row>
      <Row style={{ width: "100%" }}>
        <TmsTable
          dataSource={data}
          loading={loading}
          columns={columns}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        />
      </Row>
      <TmsCMNewCouponDrawer
        showCouponDrawer={showCouponModal}
        setShowCouponDrawer={setShowCouponModal}
        setUpdateFlag={setUpdateFlag}
        editFlag={editFlag}
        setEditFlag={setEditFlag}
        editData={editData}
      />
    </div>
  );
};

export default withRouter(TmsSmCounponManagement);
