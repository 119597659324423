import React, { useEffect, useState } from "react";
import TmsUserService from "../../services/tms-user/tms-user.service";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import CustomButton from "../tms-customs/tms-custom-button";
import { ArrowRightOutlined } from "@ant-design/icons";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import OtpInput from "react-otp-input";
import "../../assets/css/tms-login/tms-login.scss";
import { notification } from "antd";
import InputPassword from "../tms-customs/tms-input-password";
// import crypto from "crypto";
const SITE_KEY = "6LfKG_QaAAAAAI9rFw0CjwXxTjEUWZkCMzVqyba6";

const TmsLoginStepThree = ({ setPage, router, phone, setPhone }) => {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleSubmit = async () => {
    setLoading(true);
    if (!otp) {
      notification.error({
        message: "Ente OTP",
      });
      setLoading(false);
      return;
    }
    if (password !== conPassword) {
      notification.error({
        message: "Password Mismatch",
      });
      setLoading(false);
      return;
    }
    try {
      let res = await TmsUserService?.forgotPassword(phone, otp, password);
      if (res) {
        notification.success({
          message: "Password Changed Successfully",
        });
        setPage(1);
      }
      setLoading(false);
    } catch (e) {
      catchErrorFunction(e, "Token expired! Please login again.", router);
      setLoading(false);
    }
  };
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        // console.log("Script loaded!");
      }
    );
  }, []);

  return (
    <div className="tms-login-step-one">
      <h3 className="login-two-heading">Forgot Password</h3>
      <p className="cursor">
        OTP sent to {phone}
        <br />
        <span
          style={{ color: "#407dd0" }}
          onClick={() => {
            setPage(2);
            setPhone("");
          }}
        >
          Not your number?
        </span>
      </p>
      <div className="tms-input">
        <OtpInput
          className={"otp"}
          isInputNum={true}
          autoComplete="new-password"
          shouldAutoFocus
          value={otp}
          onChange={(e) => {
            setOtp(e);
          }}
          numInputs={4}
          separator={<span></span>}
        />
      </div>
      <div className="tms-input">
        <InputPassword
          size="large"
          label={"New Password"}
          setState={setPassword}
          value={password}
          error={passwordError}
          setError={setPasswordError}
        />
        <p className="tms-error-p">
          {passwordError !== "" ? passwordError : ""}
        </p>
      </div>
      <div className="tms-input">
        <InputPassword
          size="large"
          label={"Confirm Password"}
          setState={setConPassword}
          value={conPassword}
          error={passwordError}
          setError={setPasswordError}
        />
        <p className="tms-error-p">
          {passwordError !== "" ? passwordError : ""}
        </p>
      </div>

      <CustomButton
        type={"danger"}
        size="medium"
        loading={loading}
        onClickFunction={handleSubmit}
        icon={
          <ArrowRightOutlined
            style={{ marginRight: "-5px", fontSize: "12px" }}
          />
        }
      />
    </div>
  );
};
export default withRouter(TmsLoginStepThree);
