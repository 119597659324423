import React, { useContext } from "react";
import "../../../assets/css/tms-layout/tms-layout.scss";
import { BaseContext } from "../../../contexts/BaseContext";
// import { BaseContext } from "../../../contexts/BaseContext";
import moment from "moment";
import { FormatNumberINRCurrency } from "../../../utils/tms-currency-formatter/tms-curreny-formatter";
const TmsFooter = () => {
  const { shiftData } = useContext(BaseContext);

  return (
    <>
      {shiftData && (
        <div className="tms-footer">
          <p>Login POS Name: {shiftData?.counter?.name}</p>
          <p>
            Total Amount:{" "}
            {FormatNumberINRCurrency(
              (shiftData?.amountDeposited ?? 0) +
                (shiftData?.accServicesAmount ?? 0) +
                (shiftData?.darshanamServicesAmount ?? 0) +
                (shiftData?.sevaServicesAmount ?? 0) +
                (shiftData?.gstAmount ?? 0) +
                (shiftData?.prasadamServicesAmount ?? 0) +
                (shiftData?.donationServicesAmount ?? 0) +
                (shiftData?.publicationServicesAmount ?? 0) +
                (shiftData?.kalyanakattaServicesAmount ?? 0) +
                (shiftData?.tollgateServicesAmount ?? 0) +
                (shiftData?.thulabharamServicesAmount ?? 0) +
                (shiftData?.shopProductAmount ?? 0) +
                (shiftData?.manualEntryServicesAmount ?? 0) +
                (shiftData?.accAdvanceBookingCharge ?? 0) +
                (shiftData?.accMaintenanceCharge ?? 0)
            )}
          </p>
          <p>
            Total Tickets:{" "}
            {(shiftData?.publicationCount ?? 0) +
              (shiftData?.sevaTicketsCount ?? 0) +
              (shiftData?.accTicketsCount ?? 0) +
              (shiftData?.prasadamTicketsCount ?? 0) +
              (shiftData?.donationTicketsCount ?? 0) +
              (shiftData?.tollgateTicketsCount ?? 0) +
              (shiftData?.kalyanakattaTicketsCount ?? 0) +
              (shiftData?.thulabharamTicketsCount ?? 0) +
              (shiftData?.darshanamTicketsCount ?? 0) +
              (shiftData?.accAdvanceBookingCount ?? 0) +
              (shiftData?.shopProductCount ?? 0) +
              (shiftData?.manualEntryTicketsCount ?? 0)}
          </p>
          <p>
            Login Time:{" "}
            {moment(shiftData?.login).format("DD-MM-YYYY HH:mm:ss A")}
          </p>
        </div>
      )}
    </>
  );
};
export default TmsFooter;
