import React, { useState } from "react";
import { tmsLogo } from "../../assets/images/tms-image-list";
import "../../assets/css/tms-layout/tms-layout.scss";
// import TmsImageUpload from "../../components/tms-customs/tms-image-upload";
const TmsWelcome = () => {
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );
  return (
    <div className="tms-welcome">
      <div>
        <img src={tmsLogo} height="150px" />
        <h2> Devasthanam Admin Panel</h2>
        <h4>{templeDetails?.name}</h4>
        {/* <TmsImageUpload type="row" label="Upload Image" /> */}
      </div>
    </div>
  );
};
export default TmsWelcome;
