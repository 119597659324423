import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  Modal,
  notification,
  Row,
} from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import { tms_footPrint } from "../../../../../assets/images/tms-image-list.js";
import moment from "moment";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import TmsFootfallService from "../../../../../services/tms-footfall.service";
import TmsSelect from "../../../../../components/tms-customs/tms-select";
import TmsDatePickerRange from "../../../../../components/tms-customs/tms-datepicker-range";
import TmsTable from "../../../../../components/tms-customs/tms-table";

const TmsSmFootfallCount = ({ router }) => {
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [count, setCount] = useState("");
  const [currentDate, setCurrentDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [type, setType] = useState("offline");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(8);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [update, setUpdate] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [dateCount, setDateCount] = useState([
    { offlineCount: "", onlineCount: "" },
  ]);
  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Type",
      dataIndex: "source",
      key: "source",
    },
  ];

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const submit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!currentDate || currentDate === "") {
      notification.error({ message: "Select Date" });
      setLoading(false);
      return;
    }
    if (!count || count === "") {
      notification.error({ message: "Enter Count" });
      setLoading(false);
      return;
    }
    try {
      await TmsFootfallService.setFootfallOffline(currentDate, count);
      setCount("");
      setUpdate(Math.random());
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );

      setLoading(false);
    }
  };

  const footfallRange = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let body = [];
    if (!type || type === "") {
      notification.error({ message: "Select Type" });
      setLoading(false);
      return;
    }
    if (!startDate || startDate === "") {
      notification.error({ message: "Select Start Date" });
      setLoading(false);
      return;
    }
    if (!endDate || endDate === "") {
      notification.error({ message: "Select End Date" });
      setLoading(false);
      return;
    }
    body = [
      {
        type: type,
        fromDate: startDate,
        toDate: endDate,
        current: Number(tableCurrentPage),
        limit: Number(tablePageSize),
      },
    ];
    try {
      let res = await TmsFootfallService.getFootfall(...body);
      setTableData(res?.items);
      setTabelTotalSize(res?.meta?.totalItems);
      setLoading(false);
      setModalVisible(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );

      setLoading(false);
    }
  };

  const getFootfallDate = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      let off = await TmsFootfallService.getFootfallCurrentDate("offline");
      let on = await TmsFootfallService.getFootfallCurrentDate("online");
      setDateCount({ offlineCount: off, onlineCount: on });
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );

      setLoading(false);
    }
  };

  useEffect(() => {
    footfallRange();
  }, [startDate, endDate, type, update, tableCurrentPage, tablePageSize]);

  useEffect(() => {
    if (modalVisible === false) {
      getFootfallDate();
    }
  }, [modalVisible]);

  return (
    <>
      <br />
      <Row gutter={[10, 10]} style={{ textAlign: "left" }}>
        <Button
          onClick={() => {
            setModalVisible(true);
          }}
          type={"primary"}
        >
          Add Count
        </Button>
        <Modal
          visible={modalVisible}
          width={400}
          onOk={submit}
          closable={false}
          maskClosable={false}
          centered
          onCancel={() => setModalVisible(false)}
          footer={[
            <Button key="back" onClick={() => setModalVisible(false)}>
              Return
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => loading === false && submit()}
            >
              Submit
            </Button>,
          ]}
        >
          <Col>
            <label>Date</label>
            <br />
            <DatePicker
              value={currentDate}
              format="DD-MM-YYYY"
              onChange={(date) => {
                setCurrentDate(date);
              }}
              size="medium"
              disabledDate={(d) => !d || d.isAfter(moment())}
            />
          </Col>
          <br />
          <Col>
            <label>Count</label>
            <Input
              type="number"
              placeholder="Enter Count"
              value={count}
              onChange={(e) => setCount(e.target.value)}
            />
          </Col>
        </Modal>
      </Row>
      <br />
      <Row gutter={[20, 20]} style={{ marginBottom: "20px" }}>
        <Col style={{ width: "32%" }}>
          <Card title={"Total Offline"} style={{ minHeight: "200px" }}>
            <Row style={{ justifyContent: "center", alignItems: "center" }}>
              <img src={tms_footPrint} style={{ width: "15%" }} />
              <b style={{ color: "red", fontSize: "30px" }}>
                {dateCount?.offlineCount?.total}
              </b>
            </Row>
          </Card>
        </Col>
        <Col style={{ width: "32%" }}>
          <Card title={"Total Online"} style={{ minHeight: "200px" }}>
            <Row style={{ justifyContent: "center", alignItems: "center" }}>
              <img src={tms_footPrint} style={{ width: "15%" }} />
              <b style={{ color: "limegreen", fontSize: "30px" }}>
                {dateCount?.onlineCount?.total}
              </b>
            </Row>
          </Card>
        </Col>
        <Col style={{ width: "32%" }}>
          <Card
            title={`${moment(dateCount?.offlineCount?.date).format(
              "DD-MM-YYYY"
            )}`}
            style={{ minHeight: "200px" }}
          >
            <Row gutter={[10, 10]}>
              <Col span={12}>
                <Row style={{ justifyContent: "center", alignItems: "center" }}>
                  <img src={tms_footPrint} style={{ width: "30%" }} />
                  <b style={{ color: "red", fontSize: "30px" }}>
                    {dateCount?.offlineCount?.today}
                  </b>
                </Row>
                <b style={{ color: "red" }}>Offline</b>
              </Col>
              <Col span={12}>
                <Row style={{ justifyContent: "center", alignItems: "center" }}>
                  <img src={tms_footPrint} style={{ width: "30%" }} />
                  <b style={{ color: "limegreen", fontSize: "30px" }}>
                    {dateCount?.onlineCount?.today}
                  </b>
                </Row>
                <b style={{ color: "limegreen" }}>Online</b>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Row gutter={[10, 10]} style={{ marginBottom: "20px" }}>
          <Col>
            <TmsDatePickerRange
              label="Select Dates"
              to={endDate}
              from={startDate}
              setFrom={setStartDate}
              setTo={setEndDate}
            />
          </Col>
          <Col>
            <TmsSelect
              type="col"
              span={4}
              value={type}
              setValue={setType}
              label="Type"
              data={[
                { id: "online", name: "Online" },
                { id: "offline", name: "Offline" },
              ]}
            />
          </Col>
        </Row>
        <br />
        <TmsTable
          columns={columns}
          dataSource={tableData}
          loading={loading}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        />
      </Row>
    </>
  );
};

export default withRouter(TmsSmFootfallCount);
