import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import {
  Button,
  Col,
  Drawer,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
} from "antd";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import TmsCheckbox from "../../components/tms-customs/tms-checkbox";
import TmsDatePicker from "../../components/tms-customs/tms-datepicker";
import CSData from "../../constants/tms-common-slots.map";

const TmsCmsSlotsModal = ({
  type,
  // eslint-disable-next-line no-unused-vars
  counterId,
  productId,
  showModal,
  setShowModal,
  setUpdateFlag,
  editFlag,
  setEditFlag,
  editData,
  setEditData,
  sevaType,
  router,
}) => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [description, setDescription] = useState("");
  const [maxBookings, setMaxBookings] = useState("");
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [slotLocation, setSlotLocation] = useState("");
  const [data, setData] = useState([]);
  const [selectedElement, setSelectedElement] = useState({});
  const Option = Select;
  const counterOrientedList = [
    "darshanam-counters",
    "seva-counters",
    "donation-counters",
    "prasadam-counters",
    "tollgate-counters",
    "thulabharam-counters",
    "manage-acc-pos",
    "kalyankatta-tonsure",
    "products-counters",
  ];
  useEffect(() => {
    if (editFlag && !loading) {
      // console.log("inside edit flag loop");
      if (counterOrientedList.includes(type)) {
        let temps = data?.find((item) => item.id === editData.counterId);
        setDetails(
          "",
          "",
          "",
          false,
          editData?.maxCounterBookingsPerDay ?? "",
          temps
        );
      } else {
        setDetails(
          editData.description,
          moment(editData.startTime, "HH:mm:ss Z"),
          moment(editData.endTime, "HH:mm:ss Z"),
          editData.enabled,
          editData?.maxOnlineBookingsPerDay,
          ""
        );
      }
    }
  }, [editFlag, editData, loading]);

  useEffect(() => {
    // console.log(type);
    if (counterOrientedList.includes(type) && showModal) {
      fetchData();
    }
  }, [showModal]);

  const handleCreate = async (e) => {
    e?.preventDefault();

    if (["darshanam-slots", "seva-slots"]?.includes(type)) {
      if (!description || description === "") {
        return notification.error({ message: `description is required` });
      }
      if (!to || !from || to === "" || from === "") {
        return notification.error({
          message: `Make sure start and end times are valid`,
        });
      }
    } else if (counterOrientedList.includes(type)) {
      if (!selectedElement || !selectedElement.id) {
        return notification.error({
          message: `Make sure you select a counter`,
        });
      }
    }
    if (
      ![
        "donation-counters",
        "prasadam-counters",
        "tollgate-counters",
        "thulabharam-counters",
        "manage-acc-pos",
        "kalyankatta-tonsure",
        "products-counters",
      ]?.includes(type)
    ) {
      if (maxBookings === "" || maxBookings <= 0) {
        return notification.error({
          message: `Make sure max bookings is a valid number`,
        });
      }
    }

    setLoading(true);
    try {
      let body = bodytypes[type];
      if (editFlag) {
        await CSData[type].editService(body, editData?.key);
        notification.success({
          message: CSData[type].editNotificationMessage,
        });
        setDetails("", "", "", "", "", "");
        setUpdateFlag(Math.random() * 100 + 9);
      } else {
        await CSData[type].createService(body);
        notification.success({
          message: CSData[type].createNotificationMessage,
        });
        setDetails("", "", "", "", "", "");
        setUpdateFlag(Math.random() * 1000 + 9);
      }
      onClose();
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const counters = await CSData[type].fetchModalService();
      setData(counters.items);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token is expired. You wll be navigated to login page",
        router
      );
      setLoading(false);
    }
  };

  const setDetails = (
    description,
    from,
    to,
    isEnabled,
    maxbookings,
    selectedElement
  ) => {
    setDescription(description);
    setTo(to);
    setFrom(from);
    setEnabled(isEnabled);
    setMaxBookings(maxbookings);
    setSelectedElement(selectedElement);
  };

  const onClose = () => {
    setShowModal(false);
    setEditData(null);
    setEditFlag(false);
  };

  const handleReturn = () => {
    setDetails("", "", "", false, "", "");
    onClose();
  };

  const Elements = {
    description: (
      <Row
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        Description :{" "}
        <Input
          style={{ marginTop: "10px" }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter Name "
        ></Input>
      </Row>
    ),
    fromTo: (
      <Row style={{ width: "100%" }}>
        <TmsDatePicker value={from} setValue={setFrom} label="Start Time" />
        <TmsDatePicker value={to} setValue={setTo} label="End Time" />
      </Row>
    ),
    maxBooking: (
      <Row width="100%" style={{ padding: "20px 0px 10px" }}>
        Max Bookings{" "}
        {new URLSearchParams(router?.location?.search).get("slotid")
          ? "(POS)"
          : "(Online)"}
        :{" "}
        <InputNumber
          defaultValue={maxBookings}
          value={maxBookings}
          min={0}
          onKeyDown={(e) =>
            ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
          }
          style={{ width: "100%" }}
          placeholder={`Enter Maximum Bookings ${
            new URLSearchParams(router?.location?.search).get("slotid")
              ? "(POS)"
              : "(Online)"
          } allowed`}
          onChange={(e) => {
            setMaxBookings(e);
          }}
        ></InputNumber>
      </Row>
    ),
    checkbox: (
      <TmsCheckbox
        label={
          new URLSearchParams(router?.location?.search).get("slotid")
            ? "Enable Slot"
            : "Enable Shift"
        }
        value={enabled}
        setValue={setEnabled}
      />
    ),
    "select-counter": (
      <Row justify="center" style={{ width: "100%", padding: "10px 0px" }}>
        <Select
          width="100%"
          style={{ width: "100%" }}
          value={selectedElement?.name}
          placeholder="Please select a POS"
          onChange={(e) => {
            let temp = data?.find((item) => item.name === e);
            setSelectedElement(temp);
          }}
        >
          {data?.map((item, i) => {
            return (
              <Option key={item.name + "seva-list" + i} value={item.name}>
                {item.name}{" "}
              </Option>
            );
          })}
        </Select>
      </Row>
    ),
    location: (
      <Row
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        Description :{" "}
        <Input
          style={{ marginTop: "10px" }}
          value={slotLocation}
          onChange={(e) => setSlotLocation(e.target.value)}
          placeholder="Enter Location"
        ></Input>
      </Row>
    ),
  };

  const bodytypes = {
    "seva-slots": {
      sevaType: sevaType,
      description: description,
      startTime: moment(from, "Asia/Kolkata").toDate(),
      endTime: moment(to, "Asia/Kolkata").toDate(),
      maxOnlineBookingsPerDay: maxBookings,
      enabled: enabled,
      location: slotLocation,
      productId: Number(productId),
    },
    "seva-counters": {
      sevaType: sevaType,
      productSlotId: Number(
        new URLSearchParams(router?.location?.search).get("slotid")
      ),
      counterId: selectedElement?.id,
      maxCounterBookingsPerDay: maxBookings,
    },
    "donation-counters": {
      productId: Number(
        new URLSearchParams(router?.location?.search).get("id")
      ),
      counterId: selectedElement?.id,
    },
    "prasadam-counters": {
      productId: Number(
        new URLSearchParams(router?.location?.search).get("id")
      ),
      counterId: selectedElement?.id,
    },

    "products-counters": {
      productId: Number(
        new URLSearchParams(router?.location?.search).get("id")
      ),
      counterId: selectedElement?.id,
    },
  };

  return (
    <Drawer
      width="40%"
      closable={false}
      visible={showModal}
      title={CSData[type].modalLabel(editFlag, editData)}
      onOk={handleCreate}
      onClose={handleReturn}
    >
      {CSData[type].elements.map((item, i) => {
        return <div key={"cms-slot-data" + i}>{Elements[item]}</div>;
      })}
      <Row gutter={[10, 10]} style={{ float: "right" }}>
        <Col>
          <Button key="back" onClick={handleReturn}>
            Return
          </Button>
        </Col>
        <Col>
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={(e) => {
              e.preventDefault();
              handleCreate();
            }}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Drawer>
  );
};
export default TmsCmsSlotsModal;
