import { Button, Input, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import TmsCounterService from "../../../services/tms-counter.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsCheckbox from "../../tms-customs/tms-checkbox";
import TmsModal from "../../tms-customs/tms-modal";

const TmsSCCreateUserModal = ({
  showCreateCounterModal,
  setshowCreateCounterModal,
  setUpdateFlag,
  editFlag,
  setEditFlag,
  editData,
  setEditData,
  router,
}) => {
  const [counterName, setCounterName] = useState("");
  const [location, setLocation] = useState();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editFlag) {
      setDetails(editData.name, editData.location, editData.enabled);
    }
  }, [editFlag]);

  const handleCreateCounter = async () => {
    if (!counterName) {
      return notification.error({ message: `name is required` });
    }

    setLoading(true);
    try {
      let body = {
        name: counterName,
        location: location,
        enabled: disabled,
      };
      if (editFlag) {
        await TmsCounterService.editCounter({
          id: editData.key,
          ...body,
        });
        notification.success({
          message: `POS updated`,
        });
        setDetails("", "", "", "", "");
        setUpdateFlag(editData.id + Math.random());
      } else {
        await TmsCounterService.newCounter(body);
        notification.success({ message: `POS created successfully` });
        setUpdateFlag(Math.random() * 1000 + 9);
      }
      onClose();
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  const setDetails = (counterName, location, isDisabled) => {
    setCounterName(counterName);
    setLocation(location);
    setDisabled(isDisabled);
  };
  const onClose = () => {
    setshowCreateCounterModal(false);
    setEditData(null);
    setEditFlag(false);
  };

  return (
    <TmsModal
      width="40%"
      visible={showCreateCounterModal}
      title={editFlag ? `Edit POS Details - ${editData?.name}` : "Create POS"}
      onOk={handleCreateCounter}
      onCancel={() => {
        setDetails("", "", "", "", "");
        onClose();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setDetails("", "", "", "", "");
            onClose();
          }}
        >
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            handleCreateCounter();
          }}
        >
          Submit
        </Button>,
      ]}
    >
      <Row justify="center" style={{ width: "100%", padding: "10px 0px" }}>
        <Input
          placeholder="POS Name"
          value={counterName}
          onChange={(e) => {
            setCounterName(e.target.value);
          }}
          style={{ width: "400px" }}
        />
      </Row>

      <Row justify="center" style={{ width: "100%", padding: "10px 0px" }}>
        <Input
          placeholder="location"
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
          }}
          style={{ width: "400px" }}
        />
      </Row>

      <TmsCheckbox
        label={"Enable POS"}
        value={disabled}
        setValue={setDisabled}
      />
    </TmsModal>
  );
};
export default withRouter(TmsSCCreateUserModal);
