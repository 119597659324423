import { Button, Col, notification, Row, Select, Space, Tooltip } from "antd";
import { useState, useContext } from "react";
import React from "react";
// import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsShiftService from "../../../../services/tms-shift.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import moment from "moment";
import TmsModal from "../../../tms-customs/tms-modal";
import TmsDcrTemplate from "../../../tms-dcr-template/tms-dcr-template";
import TmsRowVertical from "../../../tms-customs/tms-row-vertical";
import { BaseContext } from "../../../../contexts/BaseContext";
const TmsShiftLogInButton = ({
  shiftId,
  setShiftId,
  isShiftLoggedIn,
  shiftData,
  setShiftData,
  reportPopUp,
  setReportPopUp,
  counterPop,
  setCounterPop,
  shiftChangeLoading,
  setShiftChangeLoading,
  reportVisible,
  setReportVisible,
  router,
}) => {
  const [counterLoading, setCounterLoading] = useState(false);
  const [counterDetails, setCounterDetails] = useState();
  const [counterShiftId, setCounterShiftId] = useState("");
  const { userDetails } = useContext(BaseContext);
  const getCounterLoginShifts = async () => {
    setCounterLoading(true);
    if (
      userDetails?.permissions.includes("SUPER_ADMIN") ||
      userDetails?.permissions.includes("ADMIN")
    ) {
      //
    } else {
      if (!userDetails?.permissions.includes("SHIFT_LOGIN")) {
        notification.error({
          message:
            "This account doesn't has shift-in permission. Please contact your Administrator.",
        });
        return;
      }
    }
    try {
      const counterDetails = await TmsShiftService.GetCounterForUserPreShift();
      setCounterDetails(counterDetails);
      setCounterPop(true);
    } catch (error) {
      catchErrorFunction(error, "Something Went wrong!! Please Login", router);
    }
    setCounterLoading(false);
  };

  const shiftIn = async () => {
    if (shiftChangeLoading) return;
    setShiftChangeLoading(true);
    try {
      const counterObj = counterDetails?.find(
        (item) => item.id === counterShiftId
      );
      // console.log(counterObj);
      await TmsShiftService.shiftLogIn(counterObj.id);
      await isShiftLoggedIn();
      // setShiftData(data);
      setCounterPop(false);
      setShiftChangeLoading(false);
    } catch (error) {
      setShiftChangeLoading(false);
      catchErrorFunction(error, "Token expired!! Login again", router);
    }
  };

  const shiftOut = async () => {
    setShiftChangeLoading(true);
    try {
      const shiftId = await TmsShiftService.shiftLogOut();
      if (shiftId) {
        setShiftId(shiftId);
        setTimeout(() => {
          setReportVisible(true);
        }, 200);
      }
      setShiftData(null);
    } catch (error) {
      catchErrorFunction(error, "Token Expired!! Login again", router);
    }
    setShiftChangeLoading(false);
  };

  return (
    <>
      <Space>
        <div>
          <Tooltip
            placement="bottom"
            title={
              shiftData?.id
                ? `${moment(
                    shiftData?.counterShiftTiming?.startTime,
                    "HH:mm:ss Z"
                  ).format("hh:mm A")} - ${moment(
                    shiftData?.counterShiftTiming?.endTime,
                    "HH:mm:ss Z"
                  ).format("hh:mm A")}`
                : "Shift In"
            }
          >
            <Button
              type="default"
              loading={shiftChangeLoading}
              onClick={() => {
                if (shiftData?.id) {
                  setReportPopUp(true);
                  return;
                } else {
                  getCounterLoginShifts();
                }
              }}
            >
              {shiftData?.id
                ? `Shift Out - ${shiftData.counterShiftTiming.name ?? "-"}`
                : "Shift In"}
            </Button>
          </Tooltip>
        </div>
      </Space>

      <TmsModal
        visible={counterPop}
        width={400}
        onOk={shiftIn}
        closable={false}
        centered
        onCancel={() => setCounterPop(false)}
        footer={[
          <Button key="back" onClick={() => setCounterPop(false)}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={shiftChangeLoading}
            onClick={() => shiftChangeLoading === false && shiftIn()}
          >
            Submit
          </Button>,
        ]}
      >
        <Col>
          <Row justify="start">POS</Row>
          <Row>
            <Select
              style={{ width: "400px", marginTop: "10px" }}
              loading={counterLoading}
              onChange={setCounterShiftId}
            >
              {counterDetails?.map((item) => {
                return (
                  <Select.Option value={item?.id} key={item?.id}>
                    {item?.counter?.name} ({" "}
                    {moment(
                      item?.counterShiftTiming?.startTime,
                      "HH:mm:ss Z"
                    ).format("hh:mm A")}{" "}
                    -{" "}
                    {moment(
                      item?.counterShiftTiming?.endTime,
                      "HH:mm:ss Z"
                    ).format("hh:mm A")}{" "}
                    )
                  </Select.Option>
                );
              })}
            </Select>
          </Row>
        </Col>
      </TmsModal>

      <TmsModal
        visible={reportPopUp}
        width={600}
        footer={null}
        closable={false}
        centered={true}
        onCancel={() => {
          setReportPopUp(false);
          setReportVisible(false);
        }}
      >
        {!reportVisible && (
          <div className="reportPop">
            <TmsRowVertical
              justify="center"
              style={{ justifyContent: "center", marginBottom: "10px" }}
              data={{
                one: " Are you sure you want to end the shift ?",
                two: (
                  <Space style={{ marginTop: "10px" }}>
                    <Button type="primary" onClick={shiftOut}>
                      Yes
                    </Button>
                    <Button onClick={() => setReportPopUp(false)}>No</Button>
                  </Space>
                ),
              }}
            />
          </div>
        )}
        {reportVisible && (
          <TmsDcrTemplate
            shiftId={shiftId}
            setReportPopUp={setReportPopUp}
            setReportVisible={setReportVisible}
          />
        )}
      </TmsModal>
    </>
  );
};
export default withRouter(TmsShiftLogInButton);
