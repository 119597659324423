import { Input, Row } from "antd";
import React from "react";
import "../../assets/css/tms-custom-elements/tms-custom-elements.scss";

const InputPhoneNumber = ({
  size,
  setState,
  value,
  onKeyDown,
  error,
  setError,
}) => {
  return (
    <Row className="d-flex-c">
      <label>Mobile Number</label>
      <Input
        controls={false}
        size={size}
        onWheel={(event) => event.currentTarget.blur()}
        className="mobile-field"
        placeholder="Enter your Phone Number"
        onChange={(e) => {
          if (error !== "") {
            setError("");
          }
          if (e.target.value.length > 10) {
            return;
          }
          setState(e.target.value);
        }}
        value={value}
        type="number"
        addonBefore={<p style={{ margin: "0" }}>+91</p>}
        onKeyDown={onKeyDown}
        style={{ margin: "5px 0px 10px 0px" }}
      />
    </Row>
  );
};
export default InputPhoneNumber;
