import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Row,
  Table,
  Modal,
  Card,
  Statistic,
  notification,
} from "antd";
import moment from "moment";
import TmsDcrTemplate from "../../../components/tms-dcr-template/tms-dcr-template";
import { BaseContext } from "../../../contexts/BaseContext";
import TmsShiftService from "../../../services/tms-shift.service";
import TmsCounterService from "../../../services/tms-counter.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsDatePickerRange from "../../../components/tms-customs/tms-datepicker-range";
import "../../../assets/css/tms-custom-elements/tms-custom-elements.scss";
import "../../../assets/css/tms-reports/tms-reports.scss";
import { DownloadOutlined } from "@ant-design/icons";
import TmsSelect from "../../../components/tms-customs/tms-select";
import tmsRouteMap from "../../../constants/tms-route.map";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsModal from "../../../components/tms-customs/tms-modal";
import { useReactToPrint } from "react-to-print";
import { tmsLogo } from "../../../assets/images/tms-image-list";
import { exportExcel } from "../../../utils/tms-excel/tms-excel";

const TmsReportsShiftReport = ({ router }) => {
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [counterId, setCounterId] = useState("");
  const [counters, setCounters] = useState([]);
  const { userDetails, posMap } = useContext(BaseContext);
  const [data, setData] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [tableTotalSize, setTabelTotalSize] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [total, setTotal] = useState(0);
  const [reportPopUp, setReportPopUp] = useState(false);
  const [shiftId, setShiftId] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedShift, setSelectedShift] = useState("");
  const [modalData, setModalData] = useState([]);
  const [modal, setModal] = useState(false);
  const [, setPrintLoading] = useState(false);
  const refContainer = useRef(null);
  const [excelLoading, setExcelLoading] = useState(false);
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );

  useEffect(() => {
    if (userDetails?.permissions) {
      if (
        userDetails?.permissions?.includes("SUPER_ADMIN") ||
        userDetails?.permissions?.includes("ADMIN")
      ) {
        getShiftCounters();
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getTableData();
  }, [
    fromDate,
    toDate,
    tableCurrentPage,
    tablePageSize,
    userDetails,
    counterId,
    selectedShift,
  ]);

  const viewReport = (id) => {
    setShiftId(id);
    setReportPopUp(true);
  };

  const columns = [
    { title: "ID", dataIndex: "id" },
    {
      title: "Username",
      dataIndex: "user",
      key: "user",
      render: (record) => {
        return record?.displayName;
      },
    },
    {
      title: "POS",
      dataIndex: "counter",
      key: "counter",
      render: (text, record) => record?.counter?.name,
    },
    {
      title: "Shift",
      dataIndex: "counterShiftTiming",
      key: "counterShiftTiming",
      render: (text, record) => record?.counterShiftTiming?.name,
    },
    {
      title: "Transaction Count",
      dataIndex: "transactionsCount",
      key: "transactionsCount",
    },

    {
      title: "Login",
      dataIndex: "login",
      key: "login",
      render: (record) => {
        return record ? moment(record).format("DD-MM-YYYY hh:mm A") : "Not yet";
      },
    },
    {
      title: "Logout",
      dataIndex: "logout",
      key: "logout",
      render: (record) => {
        return record ? moment(record).format("DD-MM-YYYY hh:mm A") : "Not Yet";
      },
    },
    {
      title: "Shift Amount",
      render: (text, record) => {
        return (
          (record?.accServicesAmount ?? 0) +
          (record?.darshanamServicesAmount ?? 0) +
          (record?.sevaServicesAmount ?? 0) +
          (record?.gstAmount ?? 0) +
          (record?.prasadamServicesAmount ?? 0) +
          (record?.donationServicesAmount ?? 0) +
          (record?.publicationServicesAmount ?? 0) +
          (record?.kalyanakattaServicesAmount ?? 0) +
          (record?.tollgateServicesAmount ?? 0) +
          (record?.thulabharamServicesAmount ?? 0) +
          (record?.manualEntryServicesAmount ?? 0) +
          (record?.shopProductAmount ?? 0) +
          (record?.accMaintenanceCharge ?? 0) +
          (record?.accAdvanceBookingCharge ?? 0)
        );
      },
    },
    {
      title: "Report",
      render: (text, record) => {
        return (
          <>
            <a
              onClick={() => {
                // history.push(`/report/${record.id}`);
                viewReport(record.id);
              }}
            >
              View Report
            </a>

            <a
              style={{ marginLeft: "10px" }}
              onClick={() => {
                router.navigate(
                  `${tmsRouteMap?.reports_view_shift_transactions}?shiftID=${record.id}`
                );
              }}
            >
              View Transactions
            </a>
            <a
              style={{ marginLeft: "10px" }}
              onClick={() => {
                router.navigate(
                  `${tmsRouteMap?.reports_view_shift_scan_logs}?shiftID=${record.id}`
                );
              }}
            >
              View Scan Logs
            </a>
          </>
        );
      },
    },
  ];

  const getTableData = async () => {
    setLoading(true);
    try {
      const respData = await TmsShiftService.allShiftReports(
        Number(selectedShift ?? 0),
        Number(counterId ?? 0),
        fromDate,
        toDate,
        tableCurrentPage,
        tablePageSize
      );
      setData(respData.items);
      setTabelTotalSize(respData?.meta?.totalItems);
      setTotal(respData?.meta?.totalAmount);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! login again.", router);
      setLoading(false);
    }
  };

  const getShiftCounters = async () => {
    setLoading(true);
    try {
      const respDataCounters = await TmsCounterService.getAllCounter();
      setCounters(respDataCounters.items);
      const respDataShifts = await TmsShiftService.getAllShifts();
      setShifts(respDataShifts.items);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! login again.", router);
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const printReport = async () => {
    setPrintLoading(true);
    try {
      const data = await TmsShiftService.allShiftReports(
        Number(selectedShift ?? 0),
        Number(counterId ?? 0),
        fromDate,
        toDate,
        1,
        1000
      );
      if (data?.items?.length === 0) {
        notification.info({ message: "No records found to Export" });
        setExcelLoading(false);
        return;
      }
      setModalData(data?.items ?? []);
      setModal(true);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setModalData(false);
      setPrintLoading(false);
      setModal(false);
    }
  };

  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      const data = await TmsShiftService.allShiftReports(
        Number(selectedShift ?? 0),
        Number(counterId ?? 0),
        fromDate,
        toDate,
        1,
        1000
      );
      if (data?.items?.length === 0) {
        notification.info({ message: "No records found to Export" });
        setExcelLoading(false);
        return;
      }
      const compiledData = data.items?.map((item, i) => {
        return {
          "S.no": i + 1,
          "Username ID": item.user?.displayName,
          "Counter ": item.counter?.name ?? "NA",
          "Shift ": item.counterShiftTiming?.name ?? "NA",
          "Transaction Count": item?.transactionsCount,
          Login: item?.login
            ? moment(item?.login).format("DD-MM-YYYY hh:mm A")
            : "-",
          Logout: item?.logout
            ? moment(item?.logout).format("DD-MM-YYYY hh:mm A")
            : "-",
          "Shift Amount":
            (item?.accServicesAmount ?? 0) +
            (item?.darshanamServicesAmount ?? 0) +
            (item?.sevaServicesAmount ?? 0) +
            (item?.gstAmount ?? 0) +
            (item?.prasadamServicesAmount ?? 0) +
            (item?.donationServicesAmount ?? 0) +
            (item?.publicationServicesAmount ?? 0) +
            (item?.kalyanakattaServicesAmount ?? 0) +
            (item?.tollgateServicesAmount ?? 0) +
            (item.thulabharamServicesAmount ?? 0) +
            (item?.shopProductAmount ?? 0) +
            (item?.manualEntryServicesAmount ?? 0) +
            (item?.accAdvanceBookingCharge ?? 0) +
            (item?.accMaintenanceCharge ?? 0),
        };
      });
      exportExcel(
        compiledData,
        `shift-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "tms-report-container",
    onAfterPrint: () => {
      setModal(false);
      setModalData([]);
    },
  });

  return loading ? (
    <div style={{ width: "100%", height: "100%" }}>
      <img
        src={tmsLogo}
        alt="logo"
        height={window.innerWidth < 650 ? "50px" : "70px"}
        style={{
          border: "2px solid orange",
          borderRadius: "50%",
        }}
      />
      <br />
      <p style={{ textAlign: "center" }}>Loading...</p>
    </div>
  ) : (
    <div>
      <Row>
        <TmsDatePickerRange
          label="Select Duration"
          to={toDate}
          from={fromDate}
          setFrom={setFromDate}
          setTo={setToDate}
        />

        <TmsSelect
          selectClass="w-100"
          classname="ml-10"
          type="col"
          value={counterId}
          setValue={setCounterId}
          label="POS"
          data={counters}
          span={4}
        />

        <TmsSelect
          selectClass="w-100"
          classname="ml-10"
          type="col"
          value={selectedShift}
          setValue={setSelectedShift}
          label="Shift"
          data={shifts}
          span={4}
        />

        <Col className="ml-10">
          <Row justify="start" style={{ marginBottom: "5px" }}>
            Update
          </Row>
          <Row justify="start">
            <Button type="primary" onClick={getTableData}>
              Fetch
            </Button>
          </Row>
        </Col>
        {!userDetails?.permissions.find((item) => posMap.includes(item)) && (
          <>
            <Col className="ml-10">
              <Row justify="start" style={{ marginBottom: "5px" }}>
                Print
              </Row>
              <Row justify="start">
                <Button type="primary" onClick={printReport}>
                  Print
                </Button>
              </Row>
            </Col>
            <Col className="ml-10">
              <Row
                justify="start"
                style={{ marginBottom: "5px", color: "transparent" }}
              >
                .
              </Row>
              <Row justify="start">
                <Button
                  loading={excelLoading}
                  type="primary"
                  onClick={handleExcelExport}
                >
                  <DownloadOutlined /> Excel
                </Button>
              </Row>
            </Col>
          </>
        )}
        <Col
          style={{
            width: "15%",
            textAlign: "right",
            margin: "auto",
            marginRight: "0",
          }}
        >
          <Card hoverable size="small" style={{ fontWeight: "bold" }}>
            <Statistic
              prefix="₹"
              title="Total Collection"
              value={total === null || total === 0 ? 0 : total}
            />
          </Card>
        </Col>
      </Row>
      <br />
      <Table
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        scroll={{ x: true }}
        size="small"
        onChange={handleTableChange}
        columns={columns}
        dataSource={data}
        style={{ textTransform: "capitalize" }}
      />
      <Modal
        visible={reportPopUp}
        width={450}
        footer={null}
        closable={true}
        centered
        onCancel={() => {
          setReportPopUp(false);
        }}
      >
        <TmsDcrTemplate
          shiftId={shiftId}
          setReportPopUp={setReportPopUp}
          from="shiftReportTable"
        />
      </Modal>

      <TmsModal
        visible={modal}
        width={700}
        closable={false}
        centered
        onCancel={() => {
          setModal(false);
          setModalData([]);
          setPrintLoading(false);
        }}
      >
        <div className="tms-modal-div">
          <Button type="primary" className="print-button" onClick={handlePrint}>
            Print
          </Button>
        </div>
        <br />
        <div className="tms-report-container" ref={refContainer}>
          <h3 className="ta-center">{templeDetails?.name}</h3>

          <h3 className="ta-center">Day-Wise Shift Report</h3>
          <table className="tms-report-table" style={{ fontSize: "14px" }}>
            <thead>
              <th>ID </th>
              <th>Username</th>
              <th>POS</th>
              <th>Shift</th>
              <th>Transaction Count</th>
              <th>Login</th>
              <th>Logout</th>
              <th>Shift Amount</th>
            </thead>
            <tbody>
              {modalData?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item?.id}</td>
                    <td>{item?.user?.displayName}</td>
                    <td>{item?.counter.name}</td>
                    <td>{item?.counterShiftTiming?.name}</td>
                    <td>{item?.transactionsCount}</td>
                    <td>
                      {item?.login
                        ? moment(item?.login).format("DD-MM-YYYY hh:mm A")
                        : "-"}
                    </td>
                    <td>
                      {item?.logout
                        ? moment(item?.logout).format("DD-MM-YYYY hh:mm A")
                        : "-"}
                    </td>
                    <td>
                      {(item?.amountDeposited ?? 0) +
                        (item?.accServicesAmount ?? 0) +
                        (item?.darshanamServicesAmount ?? 0) +
                        (item?.sevaServicesAmount ?? 0) +
                        (item?.gstAmount ?? 0) +
                        (item?.prasadamServicesAmount ?? 0) +
                        (item?.donationServicesAmount ?? 0) +
                        (item?.publicationServicesAmount ?? 0) +
                        (item?.kalyanakattaServicesAmount ?? 0) +
                        (item?.tollgateServicesAmount ?? 0) +
                        (item?.thulabharamServicesAmount ?? 0) +
                        (item?.shopProductAmount ?? 0) +
                        (item?.manualEntryServicesAmount ?? 0) +
                        (item?.accAdvanceBookingCharge ?? 0) +
                        (item?.accMaintenanceCharge ?? 0)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <h2 className="ta-center">Shift Total Amount: {total}</h2>
        </div>
      </TmsModal>
    </div>
  );
};

export default withRouter(TmsReportsShiftReport);
