import { Row } from "antd";
import React, { useContext } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import checkPermissions from "../../../utils/tms-permissions/tms-permissions";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import "../../../assets/css/tms-custom-elements/tms-custom-elements.scss";
import TmsReportsMap from "../../../constants/tms-reports.map";
import TmsDisplayCard from "../../../components/tms-customs/tms-cards";
const TmsReportsHome = ({ router }) => {
  const { userDetails } = useContext(BaseContext);

  return (
    <Row gutter={[20, 20]}>
      {TmsReportsMap.map((card, i) => {
        return checkPermissions(userDetails, card) ? (
          <TmsDisplayCard
            label="pos"
            card={card}
            router={router}
            i={i + `reports`}
            key={`tms-report-${i}`}
          />
        ) : (
          ""
        );
      })}
    </Row>
  );
};
export default withRouter(TmsReportsHome);
