import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import { Button, Col, notification, Row } from "antd";
import { BaseContext } from "../../contexts/BaseContext";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import TmsTable from "../../components/tms-customs/tms-table";
import TmsCmsSlotsModal from "./tms-cms-slots-modal";
import CSData from "../../constants/tms-common-slots.map";
import tmsColumsFunction from "../../constants/tms-common-slots-table-colums";
import { useNavigate } from "react-router";

const TmsCmsSlots = ({ router, type }) => {
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState();
  const [loading, setLoading] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const { userDetails } = useContext(BaseContext);
  const types = type;
  const navigate = useNavigate();

  const handleModify = async (record) => {
    setEditData(record);
    setEditFlag(true);
    setShowModal(true);
  };

  useEffect(() => {
    let id = new URLSearchParams(router.location.search).get("id");
    let slotId = new URLSearchParams(router.location.search).get("slotid");
    if (!id && !slotId) {
      navigate(CSData[type].prevRoute);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [updateFlag, router?.location?.pathname]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
  };

  const handleDelete = async (record) => {
    setLoading(true);
    try {
      await CSData[type].deleteService(record.key);
      notification.success({
        message: `${
          new URLSearchParams(router.location.search).get("slotid")
            ? "Shift has been deleted"
            : "POS has been successfully deleted"
        }`,
      });
      fetchData();
    } catch (error) {
      catchErrorFunction(error, "Token Expired!! Please Login", router);
    }

    setLoading(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      let id = new URLSearchParams(router.location.search).get("id");
      let slotId = new URLSearchParams(router.location.search).get("slotid");

      const shifts = await CSData[type].fecthService(slotId ? slotId : id);
      setData(
        shifts?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token is expired. You wll be navigated to login page",
        router
      );
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Row style={{ width: "100%", marginBottom: "15px" }} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              setShowModal(true);
            }}
            disabled={
              userDetails?.permissions?.includes("SUPER_ADMIN") ||
              userDetails?.permissions?.includes("ADMIN")
                ? false
                : true
            }
          >
            {CSData[type].buttonLabel}
          </Button>
        </Col>
      </Row>
      <Row style={{ width: "100%" }}>
        <TmsTable
          dataSource={data}
          loading={loading}
          columns={tmsColumsFunction(type, handleDelete, handleModify, router)}
          handleTableChange={handleTableChange}
          pagination={{
            current: tableCurrentPage,
            pageSize: 10,
          }}
        />
      </Row>
      <TmsCmsSlotsModal
        type={types}
        counterId={new URLSearchParams(router?.location?.search).get("counter")}
        productId={new URLSearchParams(router?.location?.search).get("id")}
        showModal={showModal}
        setShowModal={setShowModal}
        setUpdateFlag={setUpdateFlag}
        editFlag={editFlag}
        setEditFlag={setEditFlag}
        editData={editData}
        setEditData={setEditData}
        router={router}
      />
    </div>
  );
};
export default withRouter(TmsCmsSlots);
