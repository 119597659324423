import React, { useState, useEffect } from "react";
import { Row, Col, Table, Space, Button } from "antd";
import moment from "moment";
import TmsShiftService from "../../../services/tms-shift.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsDatePickerRange from "../../../components/tms-customs/tms-datepicker-range";

const TmsReportsScanLogs = ({ router }) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [reportData, setReportData] = useState("");
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const productMap = {
    counter_seva: "Sevas",
    counter_darshanam: "Darshanam",
    counter_donation: "Donation",
    counter_prasadam: "Prasadam",
    counter_thulabharam: "Thulabharam",
  };

  const fetchAllTransactions = async () => {
    setDataLoading(true);
    try {
      setReportData("");
      const data = await TmsShiftService.getAllScanLogs(
        tableCurrentPage,
        tablePageSize,
        toDate,
        fromDate
      );
      setReportData(data?.items);
      setTabelTotalSize(data?.meta?.totalItems);
    } catch (error) {
      catchErrorFunction(error, "Token expired! Please login again.", router);
    }
    setDataLoading(false);
  };

  useEffect(() => {
    fetchAllTransactions();
  }, [fromDate, toDate, tableCurrentPage, tablePageSize]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Display Name",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "POS Name",
      dataIndex: "counterName",
      key: "counterName",
    },
    {
      title: "Seva Type",
      dataIndex: "productType",
      render: (record) => {
        return productMap[record];
      },
    },

    { title: "Ticket Id", dataIndex: "ticketId", key: "ticketId" },

    {
      title: "Order Date - Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MM-YYYY HH:mm A"),
    },

    { title: "outcome", dataIndex: "outcome", key: "outcome" },
    {
      title: "Failure reason",
      dataIndex: "failureReason",
      render: (record) => record ?? "-",
    },
  ];

  return (
    <>
      <Row
        style={{ marginBottom: "20px", display: "flex", marginLeft: "0px" }}
        gutter={[20, 20]}
      >
        <TmsDatePickerRange
          label="Select Duration"
          to={toDate}
          from={fromDate}
          setFrom={setFromDate}
          setTo={setToDate}
        />

        <Col
          style={{
            maxWidth: "100px",
            marginLeft: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Button type="primary" onClick={() => fetchAllTransactions()}>
            Fetch
          </Button>
        </Col>
      </Row>

      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Scan Logs</span>
        </Space>
      </Row>
      <Table
        style={{ width: "100%", textTransform: "capitalize" }}
        columns={columns}
        dataSource={reportData}
        loading={dataLoading}
        size="small"
        scroll={{ x: true }}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
      ></Table>
    </>
  );
};

export default withRouter(TmsReportsScanLogs);
