import React, { useRef, useEffect, useState } from "react";
import { Row, Col, Button, Card, Space, notification } from "antd";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import TmsShiftService from "../../services/tms-shift.service";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import { FormatNumberINRCurrency } from "../../utils/tms-currency-formatter/tms-curreny-formatter";
import TmsSingleWrapper from "../tms-customs/tms-row-wrapper";
import "../../assets/css/tms-dcr/tms-dcr.scss";
import { tmsLogo } from "../../assets/images/tms-image-list";
import { useContext } from "react";
import { BaseContext } from "../../contexts/BaseContext";

const TmsDcrTemplate = ({
  shiftId,
  setReportPopUp,
  setReportVisible,
  from,
  router,
}) => {
  let refContainer = useRef(null);
  let count = 0;
  const { userDetails } = useContext(BaseContext);
  const [reportData, setReportData] = useState([]);
  const [thisShiftCollection, setThisShiftCollection] = useState("");
  const [totalSevaCollection, setTotalSevaCollection] = useState(0);
  const [totalAnnaPrasadamCollection, setTotalAnnaPrasadamCollection] =
    useState(0);
  const [loading, setLoading] = useState(true);
  const [totalSevaTickets, setTotalSevaTickets] = useState(0);
  const [totalAnnaPrasadamTicket, setTotalAnnaPrasadamTicket] = useState(0);
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );

  const fetchReport = async () => {
    // console.log(shiftId);
    setLoading(true);
    let totalTickets = 0;
    let sevaCollection = 0;
    let annaPrasadamCollection = 0;
    let annaPrasadamTicket = 0;
    try {
      const repData = await TmsShiftService.ShiftReportChallan(shiftId, true);
      repData?.collectionTable?.forEach((item) => {
        if (
          item?.productName?.toLowerCase().includes("annaprasadam") === false
        ) {
          totalTickets = totalTickets + Number(item?.productTickets);
          sevaCollection = sevaCollection + Number(item?.collection);
        }
        if (item?.name?.toLowerCase().includes("annaprasadam") === true) {
          annaPrasadamCollection =
            annaPrasadamCollection + Number(item?.collection);
          annaPrasadamTicket =
            annaPrasadamTicket + Number(item?.productTickets);
        }
      });
      setTotalSevaTickets(totalTickets);
      setTotalSevaCollection(sevaCollection);
      setTotalAnnaPrasadamCollection(annaPrasadamCollection);
      setTotalAnnaPrasadamTicket(annaPrasadamTicket);
      setReportData(repData);
      setThisShiftCollection(
        // Number(repData?.accDepositedAmount ?? 0) +
        Number(repData?.accServicesAmount ?? 0) +
          Number(repData?.darshanamServicesAmount ?? 0) +
          Number(repData?.sevaServicesAmount ?? 0) +
          Number(repData?.prasadamServicesAmount ?? 0) +
          Number(repData?.gstAmount ?? 0) +
          Number(repData?.donationServicesAmount ?? 0) +
          Number(repData?.publicationServicesAmount ?? 0) +
          Number(repData?.tollgateServicesAmount ?? 0) +
          Number(repData?.thulabharamServicesAmount ?? 0) +
          Number(repData?.kalyanakattaServicesAmount ?? 0) +
          Number(repData?.manualEntryServicesAmount ?? 0) +
          Number(repData?.shopProductAmount + 0) +
          Number(repData?.accAdvanceBookingCharge ?? 0) +
          Number(repData?.accMaintenanceCharge ?? 0)
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Login Again", router);
      setLoading(false);
    }
  };

  const handleFunction = () => {
    // setButtonLoading(true);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
    setTimeout(() => {
      try {
        handlePrint();
      } catch (E) {
        notification.error({ message: E?.message });
      }
    }, 800);
  };

  useEffect(() => {
    shiftId && fetchReport();
  }, [shiftId]);

  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "sd-report-container",
    onAfterPrint: () => {
      if (from === "shiftReportTable") {
        setReportPopUp(false);
      } else {
        setReportPopUp();
        setReportVisible();
        setReportData();
      }
      setLoading(false);
    },
  });

  const reportBody = (donationFlag) => {
    return (
      <Card className="cardDiv" style={{ color: "black" }}>
        <Row className="top-id">{reportData?.id}</Row>

        <Row className="top-date">
          Taken on: {moment().format("DD-MM-YYYY HH:mm:ss A")}
        </Row>
        <Row className="headerrow">
          <h3
            className="boldText contantfontsize"
            style={{ textTransform: "uppercase", fontSize: "13px" }}
          >
            {templeDetails?.name} <br />
          </h3>
        </Row>

        {/* <Row className=" header-2">
          Indrakeeladri, Mallikarjunapeta, Vijayawada, Andhra Pradesh 520001
        </Row> */}
        <div className="border-dash"></div>

        <Row className="headerrow contantfontsize">
          <h3 className="mb-0">
            {reportData?.shiftContains?.join()?.replaceAll(",", "-")} Challan
            Summary Report <br />
            {moment(reportData?.login).format("DD-MM-YYYY")}
          </h3>
        </Row>
        <div className="border-dash"></div>

        <div
          className="headerrow contantfontsize headercol"
          style={{ marginTop: "10px" }}
        >
          <p
            className="boldText"
            style={{ textTransform: "capitalize", marginLeft: "10px" }}
          >
            Shift Name : {reportData?.counterShiftTiming?.name}
          </p>
          <p className="boldText ml-10">
            Shift Timings : (
            {moment(
              reportData?.counterShiftTiming?.startTime,
              "HH:mm:ss"
            ).format("hh:mm A")}
            -
            {moment(reportData?.counterShiftTiming?.endTime, "HH:mm:ss").format(
              "hh:mm A"
            )}{" "}
            )
          </p>
          <p className="boldText ml-10">
            Employee name: {reportData?.user?.displayName}
          </p>
          <p className="boldText ml-10">
            POS name: {reportData?.counter?.name}
          </p>
          <p className="boldText ml-10">
            Login : {moment(reportData?.login).format("DD-MM-YYYY HH:mm:ss A")}
          </p>
          <p className="boldText ml-10">
            Logout :{" "}
            {reportData?.logout
              ? moment(reportData?.logout).format("DD-MM-YYYY HH:mm:ss A")
              : "Logged in"}
          </p>
        </div>
        {reportData?.shiftReport?.sd_counter?.name ===
          "online ticket scanning counter" && (
          <TmsSingleWrapper className="contantfontsize">
            <p className="boldText">Total Scanned Tickets :</p>
            <p>{reportData?.successfulTicketsScanned}</p>
          </TmsSingleWrapper>
        )}

        {/* Amount Details */}
        <table className="reportTable contantfontsize">
          {(() => {
            if (reportData?.shiftContains?.length > 0) {
              return (
                <tr>
                  <th>POS S.no</th>
                  <td style={{ whiteSpace: "nowrap" }}>
                    (
                    {reportData?.shiftStartAndEndSequence?.startingSequence
                      ? reportData?.shiftStartAndEndSequence?.startingSequence
                          ?.split("-")
                          .pop()
                      : "Null"}
                    ) - (
                    {reportData?.shiftStartAndEndSequence?.endingSequence
                      ? reportData?.shiftStartAndEndSequence?.endingSequence
                          ?.split("-")
                          .pop()
                      : "Null"}
                    )
                  </td>
                </tr>
              );
            }
            // else if (
            //   reportData?.shiftContains?.length === 1 &&
            //   reportData?.shiftContains?.[0] !== "accommodation"
            // ) {
            //   return (
            //     <tr>
            //       <th>POS S.no</th>
            //       <td style={{ whiteSpace: "nowrap" }}>
            //         (
            //         {reportData?.shiftStartAndEndSequence?.startingSequence
            //           ? reportData?.shiftStartAndEndSequence?.startingSequence
            //               ?.split("-")
            //               ?.pop()
            //           : "Null"}
            //         ) - (
            //         {reportData?.shiftStartAndEndSequence?.endingSequence
            //           ? reportData?.shiftStartAndEndSequence?.endingSequence
            //               ?.split("-")
            //               ?.pop()
            //           : "Null"}
            //         )
            //       </td>
            //     </tr>
            //   );
            // }
          })()}
          {(() => {
            if (
              reportData?.shiftContains?.includes("accommodation") &&
              donationFlag === false
            ) {
              return (
                <>
                  {reportData?.accPreviousDepositAmount > 0 && (
                    <tr>
                      <th>Deposit Opening Balance</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.accPreviousDepositAmount ?? 0
                        )}
                      </td>
                    </tr>
                  )}

                  {thisShiftCollection > 0 && (
                    <tr>
                      <th>This Shift Deposit Collection</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.accDepositedAmount ?? 0
                        )}
                      </td>
                    </tr>
                  )}

                  {/* {reportData?.accPreviousDepositAmount + thisShiftCollection >
                    0 && (
                    <tr>
                      <th>Total Amount</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.accPreviousDepositAmount +
                            thisShiftCollection ?? 0
                        )}
                      </td>
                    </tr>
                  )} */}
                  {reportData?.accDepositReturnedAmount > 0 && (
                    <tr>
                      <th>Deposit Amount Paid to Devotees</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.accDepositReturnedAmount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                  {reportData?.accPreviousDepositAmount +
                    reportData?.accDepositedAmount -
                    reportData?.accDepositReturnedAmount >
                    0 && (
                    <tr>
                      <th>Closing Balance</th>
                      <td>
                        {FormatNumberINRCurrency(
                          (reportData?.accPreviousDepositAmount ?? 0) +
                            (reportData?.accDepositedAmount ?? 0) -
                            (reportData?.accDepositReturnedAmount ?? 0)
                        )}
                      </td>
                    </tr>
                  )}
                  {/* {reportData?.accPreviousDepositAmount +
                    thisShiftCollection -
                    reportData?.accDepositReturnedAmount -
                    reportData?.accServicesAmount -
                    reportData?.gstAmount >
                    0 && (
                    <tr>
                      <th>Advance Cash Balance</th>
                      <td>
                        {FormatNumberINRCurrency(
                          (reportData?.accPreviousDepositAmount ?? 0) +
                            (thisShiftCollection ?? 0) -
                            (reportData?.accDepositReturnedAmount ?? 0) -
                            (reportData?.accServicesAmount ?? 0) -
                            (reportData?.gstAmount ?? 0)
                        )}
                      </td>
                    </tr>
                  )} */}
                  {reportData?.accServicesAmount > 0 && (
                    <tr>
                      <th>Rent Collection Amount ( Excluding GST )</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.accServicesAmount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                  {reportData?.gstAmount > 0 && (
                    <tr>
                      <th>GST Amount</th>
                      <td>
                        {FormatNumberINRCurrency(reportData?.gstAmount ?? 0)}
                      </td>
                    </tr>
                  )}
                  {reportData?.accPreviousDepositAmount > 0 && (
                    <tr>
                      <th>Maintenance Amount</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.accMaintenanceCharge ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                  {reportData?.accAdvanceBookingCharge > 0 && (
                    <tr>
                      <th>Advance Booking Amount</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.accAdvanceBookingCharge ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                  {reportData?.accServicesAmount + reportData?.gstAmount >
                    0 && (
                    <tr>
                      <th>Rent Collection Amount ( Including GST )</th>
                      <td style={{ fontSize: "14px", fontWeight: "700" }}>
                        {FormatNumberINRCurrency(
                          (reportData?.accServicesAmount ?? 0) +
                            (reportData?.gstAmount ?? 0)
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
          })()}
          {(() => {
            if (
              reportData?.shiftContains?.includes("darshanam") &&
              donationFlag === false
            ) {
              return (
                <>
                  {reportData?.darshanamServicesAmount > 0 && (
                    <tr>
                      <th>Darshanam Collection Amount</th>
                      <td style={{ fontSize: "14px", fontWeight: "700" }}>
                        {FormatNumberINRCurrency(
                          reportData?.darshanamServicesAmount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
          })()}
          {(() => {
            if (
              reportData?.shiftContains?.includes("seva") &&
              donationFlag === false
            ) {
              return (
                <>
                  {reportData?.sevaServicesAmount > 0 && (
                    <tr>
                      <th>Seva Collection Amount</th>
                      <td style={{ fontSize: "14px", fontWeight: "700" }}>
                        {FormatNumberINRCurrency(
                          reportData?.sevaServicesAmount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
          })()}
          {(() => {
            if (
              reportData?.shiftContains?.includes("publication") &&
              donationFlag === false
            ) {
              return (
                <>
                  {reportData?.publicationServicesAmount > 0 && (
                    <tr>
                      <th>Publication Collection Amount</th>
                      <td style={{ fontSize: "14px", fontWeight: "700" }}>
                        {FormatNumberINRCurrency(
                          reportData?.publicationServicesAmount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
          })()}
          {(() => {
            if (reportData?.shiftContains?.includes("donation")) {
              return (
                <>
                  {reportData?.donationServicesAmount > 0 && (
                    <tr>
                      <th>Donation Collection Amount</th>
                      <td style={{ fontSize: "14px", fontWeight: "700" }}>
                        {FormatNumberINRCurrency(
                          reportData?.donationServicesAmount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
          })()}
          {(() => {
            if (
              reportData?.shiftContains?.includes("prasadam") &&
              donationFlag === false
            ) {
              return (
                <>
                  {reportData?.prasadamServicesAmount > 0 && (
                    <tr>
                      <th>Prasadam Collection Amount</th>
                      <td style={{ fontSize: "14px", fontWeight: "700" }}>
                        {FormatNumberINRCurrency(
                          reportData?.prasadamServicesAmount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
          })()}
          {(() => {
            if (
              reportData?.shiftContains?.includes("tollgate") &&
              donationFlag === false
            ) {
              return (
                <>
                  {reportData?.tollgateServicesAmount > 0 && (
                    <tr>
                      <th>Tollgate Collection Amount</th>
                      <td style={{ fontSize: "14px", fontWeight: "700" }}>
                        {FormatNumberINRCurrency(
                          reportData?.tollgateServicesAmount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
          })()}
          {(() => {
            if (
              reportData?.shiftContains?.includes("thulabharam") &&
              donationFlag === false
            ) {
              return (
                <>
                  {reportData?.thulabharamServicesAmount > 0 && (
                    <tr>
                      <th>Thulabharam Collection Amount</th>
                      <td style={{ fontSize: "14px", fontWeight: "700" }}>
                        {FormatNumberINRCurrency(
                          reportData?.thulabharamServicesAmount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
          })()}
          {(() => {
            if (reportData?.shiftContains?.includes("kalyanakatta")) {
              return (
                <>
                  {reportData?.kalyanakattaServicesAmount > 0 && (
                    <tr>
                      <th>Kalyanakatta Collection Amount</th>
                      <td style={{ fontSize: "14px", fontWeight: "700" }}>
                        {FormatNumberINRCurrency(
                          reportData?.kalyanakattaServicesAmount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
          })()}
          {(() => {
            if (reportData?.shiftContains?.includes("manual_entry")) {
              return (
                <>
                  {reportData?.kalyanakattaServicesAmount > 0 && (
                    <tr>
                      <th>Manual Entry Collection Amount</th>
                      <td style={{ fontSize: "14px", fontWeight: "700" }}>
                        {FormatNumberINRCurrency(
                          reportData?.manualEntryServicesAmount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
          })()}
          {thisShiftCollection > 0 && (
            <tr>
              <th style={{ fontSize: "18px", fontWeight: "700" }}>
                Grand Total
              </th>
              <td style={{ fontSize: "18px", fontWeight: "700" }}>
                {FormatNumberINRCurrency(thisShiftCollection ?? 0)}
              </td>
            </tr>
          )}
        </table>

        {/* Product Details */}
        {reportData?.collectionTable?.length !== 0 && (
          <table className="bookingTable contantfontsize">
            <tr>
              <th className="serialNo">S. No.</th>
              <th className="itemName">Name</th>
              <th className="tableData">Qty</th>
              <th className="tableData">PR/Unit</th>
              <th className="collection">Total</th>
            </tr>
            {reportData?.collectionTable?.map((items, i) => (
              <tr key={"report-one" + i}>
                {items?.productName?.toLowerCase().includes("annaprasadam") ===
                  donationFlag && (
                  <>
                    <td className="serialNo"></td>
                    <td className="itemName">
                      {items?.productName}
                      <span style={{ fontSize: "8" }}>
                        Sl.no: (
                        {items?.startingCategorySequence?.substring(
                          items?.startingCategorySequence?.lastIndexOf("-") + 1
                        )}
                        -
                        {items?.endingCategorySequence?.substring(
                          items?.endingCategorySequence?.lastIndexOf("-") + 1
                        )}
                        )
                      </span>
                    </td>

                    <td className="tableData">
                      {items?.productTickets ? items?.productTickets : "NA"}
                    </td>
                    <td className="tableData">
                      {items?.endingCategorySequence.search("dona") > 0
                        ? "NA"
                        : `Rs. ${
                            items?.productPrice ? items?.productPrice : "-"
                          }`}
                    </td>
                    <td className="tableData">Rs. {items?.collection}</td>
                  </>
                )}
              </tr>
            ))}
            <tr>
              <th className="tableData" colSpan="2">
                Total
              </th>
              <td className="tableData">
                {donationFlag === true
                  ? totalAnnaPrasadamTicket
                  : totalSevaTickets}
              </td>
              <td></td>
              <td className="tableData">
                {donationFlag === true
                  ? FormatNumberINRCurrency(totalAnnaPrasadamCollection)
                  : FormatNumberINRCurrency(totalSevaCollection)}
              </td>
            </tr>
          </table>
        )}
        {/* TicketLog Details */}
        {reportData?.scanTicketLogTable?.length !== 0 && (
          <table className="reportTable contantfontsize">
            <tr>
              <th>Type</th>
              <th>Outcome</th>
              <th>count</th>
            </tr>
            {reportData?.scanTicketLogTable?.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item?.type}</td>
                  <td>{item?.outcome}</td>
                  <td>{item?.count}</td>
                </tr>
              );
            })}
          </table>
        )}

        <div className="reportFooter contantfontsize">
          <Row className="footerContent-bottom">
            {["CC", "CASHIER", "SUPT", "AEO"].map((item, i) => {
              return (
                <Col
                  key={"footer-content" + i}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p className="boldText mb-0">__________</p>
                  <p className="boldText">{item} :</p>
                </Col>
              );
            })}
          </Row>

          {reportData?.shiftContains?.map((items, i) => {
            if (items === "accommodation") {
              return (
                <Row className="footerContent contantfontsize" key={i}>
                  <Space>
                    <Col>
                      <Space>
                        <p className="boldText">Check In :</p>
                        <p>{reportData?.accTicketsCount}</p>
                      </Space>
                    </Col>
                    <Col>
                      <Space>
                        <p className="boldText">Check Out :</p>
                        <p>{reportData?.accCheckoutCount}</p>
                      </Space>
                    </Col>
                    <Col>
                      <Space>
                        <p className="boldText">Total Amount :</p>
                        <p>
                          {FormatNumberINRCurrency(
                            (reportData?.previousDeposit ?? 0) +
                              (thisShiftCollection ?? 0)
                          )}
                        </p>
                      </Space>
                    </Col>
                  </Space>
                </Row>
              );
            }
          })}
        </div>
      </Card>
    );
  };

  const annaPrasadamCount = () => {
    count = count + 1;
    // console.log(count);
    if (count === 1) {
      return (
        <>
          {reportBody(true)}
          {reportBody(true)}
        </>
      );
    }
  };
  const permission = () => {
    return userDetails?.permissions.includes("SUPER_ADMIN") ||
      userDetails?.permissions.includes("ADMIN")
      ? true
      : false;
  };
  return (
    <div
      className="report"
      style={{
        width: "100%",
        textAlign: "center",
      }}
    >
      {permission() || from !== "shiftReportTable" ? (
        <Button
          type="primary"
          style={{ margin: "20px" }}
          onClick={() => {
            !loading && handleFunction();
          }}
        >
          Print
        </Button>
      ) : (
        ""
      )}

      <div ref={refContainer} className="sd-report-container">
        {loading ? (
          <div>
            {" "}
            <img src={tmsLogo} height="70px" />
            <p>Loading.....</p>
          </div>
        ) : (
          <>
            {" "}
            {reportBody(false)}
            {reportData?.collectionTable?.map((items) =>
              items?.productName?.toLowerCase().includes("annaprasadam") ===
              true
                ? annaPrasadamCount()
                : ""
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(TmsDcrTemplate);
