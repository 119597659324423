import { Button, Col, Input, notification, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsCounterService from "../../../services/tms-counter.service";
import TmsManualEntryService from "../../../services/tms-manual-entry.service";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const Option = Select;

const AddBook = ({ router, setViewSlider, setUpdateFlag }) => {
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [counters, setCounters] = useState([]);
  const [counterId, setCounterId] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [categoryArray, setCategoryArray] = useState([
    {
      id: 1,
      name: "",
      price: 0,
    },
  ]);

  useEffect(() => {
    fetchCounter();
  }, []);

  const fetchCounter = async () => {
    setLoading(true);
    try {
      const resp = await TmsCounterService.getAllCounter(1, 100);
      setCounters(resp?.items);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const add = (i) => {
    let data = [...categoryArray];
    data.push({
      id: i + 1,
      name: "",
      price: "",
    });
    setCategoryArray(data);
  };

  const deleteData = (i) => {
    let data = categoryArray.filter((item) => item?.id !== i?.id);
    setCategoryArray(
      data?.map((item, i) => {
        item.id = i + 1;
        return item;
      })
    );
  };

  const clearFields = () => {
    setViewSlider(false);
    setUpdateFlag(Math.random() * 100);
    setType("");
    setCounters([]);
    setCounterId("");
    setFrom("");
    setTo("");
    notification.success({
      message: "New Book Added Successful",
    });
  };

  const handleSubmit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let body = [
      {
        name: type,
        counterId: Number(counterId),
        seriesStart: Number(from),
        seriesEnd: Number(to),
        categories: categoryArray.map((item) => {
          return { name: item?.name, price: item?.price };
        }),
      },
    ];
    try {
      const resp = await TmsManualEntryService.addBook(body[0]);
      setCounters(resp?.items);
      clearFields();
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  return (
    <div>
      <Row gutter={[20, 20]}>
        <Col style={{ width: "50%" }}>
          <label>Name</label>
          <br />
          <Input
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
          />
        </Col>
        <Col style={{ width: "50%" }}>
          <label>Counter</label>
          <br />
          <Select
            style={{ width: "100%" }}
            onChange={(e) => setCounterId(e)}
            value={counterId}
          >
            {counters?.map((a) => {
              return (
                <Option value={a?.id} key={a?.name}>
                  {a?.name}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <br />
      <Row gutter={[20, 20]}>
        <Col style={{ width: "50%" }}>
          <label>From</label>
          <br />
          <Input value={from} onChange={(e) => setFrom(e.target.value)} />
        </Col>
        <Col style={{ width: "50%" }}>
          <label>To</label>
          <br />
          <Input value={to} onChange={(e) => setTo(e.target.value)} />
        </Col>
      </Row>
      <br />
      {categoryArray?.map((item, index) => {
        return (
          <>
            <Row gutter={[20, 20]}>
              <Col style={{ width: "7%" }}>
                <br />
                <Button
                  type="primary"
                  style={{
                    padding: "3px 2px",
                    display: "flex",
                    fontSize: "24px",
                    borderRadius: "20px",
                    backgroundColor:
                      index + 1 === categoryArray.length ? "#1890ff" : "red",
                    border: `1px solid ${
                      index + 1 === categoryArray.length ? "#1890ff" : "red"
                    }`,
                  }}
                  onClick={() =>
                    index + 1 === categoryArray.length
                      ? add(categoryArray.length)
                      : deleteData(item)
                  }
                >
                  {index + 1 === categoryArray.length ? (
                    <PlusCircleOutlined />
                  ) : (
                    <CloseCircleOutlined />
                  )}
                </Button>
              </Col>
              <Col style={{ width: "43%" }}>
                <label>Category</label>
                <br />
                <Input
                  value={categoryArray[index]?.name}
                  onChange={(e) => {
                    let data = [...categoryArray];
                    data[index].name = e.target.value;
                    setCategoryArray(data);
                  }}
                />
              </Col>
              <Col style={{ width: "50%" }}>
                <label>Price</label>
                <br />
                <Input
                  type="number"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  value={categoryArray[index]?.price}
                  onChange={(e) => {
                    let data = [...categoryArray];
                    data[index].price = Number(e.target.value);
                    setCategoryArray(data);
                  }}
                />
              </Col>
            </Row>
            <br />
          </>
        );
      })}

      <br />
      <Row>
        <Button
          type="primary"
          onClick={() => {
            handleSubmit();
          }}
          loading={loading}
        >
          Submit
        </Button>
      </Row>
    </div>
  );
};

export default AddBook;
