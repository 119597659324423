import React, { useState, useEffect } from "react";
import {
  notification,
  Row,
  Col,
  Table,
  Space,
  Button,
  InputNumber,
  Input,
  Statistic,
} from "antd";
import moment from "moment";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsShiftService from "../../../../services/tms-shift.service";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsSRShiftDetailsCard from "../../../../components/tms-reports/tms-shift-reports/tms-sr-view-transactions-card";
import TmsModal from "../../../../components/tms-customs/tms-modal";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import "../../../../assets/css/tms-reports/tms-reports.scss";
import TmsCartService from "../../../../services/tms-cart.service";
import TmsPrintTicket from "../../../../components/tms-print-ticket/tms-print-ticket";
import postBookingDataFunction from "../../../../utils/tms-post-booking-data/tms-post-booking-data";
import { useContext } from "react";
import { BaseContext } from "../../../../contexts/BaseContext";

const TmsSRShiftTransactions = ({ router }) => {
  const { Countdown } = Statistic;
  const { userDetails } = useContext(BaseContext);
  const refContainer = useRef(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [reportData, setReportData] = useState("");
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [userShiftData, setUserShiftData] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [modal, setModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState();
  const [reprintData, setReprintData] = useState();
  const [printLoading, setPrintLoading] = useState(false);
  const [ShiftID, setShiftID] = useState("");
  const [featchloading, setFetchLoading] = useState(false);
  const [shiftData, setShiftData] = useState("");
  const [counterCartItemId, setCounterCartItemId] = useState();
  const [countDown, setCountDown] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const fetchAllTransactions = async (id) => {
    if (ShiftID === "") {
      if (!id) {
        notification.error({ message: "Please enter a shift ID" });
        return;
      }
    }
    setDataLoading(true);
    try {
      setReportData("");
      setShiftData("");
      const data = await TmsShiftService.allShiftTransactions(
        id ? Number(id) : Number(ShiftID),
        tableCurrentPage,
        tablePageSize
      );
      setUserShiftData(data?.meta?.shiftLoginLog);
      setReportData(data.items);
      setShiftData(data.shiftLog);
      setTabelTotalSize(data?.meta?.totalItems);
      setDataLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token Expired!! Please login.", router);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    const id = new URLSearchParams(router?.location?.search).get("shiftID");
    id && setShiftID(Number(id));

    fetchAllTransactions(id);
  }, [tableCurrentPage, tablePageSize]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const fetchOtp = async (id) => {
    setFetchLoading(true);
    try {
      if (userDetails?.permissions?.includes("SUPER_ADMIN")) {
        setFetchLoading(false);
        setOtpModal(true);
        setCountDown(Date?.now() + 60 * 2000);
      } else {
        await TmsCartService.sendreprintOTP(id);
        setFetchLoading(false);
        setOtpModal(true);
        setCountDown(Date?.now() + 60 * 2000);
        notification.success({ message: `OTP Sent` });
      }
    } catch (error) {
      catchErrorFunction(error, "Token expired! Please login again", router);
      setFetchLoading(false);
    }
  };

  const reprint = async () => {
    setFetchLoading(true);
    try {
      let resp = await TmsCartService.reprint(counterCartItemId, Number(otp));
      setCountDown(0);
      setOtpModal(false);
      setIsModalVisible(true);
      setReprintData(postBookingDataFunction(resp));
    } catch (error) {
      catchErrorFunction(error, "Token expired! Please login again", router);
      setFetchLoading(false);
    }
  };

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Service Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Txn No.",
      dataIndex: "categoryTransactionSequence",
      key: "categoryTransactionSequence",
      render: (item) => {
        return item?.split("-")?.pop();
      },
    },
    {
      title: "Service",
      dataIndex: "service",
      render: (record) => {
        return record === "counter_seva"
          ? "Seva"
          : record === "counter_darshanam"
          ? "Darshanam"
          : record === "counter_prasadam"
          ? "Prasadam"
          : record === "counter_thulabharam"
          ? "Thulabharam"
          : record === "counter_tollgate"
          ? "Tollgate"
          : record === "counter_donation"
          ? "Donation"
          : record === "counter_accommodation"
          ? "Accommodation"
          : record === "counter_accommodation_advance"
          ? "Accommodation(Advance)"
          : record === "counter_shop_product"
          ? "Products"
          : record === "counter_manual_entry"
          ? "Manual Entry"
          : record === "counter_accommodation_update"
          ? "Accommodation(Update)"
          : "";
      },
    },
    {
      title: "Payment type",
      dataIndex: "paymentType",
      key: "paymentType",
    },

    {
      title: "Payment ID",
      dataIndex: "paymentId",
      key: "paymentId",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (item) => {
        return moment(new Date(item)).format("DD-MM-YYYY - hh:mm A");
      },
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdDate > b.createdDate,
    },

    {
      title: "Deposit Amount",
      dataIndex: "deposit",
      key: "deposit",
    },
    {
      title: "GST Amount",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "Total Amount",
      render: (record) => {
        return `${record.gst + record?.deposit + record.price}`;
      },
    },

    {
      title: "Action",
      render: (record) => {
        if (
          userDetails?.permissions.includes("SUPER_ADMIN") ||
          userDetails?.permissions.includes("ADMIN")
        ) {
          return (
            <Space>
              <Button
                loading={featchloading}
                onClick={() => {
                  fetchOtp(record.counterCartItemId);
                  setCounterCartItemId(record.counterCartItemId);
                }}
              >
                Reprint
              </Button>
            </Space>
          );
        } else {
          return "-";
        }
      },
    },
  ];
  const printReport = async () => {
    setPrintLoading(true);
    try {
      const data = await TmsShiftService.allShiftTransactions(
        Number(ShiftID),
        1,
        50000
      );
      setModalData(data?.items);
      setModal(true);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setModalData(false);
      setPrintLoading(false);
      setModal(false);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "tms-report-container",
    onAfterPrint: () => {
      setModal(false);
      setModalData([]);
    },
  });

  return (
    <>
      <Row style={{ marginBottom: "20px", display: "flex" }} gutter={[20, 20]}>
        <Col>
          <Row justify="start"> Shift Login Log ID</Row>
          <Row justify="start" style={{ flexDirection: "row" }}>
            <InputNumber
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              style={{ width: "300px", maxWidth: "300px" }}
              value={ShiftID}
              defaultValue={ShiftID}
              placeholder="Please Enter a Shift ID"
              onChange={(e) => {
                setShiftID(e);
              }}
            />
            <Button
              type="primary"
              style={{ maxWidth: "100px", marginLeft: "20px" }}
              onClick={() => fetchAllTransactions()}
            >
              Fetch
            </Button>
            {reportData === "" || reportData.length === 0 || !reportData ? (
              ""
            ) : (
              <Button
                type="primary"
                style={{ maxWidth: "100px", marginLeft: "20px" }}
                onClick={() => printReport()}
              >
                Print Report
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Shift Transactions</span>
        </Space>
      </Row>
      <Table
        style={{ width: "100%", textTransform: "capitalize" }}
        columns={columns}
        dataSource={reportData}
        loading={dataLoading}
        size="small"
        scroll={{ x: true }}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
      ></Table>
      {shiftData !== "" && reportData !== "" && (
        <TmsSRShiftDetailsCard shiftData={userShiftData} />
      )}
      <br />
      <TmsModal
        visible={modal}
        width={700}
        closable={false}
        centered
        onCancel={() => {
          setModal(false);
          setPrintLoading(false);
        }}
      >
        <div className="tms-modal-div">
          <Button
            type="primary"
            className="print-button"
            onClick={handlePrint}
            loading={printLoading}
          >
            Print
          </Button>
        </div>
        <br />
        <div className="tms-report-container">
          <table
            className="tms-report-table"
            ref={refContainer}
            style={{ fontSize: "14px" }}
          >
            <thead>
              <th>Service name</th>
              <th>Transaction No</th>
              <th>Payment ID</th>
              <th>Payment Type</th>
              <th>Deposit Amount</th>
              <th>Gst Amount</th>
              <th>Price</th>
              <th>Created at</th>
            </thead>
            <tbody>
              {modalData?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className="al">{item?.productName}</td>
                    <td>
                      {item?.categoryTransactionSequence?.split("-")?.pop()}
                    </td>
                    <td>{item?.paymentId}</td>
                    <td>{item?.paymentType}</td>
                    <td>{item?.deposit}</td>
                    <td>{item?.gst}</td>

                    <td>{item?.price}</td>
                    <td>
                      {moment(new Date(item?.createdAt)).format(
                        "DD-MM-YYYY - hh:mm A"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </TmsModal>
      <TmsModal
        visible={otpModal}
        width={400}
        closable={true}
        centered
        onCancel={() => {
          setOtpModal(false);
          setCountDown(0);
        }}
        footer={
          <>
            <Button
              disabled={countDown > 0 ? true : false}
              onClick={() => {
                fetchOtp(counterCartItemId);
              }}
            >
              Resend OTP
            </Button>
            <Button loading={featchloading} type="primary" onClick={reprint}>
              Submit
            </Button>
          </>
        }
      >
        <div>
          <p>Enter OTP</p>
          <Input onChange={(e) => setOtp(e.target.value)} value={otp}></Input>
          <br />
          <Countdown
            className="countDownOTP"
            value={countDown}
            format={"mm:ss"}
            style={{
              paddingTop: "10px",
              display: `${countDown > 0 ? "block" : "none"}`,
            }}
            suffix={"to resend OTP"}
            onFinish={() => {
              setCountDown(0);
            }}
          />
        </div>
      </TmsModal>
      <TmsModal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type={reprintData?.type}
          from="Counter"
          data={reprintData?.array}
          setData={setReprintData}
          externalPrint={reprintData?.externalPrint}
          setExternalPrint={setReprintData}
          setButtonLoading={setFetchLoading}
          setPrintTicketModal={setIsModalVisible}
          ticketId={reprintData?.ticketID}
          duplicate={true}
        />
      </TmsModal>
    </>
  );
};

export default withRouter(TmsSRShiftTransactions);
