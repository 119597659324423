import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import TmsHomeSider from "./tms-sider/tms-sider";
import TmsHomeHeader from "./tms-header/tms-header";
import TmsBreadCrumb from "./tms-header/tms-breadcrumb";
import TmsFooter from "./tms-footer/tms-footer";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import tmsRouteMap from "../../constants/tms-route.map";
import TmsRoutes from "../../routes/tms-routes";
const { Content } = Layout;

const TmsLayout = ({ location, navigate }) => {
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const templeDetails = JSON.parse(localStorage.getItem("templeDetails"));
  useEffect(() => {
    setLoading(true);
    if (
      localStorage.getItem("accessToken") === null ||
      !localStorage.getItem("accessToken") === null
    ) {
      navigate(tmsRouteMap.login);
    }
    setLoading(false);
  }, [location?.pathname]);

  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };

  return loading ? (
    ""
  ) : (
    <Layout
      style={{ maxWidth: "100%", maxHeight: "100vh", overflow: "hidden" }}
    >
      <TmsHomeSider
        siderCollapsed={siderCollapsed}
        toggleSider={toggleSider}
        templeDetails={templeDetails}
      />
      <Layout className="site-layout">
        <TmsHomeHeader
          toggleSider={toggleSider}
          siderCollapsed={siderCollapsed}
          templeDetails={templeDetails}
        />
        <TmsBreadCrumb />
        {/* <ShiftDetailsBreadCrumb /> */}
        <Content className="tms-layout-content">
          <TmsRoutes />
        </Content>
        {<TmsFooter />}
      </Layout>
    </Layout>
  );
};

export default withRouter(TmsLayout);
