import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  notification,
  Modal,
  Card,
  Radio,
} from "antd";
import moment from "moment";
import TmsTollGateService from "../../../services/tms-tollgate.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { FormatNumberINRCurrency } from "../../../utils/tms-currency-formatter/tms-curreny-formatter";
import { RightOutlined } from "@ant-design/icons";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import "../../../assets/css/tms-tollgate/tms-pos-tollgate.scss";
import {
  dragEnter,
  dragStart,
  drop,
  updateData,
} from "../../../utils/tms-card-dragger/tms-card-dragger";

const TmsPosTollagate = ({ router }) => {
  const ref = useRef(null);

  const [tollGateId, setTollGateId] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [price, setPrice] = useState();
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [array, setArray] = useState([]);
  const [externalPrint, setExternalPrint] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ticketType, setTicketType] = useState();
  const [ids, setIds] = useState([]);
  const [updateFlag, setUpdateFlag] = useState();

  const dragItem = useRef();
  const dragOverItem = useRef();

  const TollGateData = (data) => {
    let schemaBody = [];
    data?.cartItems?.tollgate?.forEach((item) => {
      setTicketType(item?.productDetails?.productMetadata?.ticketType);
      const basicBody = [
        [
          "header",
          item?.counterCartItem?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.counterCartItem?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop(),
          item?.counterCartItem?.ticketId,
        ],
        [
          "Ticket Id",
          item?.productDetails?.productMetadata?.ticketType === "mini_ticket"
            ? item?.counterCartItem?.ticketId?.split("-")?.pop()
            : item?.counterCartItem?.ticketId,
          { margin: "0" },
        ],
      ];
      const generalBody = [
        [
          "Booking Date & Time",
          moment().format("DD-MM-YYYY hh:mm:ss A"),
          { margin: "0" },
        ],
        ["Vehicle Type", item?.productDetails?.productName, { margin: "0" }],
        [
          "Vehicle Number",
          item?.tollgateBooking?.numberPlate ?? "NA",
          { margin: "0" },
        ],
        [
          "Ticket Price",
          `Rs. ${item?.productDetails?.productActualPrice}.00`,
          { margin: "0" },
        ],
        [
          "Ticket Validity",
          item?.productDetails?.duration % 1440 === 0
            ? `${item?.productDetails?.duration / 1440} days`
            : item?.productDetails?.duration % 60 === 0
            ? `${item?.productDetails?.duration / 60} hours`
            : `${item?.productDetails?.duration} mins`,
          { margin: "0" },
        ],
        [
          "Total Amount",
          `Rs. ${item?.tollgateBooking?.amount}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(item?.tollgateBooking?.amount)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.counterCartItem?.createdAt,
        ],
      ];
      const miniTicketBody = [
        ["Vehicle", item?.productDetails?.productName, { margin: "0" }],
        [
          "Validity",
          item?.productDetails?.duration % 1440 === 0
            ? `${item?.productDetails?.duration / 1440} days`
            : item?.productDetails?.duration % 60 === 0
            ? `${item?.productDetails?.duration / 60} hours`
            : `${item?.productDetails?.duration} mins`,
          { margin: "0" },
        ],
        [
          "Amount",
          `Rs. ${item?.tollgateBooking?.amount}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          null,
          item?.counterCartItem?.createdAt,
        ],
      ];
      if (item?.productDetails?.productMetadata?.ticketType === "mini_ticket") {
        schemaBody = [...schemaBody, ...basicBody, ...miniTicketBody];
      } else {
        schemaBody = [...schemaBody, ...basicBody, ...generalBody];
      }
    });
    setArray([...schemaBody]);
  };

  useEffect(() => {
    fetchVehicleList();
  }, [updateFlag]);

  const fetchVehicleList = async () => {
    setButtonLoading(true);
    try {
      let id = [];
      let resp = await TmsTollGateService.fetchTollGateVehicleType();
      setIds(id);
      updateData(resp, setVehicleList, "vehicleList");
      setButtonLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setButtonLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (buttonLoading) {
      return;
    }
    setButtonLoading(true);
    let body = {};

    if (tollGateId !== "") {
      body.tollgateId = Number(tollGateId);
    } else {
      notification.error({ message: "Please select Vehicle Type" });
      setButtonLoading(false);
      return;
    }

    if (vehicleNumber !== "") {
      let regex = /^[A-Z]{2}\s[0-9]{2}\s[A-Z]{1,2}\s[0-9]{4}$/;
      if (!regex.test(vehicleNumber.toUpperCase())) {
        notification.error({ message: "Invalid Number Plate." });
        setButtonLoading(false);
        return;
      } else {
        body.numberPlate = vehicleNumber.toUpperCase();
      }
    }
    try {
      const resp = await TmsTollGateService.tollgateBooking(
        body,
        "CASH",
        "",
        ""
      );
      TollGateData(resp);
      setExternalPrint(true);
      setIsModalVisible(true);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setButtonLoading(false);
    }
  };

  const selectSlot = (tollGatePrice, id) => {
    setTollGateId(id);
    setPrice(tollGatePrice);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "white",
          padding: "30px 50px",
          textAlign: "left",
        }}
      >
        <Row style={{ justifyContent: "space-between" }}>
          <Col style={{ width: "48%" }}>
            <label>
              Booking Date & Time
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <DatePicker
              style={{ width: "100%" }}
              value={moment()}
              format="DD-MM-YYYY hh:mm:ss A"
              disabled
            />
          </Col>
          <Col style={{ width: "48%" }}>
            <label>Enter Vehicle Number</label>
            <Input
              size="medium"
              type="text"
              placeholder="Enter Number Plate"
              style={{ width: "100%" }}
              value={vehicleNumber.toUpperCase()}
              onChange={(e) => {
                setVehicleNumber(e.target.value);
              }}
            />
          </Col>
        </Row>
        <br />
        <Row className="VehicleTypeDiv">
          {vehicleList
            // ?.filter((item) => ids?.includes?.(item?.product?.id))
            ?.map((item, i) => {
              return (
                <Card
                  key={i}
                  hoverable
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    selectSlot(item?.product?.price, item?.product?.id);
                    ref.current.focus();
                  }}
                  onDragStart={(e) => dragStart(e, i, dragItem)}
                  onDragEnter={(e) => dragEnter(e, i, dragOverItem)}
                  onDragEnd={(e) =>
                    drop(
                      e,
                      setUpdateFlag,
                      dragItem,
                      dragOverItem,
                      "vehicleList",
                      ids
                    )
                  }
                  draggable
                >
                  <Col style={{ width: "30%" }}>
                    <p>{item?.product?.name}</p>
                  </Col>
                  <Col style={{ width: "30%" }}>
                    <p style={{ marginRight: "5px" }}>Price:</p>
                    <p style={{ color: "red" }}> Rs.{item?.product?.price}</p>
                  </Col>
                  <Col style={{ width: "30%" }}>
                    <p style={{ marginRight: "5px" }}>Duration:</p>
                    <p style={{ color: "red" }}>
                      {item?.product?.duration % 1440 === 0
                        ? `${item?.product?.duration / 1440} days`
                        : item?.product?.duration % 60 === 0
                        ? `${item?.product?.duration / 60} hours`
                        : `${item?.product?.duration} mins`}
                    </p>
                  </Col>
                  <Col style={{ width: "10%" }}>
                    <Radio
                      autoFocus={i === 0 ? true : false}
                      onKeyDown={(E) => {
                        if (E.key === "Enter") {
                          E.preventDefault();
                          selectSlot(item?.product?.price, item?.product?.id);
                          ref.current.focus();
                        }
                        if (E.key === "ArrowUp") {
                          E.preventDefault();
                          window.setTimeout(function () {
                            document
                              .getElementById(`input-navigation-${i - 1}`)
                              ?.focus();
                          }, 0);
                        }
                        if (E.key === "ArrowDown") {
                          E.preventDefault();

                          window.setTimeout(function () {
                            document
                              .getElementById(`input-navigation-${i + 1}`)
                              ?.focus();
                          }, 0);
                        }
                      }}
                      id={`input-navigation-${i}`}
                      defaultChecked={false}
                      value={item?.product?.id}
                      checked={item?.product?.id === tollGateId ? true : false}
                      onClick={() => {
                        setTollGateId(item?.product?.id);
                        setPrice(item?.product?.price);
                      }}
                    />
                  </Col>
                </Card>
              );
            })}
        </Row>
      </div>
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "#FFFCDC",
        }}
      >
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "500",
            padding: "10px 50px",
          }}
        >
          <p
            style={{
              fontSize: "24px",
              textAlign: "left",
              margin: "0",
            }}
          >
            Total Amount :
          </p>
          <p
            style={{
              fontSize: "24px",
              margin: "0",
              textAlign: "right",
            }}
          >
            {FormatNumberINRCurrency(price ?? 0)}
          </p>
        </Row>
      </div>
      <br />
      <br />
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
        }}
      >
        <Row style={{ justifyContent: "end", width: "100%" }}>
          <Col
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              ref={ref}
              type="primary"
              style={{
                width: "100%",
                background: "#E11513",
                borderColor: "#E11513",
                height: "42px",
                borderRadius: "3px",
              }}
              onClick={() => {
                buttonLoading === false && handleSubmit();
              }}
              loading={buttonLoading}
            >
              Submit <RightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type="toll gate"
          from="Counter"
          setData={setArray}
          data={array}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setButtonLoading}
          setPrintTicketModal={setIsModalVisible}
          ticketType={ticketType}
        />
      </Modal>
    </div>
  );
};
export default withRouter(TmsPosTollagate);
