import { Button, Col, Input, notification, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsKalayanakattaService from "../../../../services/tms-kalayanakatta.service";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import "../../../../assets/css/tms-reports/tms-reports.scss";

const TmskalyanakattaPerformerReport = ({ router }) => {
  const [loading, setLoading] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [performerReportDetails, setPerformerReportDetails] = useState([]);
  const [bookingType, setBookingType] = useState("POS");
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  //   const [kalyankattaList, setKalyanakattaList] = useState([]);
  //   const [kalyankattaType, setKalyankattaType] = useState("");
  const [performerList, setPerformerList] = useState([]);
  const [performerId, setPerformerId] = useState("");
  const [excelLoading, setExcelLoading] = useState(false);

  useEffect(() => {
    fetchPerformer();
  }, []);

  useEffect(() => {
    fetchPerformerReportDetails();
  }, [
    bookingFromDate,
    bookingToDate,
    tableCurrentPage,
    tablePageSize,
    performerId,
  ]);

  const fetchPerformerReportDetails = async () => {
    setLoading(true);
    try {
      const data = await TmsKalayanakattaService.performerReport(
        Number(tableCurrentPage),
        Number(tablePageSize),
        bookingFromDate,
        bookingToDate,
        "",
        "",
        "",
        performerId
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setPerformerReportDetails(
        data?.map((item) => {
          return { key: item?.kalyanakatta_id, ...item };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Tronsure Type",
      dataIndex: "kalyanakatta_name",
      key: "kalyanakatta_name",
      ...getColumnSearchProps("kalyanakatta_name"),
    },
    {
      title: "Performer",
      dataIndex: "kalyanakattaperformer_name",
      key: "kalyanakattaperformer_name",
      ...getColumnSearchProps("kalyanakattaperformer_name"),
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Collection",
      dataIndex: "collection",
      key: "collection",
      render: (text) => {
        return `Rs. ${text}`;
      },
      sorter: (a, b) => a.collection > b.collection,
    },
  ];

  const fetchPerformer = async () => {
    try {
      let res = await TmsKalayanakattaService.getKalayanakattaPerformers(
        1,
        300
      );
      setPerformerList(res.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };

  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      const data = await TmsKalayanakattaService.performerReport(
        Number(tableCurrentPage),
        Number(50000),
        bookingFromDate,
        bookingToDate,
        "",
        "",
        "",
        performerId
      );

      const compiledData = data.map((item, i) => {
        return {
          "S.no": i + 1,
          "Tronsure Type": item.kalyanakatta_name,
          Performer: item.kalyanakattaperformer_name,
          Count: item.count,
          Collection: item.collection,
        };
      });
      exportExcel(
        compiledData,
        `kalyankatta-performer-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "pos" },
            { id: "online", name: "online" },
          ]}
        />
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Performer"
          setValue={(e) => {
            setPerformerId(e);
          }}
          value={performerId}
          data={performerList}
        />
        <Col span={6}>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
        <TmsExcelButton
          loading={excelLoading}
          handleExcelExport={handleExcelExport}
          top={true}
        />
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={columns}
          dataSource={performerReportDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>
    </>
  );
};

export default withRouter(TmskalyanakattaPerformerReport);
