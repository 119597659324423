import React from "react";
import { Tabs } from "antd";
import "../../assets/css/tms-tabs/tms-tabs.scss";
import { useNavigate } from "react-router";
const { TabPane } = Tabs;

const TmsTabsLayout = ({ components, active, setActive }) => {
  const navigate = useNavigate();
  return (
    <div className="card-container">
      <Tabs
        type="card"
        activeKey={active}
        onChange={(e) => {
          let findElement = components.find((item) => item.key === e);
          setActive(e);
          navigate(findElement.route);
        }}
      >
        {components.map((component) => {
          return (
            <TabPane tab={component?.name} key={component.key}>
              {component.Element}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};
export default TmsTabsLayout;
