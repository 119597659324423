import axios from "axios";

export const baseURL =
  process.env.REACT_APP_API_URL ?? "https://d-api.9and9.supercharged.dev/api/";
// process.env.REACT_APP_API_URL ?? "https://api-ws.srisailadevasthanam.org/";

const Axios = axios.create({
  baseURL: baseURL,
});

Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Prevent infinite loops
    if (
      error.response.status === 401 &&
      (originalRequest.url === "v1/user/refresh" ||
        originalRequest.url === "v1/user/login" ||
        originalRequest.url === "v1/user/logout")
    ) {
      if (originalRequest.url === "v1/user/logout") {
        localStorage.clear();
        return Promise.resolve();
      }
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");

      if (refreshToken) {
        try {
          const resp = await Axios.post(
            "v1/user/refresh",
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
                "X-Temple-Id": localStorage.getItem("templeId"),
              },
            }
          );
          localStorage.setItem("accessToken", resp.data.accessToken);

          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("accessToken")}`;

          return Axios(originalRequest);
        } catch (e) {
          if (error.response.status === 401) {
            return Promise.reject(error);
          }
        }
      } else {
        return Promise.reject(error);
      }
    }
    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default Axios;
