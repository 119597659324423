import React, { useEffect, useState } from "react";
import TmsTable from "../../components/tms-customs/tms-table";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import { Space, Row, Col, Button } from "antd";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import TmsTempleService from "../../services/tms-temple.service";
import moment from "moment";
import TmsTMreateUserModal from "../../components/tms-temple-management/tms-tm-create-modal";

const TmsTempleManagement = ({ router }) => {
  const [loading, setLoading] = useState(false);
  const [templeData, setTempleData] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [showCreateTempleModal, setShowCreateTempleModal] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    getTemples();
  }, [updateFlag]);

  const getTemples = async () => {
    setLoading(true);
    try {
      const data = await TmsTempleService.getAllTempleDetails();
      setTempleData(
        data?.map((item, i) => {
          return { ...item, key: i };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleModify = async (record) => {
    // console.log(record);
    setEditData(record);
    setEditFlag(true);
    setShowCreateTempleModal(true);
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "Temple Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      key: "shortName",
    },
    { title: "Display Name", dataIndex: "displayName", key: "displayName" },
    {
      title: "Temple Category",
      dataIndex: "templeCategory",
      key: "templeCategory",
    },
    {
      title: "Created By",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },

    {
      title: "Action",
      render: (record) => {
        return (
          <Space size="middle">
            {/* <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={() => {
                removeTemple(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm> */}
            <a
              onClick={() => {
                handleModify(record);
              }}
            >
              Modify
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Row style={{ marginTop: "15px" }}>
        <Col span={3} flex="initial">
          <Button
            type="primary"
            onClick={() => {
              setShowCreateTempleModal(true);
            }}
          >
            Create Temple
          </Button>
        </Col>
      </Row>
      <br />
      <TmsTable
        dataSource={templeData}
        loading={loading}
        searchable={["display_name"]}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <Row>
              <Col className="mr-10">
                Created At: {moment(record.createdAt).format("YYYY-MM-DD")}
              </Col>
              <Col className="mr-10">Description: {record.description}</Col>
              <Col className="mr-10">Color Code: {record.colorCode}</Col>
            </Row>
          ),
        }}
      />
      <TmsTMreateUserModal
        showCreateTempleModal={showCreateTempleModal}
        setShowCreateTempleModal={setShowCreateTempleModal}
        setUpdateFlag={setUpdateFlag}
        editFlag={editFlag}
        setEditFlag={setEditFlag}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};
export default withRouter(TmsTempleManagement);
