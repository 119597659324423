import { DatePicker, Row } from "antd";
import React from "react";
// import moment from "moment";
const TmsDatePicker = ({ value, setValue, label }) => {
  return (
    <Row style={{ width: "100%", padding: "20px 0px 10px 0px" }}>
      {label}:
      <DatePicker
        allowClear
        picker="time"
        style={{ width: "100%" }}
        defaultValue={value}
        value={value}
        onChange={(e) => {
          setValue(e);
        }}
      />
    </Row>
  );
};
export default TmsDatePicker;
