import {
  tms_icon_accommodation,
  tms_icon_counter_management,
  tms_icon_coupon_management,
  tms_icon_darshanam,
  tms_icon_donation,
  tms_icon_kalyanakatta,
  tms_icon_prasadam,
  tms_icon_pratyaksha_Seva,
  tms_icon_publication,
  tms_icon_shift_managament,
  tms_icon_thulabharam,
  tms_icon_footfall,
  tms_icon_tollgate,
} from "../assets/images/tms-image-list";
import tmsRouteMap from "./tms-route.map";

const TmsServiceCardMap = [
  // {
  //   title: "Accommodation",
  //   route: tmsRouteMap.serviceManagement_accommodation,
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  //   color: "#E6F8FD",
  //   logo: tms_icon_accommodation,
  //   textColor: "#0EB5E4",
  // },
  {
    title: "Poojas",
    route: tmsRouteMap.serviceManagement_sevas,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#EEF7EE",
    logo: tms_icon_pratyaksha_Seva,
    active: true,
    textColor: "#53B04A",
  },
  // {
  //   title: "Darshanam",
  //   route: tmsRouteMap.serviceManagement_darshanam,
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  //   color: "#F0F9FF",
  //   logo: tms_icon_darshanam,
  //   active: true,
  //   textColor: "#3399CC",
  // },
  {
    title: "Donation",
    route: tmsRouteMap.serviceManagement_donation,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#FFF6FB",
    logo: tms_icon_donation,
    textColor: "#CC3399",
  },
  {
    title: "Products",
    route: tmsRouteMap.serviceManagement_products,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#FEFBEC",
    logo: tms_icon_publication,
    textColor: "#CC9933",
  },
  // {
  //   title: "Toll Gate",
  //   route: tmsRouteMap.serviceManagement_tollgate,
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  //   color: "#F9F8FD",
  //   logo: tms_icon_tollgate,
  //   textColor: "#666699",
  // },
  // {
  //   title: "Prasadam",
  //   route: tmsRouteMap.serviceManagement_prasadam,
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  //   color: "#F0F7D8",
  //   logo: tms_icon_prasadam,
  //   textColor: "#999966",
  // },
  // {
  //   title: "Prasadam Stock",
  //   route: tmsRouteMap.sm_prasadam_inventory,
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN", "PRASAD_INVENTORY_MANAGEMENT"],
  //   color: "#F0I5J6",
  //   logo: tms_icon_prasadam,
  //   textColor: "#479079",
  // },
  {
    title: "Streaming Management",
    route: tmsRouteMap.serviceManagement_kalayanakatta,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#D9F7E0",
    logo: tms_icon_kalyanakatta,
    textColor: "#669966",
  },
  {
    title: "Priests",
    route: tmsRouteMap.serviceManagement_thulabharam,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#D6F8FF",
    logo: tms_icon_thulabharam,
    textColor: "#669999",
  },
  {
    title: "Footfall",
    route: tmsRouteMap.serviceManagement_footfall,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "VISITOR_COUNT"],
    color: "#F9F8FD",
    logo: tms_icon_footfall,
    textColor: "#666699",
  },
  // {
  //   title: "POS Management",
  //   route: tmsRouteMap.serviceManagement_counter_management,
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  //   color: "#FFEBF7",
  //   logo: tms_icon_counter_management,
  //   textColor: "#4E8482",
  // },
  // {
  //   title: "Coupon Management",
  //   route: tmsRouteMap.serviceManagement_coupon_management,
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  //   color: "#FFE2E5",
  //   logo: tms_icon_coupon_management,
  //   textColor: "#848355",
  // },
  // {
  //   title: "Shift Management",
  //   route: tmsRouteMap.serviceManagement_shift_management,
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  //   color: "#FEF6D7",
  //   logo: tms_icon_shift_managament,
  //   textColor: "#EF7843",
  // },
  // {
  //   title: "Manual Entry",
  //   route: tmsRouteMap.serviceManagement_manual_entry,
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  //   color: "#FEFBEC",
  //   logo: tms_icon_publication,
  //   textColor: "#CC9933",
  // },
];
export default TmsServiceCardMap;
