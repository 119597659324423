import React from "react";
import { Route, Routes } from "react-router";
import tmsRouteMap from "../constants/tms-route.map";
import TmsReportsHome from "../views/tms-reports/tms-reports-inner-pages/tms-reports-home";
import TmsReportsShiftReport from "../views/tms-reports/tms-reports-inner-pages/tms-reports-shift-report";
import TmsSRShiftTransactions from "../views/tms-reports/tms-reports-inner-pages/tms-reports-shift-report-inner-pages/tms-reports-sr-view-transactions";
import TmsSRShiftScanLogs from "../views/tms-reports/tms-reports-inner-pages/tms-reports-shift-report-inner-pages/tms-reports-sr-scan-logs";
import TmsReportsScanLogs from "../views/tms-reports/tms-reports-inner-pages/tms-reports-scan-log-report";
import TmsReportsDarshanam from "../views/tms-reports/tms-reports-inner-pages/tms-reports-darshanam-counter/tms-reports-darshanam";
import TmsReportsSeva from "../views/tms-reports/tms-reports-inner-pages/tms-reports-seva-counter/tms-reports-seva";
import TmsReportPrasadam from "../views/tms-reports/tms-reports-inner-pages/tms-reports-prasadam-counter/tms-reports-prasadam";
import TmsReportsDonation from "../views/tms-reports/tms-reports-inner-pages/tms-reports-donation-counter/tms-reports-donation";
import TmsConsolidatedReport from "../views/tms-reports/tms-reports-inner-pages/tms-reports-consolidated-report/tms-report-consolidated-counter";
import TmsReportTollgate from "../views/tms-reports/tms-reports-inner-pages/tms-reports-tollgate-counter/tms-report-tollgate";
import TmsReportThulabharam from "../views/tms-reports/tms-reports-inner-pages/tms-reports-thulabharam-counter/tms-reports-thulabharam";
import TmsReportsAccommodation from "../views/tms-reports/tms-reports-inner-pages/tms-reports-accommodation-report/tms-reports-accommodation";
import TmsReportKalyanakatta from "../views/tms-reports/tms-reports-inner-pages/tms-reports-kalyanakatta-report/tms-report-kalyanakatta";
import TmsReportManualReport from "../views/tms-reports/tms-reports-inner-pages/tms-reports-manual-entry-report/tms-report-manual-entry";
const TmsReportRoutes = () => {
  return (
    <Routes>
      <Route
        path={tmsRouteMap.reports_shift_report.replace("/reports", "")}
        element={<TmsReportsShiftReport />}
      />
      <Route
        path={tmsRouteMap.reports_view_shift_transactions.replace(
          "/reports",
          ""
        )}
        element={<TmsSRShiftTransactions />}
      />

      <Route
        exact
        path={tmsRouteMap.reports_darshanam.replace("/reports", "") + "/*"}
        element={<TmsReportsDarshanam />}
      />
      <Route
        exact
        path={tmsRouteMap.reports_seva.replace("/reports", "") + "/*"}
        element={<TmsReportsSeva />}
      />
      <Route
        exact
        path={tmsRouteMap.reports_donation.replace("/reports", "") + "/*"}
        element={<TmsReportsDonation />}
      />
      <Route
        path={tmsRouteMap.reports_prasadam.replace("/reports", "") + "/*"}
        element={<TmsReportPrasadam />}
      />
      <Route
        path={tmsRouteMap.reports_thulabharam.replace("/reports", "") + "/*"}
        element={<TmsReportThulabharam />}
      />
      <Route
        path={tmsRouteMap.reports_tollgate.replace("/reports", "") + "/*"}
        element={<TmsReportTollgate />}
      />
      <Route
        path={tmsRouteMap.reports_accommodation.replace("/reports", "") + "/*"}
        element={<TmsReportsAccommodation />}
      />
      <Route
        path={tmsRouteMap.reports_kalyanakatta.replace("/reports", "") + "/*"}
        element={<TmsReportKalyanakatta />}
      />
      <Route
        path={tmsRouteMap.reports_view_shift_scan_logs.replace("/reports", "")}
        element={<TmsSRShiftScanLogs />}
      />
      <Route
        path={tmsRouteMap.reports_scan_logs.replace("/reports", "")}
        element={<TmsReportsScanLogs />}
      />
      <Route
        path={tmsRouteMap.reports_consolidated_reports.replace("/reports", "")}
        element={<TmsConsolidatedReport />}
      />
      <Route
        path={tmsRouteMap.report_manual_entry.replace("/reports", "")}
        element={<TmsReportManualReport />}
      />
      <Route path={"/"} element={<TmsReportsHome />} />
    </Routes>
  );
};
export default TmsReportRoutes;
