import {
  // tms_d_accommodation,
  tms_d_darshanam,
  tms_d_donations,
  tms_d_footfall,
  // tms_d_kalayanakata,
  // tms_d_misc,
  // tms_d_prasadam,
  tms_d_pratyaksha,
  // tms_d_products,
  // tms_d_publication,
  tms_d_registrations,
  // tms_d_transport,
} from "../assets/images/tms-image-list";

const tmsDashboardMap = [
  {
    name: "Total Collection",
    background: "#FC4B35",
    bookings: true,
    logo: false,
    key: "total",
    onlinekey: "total",
  },

  {
    name: "Sevas",
    background: "#FF5E23",
    bookings: true,
    logo: tms_d_darshanam,
    key: "counter_seva",
    onlinekey: "online_seva",
  },
  // {
  //   name: "Darshanam",
  //   background: "#FF9128",
  //   bookings: true,
  //   logo: tms_d_darshanam,
  //   key: "counter_darshanam",
  //   onlinekey: "online_darshanam",
  // },

  // {
  //   name: "Prasadam",
  //   background: "#FFB430",
  //   bookings: true,
  //   logo: tms_d_prasadam,
  //   key: "counter_prasadam",
  //   onlinekey: "online_prasadam",
  // },
  // {
  //   name: "Kalyana Katta",
  //   background: "#BFCA41",
  //   bookings: true,
  //   logo: tms_d_kalayanakata,
  //   key: "counter_kalyanakatta",
  //   onlinekey: "online_kalyanakatta",
  // },
  // {
  //   name: "Accommodation",
  //   background: "#76B743",
  //   bookings: true,
  //   logo: tms_d_accommodation,
  //   key: "counter_accommodation",
  //   onlinekey: "online_accommodation",
  // },
  {
    name: "Foot Fall",
    background: "#2F833A",
    bookings: true,
    logo: tms_d_footfall,
    key: "counter_footfall",
    onlinekey: "online_footfall",
  },

  // {
  //   name: "Thulabharam",
  //   background: "#A4B53D",
  //   bookings: true,
  //   logo: tms_d_products,
  //   key: "counter_thulabharam",
  //   onlinekey: "online_thulabharam",
  // },
  // {
  //   name: "Publications",
  //   background: "#F1371A",
  //   bookings: true,
  //   logo: tms_d_publication,
  //   key: "counter_publication",
  //   onlinekey: "online_publication",
  // },
  // {
  //   name: "Electricity",
  //   background: "#2FB6C6",
  //   bookings: true,
  //   logo: tms_d_electricity,
  //   key: "counter_electricity",
  //   onlinekey: "online_electricity",
  // },
  // {
  //   name: "Water",
  //   background: "#28A08A",
  //   bookings: true,
  //   logo: tms_d_water,
  //   key: "counter_water",
  //   onlinekey: "online_water",
  // },
  // {
  //   name: "Gas",
  //   background: "#44A645",
  //   bookings: true,
  //   logo: tms_d_gas,
  //   key: "counter_gas",
  //   onlinekey: "online_gas",
  // },
  // {
  //   name: "Petrol bunk",
  //   background: "#BFCA41",
  //   bookings: true,
  //   logo: tms_d_petrolbunk,
  //   key: "counter_petrolBunk",
  //   onlinekey: "online_petrolBunk",
  // },
  // {
  //   name: "Products",
  //   background: "#2393AC",
  //   bookings: true,
  //   logo: tms_d_products,
  //   key: "counter_footfall",
  //   onlinekey: "online_footfall",
  // },
  {
    name: "Registered Temples",
    background: "#1F7764",
    bookings: true,
    logo: tms_d_pratyaksha,
    key: "counter_registrations",
    onlinekey: "online_registrations",
  },
  {
    name: "Donations",
    background: "#FFB430",
    bookings: true,
    logo: tms_d_donations,
    key: "counter_donation",
    onlinekey: "online_donation",
  },
  // {
  //   name: "Transport",
  //   background: "#FF9128",
  //   bookings: true,
  //   logo: tms_d_transport,
  //   key: "counter_tollgate",
  //   onlinekey: "online_tollgate",
  // },

  {
    name: "Priests Collections",
    background: "#FF6E21",
    bookings: true,
    logo: tms_d_registrations,
    key: "counter_misc",
    onlinekey: "online_misc",
  },
];
export default tmsDashboardMap;
