import { Button, Card, Col, notification, Row, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import TmsAccommodationService from "../../../services/tms-accommodation.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import "../../../assets/css/tms-accommodation/tms-pos-accommodation.scss";
import moment from "moment-timezone";
import TmsModal from "../../../components/tms-customs/tms-modal";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import { LoadingOutlined } from "@ant-design/icons";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import tmsRouteMap from "../../../constants/tms-route.map";
import { useNavigate } from "react-router";
import TmsShiftService from "../../../services/tms-shift.service";

const TmsPosAccommodationRooms = ({ router }) => {
  const {
    details,
    setDetails,
    setShiftData,
    rebookingConfi,
    setReBookingConfi,
  } = useContext(BaseContext);
  const [loading, setLoading] = useState(false);
  const [roomsData, setRoomsData] = useState();
  const [externalPrint, setExternalPrint] = useState(false);
  const [array, setArray] = useState([]);
  const [ticketId, setTicketId] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const formatBookingData = (data) => {
    let schemaBody = [];
    data?.cartItems?.accommodation?.map((item) => {
      setTicketId(item?.counterCartItem?.ticketId);

      const headerBody = [
        [
          "header",
          item?.counterCartItem?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.counterCartItem?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop(),
          item?.counterCartItem?.ticketId,
        ],
        ["Ticket Id", item?.counterCartItem?.ticketId, { margin: "0" }],
        [
          "Devotee Name",
          item?.accommodationBooking?.accCounterBooking?.devoteeName ??
            item?.accommodationBooking?.devoteeName,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "No. of Persons",
          ["KALYANA_MANDAPAM", "KALYANA MANDAPAM"]?.includes(
            item?.productDetails?.roomType
          )
            ? "null"
            : `${item?.productDetails?.noOfPeople}`,
          { margin: "0" },
        ],
        [
          "Room Details",
          details?.bookingType === "same-day"
            ? `${item?.productDetails?.productName}-(${item?.productDetails?.roomType})-${item?.productDetails?.roomNumber}`
            : `${item?.productDetails?.productName}-(${item?.productDetails?.roomType})`,
          { margin: "0" },
        ],
        [
          "Booking Date",
          `${moment(item?.productDetails?.bookingDate).format("DD-MM-YYYY")}`,
          { margin: "0" },
        ],
      ];

      const footerBody = [
        [
          "Checkin Time",
          details?.bookingType === "same-day"
            ? `${moment(item?.accommodationBooking?.checkIn).format(
                "DD-MM-YYYY hh:mm A"
              )}`
            : "null",
          { margin: "0" },
        ],
        [
          "Checkout Time",
          details?.bookingType === "same-day"
            ? `${moment(item?.productDetails?.checkOutTime).format(
                "DD-MM-YYYY hh:mm A"
              )}`
            : "null",
          { margin: "0" },
        ],
        [
          "Room Price",
          `Rs. ${item?.productDetails?.productActualPrice ?? 0}.00`,
          { margin: "0" },
        ],
        [
          "GST Amount",
          `Rs. ${item?.accommodationBooking?.gst ?? 0}.00`,
          { margin: "0" },
        ],
        [
          "Maintenance Charge",
          `Rs. ${item?.accommodationBooking?.maintenanceCharge ?? 0}.00`,
          { margin: "0" },
        ],
        [
          "Deposit Amount",
          details?.bookingType === "same-day"
            ? `Rs. ${item?.accommodationBooking?.depositAmount ?? 0}.00`
            : "null",
          { margin: "0" },
        ],
        [
          "Coupon Amount",
          item?.productDetails?.discountAmount
            ? `Rs. ${item?.productDetails?.discountAmount ?? 0}.00`
            : "null",
          { margin: "0" },
        ],
        [
          "Total Amount",
          details?.bookingType === "same-day"
            ? `Rs. ${Number(
                (item?.accommodationBooking?.depositAmount ?? 0) +
                  (item?.accommodationBooking?.price ?? 0) +
                  (item?.accommodationBooking?.gst ?? 0) +
                  (item?.accommodationBooking?.maintenanceCharge ?? 0)
              )}.00 `
            : `Rs. ${Number(
                (item?.accommodationBooking?.price ?? 0) +
                  (item?.accommodationBooking?.gst ?? 0) +
                  (item?.accommodationBooking?.maintenanceCharge ?? 0)
              )}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "",
          details?.bookingType === "same-day"
            ? `(${numberToWords(
                Number(
                  (item?.accommodationBooking?.depositAmount ?? 0) +
                    (item?.accommodationBooking?.price ?? 0) +
                    (item?.accommodationBooking?.gst ?? 0) +
                    (item?.accommodationBooking?.maintenanceCharge ?? 0)
                )
              )} Rupees)`
            : `(${numberToWords(
                Number(
                  (item?.accommodationBooking?.price ?? 0) +
                    (item?.accommodationBooking?.gst ?? 0) +
                    (item?.accommodationBooking?.maintenanceCharge ?? 0)
                )
              )} Rupees)`,

          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.counterCartItem?.createdAt,
        ],
      ];

      if (
        ["KALYANA_MANDAPAM", "KALYANA MANDAPAM"]?.includes(
          item?.productDetails?.roomType
        )
      ) {
        const personDetails =
          item.accommodationBooking.accCounterBooking
            .accKalyanMandapamBookingDetails;
        const kalayanaMandapamBody = [
          [
            "Bride Details",
            `${personDetails?.brideDetails?.name} - (${moment(
              personDetails?.brideDetails?.dob
            ).format(
              "DD-MM-YYYY"
            )} - Aadhar- ${personDetails?.groomDetails.aadharNumber.slice(
              8,
              12
            )})`,
            { margin: "0" },
          ],
          [
            "Groom Details",
            `${personDetails?.groomDetails?.name} - (${moment(
              personDetails?.groomDetails?.dob
            ).format(
              "DD-MM-YYYY"
            )}) - Aadhar- ${personDetails?.groomDetails.aadharNumber.slice(
              8,
              12
            )}`,
            { margin: "0" },
          ],
          ["Bride Family", `${personDetails?.brideDetails?.name} `],
          [
            "Bride Father Name",
            `${personDetails?.brideFatherDetails?.name} - (${moment(
              personDetails?.brideFatherDetails?.dob
            ).format(
              "DD-MM-YYYY"
            )}) - Aadhar- ${personDetails?.brideFatherDetails.aadharNumber.slice(
              8,
              12
            )}`,
            { margin: "0" },
          ],
          [
            "Bride Mother Name",
            `${personDetails?.brideMotherDetails?.name} - (${moment(
              personDetails?.brideMotherDetails?.dob
            ).format(
              "DD-MM-YYYY"
            )}) - Aadhar- ${personDetails?.brideMotherDetails.aadharNumber.slice(
              8,
              12
            )}`,
            { margin: "0" },
          ],
          [
            "Groom Father Name",
            `${personDetails?.groomFatherDetails?.name} - (${moment(
              personDetails?.groomFatherDetails?.dob
            ).format(
              "DD-MM-YYYY"
            )}) - Aadhar- ${personDetails?.groomFatherDetails.aadharNumber.slice(
              8,
              12
            )}`,
            { margin: "0" },
          ],
          [
            "Groom Mother Name",
            `${personDetails?.groomMotherDetails?.name} - (${moment(
              personDetails?.groomMotherDetails?.dob
            ).format(
              "DD-MM-YYYY"
            )}) - Aadhar- ${personDetails?.groomMotherDetails.aadharNumber.slice(
              8,
              12
            )}`,
            { margin: "0" },
          ],
          [
            "Muhurtham",
            `${moment(personDetails?.muhurthamDetails.muhurthamDate).format(
              "DD-MM-YYYY"
            )} - ${personDetails?.muhurthamDetails?.muhurthamTime}`,
            { margin: "0" },
          ],
        ];
        schemaBody = [
          ...schemaBody,
          ...headerBody,
          ...kalayanaMandapamBody,
          ...footerBody,
        ];
      } else {
        schemaBody = [...schemaBody, ...headerBody, ...footerBody];
      }
    });
    setArray([...schemaBody]);
    notification.success({ message: "Success" });
    setIsModalVisible(true);
    setExternalPrint(true);
    setLoading(false);
  };

  const fetchRooms = async () => {
    setLoading(true);
    try {
      const resp = await TmsAccommodationService.fetchValidRooms(
        details?.selectedRoom?.blockCategoryId,
        details.bookingType === "advance" &&
          details?.selectedRoom?.roomType === "KALYANA_MANDAPAM"
          ? moment(details?.startDate).format("YYYY-MM-DD")
          : null
      );
      setRoomsData(resp);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const bookRoom = async (roomId) => {
    setLoading(true);
    try {
      let body = {
        noOfPeople: Number(details?.persons),
        contact: `+91${details?.phone}`,
        pinCode: Number(details?.zip),
        dob: moment().subtract(details?.age, "years").format("YYYY-MM-DD"),
        gender: details?.gender,
        address: details?.address,
        city: details?.city,
        state: details?.state,
        country: details?.country,
        devoteeName: details?.devoteeName,
        idProofType: details?.idProofType,
        idProofNumber: details?.idProofNumber,
      };
      if (details.verifiedCouponData) {
        body.couponCode = details?.verifiedCouponData?.coupon?.couponCode;
        body.couponType = details?.usedBy;
      }
      if (details?.email !== "") body.email = details?.email;
      if (details?.selectedRoom?.roomType === "KALYANA_MANDAPAM") {
        let mapped = { ...details?.kalayanaMandapam };
        for (const key in mapped) {
          mapped[key].dob = moment(mapped[key].dob).format("YYYY-MM-DD");
        }
        body.accKalyanMandapamBookingDetails = {
          ...mapped,
          muhurthamDetails: {
            muhurthamDate: moment(details.muhurthamDate).format("YYYY-MM-DD"),
            muhurthamTime: moment(details.muhurthamTime).format("HH:mm A"),
          },
        };
      }
      if (
        details?.selectedRoom?.roomType === "KALYANA_MANDAPAM" &&
        details?.bookingType !== "same-day"
      ) {
        body.accPreAssignedRoomId = roomId;
        body.blockCategoryId = details?.selectedRoom?.blockCategoryId;
      }
      if (details?.bookingType === "same-day") {
        body.maintenanceCharge = details?.selectedRoom?.maintenanceCharge;
        body.roomId = roomId;
        body.depositAmount = details?.selectedRoom?.depositAmount;
      } else if (details?.bookingType === "advance") {
        body.blockCategoryId = details?.selectedRoom?.blockCategoryId;
        body.advanceBookingCharge =
          details?.selectedRoom?.advanceBookingCharge ?? 0;
        body.bookingDate = moment(details?.startDate).format("YYYY-MM-DD");
      }
      const data = await TmsAccommodationService.accommodationBooking(
        body,
        details?.payment,
        details?.cashReference,
        details?.cashRemarks,
        details?.bookingType === "same-day" ? true : false
      );
      formatBookingData(data);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const reBooking = async (flag) => {
    if (!flag) {
      setDetails(null);
    } else {
      setDetails({
        selectedRoom: details?.selectedRoom,
        devoteeName: details?.devoteeName,
        age: details?.age,
        gender: details?.gender,
        phone: details?.phone,
        email: details?.email,
        address: details?.address,
        zip: details?.zip,
        city: details?.city,
        state: details?.state,
        country: details?.country,
        idProofType: details?.idProofType,
        idProofNumber: details?.idProofNumber,
        persons: details?.persons,
        payment: details?.payment,
        cashReference: details?.cashReference,
        cashRemarks: details?.cashRemarks,
        roomType: details?.roomType,
        printType: details?.printType,
        bookingType: details?.bookingType,
        startDate: details?.startDate,
        kalayanaMandapam: details?.kalayanaMandapam,
        muhurthamDate: details?.muhurthamDate,
        muhurthamTime: details?.muhurthamTime,
      });
    }
    setReBookingConfi(false);
    try {
      const shiftData = await TmsShiftService.IsShiftLoggedIn();
      if (!shiftData) {
        return;
      }
      setShiftData(shiftData);
    } catch (error) {
      catchErrorFunction(error, "Token Expired!! please login", router);
    }
    router.navigate(tmsRouteMap.pos_accommodation);
  };

  useEffect(() => {
    console.log(details);
    if (details === {} || !details || details === "" || details === null) {
      navigate(tmsRouteMap.pos_accommodation);
    } else {
      if (details?.bookingType === "same-day") {
        fetchRooms();
      } else {
        details?.selectedRoom?.roomType === "KALYANA_MANDAPAM"
          ? fetchRooms()
          : bookRoom();
      }
    }
  }, []);

  return (
    <>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <Row
            style={{
              justifyContent: "space-between",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            <Button
              onClick={() => {
                navigate(tmsRouteMap.pos_accommodation);
              }}
            >
              Back
            </Button>
            <p>
              Block Name: {details?.selectedRoom?.blockName}-(
              {details?.selectedRoom?.roomType})
            </p>
            <Button style={{ visibility: "hidden" }}>Back</Button>
          </Row>
          <br />
          {details?.bookingType === "same-day" && (
            <Row style={{ fontSize: "17px", fontWeight: "600" }}>
              Available Rooms
            </Row>
          )}
          <br />
          <Row gutter={[20, 20]}>
            {roomsData?.map((item) => {
              return (
                <Col key={item?.id} style={{ width: "25%" }}>
                  <Card
                    hoverable
                    className="RoomCard"
                    onClick={() => {
                      loading === false && bookRoom(item?.id);
                    }}
                  >
                    <Row style={{ height: "100%" }}>
                      <Col
                        style={{
                          width: "100%",
                          padding: "10px 0px",
                          fontWeight: "bold",
                          fontSize: "20px",
                        }}
                      >
                        {item?.roomNumber}
                      </Col>
                      <Col
                        style={{
                          width: "100%",
                          backgroundColor: "lightgray",
                          padding: "5px 0px",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        Book Room
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <TmsModal
            visible={isModalVisible}
            width={850}
            footer={null}
            closable={false}
            centered
            maskClosable={false}
          >
            <TmsPrintTicket
              type={
                details?.bookingType !== "same-day"
                  ? "advance accommodation"
                  : "accommodation"
              }
              from="Counter"
              setData={setArray}
              data={array}
              externalPrint={externalPrint}
              setExternalPrint={setExternalPrint}
              setButtonLoading={setLoading}
              setPrintTicketModal={setIsModalVisible}
              ticketId={ticketId}
              printType="a4"
            />
          </TmsModal>
          <TmsModal
            visible={rebookingConfi}
            width={850}
            footer={null}
            closable={false}
            centered
          >
            <Row style={{ justifyContent: "center", width: "100%" }}>
              <p style={{ width: "100%", textAlign: "center" }}>
                Do you want to Book Another Room ?
              </p>
              <Row>
                <Space>
                  <Button type="primary" onClick={() => reBooking(true)}>
                    Yes
                  </Button>
                  <Button onClick={() => reBooking(false)}>No</Button>
                </Space>
              </Row>
            </Row>
          </TmsModal>
        </>
      )}
    </>
  );
};

export default withRouter(TmsPosAccommodationRooms);
