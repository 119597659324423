import Axios from "../utils/tms-axios/tms-axios";
const TmsTempleService = {
  getTempleDetails: async (id) => {
    const resp = await Axios.get(`v1/temples/get-by-id/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getAllTempleDetails: async () => {
    const resp = await Axios.get(`v1/temples/get-all`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  createTemple: async (
    shortName,
    name,
    colorCode,
    description,
    logoUrl,
    displayName,
    category
  ) => {
    const resp = await Axios.post(
      `v1/temples/create`,
      {
        shortName,
        name,
        colorCode,
        description,
        logoUrl,
        displayName,
        templeCategory: category,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteTemple: async (id) => {
    let resp = await Axios.delete(`v1/temples/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  modifyTempleDetails: async (
    shortName,
    name,
    colorCode,
    description,
    logoUrl,
    id,
    displayName,
    category
  ) => {
    const resp = await Axios.post(
      `v1/temples/update/${id}`,
      {
        shortName,
        name,
        colorCode,
        description,
        logoUrl,
        displayName,
        templeCategory: category,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateTempleSettings: async (
    darshanamMultiTickets,
    donationPrintTicketSize,
    darshnamReprintOtp,
    prasadamReprintOtp,
    donationReprintOtp,
    tollgateReprintOtp,
    sevaReprintOtp,
    thulabharamReprintOtp,
    kalyanakattaReprintOtp,
    accommodationReprintOTP,
    kalyanakattaPerformer,
    prasadamInventory,
    sevaSplitTicket,
    enableDonationBond
  ) => {
    let body = {
      darshanamMultiTickets,
      donationPrintTicketSize,
      prasadamInventory: prasadamInventory ?? false,
      kalyanakattaPerformer: kalyanakattaPerformer ?? false,
      sevaSplitTickets: sevaSplitTicket ?? false,
      enableDonationBond: enableDonationBond ?? false,
    };
    if (darshnamReprintOtp !== "" && darshnamReprintOtp !== undefined) {
      body.counterDarshanamReprintOTPPhone = `+91${darshnamReprintOtp}`;
    }
    if (
      accommodationReprintOTP !== "" &&
      accommodationReprintOTP !== undefined
    ) {
      body.counterAccommodationReprintOTPPhone = `+91${accommodationReprintOTP}`;
    }
    if (donationReprintOtp !== "" && donationReprintOtp !== undefined) {
      body.counterDonationReprintOTPPhone = `+91${donationReprintOtp}`;
    }
    if (sevaReprintOtp !== "" && sevaReprintOtp !== undefined) {
      body.counterSevaReprintOTPPhone = `+91${sevaReprintOtp}`;
    }
    if (prasadamReprintOtp !== "" && prasadamReprintOtp !== undefined) {
      body.counterPrasadamReprintOTPPhone = `+91${prasadamReprintOtp}`;
    }
    if (tollgateReprintOtp !== "" && tollgateReprintOtp !== undefined) {
      body.counterTollgateReprintOTPPhone = `+91${tollgateReprintOtp}`;
    }
    if (thulabharamReprintOtp !== "" && thulabharamReprintOtp !== undefined) {
      body.counterThulabharamReprintOTPPhone = `+91${thulabharamReprintOtp}`;
    }
    if (kalyanakattaReprintOtp !== "" && kalyanakattaReprintOtp !== undefined) {
      body.counterKalyanakattaReprintOTPPhone = `+91${kalyanakattaReprintOtp}`;
    }
    const resp = await Axios.patch(`v1/temples/configuration`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  updateTempleOnlineSettings: async (body) => {
    let fee = {
      type: body.feeType,
      condition: body.conditionOne,
      conditionValue: Number(body.conditionOneValue),
    };

    body.feeType === "fixed"
      ? (fee.fixedValue = Number(body.feeValue))
      : (fee.percentageValue = Number(body.feeValue));
    if (body.conditionTwo !== "" && body.conditionTwo) {
      fee.condition2 = body.conditionTwo;
      fee.condition2Value = Number(body.conditionTwoValue);
    }
    let payload = {
      type: body.payementGateway === "razorpay" ? "razorpay" : "ccavenue",
      fee: [fee],
    };
    if (body.razorpayKey) {
      if (body.payementGateway === "razorpay") {
        payload.razorpay = {
          key: body.razorpayKey,
          secret: body.razorpaySecret,
          merchantId: body.merchantId,
        };
      } else {
        payload.ccavenue = {
          accessCode: body.razorpayKey,
          workingKey: body.razorpaySecret,
          merchantId: body.merchantId,
        };
      }
    }
    const resp = await Axios.put(`v1/online-payment/temple-config`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  modifyTempleOnlineSettings: async (body) => {
    let fee = {
      type: body.feeType,
      condition: body.conditionOne,
      conditionValue: Number(body.conditionOneValue),
    };

    body.feeType === "fixed"
      ? (fee.fixedValue = Number(body.feeValue))
      : (fee.percentageValue = Number(body.feeValue));
    if (body.conditionTwo !== "" && body.conditionTwo) {
      fee.condition2 = body.conditionTwo;
      fee.condition2Value = Number(body.conditionTwoValue);
    }
    let payload = {
      type: body.payementGateway === "razorpay" ? "razorpay" : "ccavenue",
      fee: [fee],
    };
    if (body.razorpayKey) {
      if (body.payementGateway === "razorpay") {
        payload.razorpay = {
          key: body.razorpayKey,
          secret: body.razorpaySecret,
          merchantId: body.merchantId,
        };
      } else {
        payload.ccavenue = {
          accessCode: body.razorpayKey,
          workingKey: body.razorpaySecret,
          merchantId: body.merchantId,
        };
      }
    }
    const resp = await Axios.post(`v1/online-payment/temple-config`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getOnlineTempleSettings: async () => {
    const resp = await Axios.get(`v1/online-payment/temple-config`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  templeProfile: async (body) => {
    const resp = await Axios.patch(
      `v1/temples/devotee-portal-configuration`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
};
export default TmsTempleService;
