import React, { useRef } from "react";
import {
  Button,
  Col,
  notification,
  Row,
  Table,
  Drawer,
  Card,
  Statistic,
  Empty,
} from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsCounterService from "../../../../services/tms-counter.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsShiftService from "../../../../services/tms-shift.service";
import TmsDonationService from "../../../../services/tms-donation.service";
import TmsDarshanamService from "../../../../services/tms-darshanam/tms-darshanam.service";
import TmsPrasadamService from "../../../../services/tms-prasadam.service";
import TmsTollgateService from "../../../../services/tms-tollgate.service";
import TmsThulabharamService from "../../../../services/tms-thulabharam.service";
import "../../../../assets/css/tms-reports/tms-reports.scss";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import { TmsConsildatedColums } from "../../../../constants/tms-consolidated-report-columns";
import TmsSevasService from "../../../../services/tms-sevas.service";
import TmsModal from "../../../../components/tms-customs/tms-modal";
import { useReactToPrint } from "react-to-print";
import { DownloadOutlined } from "@ant-design/icons";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import TmsCommonService from "../../../../services/tms-common.service";
import TmsKalayanakattaService from "../../../../services/tms-kalayanakatta.service";
import { tmsLogo } from "../../../../assets/images/tms-image-list";
import TmsAccommodationService from "../../../../services/tms-accommodation.service";
import TmsManualEntryService from "../../../../services/tms-manual-entry.service";
const TmsConsolidatedReport = ({ router }) => {
  const [FromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [countersList, setCounterList] = useState([]);
  const [selectedCounter, setSelectedCounter] = useState("");
  const [shiftList, setShiftList] = useState([]);
  const [selectedShift, setSelectedShift] = useState("");
  const [active, setActive] = useState("Sevas");
  const [printLoading, setPrintLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modal, setModal] = useState(false);
  const refContainer = useRef(null);
  const [bookingType, setBookingType] = useState("pos");
  const [menu, setMenu] = useState([]);
  const [accType, setAccType] = useState("counter_accommodation");
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );

  const menuService = {
    Sevas: TmsSevasService,
    Darshanam: TmsDarshanamService,
    Donation: TmsDonationService,
    Prasadam: TmsPrasadamService,
    Thulabharam: TmsThulabharamService,
    Tollgate: TmsTollgateService,
    Kalyanakatta: TmsKalayanakattaService,
    Accommodation: TmsAccommodationService,
    "Manual Entry": TmsManualEntryService,
    All: TmsCommonService,
  };

  const menuKeys = {
    Sevas: { online: "online_seva", pos: "counter_seva" },
    Darshanam: { online: "online_darshanam", pos: "counter_darshanam" },
    Donation: { online: "online_donation", pos: "counter_donation" },
    Products: { online: "online_products", pos: "counter_products" },
    Accommodation: {
      online: "online_accommodation",
      pos: "counter_accommodation",
    },
    Prasadam: { pos: "counter_prasadam" },
    Thulabharam: { pos: "counter_thulabharam" },
    "Manual Entry": { pos: "counter_manual_entry" },
    Kalyanakatta: { pos: "counter_kalyanakatta" },
    Tollgate: { pos: "counter_tollgate" },
  };

  const AllColums = [
    { title: "ID", dataIndex: "productId", key: "productId" },
    { title: "Product Id", dataIndex: "productId", key: "productId" },
    { title: "Product Name", dataIndex: "productName", key: "productName" },
    { title: "Product Price", dataIndex: "productPrice", key: "productPrice" },
    {
      title: "Ticket No",
      hidden: bookingType === "pos" ? false : true,
      children: [
        {
          title: "From",
          dataIndex: "ticketStart",
          key: "ticketStart",
          render: (text) => {
            return <>{text ?? "-"}</>;
          },
        },
        {
          title: "To",
          dataIndex: "ticketEnd",
          key: "ticketEnd",
          render: (text) => {
            return <>{text ?? "-"}</>;
          },
        },
      ],
    },
    {
      title: "Total Collection",
      dataIndex: "totalCollection",
      key: "totalCollection",
    },
    { title: "Total Tickets", dataIndex: "totalTickets", key: "totalTickets" },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    getCounters();
    getShifts();
  }, []);

  useEffect(() => {
    bookingType === "pos"
      ? setMenu([
          "Sevas",
          "Darshanam",
          "Donation",
          "Accommodation",
          "Prasadam",
          "Thulabharam",
          "Kalyanakatta",
          "Tollgate",
          "Products",
          "Manual Entry",
          "All",
        ])
      : setMenu(["Sevas", "Darshanam", "Donation", "Accommodation", "All"]);
  }, [bookingType]);

  useEffect(() => {
    fetchReport();
  }, [
    FromDate,
    toDate,
    selectedCounter,
    selectedShift,
    active,
    bookingType,
    accType,
  ]);

  const fetchReport = async () => {
    setDataLoading(true);
    if (!FromDate || !toDate) {
      notification.error({ message: "Please select a valid date Range" });
      setDataLoading(false);
      return;
    }
    if (["Products"].includes(active)) {
      setData([]);
      setDataLoading(false);
      return;
    }
    try {
      // let CounterIds = selectedCounter.map((item) => item.id)?.join(",");
      // let ShiftIds = selectedShift.map((item) => item.id)?.join(",");
      let respData = {};
      if (bookingType === "pos") {
        respData = await menuService[active].getConsolidateReport(
          "json",
          "",
          FromDate,
          toDate,
          selectedCounter,
          selectedShift,
          accType
        );
      } else {
        respData = await menuService[active].getOnlineConsolidateReport(
          "json",
          "",
          FromDate,
          toDate
        );
      }

      setData(respData);
      respData = {};
      setDataLoading(false);

      // setTabelTotalSize(respData?.length);
    } catch (error) {
      catchErrorFunction(error, "Token expired! Please login again.", router);
      setDataLoading(false);
    }
  };

  const getCounters = async () => {
    try {
      const counters = await TmsCounterService.getAllCounter();
      setCounterList(counters?.items);
    } catch (error) {
      catchErrorFunction(error, "Token exipred! Please login again.", router);
    }
  };

  const getShifts = async () => {
    try {
      const res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (error) {
      catchErrorFunction(error, "Token expired! please login again.", router);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "tms-report-container",
    onAfterPrint: () => {
      setModal(false);
      setModalData([]);
      setPrintLoading(false);
    },
  });

  const handleUserDetails = () => {
    // setUserDetails(undefined);
    setShowUserDetails(false);
  };

  const printReport = async () => {
    setPrintLoading(true);
    try {
      const respData =
        bookingType === "pos"
          ? await menuService[active].getConsolidateReport(
              "json",
              "",
              FromDate,
              toDate,
              selectedCounter,
              selectedShift,
              accType
            )
          : await menuService[active].getOnlineConsolidateReport(
              "json",
              "",
              FromDate,
              toDate
            );
      setData(respData);
      setDataLoading(false);
      setModalData(data ?? []);
      setModal(true);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setModalData(false);
      setPrintLoading(false);
      setModal(false);
    }
  };

  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      const data =
        bookingType === "pos"
          ? await menuService[active].getConsolidateReport(
              "json",
              "",
              FromDate,
              toDate,
              selectedCounter,
              selectedShift
            )
          : await menuService[active].getOnlineConsolidateReport(
              "json",
              "",
              FromDate,
              toDate
            );
      const compiledData = data.map((item, i) => {
        let data =
          bookingType === "pos"
            ? {
                "S.no": i + 1,
                "Product ID": item.productId,
                "Product Name": item.productName,
                Date: `${moment(FromDate).format("DD-MM-YYYY")} -> ${moment(
                  toDate
                ).format("DD-MM-YYYY")}`,
                "Ticket S.no (Start)": item.ticketStart,
                "Ticket S.no (End)": item.ticketEnd,
              }
            : {
                "S.no": i + 1,
                "Product ID": item.productId,
                "Product Name": item.productName,
                Date: `${moment(FromDate).format("DD-MM-YYYY")} -> ${moment(
                  toDate
                ).format("DD-MM-YYYY")}`,
              };
        if (active !== "Donation") {
          data["Unit Price"] = item.productPrice;
        }
        data.Quantity = item.totalTickets;
        data.Amount = item.totalCollection;
        return data;
      });
      exportExcel(
        compiledData,
        `${active}-consolidated-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };

  const renderTable = () => {
    if (dataLoading) {
      return;
    }
    const tableStructure = (key, modalData) => {
      if (modalData?.length === 0) return;
      return (
        <>
          <h4>{key}</h4>
          <table className="tms-report-table" style={{ fontSize: "14px" }}>
            <thead>
              <th>S.no </th>
              <th>Product ID </th>
              <th>
                {key === "Darshanam"
                  ? "Darshanam Type"
                  : key === "Sevas"
                  ? "Seva Type"
                  : key === "Thulabharam"
                  ? "Thulabahram Type"
                  : key === "Toll Gate"
                  ? "Vehicle Type"
                  : key === "Prasadam"
                  ? "Prasadam Type"
                  : key === "Sevas"
                  ? "Seva Type"
                  : key === "Donation"
                  ? "Donation Type"
                  : key === "Accommodation"
                  ? "Block Name"
                  : key === "Products"
                  ? "Products/Publication Name"
                  : "Product Name"}
              </th>
              <th>Date</th>
              {bookingType === "pos" ? (
                <>
                  <th>Tickets No (Start)</th>
                  <th>Tickets No (End)</th>
                </>
              ) : (
                <></>
              )}

              {modalData?.[0]?.productPrice || modalData?.[0]?.unitPrice ? (
                <th>{key === "Donation" ? "Min-Amount" : "Unit Price"}</th>
              ) : (
                ""
              )}
              <th>Quantity</th>
              <th>Amount</th>
            </thead>
            <tbody>
              {modalData?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item?.productId}</td>
                    <td>{item?.productName}</td>
                    <td>
                      {moment(FromDate).format("DD-MM-YYYY")} -{" "}
                      {moment(toDate).format("DD-MM-YYYY")}
                    </td>
                    {bookingType === "pos" ? (
                      <>
                        <td>{item?.ticketStart}</td>
                        <td>{item?.ticketEnd}</td>
                      </>
                    ) : (
                      <></>
                    )}

                    <td>{item?.productPrice ?? item?.unitPrice}</td>
                    <td>{item?.totalTickets}</td>
                    <td>{item.totalCollection}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
    };
    return active === "All" ? (
      <>
        {menu.map((item) => {
          if (item === "All") return;
          return (
            <>
              {tableStructure(
                item,
                bookingType === "pos"
                  ? modalData?.data?.[menuKeys[item]?.pos ?? []]
                  : modalData?.data?.[menuKeys[item]?.online ?? []]
              )}
              <br />
              {item === "Accommodation" && bookingType === "pos" && (
                <>
                  <br />{" "}
                  {tableStructure(
                    `Accommodation Update(Renew/Checkout)`,
                    modalData?.data?.counter_accommodation_update
                  )}
                </>
              )}
            </>
          );
        })}
      </>
    ) : (
      tableStructure(active, modalData)
    );
  };

  return (
    <>
      <Row style={{ marginBottom: "20px" }} justify={"start"} gutter={[20, 20]}>
        <Col>
          <TmsDatePickerRange
            label={"Date range"}
            from={FromDate}
            to={toDate}
            setFrom={setFromDate}
            setTo={setToDate}
          />
        </Col>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "pos" },
            { id: "online", name: "online" },
          ]}
          optional={true}
        />
        <TmsSelect
          type="col"
          span={4}
          label="Counters"
          selectClass="w-100"
          setValue={setSelectedCounter}
          value={selectedCounter}
          data={countersList}
        />
        <TmsSelect
          type="col"
          span={4}
          label="Shifts"
          selectClass="w-100"
          setValue={setSelectedShift}
          value={selectedShift}
          data={shiftList}
        />
        {active === "Accommodation" && (
          <TmsSelect
            type="col"
            span={4}
            label="Accommodation Type"
            selectClass="w-100"
            setValue={setAccType}
            value={accType}
            data={[
              { id: "counter_accommodation" },
              { id: "counter_accommodation_advance" },
              { id: "counter_accommodation_update" },
            ]}
            optional={true}
          />
        )}
        <>
          {" "}
          <Col>
            <Row
              justify="start"
              style={{ marginBottom: "5px", color: "transparent" }}
            >
              .
            </Row>
            <Row justify="start">
              <Button
                loading={printLoading}
                type="primary"
                onClick={printReport}
              >
                Print
              </Button>
            </Row>
          </Col>
          {active !== "All" && (
            <Col>
              <Row
                justify="start"
                style={{ marginBottom: "5px", color: "transparent" }}
              >
                .
              </Row>
              <Row justify="start">
                <Button
                  loading={excelLoading}
                  type="primary"
                  onClick={handleExcelExport}
                >
                  <DownloadOutlined /> Excel
                </Button>
              </Row>
            </Col>
          )}
        </>

        <Col
          style={{
            width: "250px",
            textAlign: "right",
            margin: "auto",
            marginRight: "0",
          }}
        >
          <Card hoverable size="small" style={{ fontWeight: "bold" }}>
            <Statistic
              prefix="₹"
              title="Total Collection"
              value={
                data?.meta
                  ? data?.meta?.totalCollection
                  : data.reduce(
                      (t, item) => t + Number(item.totalCollection),
                      0
                    )
              }
            />
          </Card>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={3}>
          <div className="tms-left-menu">
            {menu.map((item, i) => {
              return (
                <p
                  key={"tms-left-side-menu" + i}
                  className={active === item ? "tms-lm-active" : ""}
                  onClick={() => {
                    setData([]);
                    setActive(item);
                  }}
                >
                  {item}
                </p>
              );
            })}
          </div>
        </Col>
        <Col span={21}>
          {active === "All" ? (
            <>
              {dataLoading === false ? (
                data?.meta?.totalTickets > 0 ? (
                  <>
                    {" "}
                    {data?.data?.counter_darshanam?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => <h3>POS Darshanam</h3>}
                        columns={AllColums}
                        dataSource={data?.data?.counter_darshanam ?? []}
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                    {data?.data?.counter_seva?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => <h3>POS Seva</h3>}
                        columns={AllColums}
                        dataSource={data?.data?.counter_seva ?? []}
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                    {data?.data?.counter_donation?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => <h3>POS Donation</h3>}
                        columns={AllColums}
                        dataSource={data?.data?.counter_donation ?? []}
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                    {data?.data?.counter_prasadam?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => <h3>POS Prasadam</h3>}
                        columns={AllColums}
                        dataSource={data?.data?.counter_prasadam ?? []}
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                    {data?.data?.counter_thulabharam?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => <h3>POS Thulabharam</h3>}
                        columns={AllColums}
                        dataSource={data?.data?.counter_thulabharam ?? []}
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                    {data?.data?.counter_accommodation?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => <h3>POS Accommodation</h3>}
                        columns={AllColums}
                        dataSource={data?.data?.counter_accommodation ?? []}
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                    {data?.data?.counter_accommodation_update?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => (
                          <h3>POS Accommodation(Checkout/Renewal)</h3>
                        )}
                        columns={AllColums}
                        dataSource={
                          data?.data?.counter_accommodation_update ?? []
                        }
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                    {data?.data?.counter_kalyanakatta?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => <h3>POS Kalyanakatta</h3>}
                        columns={AllColums}
                        dataSource={data?.data?.counter_kalyanakatta ?? []}
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                    {data?.data?.counter_tollgate?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => <h3>POS Toll gate</h3>}
                        columns={AllColums}
                        dataSource={data?.data?.counter_tollgate ?? []}
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                    {data?.data?.counter_manual_entry?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => <h3>POS Manual Entry</h3>}
                        columns={AllColums}
                        dataSource={data?.data?.counter_manual_entry ?? []}
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                    {data?.data?.online_darshanam?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => <h3>Online Darshanam</h3>}
                        columns={AllColums}
                        dataSource={data?.data?.online_darshanam ?? []}
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                    {data?.data?.online_donation?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => <h3>Online Donation</h3>}
                        columns={AllColums}
                        dataSource={data?.data?.online_donation ?? []}
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                    {data?.data?.online_accommodation?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => <h3>Online Accommodation</h3>}
                        columns={AllColums}
                        dataSource={data?.data?.online_accommodation ?? []}
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                    {data?.data?.online_seva?.length > 0 && (
                      <Table
                        className={"row-align mb-5"}
                        title={() => <h3>Online Seva</h3>}
                        columns={AllColums}
                        dataSource={data?.data?.online_seva ?? []}
                        pagination={false}
                        loading={dataLoading}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      style={{
                        background: "white",
                        padding: "50px",
                        margin: "0",
                      }}
                    />
                  </>
                )
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "white",
                    padding: "50px",
                    margin: "0",
                  }}
                >
                  <img
                    src={tmsLogo}
                    alt="logo"
                    height={window.innerWidth < 650 ? "50px" : "70px"}
                    style={{
                      border: "2px solid orange",
                      borderRadius: "50%",
                    }}
                  />
                  <br />
                  <p style={{ textAlign: "center" }}>Loading...</p>
                </div>
              )}
            </>
          ) : (
            <>
              <Table
                style={{ width: "100%", textTransform: "capitalize" }}
                columns={TmsConsildatedColums(active, bookingType)}
                dataSource={data ?? []}
                loading={dataLoading}
                scroll={{ x: true }}
                size="small"
                align="center"
                className={"row-align"}
                pagination={false}
              ></Table>
            </>
          )}
        </Col>
      </Row>
      <Drawer
        title={`User`}
        placement="right"
        closable={false}
        onClose={handleUserDetails}
        visible={showUserDetails}
        width={500}
        destroyOnClose={true}
      >
        {/* <SevaCounterTable userid={userDetails} /> */}
      </Drawer>
      <TmsModal
        visible={modal}
        width={800}
        closable={true}
        centered
        onCancel={() => {
          setModal(false);
          setModalData([]);
          setPrintLoading(false);
        }}
      >
        <div className="tms-modal-div">
          <Button type="primary" className="print-button" onClick={handlePrint}>
            Print
          </Button>
        </div>
        <br />
        <div className="tms-report-container" ref={refContainer}>
          <h3 className="ta-center">{templeDetails?.name}</h3>

          <h3 className="ta-center">Consolidated Report</h3>
          <h6 className="ta-center">
            {moment().format("DD-MM-YYYY HH:mm:ss ")}
          </h6>
          {renderTable()}
          <br />
          <div>
            <h4 style={{ float: "left" }}>
              Total Tickets :{" "}
              {active === "All"
                ? modalData?.meta?.totalTickets
                : modalData?.reduce((t, item) => {
                    return t + item.totalTickets;
                  }, 0)}
            </h4>
            <h4 style={{ float: "right" }}>
              Total Collection : Rs.{" "}
              {active === "All"
                ? modalData?.meta?.totalCollection
                : modalData?.reduce((t, item) => {
                    return t + item.totalCollection;
                  }, 0)}
            </h4>
          </div>
        </div>
      </TmsModal>
    </>
  );
};

export default withRouter(TmsConsolidatedReport);
