import React, { useEffect, useState } from "react";
import { tmsLogo, tms_404, tms_500 } from "../../assets/images/tms-image-list";
import TmsCarousel from "../../components/tms-customs/tms-carousel";
import "../../assets/css/tms-common/tms-common.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
const TmsErrorPage = ({ router }) => {
  const [errorType, setErrorType] = useState("");
  useEffect(() => {
    let { error } = router.params;
    setErrorType(error);
  }, []);
  const errorDesc = {
    404: { desc: "404 Page not found", image: tms_404 },
    500: { desc: "500 Internal Service Error", image: tms_500 },
    403: { desc: "403 Forbidden", image: "IMG" },
  };
  return (
    <div className="tms-outer-wrapper">
      <div className="tms-left">
        <TmsCarousel />
        {
          //carousel
        }
      </div>
      <div className="tms-right">
        <img src={tmsLogo} className="tms-logo" alt="error" />
        <h2 className="tms-h2">Devasthanam</h2>
        <img src={errorDesc[errorType]?.image} />
        <div>{errorDesc[errorType]?.desc}</div>
        <br />
        <div className="tms-back-arrow-text">
          <Link
            onClick={() => {
              router.navigate(-1);
            }}
          >
            <ArrowLeftOutlined /> Back
          </Link>
        </div>
        <p className="tms-reserved">
          © {new Date().getFullYear()} All rights reserved{" "}
        </p>
      </div>
    </div>
  );
};
export default withRouter(TmsErrorPage);
