import { Select, Row, Col } from "antd";
import React from "react";
import "../../assets/css/tms-custom-elements/tms-custom-elements.scss";
const TmsSelect = ({
  type,
  label,
  setValue,
  value,
  data,
  selectClass,
  classname,
  labelClass,
  span,
  optional,
}) => {
  const item = (
    <>
      {" "}
      <Row
        justify="start"
        style={{ marginBottom: "5px" }}
        className={labelClass}
      >
        {label}
      </Row>
      <Row justify="start">
        <Select
          className={selectClass ? selectClass : "sd-select-r"}
          dropdownClassName="sd-select-dropdown-r"
          onChange={setValue}
          value={value}
          defaultValue={value}
          placeholder={`Select a ${label}`}
        >
          {data?.map((item) => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.name ?? item.description}
              </Select.Option>
            );
          })}
          {!optional && (
            <Select.Option key={""} value="">
              All
            </Select.Option>
          )}
        </Select>
      </Row>
    </>
  );
  return (
    <>
      {type === "row" ? (
        <Row className={classname ? classname : ""}>{item}</Row>
      ) : type === "col" ? (
        <Col span={span} className={classname ? classname : ""}>
          {item}
        </Col>
      ) : (
        ""
      )}
    </>
  );
};
export default TmsSelect;
