import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import moment from "moment";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Menu,
  Modal,
  notification,
  Row,
  Switch,
} from "antd";
import { LoadingOutlined, RightOutlined } from "@ant-design/icons";
import TmsStringUtils from "../../../utils/tms-string-utils/tms-string-utils";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import TmsThulabharamService from "../../../services/tms-thulabharam.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import "../../../assets/css/tms-prasadam/tms-pos-prasadam.scss";

const TmsPosThulabharam = ({ router }) => {
  const ref = useRef(null);
  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [bookingData, setBookingData] = useState([]);
  const [itemCount, setItemCount] = useState([]);
  const [amountpaid, setAmountPaid] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [externalPrint, setExternalPrint] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [array, setArray] = useState([]);

  const thulabharamData = (data) => {
    let totalPrice = 0;
    let schemaBody = [];
    let firstItem = data?.cartItems?.thulabharam[0];
    data?.cartItems?.thulabharam?.forEach((item) => {
      totalPrice += item.thulabharamBooking.price;

      schemaBody = [
        ...schemaBody,
        [
          "",
          `<tr style="text-transform:capitalize;"><td style="border:1px solid black">${item.productDetails.productName}</td><td class="col-2" style="border:1px solid black">${item.thulabharamBooking.noOfItems}</td><td style="border:1px solid black">Rs. ${item.thulabharamBooking.price}.00</td></tr>`,
          {
            display: "none",
          },
        ],
      ];
    });
    let schemaHeader = [
      [
        "header",
        firstItem?.counterCartItem?.categoryTransactionSequence
          ?.split("-")
          ?.pop(),
        {},
        firstItem?.counterCartItem?.dailyCategoryTransactionSequence
          ?.split("-")
          ?.pop(),
        firstItem?.counterCartItem?.ticketId,
      ],
      [
        "Date",
        moment(firstItem?.thulabharamBooking?.bookingDate).format("DD-MM-YYYY"),
        { margin: "0" },
      ],
      [
        "Ticket Id",
        `${
          firstItem?.counterCartItem?.ticketId?.split("-")[1]
        }-${firstItem?.counterCartItem?.ticketId?.split("-")?.pop()}`,
        { margin: "0" },
      ],
      [
        "",
        `<tr style="text-transform:capitalize;font-weight:bold;"><td>Item</td><td>Qty</td><td>Total</td></tr>`,
        {
          display: "none",
        },
      ],
    ];
    let schemaFooter = [
      [
        "Total Amount",
        `Rs. ${totalPrice}.00 `,
        { margin: "0", fontSize: "22px", fontWeight: "800" },
      ],
      [
        "",
        `(${numberToWords(totalPrice)} Rupees)`,
        { margin: "0", fontWeight: "bold" },
      ],
      [
        "footer",
        firstItem?.counterCartItem?.ticketId,
        {},
        {},
        {},
        !firstItem?.productDetails?.productMetadata?.termsAndConditions ||
        firstItem?.productDetails?.productMetadata?.termsAndConditions[0] === ""
          ? null
          : firstItem?.productDetails?.productMetadata?.termsAndConditions
              ?.map((item, i) => {
                return `<p style="margin:0" key=${i}>${item}</p>`;
              })
              .join(" "),
        firstItem?.counterCartItem?.createdAt,
      ],
    ];
    setArray([...schemaHeader, ...schemaBody, ...schemaFooter]);
  };

  useEffect(() => {
    const localStorageSelectedItemIds = localStorage.getItem(
      "selectedCounterItemIds"
    );

    if (localStorageSelectedItemIds) {
      const ids = localStorageSelectedItemIds.split(",").map(Number);
      setSelectedItemIds(ids);
    } else {
      setSelectedItemIds([]);
    }
  }, []);

  const getItems = async () => {
    setLoading(true);
    try {
      let res = await TmsThulabharamService.fetchThulabharamBookingSlots();
      setItemList(res);
      setItemCount(
        res.map((item) => {
          return {
            id: item?.product?.id,
            price: item?.product?.price,
            count: 0,
          };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getItems();
  }, []);

  const handleSubmit = async () => {
    setButtonLoading(true);
    setLoading(true);
    let a = false;
    itemCount?.forEach((e) => {
      if (e.count !== 0 && e.count > 0) {
        a = true;
      }
    });
    if (!a) {
      notification.error({ message: "Please select atleast one item" });
      setButtonLoading(false);
      return;
    }
    try {
      let body = {
        thulabharamItems: [],
      };
      itemCount.forEach((item) => {
        if (item.count > 0 && item?.count !== 0) {
          body.thulabharamItems.push({
            thulabharamId: Number(item?.id),
            noOfItems: Number(item?.count),
          });
        }
      });
      let res = await TmsThulabharamService.thulabharamBooking(body);
      notification.success({ message: "Success" });
      thulabharamData(res);
      clearFields();
      setIsModalVisible(true);
      setExternalPrint(true);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setButtonLoading(false);
    }
    // setLoading(false);
  };

  const getAmount = () => {
    let amount = 0;
    itemCount.forEach((e) => {
      if (e?.count === 0) {
        return;
      } else {
        amount += e?.count * e?.price;
      }
    });
    return amount;
  };

  const clearFields = () => {
    setStartDate("");
    setItemList([]);
  };

  const toggleSwitch = (id) => {
    let newIds = [];
    if (selectedItemIds?.includes(id)) {
      newIds = selectedItemIds.filter((e) => e !== id);
    } else {
      newIds = [...selectedItemIds, id];
    }
    setSelectedItemIds(newIds);
    localStorage.setItem("selectedCounterItemIds", newIds);
  };

  const menu = (
    <Menu onClick={() => setMenuVisible(true)}>
      {itemList.map((item) => {
        return (
          <Menu.Item
            key={item?.product?.id}
            onClick={() => {
              toggleSwitch(item?.product?.id);
            }}
          >
            {item?.product.name}
            <Switch
              size="small"
              style={{ float: "right" }}
              checked={selectedItemIds?.includes(item?.product?.id)}
            />
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const handleVisibleChange = () => {
    if (menuVisible) {
      setMenuVisible(false);
    } else {
      setMenuVisible(true);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <div
            style={{
              maxWidth: "1300px",
              width: "100%",
              background: "white",
              padding: "30px 50px",
            }}
          >
            <Row justify="space-between" style={{ textAlign: "left" }}>
              <Col style={{ width: "48%", justifyContent: "start" }}>
                <label className="labelStyles">
                  Booking Date{" "}
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />{" "}
                <DatePicker
                  disabled={true}
                  value={startDate}
                  style={{ width: "100%" }}
                  format="DD-MM-YYYY"
                  disabledDate={(d) =>
                    !d || d.isBefore(TmsStringUtils.addDays(new Date(), -1))
                  }
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  size="medium"
                />
              </Col>
              <Col style={{ width: "48%", justifyContent: "start" }}>
                <label className="labelStyles">Item Menu </label>
                <br />{" "}
                <Dropdown
                  overlay={menu}
                  placement="bottomLeft"
                  onVisibleChange={() => handleVisibleChange()}
                  visible={menuVisible}
                >
                  <Button
                    style={{ width: "100%", textAlign: "left" }}
                    size="medium"
                  >
                    Select Menu
                  </Button>
                </Dropdown>
              </Col>
            </Row>
            <br />
            <Row
              style={{
                backgroundColor: "#ECF0F5",
                borderRadius: "2px 2px 0px 0px",
                paddingRight: "18px",
              }}
            >
              <Col
                style={{
                  width: "25%",
                  padding: "15px 10px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "600",
                }}
              >
                Item Name
              </Col>
              <Col
                style={{
                  width: "25%",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "600",
                  padding: "15px 10px",
                  borderLeft: "1px solid #CDD7E2",
                }}
              >
                Unit Price
              </Col>
              <Col
                style={{
                  width: "25%",
                  display: "flex",
                  alignItems: "center",
                  padding: "15px 10px",
                  borderLeft: "1px solid #CDD7E2",
                }}
              >
                Quantity
              </Col>
              <Col
                style={{
                  width: "25%",
                  display: "flex",
                  alignItems: "center",
                  padding: "15px 10px",
                  borderLeft: "1px solid #CDD7E2",
                }}
              >
                Net Amount
              </Col>
            </Row>
            <Row justify="space-between" style={{ overflow: "hidden" }}>
              {itemList
                .filter((item) => selectedItemIds?.includes(item?.product?.id))
                .map((item, i) => {
                  return (
                    <Card
                      key={i}
                      hoverable
                      style={{ width: "100%" }}
                      className="itemCard"
                    >
                      <Row style={{ padding: "0" }}>
                        <Col
                          style={{
                            width: "25%",
                            display: "flex",
                            alignItems: "center",
                            color: "red",
                            fontWeight: "600",
                            padding: "10px",
                          }}
                        >
                          {item?.product.name}

                          <Divider type="vertical" />
                        </Col>

                        <Col
                          style={{
                            width: "25%",
                            display: "flex",
                            alignItems: "center",
                            color: "red",
                            fontWeight: "600",
                            padding: "10px",
                            borderLeft: "1px solid #CDD7E2",
                          }}
                        >
                          Rs. {item?.product?.price}
                        </Col>
                        <Col
                          style={{
                            width: "25%",
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                            borderLeft: "1px solid #CDD7E2",
                          }}
                        >
                          <Input
                            id={`input-navigation-${i}`}
                            tabIndex={i}
                            style={{
                              width: "70px",
                              textAlign: "center",
                            }}
                            type="number"
                            min="0"
                            max="200"
                            maxLength="3"
                            key="edit"
                            placeholder="0"
                            autoFocus={i === 0 ? true : false}
                            value={
                              itemCount?.find((e) => e.id === item?.product?.id)
                                ?.count || undefined
                            }
                            controls={false}
                            step="false"
                            onKeyDown={(E) => {
                              if (E.key === "Enter") {
                                E.preventDefault();
                                buttonLoading === false && handleSubmit();
                              }
                              if (E.key === "ArrowUp") {
                                E.preventDefault();

                                window.setTimeout(function () {
                                  document
                                    .getElementById(`input-navigation-${i - 1}`)
                                    ?.focus();
                                }, 0);
                              }
                              if (E.key === "ArrowDown") {
                                E.preventDefault();

                                window.setTimeout(function () {
                                  document
                                    .getElementById(`input-navigation-${i + 1}`)
                                    ?.focus();
                                }, 0);
                              }
                            }}
                            onChange={(event) => {
                              if (event.target.value > 200) {
                                return;
                              }
                              let data = [...itemCount];
                              data.forEach((e) => {
                                if (e?.id === item?.product?.id) {
                                  e.count = event.target.value;
                                }
                              });
                              setItemCount(data);
                            }}
                          />
                        </Col>
                        <Col
                          style={{
                            width: "25%",
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                            borderLeft: "1px solid #CDD7E2",
                          }}
                        >
                          <div key={i}>
                            <span style={{ color: "red", fontWeight: "600" }}>
                              Rs.{" "}
                              {itemCount?.find(
                                (e) => e.id === item?.product?.id
                              )?.price *
                                itemCount?.find(
                                  (e) => e.id === item?.product?.id
                                )?.count}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  );
                })}
            </Row>
          </div>
          <div
            style={{
              maxWidth: "1300px",
              width: "100%",
              background: "#FFFCDC",
            }}
          >
            <Row
              style={{
                alignItems: "center",
                textAlign: "left",
                padding: "10px 50px",
              }}
            >
              <Col
                className="amountLabelStyles"
                style={{
                  width: "260px",
                  fontSize: "24px",
                  marginRight: "20px",
                }}
              >
                Amount Paid: Rs.
                <Input
                  className="amountPaidStyle"
                  type="number"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) => {
                    setAmountPaid(e.target.value);
                  }}
                />
              </Col>
              <Col className="amountLabelStyles">
                Amount to be Returned: Rs.{" "}
                {amountpaid - getAmount() > 0 ? amountpaid - getAmount() : 0}
              </Col>
            </Row>
            <Divider style={{ margin: "0" }} />
            <Row
              style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "500",
                padding: "10px 50px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  textAlign: "left",
                  margin: "0",
                }}
              >
                Total Amount :
              </p>
              <p
                style={{
                  fontSize: "24px",
                  margin: "0",
                  textAlign: "right",
                }}
              >
                Rs. {Number(getAmount() ?? 0)}.00
              </p>
            </Row>
          </div>
          <br />
          <br />
          <div
            style={{
              maxWidth: "1300px",
              width: "100%",
            }}
          >
            <Row style={{ justifyContent: "end", width: "100%" }}>
              <Col
                style={{
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  ref={ref}
                  type="primary"
                  style={{
                    width: "100%",
                    background: "#E11513",
                    borderColor: "#E11513",
                    height: "42px",
                    borderRadius: "3px",
                  }}
                  onClick={() => {
                    buttonLoading === false && handleSubmit();
                  }}
                  loading={buttonLoading}
                >
                  Submit <RightOutlined />
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
      <Modal
        visible={isModalVisible}
        width={400}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type="thulabharam"
          from="Counter"
          data={array}
          setData={setArray}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setButtonLoading}
          setPrintTicketModal={setIsModalVisible}
        />
      </Modal>
    </div>
  );
};
export default withRouter(TmsPosThulabharam);
