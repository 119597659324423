import { Button, notification, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import TmsCounterService from "../../../services/tms-counter.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsModal from "../../tms-customs/tms-modal";
import moment from "moment";
import TmsUserService from "../../../services/tms-user/tms-user.service";
import TmsShiftService from "../../../services/tms-shift.service";
import TmsDatePickerRange from "../../tms-customs/tms-datepicker-range";
import TmsCheckbox from "../../tms-customs/tms-checkbox";
const TmsCMPreShiftsModal = ({
  counterId,
  showPreShifsModal,
  setShowPreShiftsModal,
  setUpdateFlag,
  editFlag,
  setEditFlag,
  editData,
  setEditData,
  router,
}) => {
  const { Option } = Select;

  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().add(1, "d"), moment());
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedShift, setSelectedShift] = useState({});

  useEffect(() => {
    loadShiftsandUsers();
  }, []);

  useEffect(() => {
    if (editFlag && shifts.length > 0 && users.length > 0) {
      let shift = shifts?.find(
        (item) => item.id === editData.counterShiftTiming.id
      );
      let user = users.find((e) => e.id === editData.userId);
      setDetails(
        shift,
        user,
        moment(editData.startDate).format("YYYY-MM-DD"),
        moment(editData.endDate).format("YYYY-MM-DD"),
        editData.enabled
      );
    }
  }, [editFlag]);

  const loadShiftsandUsers = async () => {
    try {
      setLoading(true);
      const shifts = await TmsShiftService.getAllShifts();
      const users = await TmsUserService.getUsers();
      setShifts(shifts.items);
      setUsers(users);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login", router);
      setLoading(false);
    }
  };

  const handleAssignShifts = async () => {
    if (selectedShift === {}) {
      return notification.error({ message: `Select a Shift` });
    }
    if (selectedUser === {}) {
      return notification.error({ message: `Select a Shift` });
    }
    if (to === "" || from === "") {
      return notification.error({
        message: `Select a valid Start and End date`,
      });
    }
    setLoading(true);
    try {
      let body = {
        counterId: parseInt(counterId),
        counterShiftTimingId: selectedShift.id,
        startDate: moment(from).format("YYYY-MM-DD"),
        endDate: moment(to).format("YYYY-MM-DD"),
        enabled: enabled,
        userId: selectedUser.id,
      };
      if (editFlag) {
        await TmsCounterService.editCounterPreShiftById(body, editData.id);
        notification.success({
          message: `Shift ${selectedShift.name} has been assigned to ${selectedUser.displayName} to POS ID: ${counterId}`,
        });
      } else {
        await TmsCounterService.addCounterPreShift(body);
        notification.success({
          message: `Pre-Shift ${selectedShift.name} has been assigned to ${selectedUser.displayName} to POS ID: ${counterId}`,
        });
      }
      setUpdateFlag(Math.random(1000) * 10 + 9);
      handleCancel();
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please Login", router);
    }
    setLoading(false);
  };

  const setDetails = (selectedShift, selectedUser, from, to, enabled) => {
    setSelectedShift(selectedShift);
    setSelectedUser(selectedUser);
    setTo(to);
    setFrom(from);
    setEnabled(enabled);
  };

  const onClose = () => {
    setShowPreShiftsModal(false);
    setEditData(null);
    setEditFlag(false);
  };

  const handleCancel = () => {
    setDetails(
      "",
      "",
      moment().format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
      false
    );
    onClose();
  };

  return (
    <TmsModal
      width="40%"
      visible={showPreShifsModal}
      title={
        editFlag
          ? `Edit Pre Shift Details - ${editData?.id}`
          : "Create Pre Shift"
      }
      onOk={handleAssignShifts}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            handleAssignShifts();
          }}
        >
          Submit
        </Button>,
      ]}
    >
      {loading ? (
        ""
      ) : (
        <>
          <Row justify="center" style={{ width: "100%", paddingTop: "10px" }}>
            POS Name :{" "}
            {new URLSearchParams(router?.location.search).get("counterName")}
          </Row>
          <Row
            justify="center"
            style={{
              width: "100%",
              padding: "10px 0px 0px 0px",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "5px" }}>Select a Shift</div>

            <Select
              width="100%"
              style={{ width: "100%" }}
              value={selectedShift?.id}
              placeholder="Please select a Shift"
              onChange={(e) => {
                let temp = shifts?.find((item) => item.id === e);
                setSelectedShift(temp);
              }}
            >
              {shifts?.map((item, i) => {
                return (
                  <Option
                    key={`${item.name}-${i}-counter-list`}
                    value={item.id}
                  >
                    {item.name}-(
                    {moment(item?.startTime, "HH:mm:ss Z").format("hh:mm A")} -
                    {moment(item?.endTime, "HH:mm:ss Z").format("hh:mm A")})
                  </Option>
                );
              })}
            </Select>
          </Row>
          <Row
            justify="center"
            style={{
              width: "100%",
              padding: "10px 0px",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "5px" }}>
              Select a user to assign shift
            </div>

            <Select
              width="100%"
              style={{ width: "100%" }}
              value={selectedUser?.displayName}
              placeholder="Please select a User to assign "
              onChange={(e) => {
                let temp = users?.find((item) => item.id === e);
                setSelectedUser(temp);
              }}
            >
              {users?.map((item, i) => {
                if (!item?.id || !item?.displayName) {
                  return;
                }
                return (
                  <Option
                    key={item.displayName + `${i}-display_name`}
                    value={item.id}
                  >
                    {item.displayName} - ({item.phone})
                  </Option>
                );
              })}
            </Select>
          </Row>
          <TmsDatePickerRange
            label="Select Duration of Shift"
            to={to}
            from={from}
            setFrom={setFrom}
            setTo={setTo}
          />
          <TmsCheckbox
            label="Enable Pre-Shift"
            setValue={setEnabled}
            value={enabled}
          />
        </>
      )}
    </TmsModal>
  );
};
export default withRouter(TmsCMPreShiftsModal);
