import React, { useState, useEffect } from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import TmsStringUtils from "../../../utils/tms-string-utils/tms-string-utils";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsDonationService from "../../../services/tms-donation.service";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import TmsPosDonationPreviewModal from "../../../components/tms-pos/tms-pos-donation/tms-pos-donation-preview";
import TmsCalenderMasam from "../../../components/tms-customs/tms-calender-masam";
import TmsCalenderTithi from "../../../components/tms-customs/tms-calender-tithi";

const { Option } = Select;
const TmsPosDonation = ({ router }) => {
  const [selectedDonationId, setSelectedDonationId] = useState();
  const [previewFlag, setPreviewFlag] = useState(false);
  const [externalPrint, setExternalPrint] = useState(false);
  const [sevaList, setSevaList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [gothram, setGothram] = useState("");
  const [payment, setPayment] = useState("CASH");
  const [idProofType, setIdProofType] = useState("");
  const [idProofNumber, setIdProofNumber] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [masam, setMasam] = useState("Chaithram");
  const [tithi, setTithi] = useState("Prathama");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState();
  const [paksham, setPaksham] = useState("shukla");
  const [calenderType, setCalenderType] = useState("english");
  const [cashReference, setCashReference] = useState("");
  const [cashRemarks, setCashRemarks] = useState("");
  const [amount, setAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [date, setDate] = useState();
  const [email, setEmail] = useState("");
  const labelStyles = { float: "left", fontSize: "17px", marginBottom: "5px" };
  const [devoteeName, setDevoteeName] = useState("");
  const [onBehalfOf, setOnBehalfof] = useState("");
  const [occasion, setOccasion] = useState("");
  const [array, setArray] = useState([]);
  const [printType, setPrintType] = useState(
    localStorage.getItem("donation-print") ?? "a4"
  );
  const [previewData, setPreviewData] = useState({});
  const [bondData, setBondData] = useState([]);
  useEffect(() => {
    getDonationSevaList();
    setDevoteeName("");
    setOnBehalfof("");
  }, []);

  const DoantionData = (data) => {
    let schemaBody = [];
    data?.cartItems?.donation?.map((item) => {
      schemaBody = [
        ...schemaBody,
        [
          "header",
          item?.counterCartItem?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.counterCartItem?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop(),
          item?.counterCartItem?.ticketId,
        ],
        ["Ticket Id", item?.counterCartItem?.ticketId, { margin: "0" }],
        [
          "Donation",
          item?.productDetails?.productName,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "Devotee Name",
          item?.donationBooking?.devoteeName
            ? item?.donationBooking?.contact
              ? `${item?.donationBooking?.devoteeName
                  ?.split(",")[0]
                  .trim()} | ${item?.donationBooking?.contact}`
              : item?.donationBooking?.devoteeName?.split(",")[0].trim()
            : item?.donationBooking?.contact ?? "NA",
          { margin: "0" },
        ],
        [
          "In Behalf of",
          `${item?.donationBooking?.inTheNameOf?.split(",")[0].trim()}
          `,
          { margin: "0" },
        ],
        ["Gothram", `${item?.donationBooking?.gothram}`, { margin: "0" }],
        ["Occasion", `${item?.donationBooking?.occasion}`, { margin: "0" }],
        [
          "Address",
          `${item?.donationBooking?.address}, ${item?.donationBooking?.city}, ${item?.donationBooking?.state}-${item?.donationBooking?.pinCode}`,
          { margin: "0" },
        ],
        [
          "Performance Date",
          `${
            item?.donationBooking?.calendarType === "english"
              ? !data?.donationBooking?.month
                ? `${moment(item?.donationBooking?.bookingDate).format(
                    "DD-MM-YYYY"
                  )}`
                : `${data?.donationBooking?.date}-${
                    data?.donationBooking?.month
                  }-${moment().format("YYYY")}`
              : `${item?.donationBooking?.masam} - 
                  ${item?.donationBooking?.paksham} - 
                  ${item?.donationBooking.tithi}
                `
          }`,
          { margin: "0" },
        ],
        ["Booking Date", moment().format("DD-MM-YYYY"), { margin: "0" }],
        [
          "Ticket Price",
          `Rs. ${item?.productDetails?.productActualPrice}.00`,
          { margin: "0" },
        ],
        [
          "Total Amount",
          `Rs. ${item?.donationBooking?.amount}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(item?.donationBooking?.amount)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.counterCartItem?.createdAt,
        ],
      ];
    });
    setArray([...schemaBody]);
  };

  const getDonationSevaList = async () => {
    setLoading(true);
    try {
      let res = await TmsDonationService.fetchDonationBookingSlots("general");
      setSevaList(res);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const clearLoadings = () => {
    setLoading(false);
    setButtonLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setButtonLoading(true);
    if (
      phone === "" ||
      devoteeName === "" ||
      zip === "" ||
      address === "" ||
      city === "" ||
      state === "" ||
      payment === "" ||
      onBehalfOf === "" ||
      !selectedDonationId
    ) {
      notification.error({ message: "Please fill all mandatory fields" });
      clearLoadings(false);

      return;
    }
    let donation = sevaList.find((e) => e?.product?.id === selectedDonationId);
    if (amount < donation?.product?.price) {
      notification?.error({
        message: `Amount cannot be less than minimum donation amount of Rs.${donation?.product?.price}`,
      });
      clearLoadings();
      return;
    }
    if (donation?.product?.maxAmount && amount > donation?.product?.maxAmount) {
      notification?.error({
        message: `Amount cannot be more than maximum donation amount of Rs.${donation?.product?.maxAmount}`,
      });
      clearLoadings();
      return;
    }
    if (devoteeName.length < 5) {
      notification.error({ message: "Name must be more than 4 letters" });
      clearLoadings();
      return;
    }

    if (phone.length !== 10) {
      notification.error({ message: "Phone number must be 10 digits" });
      clearLoadings();
      return;
    }
    if (idProofNumber !== "" && idProofType !== "") {
      if (idProofType === "pan") {
        let txt = idProofNumber.toUpperCase();
        let regex = /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
        let pan = {
          C: "Company",
          P: "Personal",
          H: "Hindu Undivided Family (HUF)",
          F: "Firm",
          A: "Association of Persons (AOP)",
          T: "AOP (Trust)",
          B: "Body of Individuals (BOI)",
          L: "Local Authority",
          J: "Artificial Juridical Person",
          G: "Govt",
        };
        pan = pan[txt[3]];
        if (regex.test(txt)) {
          if (pan !== "Personal") {
            notification.error({ message: "Invalid Pan Card Number." });
            clearLoadings();
            return;
          }
        } else {
          notification.error({ message: "Invalid Pan Card Number." });
          clearLoadings();
          return;
        }
      } else if (idProofType === "voter") {
        let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
        if (!regex.test(idProofNumber.toUpperCase())) {
          notification.error({ message: "Invalid Voter Card Number." });
          clearLoadings();
          return;
        }
      } else if (idProofType === "aadhar") {
        let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
        if (!regex.test(idProofNumber.toUpperCase())) {
          notification.error({ message: "Invalid Aadhar Card Number." });
          clearLoadings();
          return;
        }
      }
    }
    if (email !== "") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase())) {
        notification.error({ message: "Invalid Email." });
        clearLoadings();
        return;
      }
    }
    if (calenderType === "english") {
      if (date === "") {
        notification.error({ message: "Select donation date" });
        clearLoadings();
        return;
      }
    }
    try {
      let body = {
        contact: `+91${phone}`,
        address: address,
        inTheNameOf: onBehalfOf,
        calendarType: calenderType,
        pinCode: Number(zip),
        state: state,
        city: city,
        country: country,
        donationId: selectedDonationId,
        amount: Number(amount),
        devoteeName: devoteeName,
      };
      if (idProofNumber !== "") {
        body.documentType = idProofType;
        body.documentId = idProofNumber;
      }
      if (calenderType === "telugu") {
        body.masam = masam;
        body.paksham = paksham;
        body.tithi = tithi;
      } else {
        body.bookingDate = moment(date).format("YYYY-MM-DD");
        // body.month = Number(moment(date).format("MM"));
      }
      if (email !== "") body.email = email;
      if (gothram !== "") body.gothram = gothram;
      if (occasion !== "") body.occasion = occasion;

      let res = await TmsDonationService.donationBooking(
        body,
        payment,
        cashReference,
        cashRemarks
      );
      setPreviewFlag(false);
      notification.success({ message: "Success" });
      DoantionData(res);
      setBondData(res?.cartItems?.donation[0]);
      clearFields();
      setLoading(false);
      setIsModalVisible(true);
      setExternalPrint(true);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      clearLoadings();
      setLoading(false);
    }
  };

  const clearFields = () => {
    setPhone("");
    setIdProofNumber("");
    setIdProofType("");
    setSelectedDonationId("");
    setGothram("");
    setAddress("");
    setCity("");
    setZip("");
    setEmail("");
    setAmount("");
    setMaxAmount("");

    setPaksham("shukla");
    setMasam("Chaithram");
    setTithi("Prathama");
    setCashReference("");
    setCashRemarks("");
    setDevoteeName("");
    setOnBehalfof("");
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              maxWidth: "1300px",
              width: "100%",
              background: "white",
              padding: "30px 50px",
            }}
          >
            <Row justify="space-between">
              <Col style={{ width: "48%", justifyContent: "start" }}>
                <label style={labelStyles}>
                  Select Donation Type
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />
                <Select
                  size="medium"
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Select Donation Type"
                  optionFilterProp="children"
                  value={selectedDonationId}
                  onChange={(e) => {
                    setSelectedDonationId(e);
                    console.log(e);
                    setMaxAmount(
                      sevaList.find((item) => item.product.id === e)?.product
                        ?.maxAmount
                    );
                  }}
                >
                  {sevaList?.map((item) => {
                    return (
                      <Option
                        key={item?.product?.name}
                        value={item?.product?.id}
                      >
                        {item?.product?.name} - ( Min -Rs.
                        {item?.product?.price} - Max Rs.{" "}
                        {item?.product?.maxAmount ?? "-"})
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col style={{ width: "22%", justifyContent: "start" }}>
                <label style={labelStyles}>
                  Booking Date
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />
                <DatePicker
                  value={moment(new Date())}
                  style={{ width: "100%" }}
                  format="DD-MM-YYYY"
                  defaultValue={moment(new Date())}
                  disabled={true}
                />
              </Col>

              <Col style={{ width: "22%", justifyContent: "start" }}>
                <label style={labelStyles}>
                  Print Type
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />
                <Select
                  size="medium"
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Select Print Type"
                  value={printType}
                  onChange={(e) => {
                    setPrintType(e);
                    localStorage.setItem("donation-print", e);
                  }}
                >
                  <Option key={"thermal"} value={"thermal"}>
                    Thermal Print
                  </Option>
                  <Option key={"a4"} value={"a4"}>
                    A4 Print
                  </Option>
                </Select>
              </Col>
            </Row>
            <br />
            <Row justify="space-between">
              <Col style={{ width: "48%", justifyContent: "start" }}>
                <label style={labelStyles}>
                  Devotee Name
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />
                <Input
                  type="text"
                  placeholder="Enter a Name"
                  value={devoteeName}
                  onChange={(e) => {
                    setDevoteeName(e.target.value);
                  }}
                />
              </Col>
              <Col style={{ width: "48%", justifyContent: "start" }}>
                <label style={labelStyles}>
                  Amount
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />
                <Input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  size="medium"
                  style={{ textTransform: "none" }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                      e.preventDefault();
                  }}
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => {
                    if (maxAmount > e.target.value) setAmount(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row style={{ display: "flex", flexDirection: "column" }}>
              <Row justify="space-between">
                <Col style={{ width: "48%", justifyContent: "start" }}>
                  <label style={labelStyles}>
                    On behalf Of
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <br />
                  <Input
                    type="text"
                    placeholder="Enter a Name"
                    value={onBehalfOf}
                    onChange={(e) => {
                      setOnBehalfof(e.target.value);
                    }}
                  />
                </Col>
                <Col style={{ width: "48%" }}>
                  <label style={labelStyles}>Gothram</label>
                  <br />
                  <Input
                    type="text"
                    placeholder="Enter a Gothram"
                    value={gothram}
                    onChange={(e) => {
                      setGothram(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Divider />
              <Row justify="space-between">
                <Col style={{ width: "31%" }}>
                  <label style={labelStyles}>Occasion / Auspicious Day</label>
                  <br />
                  <Input
                    size="medium"
                    type="text"
                    placeholder="Enter Occasion"
                    style={{ width: "100%" }}
                    value={occasion}
                    onChange={(e) => {
                      setOccasion(e.target.value);
                    }}
                  />
                </Col>
                <Col style={{ width: "31%", justifyContent: "start" }}>
                  <label style={labelStyles}>
                    Calendar Type
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <br />
                  <Select
                    size="medium"
                    style={{ width: "100%" }}
                    placeholder="Select Calender Type"
                    optionFilterProp="children"
                    value={calenderType}
                    onChange={(e) => {
                      setCalenderType(e);
                    }}
                  >
                    <Option key={"english"} value={"english"}>
                      English Calendar
                    </Option>
                    <Option key={"telugu"} value={"telugu"}>
                      Telugu Calendar
                    </Option>
                  </Select>
                </Col>
                {calenderType === "english" ? (
                  <Col style={{ width: "31%", justifyContent: "start" }}>
                    <label style={labelStyles}>
                      Performance Date
                      <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    </label>
                    <br />
                    <DatePicker
                      value={date}
                      style={{ width: "100%" }}
                      format="DD-MM-YYYY"
                      disabledDate={(current) => {
                        return (
                          !current ||
                          current.isBefore(
                            TmsStringUtils.addDays(new Date(), -1)
                          )
                        );
                      }}
                      onChange={(dates) => {
                        setDate(dates);
                      }}
                    />
                  </Col>
                ) : (
                  <TmsCalenderMasam
                    masam={masam}
                    setMasam={setMasam}
                    labelStyles={labelStyles}
                    percentage={"31%"}
                  />
                )}
              </Row>
              {calenderType === "telugu" && (
                <>
                  <Row justify="space-between">
                    <Col style={{ width: "48%", justifyContent: "start" }}>
                      <label style={labelStyles}>
                        Paksham
                        <span style={{ color: "red", marginLeft: "3px" }}>
                          *
                        </span>
                      </label>
                      <br />
                      <Select
                        size="medium"
                        style={{ width: "100%" }}
                        placeholder="Select a Paksham"
                        optionFilterProp="children"
                        value={paksham}
                        onChange={(e) => {
                          setPaksham(e);
                        }}
                      >
                        <Option key={"Shukla"} value={"Shukla"}>
                          Shukla
                        </Option>
                        <Option key={"Krishna"} value={"Krishna"}>
                          Krishna
                        </Option>
                      </Select>
                    </Col>

                    <TmsCalenderTithi
                      labelStyles={labelStyles}
                      paksham={paksham}
                      tithi={tithi}
                      setTithi={setTithi}
                    />
                  </Row>
                  <br />
                </>
              )}
              <Row justify="space-between">
                <Col style={{ width: "48%" }}>
                  <label style={labelStyles}>
                    Mobile Number
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <br />
                  <Input
                    size="medium"
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    maxLength={10}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-", "."].includes(e.key) &&
                      e.preventDefault()
                    }
                    addonBefore={"+91"}
                    placeholder="Please Enter Mobile Number"
                    style={{ width: "100%" }}
                    value={phone}
                    onChange={(e) => {
                      if (e.target.value.length > 10) {
                        return;
                      }
                      setPhone(`${e.target.value}`);
                    }}
                  />
                </Col>{" "}
                <Col style={{ width: "48%" }}>
                  <label style={labelStyles}>
                    Address{" "}
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <br />
                  <Input
                    type="text"
                    placeholder="Enter address"
                    value={address}
                    onKeyDown={(e) =>
                      ["#", "+"].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row justify="space-between">
                <Col style={{ width: "48%" }}>
                  <label style={labelStyles}>
                    City{" "}
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <br />
                  <Input
                    type="text"
                    placeholder="Enter City"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                </Col>
                <Col style={{ width: "48%" }}>
                  <label style={labelStyles}>
                    Enter Pincode{" "}
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>{" "}
                  <br />
                  <Input
                    type="number"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-", "."].includes(e.key) &&
                      e.preventDefault()
                    }
                    onWheel={(event) => event.currentTarget.blur()}
                    placeholder="Enter Pincode"
                    value={zip}
                    onChange={(e) => {
                      if (e.target.value.length > 6) {
                        return;
                      }
                      setZip(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row justify="space-between">
                <Col style={{ width: "48%" }}>
                  <label style={labelStyles}>
                    {" "}
                    Select State{" "}
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <br />
                  <RegionDropdown
                    className="CountryRegionDropDown"
                    style={{
                      border: "1px solid #d9d9d9",
                      marginBottom: "20px",
                      height: "32px",
                      fontSize: "14px",
                      padding: "0px 8px",
                      width: "100%",
                    }}
                    country={country}
                    value={state}
                    showDefaultOption={true}
                    blankOptionLabel="Select a State"
                    onChange={(val) => {
                      setState(val);
                    }}
                  />
                </Col>
                <Col style={{ width: "48%" }}>
                  <label style={labelStyles}>
                    {" "}
                    Select Country{" "}
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <br />
                  <CountryDropdown
                    className="CountryRegionDropDown"
                    style={{
                      border: "1px solid #d9d9d9",
                      marginBottom: "20px",
                      height: "32px",
                      fontSize: "14px",
                      padding: "0px 8px",
                      width: "100%",
                    }}
                    value={country}
                    onChange={(val) => {
                      setCountry(val);
                    }}
                  />
                </Col>
              </Row>
              <Divider />
              <Row justify="space-between">
                <Col style={{ width: "48%", justifyContent: "start" }}>
                  <label style={labelStyles}>Select ID Proof</label>
                  <br />
                  <Select
                    size="medium"
                    style={{ width: "100%" }}
                    placeholder="Select an ID"
                    optionFilterProp="children"
                    value={idProofType}
                    onChange={(e) => {
                      setIdProofType(e);
                      setIdProofNumber("");
                    }}
                  >
                    {/* <Option key="pan" value="pan">
                      PAN
                    </Option> */}
                    <Option key="aadhar" value="aadhar">
                      AADHAR
                    </Option>
                    {/* <Option key="voter" value="voter">
                      Voter ID
                    </Option> */}
                  </Select>
                </Col>
                <Col style={{ width: "48%" }}>
                  <label style={labelStyles}>Enter ID Proof</label>
                  <br />
                  <Input
                    size="medium"
                    type="text"
                    placeholder="Enter ID Proof"
                    style={{ width: "100%" }}
                    value={idProofNumber}
                    onChange={(e) => {
                      setIdProofNumber(`${e.target.value?.toUpperCase()}`);
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row justify="space-between">
                <Col style={{ width: "48%", justifyContent: "start" }}>
                  <label style={labelStyles}>
                    Select Payment Type
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <br />
                  <Select
                    size="medium"
                    style={{ width: "100%" }}
                    placeholder="Select payment type"
                    optionFilterProp="children"
                    value={payment}
                    onChange={(e) => {
                      setPayment(e);
                    }}
                  >
                    <Option key="CASH" value="CASH">
                      Cash
                    </Option>
                    <Option key="UPI" value="UPI">
                      UPI
                    </Option>
                    <Option key="CARD" value="CARD">
                      Card
                    </Option>
                    <Option key="CHEQUE" value="CHEQUE">
                      Cheque
                    </Option>
                    <Option key="DD" value="DD">
                      DD
                    </Option>
                  </Select>
                </Col>
                <Col style={{ width: "48%" }}>
                  <label style={labelStyles}>
                    Enter Reference (UPI Payment ID, Cheque ID, DD ID)
                  </label>
                  <br />
                  <Input
                    size="medium"
                    type="text"
                    placeholder="Enter Reference ID"
                    style={{ width: "100%" }}
                    value={cashReference}
                    onChange={(e) => {
                      setCashReference(`${e.target.value}`);
                    }}
                  />
                </Col>
              </Row>
              <Row justify="space-between">
                <Col style={{ width: "48%" }}>
                  <label style={labelStyles}>Enter Payment Remarks </label>
                  <br />
                  <Input
                    size="medium"
                    type="text"
                    placeholder="Enter Payment Remarks"
                    style={{ width: "100%" }}
                    value={cashRemarks}
                    onChange={(e) => {
                      setCashRemarks(`${e.target.value}`);
                    }}
                  />
                </Col>
                <Col style={{ width: "48%" }}>
                  <label style={labelStyles}>Enter Email </label>
                  <br />
                  <Input
                    size="medium"
                    type="email"
                    placeholder="Enter Email"
                    style={{ width: "100%" }}
                    value={email}
                    onChange={(e) => {
                      setEmail(`${e.target.value}`);
                    }}
                  />
                </Col>
              </Row>
            </Row>
          </div>
          <div
            style={{
              maxWidth: "1300px",
              width: "100%",
              background: "#FFFCDC",
              padding: "10px 50px",
            }}
          >
            <Row
              style={{
                height: "50px",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "500",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  textAlign: "left",
                  margin: "0",
                }}
              >
                Total Amount :
              </p>
              <p
                style={{
                  fontSize: "24px",
                  margin: "0",
                  textAlign: "right",
                }}
              >
                Rs. {Number(amount ?? 0)}.00
              </p>
            </Row>
          </div>
          <br />
          <br />
          <div
            style={{
              maxWidth: "1300px",
              width: "100%",
            }}
          >
            <Row>
              <Col
                style={{
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    background: "#E11513",
                    borderColor: "#E11513",
                    height: "42px",
                    borderRadius: "3px",
                  }}
                  type="primary"
                  onClick={() => {
                    if (selectedDonationId === "") {
                      notification.error({
                        message: "Please select a donation",
                      });
                      return;
                    } else {
                      const donation = sevaList.find(
                        (e) => e?.product?.id === selectedDonationId
                      );
                      const data = {
                        "Donation Name": donation?.product?.name,
                        "Donation Amount": amount,
                        "Devotee Name": devoteeName,
                        "On Behalf Of": onBehalfOf,
                        Gothram: gothram,
                        Occasion: occasion,
                        Phone: phone,
                        Address: address,
                        City: city,
                        State: state,
                        Country: country,
                        "ID Proof Number": idProofNumber ?? "-",
                        "ID Proof Type": idProofType ?? "-",
                        Email: email ?? "-",
                        "Calender Type": calenderType,
                      };
                      if (calenderType === "telugu") {
                        data.Masam = masam;
                        data.Paksham = paksham;
                        data.Tithi = tithi;
                      } else if (calenderType === "english") {
                        data[`Performace Date`] =
                          moment(date).format("DD-MM-YYYY");
                      }
                      setPreviewData(data);
                      setPreviewFlag(true);
                    }
                  }}
                  loading={buttonLoading}
                >
                  Preview
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      )}
      <TmsPosDonationPreviewModal
        setPreviewFlag={setPreviewFlag}
        previewFlag={previewFlag}
        buttonLoading={buttonLoading}
        handleSubmit={handleSubmit}
        previewData={previewData}
      />
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type="donation"
          from="Counter"
          setData={setArray}
          data={array}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setButtonLoading}
          setPrintTicketModal={setIsModalVisible}
          printType={printType}
          bondData={bondData}
          setBondData={setBondData}
        />
      </Modal>
    </div>
  );
};
export default withRouter(TmsPosDonation);
