import {
  Button,
  Col,
  Input,
  notification,
  Modal,
  Row,
  Space,
  Typography,
  Select,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";

import TmsRenderDetailsRenderer from "../../../components/tms-ticket-details-renderer/tms-ticket-details-renderer";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsCartService from "../../../services/tms-cart.service";
import { BaseContext } from "../../../contexts/BaseContext";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import postBookingDataFunction from "../../../utils/tms-post-booking-data/tms-post-booking-data";

const { Option } = Select;
const TmsPosTicketDetails = ({ router, from, bookingUpdate }) => {
  const inputEl = useRef();
  const [printType, setPrintType] = useState("a4");
  const { userDetails } = useContext(BaseContext);
  const [ticketId, setTicketId] = useState("");
  const [data, setData] = useState();
  // const [array, setArray] = useState();
  const [postBookingData, setPostBookingData] = useState();

  const setInputFocus = () => {
    inputEl?.current?.input?.focus();
  };
  const [externalPrint, setExternalPrint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState("");

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  const submit = async () => {
    setData(undefined);
    setType("");
    setPostBookingData(undefined);

    if (!ticketId) {
      notification.error({ message: "Enter Ticket Number!" });
    }
    try {
      const data = await TmsCartService.getAdminTicketDetails(ticketId);
      if (data) {
        setData(data);
        setTicketId("");
      }
      setInputFocus();
    } catch (error) {
      catchErrorFunction(error, "Token expired!! please login again.", router);
      setPostBookingData("");
    }
  };

  useEffect(() => {
    setInputFocus();
  }, []);

  const handlePrint = () => {
    setPostBookingData(postBookingDataFunction(data));
    setIsModalVisible(true);
  };
  return (
    <>
      <Row justify="center">
        <Typography.Title>
          {" "}
          {from !== "accoScan" ? "Ticket Details" : "Accommodation Scan"}{" "}
        </Typography.Title>
      </Row>
      <Row justify="center">
        <Space size="middle">
          <Col> Ticket No: </Col>
          <Col>
            <Input
              ref={inputEl}
              value={ticketId}
              onChange={(e) => {
                setTicketId(e.target.value.trim());
              }}
              onKeyDown={onKeyDown}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={submit}>
              GO!
            </Button>
          </Col>
        </Space>
      </Row>
      <Row justify="center">
        <Col
          style={{
            marginTop: "20px",
            width: from === "accoScan" ? "100%" : "60%",
          }}
        >
          {data && (
            <TmsRenderDetailsRenderer
              data={data}
              bookingUpdate={bookingUpdate}
              from={from}
            />
          )}
        </Col>
      </Row>
      {userDetails?.permissions.includes("SUPER_ADMIN") ||
      userDetails?.permissions.includes("ADMIN") ? (
        <>
          {data && from !== "accoScan" && (
            <Row justify="center">
              <Col
                style={{
                  marginTop: "10px",
                  marginRight: "10px",
                  width: "20%",
                  justifyContent: "start",
                }}
              >
                <Select
                  size="medium"
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Select Print Type"
                  value={printType}
                  onChange={(e) => {
                    setPrintType(e);
                  }}
                >
                  <Option key={"thermal"} value={"thermal"}>
                    Thermal Print
                  </Option>
                  <Option key={"a4"} value={"a4"}>
                    A4 Print
                  </Option>
                </Select>
              </Col>
              <Button
                loading={loading}
                style={{
                  color: "white",
                  backgroundColor: "red",
                  borderRadius: "4px",
                  marginTop: "10px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handlePrint();
                }}
              >
                Print
              </Button>
              <Modal
                visible={isModalVisible}
                width={850}
                footer={null}
                closable={false}
                centered
                maskClosable={false}
              >
                <TmsPrintTicket
                  type={postBookingData?.type}
                  from="Counter"
                  data={postBookingData?.array}
                  setData={setPostBookingData}
                  externalPrint={postBookingData?.externalPrint}
                  setExternalPrint={setExternalPrint}
                  setButtonLoading={setLoading}
                  setPrintTicketModal={setIsModalVisible}
                  printType={printType}
                  duplicate={true}
                />
              </Modal>
            </Row>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default withRouter(TmsPosTicketDetails);
