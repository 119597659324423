import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";

const TmsTollGateService = {
  getTollGate: async (page, limit) => {
    const resp = await Axios.get(
      `v1/tollgate/all?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createTollGate: async (body) => {
    const resp = await Axios.post(`v1/tollgate/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  deleteTollGate: async (productId) => {
    const resp = await Axios.delete(`v1/tollgate/delete/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  viewTollGate: async (productId) => {
    const resp = await Axios.get(`v1/tollgate/by-id/${productId}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editTollGate: async (productId, body) => {
    const resp = await Axios.put(`v1/tollgate/update/${productId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  //tollgate-counters
  getTollGateCounters: async (id) => {
    const resp = await Axios.get(`v1/tollgate-counter/by-product/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  createTollGateCounters: async (body) => {
    const resp = await Axios.post(`v1/tollgate-counter/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editTollGateCounters: async (body, slotId) => {
    const resp = await Axios.put(`v1/tollgate-counter/update/${slotId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  deleteTollGateCounters: async (slotId) => {
    const resp = await Axios.delete(`v1/tollgate-counter/delete/${slotId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  updateTerms: async (productId, termsData, ticketType) => {
    const resp = await Axios.patch(
      `v1/tollgate/metadata/${productId}`,
      {
        termsAndConditions: termsData,
        ticketType: ticketType ? "mini_ticket" : "full_ticket",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //TollGate booking

  fetchTollGateVehicleType: async () => {
    const resp = await Axios.get(
      `v1/tollgate-counter-booking/valid-counter-products`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  tollgateBooking: async (body, payment, cashReference, cashRemarks) => {
    const resp = await Axios.post(
      `v1/counter-cart/new`,
      {
        tollgateBookings: [body],
        paymentType: payment,
        cashRemarks: cashReference,
        cashReference: cashRemarks,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  tollgateBookingDetails: async (
    format,
    page,
    limit,
    fromDate,
    toDate,
    dateType,
    sortType,
    selectedTollGateId
  ) => {
    let url = `v1/tollgate-counter-booking/reports/${format}?page=${page}&limit=${limit}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${sortType}`;
    if (selectedTollGateId && selectedTollGateId !== "") {
      url += `&productId=${Number(selectedTollGateId)}`;
    }
    const resp = await ReportsAxios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getConsolidateReport: async (format, productId, from, to, counter, shift) => {
    let query = `v1/tollgate-counter-report/consolidated-ticket-collection-for-product/${format}?bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }

    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
  getOnlineConsolidateReport: async (format, productId, from, to) => {
    let query = `v1/tollgate-online-report/consolidated-ticket-collection-for-product/${format}?bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }

    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },

  tollGateReport: async (
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    counter,
    shift,
    tollgateType
  ) => {
    let query = `v1/tollgate-counter-report/booking/${format}?page=${tableCurrentPage}&limit=${tablePageSize}&bookingFromDate=${fromDate}&bookingToDate=${toDate}`;

    if (counter && counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }

    if (tollgateType && tollgateType !== "") {
      query += `&productId=${tollgateType}`;
    }
    const resp = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
};

export default TmsTollGateService;
