import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import catchErrorFunction from "../../../../../../utils/tms-catch-error/tms-catch-error";
import { Col, Drawer } from "antd";
import "../../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
import TmsAccManageRoomCategoryActions from "../../../../../../components/tms-service-management/tms-sm-accommodation/TmsAccManageRoomCategoryActions";
import TmsAccommodationService from "../../../../../../services/tms-accommodation.service";
import TmsAccInnerPagesManageBlocksAddNew from "./tms-mrc-add-new";
import { FormatNumberINRCurrency } from "../../../../../../utils/tms-currency-formatter/tms-curreny-formatter";

const TmsAccInnerPagesManageBlocksAll = ({ active, router }) => {
  const [updateFlag, setUpdateFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accommodationData, setAccommodationData] = useState([]);
  const [tmsModalVisible, settmsModalVisible] = useState(false);
  const [viewAccommodationData, setViewAccommodationData] = useState();
  const [editFlag, setEditFlag] = useState();
  const [roomCategorytId, setRoomCategorytId] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);

  const tableColums = [
    ["Room Type", viewAccommodationData?.roomType],
    ["No Of Persons", viewAccommodationData?.noOfPersons],
    ["Available Rooms POS", viewAccommodationData?.counterAvailableRooms],
    ["Available Rooms Online", viewAccommodationData?.onlineAvailableRooms],
    ["Deposit Amount", viewAccommodationData?.depositAmount],
    ["Gst Amount", viewAccommodationData?.gstAmount],
    ["Price", viewAccommodationData?.price],
  ];

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const getAccommadation = async () => {
    setLoading(true);
    try {
      setAccommodationData([]);
      const data = await TmsAccommodationService.getAllBlockCategorys(
        new URLSearchParams(router?.location.search).get("id"),
        tableCurrentPage,
        tablePageSize
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setAccommodationData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "Room Type",
      dataIndex: "roomType",
      key: "roomType",
      //   ...getColumnSearchProps("darshanam_name"),
    },

    {
      title: "Available Rooms",
      children: [
        {
          title: "Online",
          dataIndex: "onlineAvailableRooms",
          key: "onlineAvailableRooms",
        },
        {
          title: "Offline",
          dataIndex: "counterAvailableRooms",
          key: "counterAvailableRooms",
        },
      ],
    },
    {
      title: "Deposit Amount",
      dataIndex: "depositAmount",
      key: "depositAmount",
      sorter: (a, b) => a.depositAmount > b.depositAmount,
      render: (text) => {
        return `Rs. ${text}`;
      },
    },
    {
      title: "GST Amount",
      dataIndex: "gstAmount",
      key: "gstAmount",
      sorter: (a, b) => a.gstAmount > b.gstAmount,
      render: FormatNumberINRCurrency,
    },
    {
      title: "Maintanence Fee",
      dataIndex: "maintenanceCharge",
      key: "maintenanceCharge",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price > b.price,
      render: (text) => {
        return `Rs. ${text}`;
      },
    },
    {
      title: "Persons",
      dataIndex: "noOfPersons",
      key: "noOfPersons",
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <TmsAccManageRoomCategoryActions
            record={record}
            setLoading={setLoading}
            setViewAccommodationData={setViewAccommodationData}
            settmsModalVisible={settmsModalVisible}
            setUpdateFlag={setUpdateFlag}
            setEditFlag={setEditFlag}
            setRoomCategorytId={setRoomCategorytId}
            blockID={new URLSearchParams(router?.location.search).get("id")}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (
      router.location?.pathname.includes(
        "service-management/accommodation/manage-room-categories"
      ) &&
      active === "Manage Room Category"
    ) {
      getAccommadation();
      if (!editFlag) {
        settmsModalVisible(false);
      }
    }
  }, [
    active,
    updateFlag,
    editFlag,
    tableCurrentPage,
    tablePageSize,
    router.location?.pathname,
  ]);

  return (
    <>
      {" "}
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={accommodationData}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      />
      <Drawer
        className="DetailsModal"
        closable={true}
        width={editFlag ? "800px" : "600px"}
        visible={tmsModalVisible}
        onClose={() => {
          settmsModalVisible(false);
          setEditFlag(false);
          setViewAccommodationData(null);
        }}
        title={editFlag ? "Edit Room Category" : "Room Category Details"}
      >
        {editFlag ? (
          <TmsAccInnerPagesManageBlocksAddNew
            viewAccommodationData={viewAccommodationData}
            editFlag={editFlag}
            roomCategorytId={roomCategorytId}
            setEditFlag={setEditFlag}
            settmsModalVisible={settmsModalVisible}
          />
        ) : (
          <div className="viewDetails">
            <Col style={{ width: "100%" }}>
              <table className="DetailsTable">
                {tableColums.map((item) => {
                  return (
                    <tr key={"table" + item[0]}>
                      <th>{item[0]}</th>
                      <td>{item[1]}</td>
                    </tr>
                  );
                })}
              </table>
            </Col>
            <br />
            <Col className="ImgCol">
              <img src={viewAccommodationData?.imageUrl} alt="Image" />
            </Col>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default withRouter(TmsAccInnerPagesManageBlocksAll);
