import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import { Button, Col, Drawer, Switch } from "antd";
import TmsDonationService from "../../../../../services/tms-donation.service";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
import TmsDonationinnerActions from "../../../../../components/tms-service-management/tms-sm-donation/tms-sm-donation-inner-actions";
import TmsSmDonationInnerPagesAnd from "./tms-sm-donation-inner-pages-and";
import TextArea from "antd/lib/input/TextArea";

const TmsDonationInnerPagesAll = ({ router }) => {
  const [updateFlag, setUpdateFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  // const { userDetails } = useContext(BaseContext);
  const [donationData, setDonationData] = useState([]);
  const [tmsModalVisible, settmsModalVisible] = useState(false);
  const [viewDonationData, setViewDonationData] = useState();
  const [editFlag, setEditFlag] = useState();
  const [productId, setProductId] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [termsData, setTermsData] = useState([]);

  const tableColums = [
    ["Donation Type", viewDonationData?.donationType],

    ["Name", viewDonationData?.name],
    ["Description", viewDonationData?.description],
    [
      "Amount (min-max)",
      `Rs.${viewDonationData?.price} - Rs.${viewDonationData?.maxAmount}`,
    ],
    ["POS-(Status)", viewDonationData?.counterEnabled ? "enabled" : "disabled"],
    [
      "Online-(Status)",
      viewDonationData?.onlineEnabled ? "enabled" : "disabled",
    ],
    ["Price", viewDonationData?.price],
  ];

  const switchTrigger = async (key, status, from) => {
    setLoading(true);
    try {
      const darData = await TmsDonationService.viewDonation(key);
      let body = {
        name: darData?.name,
        description: darData?.description,
        counterEnabled: from === "counter" ? status : darData?.counterEnabled,
        onlineEnabled: from === "online" ? status : darData?.onlineEnabled,
        price: darData?.price,
        imageUrl: darData?.imageUrl,
        donationType: darData.donationType,
        maxAmount: darData.maxAmount,
      };
      await TmsDonationService.editDonation(key, body);
      setUpdateFlag(Math.random() + 1);
      // console.log(updateFlag);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    router.location?.pathname === "/service-management/donation" &&
      getDonations();
    if (!editFlag) {
      settmsModalVisible(false);
    }
  }, [
    updateFlag,
    editFlag,
    tableCurrentPage,
    tablePageSize,
    router.location?.pathname,
  ]);

  const getDonations = async () => {
    setLoading(true);
    try {
      let data = [];
      setDonationData([]);

      data = await TmsDonationService.getDonations(
        tableCurrentPage,
        tablePageSize
      );

      setTabelTotalSize(data?.meta?.totalItems);
      setDonationData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Donation Name",
      dataIndex: "name",
      key: "name",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Donation Type",
      dataIndex: "donationType",
      key: "donationType",
    },
    // {
    //   title: "Target Date",
    //   dataIndex: "targetDate",
    //   key: "targetDate",
    //   render: (data) => {
    //     return moment(data, "YYYY-MM-DD").format("DD-MM-YYYY");
    //   },
    // },

    {
      title: "Minimum amount",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price > b.price,
      render: (text) => {
        return `Rs. ${text}`;
      },
    },
    {
      title: "Max amount",
      dataIndex: "maxAmount",
      key: "maxAmount",
      sorter: (a, b) => a.maxAmount > b.maxAmount,
      render: (text) => {
        return `Rs. ${text ?? "-"}`;
      },
    },
    {
      title: "POS-(Status)",

      dataIndex: "counterEnabled",
      key: "counterEnabled",
      render: (text, record) => {
        return (
          <Switch
            size={"small"}
            checked={text}
            style={{ background: text ? "#4CBB17" : "EFEFEF" }}
            onClick={() => {
              switchTrigger(record?.key, !text, "counter");
            }}
          />
        );
      },
    },
    {
      title: "Online-(Status)",

      dataIndex: "onlineEnabled",
      key: "onlineEnabled",
      render: (text, record) => {
        return (
          <Switch
            size={"small"}
            checked={text}
            style={{
              background: text ? "#4CBB17 " : "EFEFEF ",
            }}
            onClick={() => {
              switchTrigger(record?.key, !text, "online");
            }}
          />
        );
      },
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <TmsDonationinnerActions
            record={record}
            setLoading={setLoading}
            setViewDonationData={setViewDonationData}
            settmsModalVisible={settmsModalVisible}
            setUpdateFlag={setUpdateFlag}
            setEditFlag={setEditFlag}
            setProductId={setProductId}
            setShowDrawer={setShowDrawer}
            setTermsData={setTermsData}
          />
        );
      },
    },
  ];

  return (
    <>
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={donationData}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: 10,
        }}
      />
      <Drawer
        className="DetailsModal"
        width={editFlag ? "800px" : "600px"}
        visible={tmsModalVisible}
        onClose={() => {
          settmsModalVisible(false);
          setEditFlag(false);
          setViewDonationData(null);
        }}
        title={editFlag ? "Edit Donation" : "Donation Details"}
      >
        {editFlag ? (
          <TmsSmDonationInnerPagesAnd
            viewDonationData={viewDonationData}
            editFlag={editFlag}
            productId={productId}
            setEditFlag={setEditFlag}
            settmsModalVisible={settmsModalVisible}
          />
        ) : (
          <div className="viewDetails">
            <Col style={{ width: "100%" }}>
              <table className="DetailsTable">
                {tableColums.map((item) => {
                  return (
                    <tr key={"table" + item[0]}>
                      <th>{item[0]}</th>
                      <td>{item[1]}</td>
                    </tr>
                  );
                })}
              </table>
            </Col>
            <br />
            <Col className="ImgCol">
              <img src={viewDonationData?.imageUrl} alt="Image" />
            </Col>
          </div>
        )}
      </Drawer>
      <Drawer
        className="TermsDrawer"
        visible={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setTermsData([]);
        }}
        title="Terms & Conditions"
        extra={
          <Button
            type="primary"
            onClick={async () => {
              setLoading(true);
              try {
                await TmsDonationService.updateTerms(
                  productId,
                  termsData?.toString()?.replaceAll(",", "\n")?.split("\n")
                );
                setUpdateFlag(Math.random() + 1);
                setShowDrawer(false);
                setTermsData([]);
              } catch (error) {
                catchErrorFunction(
                  error,
                  "Token Expired, redirecting to login page in 5 seconds",
                  router
                );
              }
              setLoading(false);
            }}
          >
            Submit
          </Button>
        }
      >
        <TextArea
          rows={4}
          value={termsData?.toString()?.replaceAll(",", "\n")}
          onChange={(e) => {
            setTermsData(e.target.value);
          }}
        />
      </Drawer>
    </>
  );
};

export default withRouter(TmsDonationInnerPagesAll);
