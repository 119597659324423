import Axios from "../utils/tms-axios/tms-axios";
const TmsCounterService = {
  newCounter: async ({ name, location, phone, email, enabled }) => {
    const body = {
      name,
      location,
      phone,
      email,
      enabled,
    };

    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });

    if (!enabled) {
      body.enabled = false;
    }

    const resp = await Axios.post("v1/counter/create", body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });

    return resp.data;
  },

  editCounter: async ({ id, name, location, phone, email, enabled }) => {
    const body = {
      name,
      location,
      phone,
      email,
      enabled,
    };

    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });

    if (!enabled) {
      body.enabled = false;
    }

    const resp = await Axios.post(`v1/counter/update/${id}`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });

    return resp.data;
  },

  deleteCounter: async (id) => {
    const resp = await Axios.delete(`v1/counter/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  getAllCounter: async () => {
    const resp = await Axios.get(`v1/counter/all?`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  getCounterById: async (id) => {
    const resp = await Axios.get(`v1/counter?counterId=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data?.[0];
  },

  reOpenCurrentShift: async (counterId) => {
    const resp = await Axios.post(
      `v1/counter-shift-login/admin/reopen-current-shift?counterId=${Number(
        counterId
      )}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  closeCurrentShift: async (counterId) => {
    const resp = await Axios.post(
      `v1/counter-shift-login/admin/close-current-shift?counterId=${Number(
        counterId
      )}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //------------------Couner Pre Shifts ---------------//
  getCounterShiftsForReports: async (id) => {
    const resp = await Axios.get(`v1/counter/c-shift/status?counterId=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  getCounterPreShifts: async (id) => {
    const resp = await Axios.get(`v1/counter-pre-assign-shift/all/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  addCounterPreShift: async (data) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      "X-Temple-Id": localStorage.getItem("templeId"),
    };
    const resp = await Axios.post(`v1/counter-pre-assign-shift/create`, data, {
      headers: headers,
    });

    return resp.data;
  },

  deleteCounterPreShiftById: async (id) => {
    const resp = await Axios.delete(
      `v1/counter-pre-assign-shift/delete/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  editCounterPreShiftById: async (body, id) => {
    const resp = await Axios.post(
      `v1/counter-pre-assign-shift/update/${id}`,
      body,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );

    return resp.data;
  },

  //------------------Couner Pre Shifts ---------------//

  editCounterShift: async (id, counterID, shiftID, userID) => {
    const resp = await Axios.post(
      `v1/counter/edit/c-shift/${id}?counterId=${counterID}&shiftId=${shiftID}&assignUserId=${userID}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );

    return resp.data;
  },

  //------------------Counter Shifts----------------//
  addCounterShift: async (counter, shift, userID) => {
    const resp = await Axios.post(
      `v1/counter/add/c-shift?counterId=${counter}&shiftId=${shift}&assignUserId=${userID}`,

      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );

    return resp.data;
  },

  getCounterRoles: async (id) => {
    const resp = await Axios.get(`v1/counter/role?counterId=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  addRoleToCounter: async (id, role) => {
    const resp = await Axios.post(
      `v1/counter/add/role?counterId=${id}&role=${role}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  deleteRoleFromCounter: async (id, role) => {
    const resp = await Axios.delete(
      `v1/counter/remove/role?counterId=${id}&role=${role}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  getCounterByblockId: async (id) => {
    const resp = await Axios.get(`v1/block-counter/counter/byBlockId/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  deleteCounterbyBlockId: async (blockID, counterId) => {
    const resp = await Axios.delete(
      `v1/block-counter/byCounterId/BlockId?blockId=${blockID}&counterId=${counterId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  addCounterbyBlockId: async (blockID, counterID) => {
    const resp = await Axios.post(
      `v1/block-counter?blockId=${blockID}&counterId=${counterID}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );

    return resp.data;
  },

  getCoupons: async (page) => {
    const resp = await Axios.get(`/coupon/all?page=${page}&limit=10`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  addCoupon: async (body) => {
    const resp = await Axios.post(`/coupon/new`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });

    return resp.data;
  },
  editCoupon: async (body) => {
    const resp = await Axios.put(`/coupon/edit`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });

    return resp.data;
  },
  deleteCouponById: async (id) => {
    const resp = await Axios.delete(`coupon/byId/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  deleteCouponByCode: async (code) => {
    const resp = await Axios.delete(`coupon/byCouponCode/${code}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  verifyCoupon: async (code, blockCategoryId, priceOfRoom, usedBy) => {
    const resp = await Axios.get(
      `v1/coupon/validate-room?couponCode=${code}&type=${usedBy}&blockCategoryId=${blockCategoryId}&priceOfRoom=${priceOfRoom}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  addShift: async (body) => {
    const resp = await Axios.post(`v1/counter/shift/new`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });

    return resp.data;
  },
  deleteShiftById: async (id) => {
    const resp = await Axios.delete(`v1/counter/shift/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
};
export default TmsCounterService;
