import { Button, Input, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import TmsTempleService from "../../services/tms-temple.service";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import TmsImageUpload from "../tms-customs/tms-image-upload";
import TmsModal from "../tms-customs/tms-modal";
import TmsRowVertical from "../tms-customs/tms-row-vertical";
const { Option } = Select;

const TmsTMreateUserModal = ({
  showCreateTempleModal,
  setShowCreateTempleModal,
  setUpdateFlag,
  editFlag,
  setEditFlag,
  editData,
  setEditData,
  router,
}) => {
  const [shortName, setShortName] = useState("");
  const [name, setName] = useState("");
  const [colorCode, setColorCode] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [displayName, setDisplayName] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    // console.log(editFlag);
    if (editFlag) {
      setDetails(
        editData.shortName,
        editData.description,
        editData.logo,
        editData.colorCode,
        editData.name,
        editData.displayName,
        editData?.templeCategory
      );
    }
  }, [editFlag]);

  const handleCreateTemple = async () => {
    setLoading(true);
    try {
      if (editFlag) {
        let id = editData.id;
        await TmsTempleService.modifyTempleDetails(
          shortName,
          name,
          colorCode,
          description,
          fileList[0].url,
          id,
          displayName,
          category
        );
        const data = await TmsTempleService.getTempleDetails(id);
        if (localStorage.getItem("templeId") === id) {
          localStorage.setItem("templeDetails", JSON.stringify(data));
          window.location.reload();
        }
      } else {
        await TmsTempleService.createTemple(
          shortName,
          name,
          colorCode,
          description,
          fileList[0].url,
          displayName,
          category
        );
      }
      setLoading(false);
      setDetails("", "", "", "", "", "", "");
      notification.success({
        message: `Temple ${editFlag ? "Modified" : "Created"}  Successfully`,
      });
      setShowCreateTempleModal(false);
      setUpdateFlag(Math.random() * 100 + 7);
      // setUpdateTable(Math.random());
    } catch (error) {
      setLoading(false);
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
  };
  const setDetails = (
    shortName,
    desc,
    logoUrl,
    colorCode,
    name,
    display,
    category
  ) => {
    setShortName(shortName);
    if (logoUrl === "") {
      setFileList([]);
    } else {
      setFileList([{ url: logoUrl, status: "done" }]);
    }
    setDescription(desc);
    setColorCode(colorCode);
    setName(name);
    setCategory(category);
    setDisplayName(display);
  };
  const onClose = () => {
    setShowCreateTempleModal(false);
    setEditData(null);
    setEditFlag(false);
  };
  return (
    <TmsModal
      width="40%"
      visible={showCreateTempleModal}
      title={
        editFlag ? `Edit Temple Details - ${editData?.name}` : "Create Temple"
      }
      onOk={handleCreateTemple}
      onCancel={() => {
        setDetails("", "", "", "", "", "", "");
        onClose();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setDetails("", "", "", "", "", "", "");
            onClose();
          }}
        >
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            handleCreateTemple();
          }}
        >
          Submit
        </Button>,
      ]}
    >
      <TmsRowVertical
        data={{
          one: "Short Name",
          two: (
            <Input
              placeholder="Short Name"
              style={{ width: "250px" }}
              value={shortName}
              onChange={(e) => setShortName(e.target.value)}
            />
          ),
          justify: "center",
          style: { width: "100%", padding: "10px 0px" },
        }}
      />

      <TmsRowVertical
        data={{
          one: "Name",
          two: (
            <Input
              placeholder="name"
              style={{ width: "250px" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          ),
          justify: "center",
          style: { width: "100%", padding: "10px 0px" },
        }}
      />
      <TmsRowVertical
        data={{
          one: "Color Code ",
          two: (
            <Input
              placeholder="Color Code"
              style={{ width: "250px" }}
              value={colorCode}
              onChange={(e) => setColorCode(e.target.value)}
            />
          ),
          justify: "center",
          style: { width: "100%", padding: "10px 0px" },
        }}
      />
      <TmsRowVertical
        data={{
          one: "Description ",
          two: (
            <Input
              placeholder="Description "
              style={{ width: "250px" }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          ),
          justify: "center",
          style: { width: "100%", padding: "10px 0px" },
        }}
      />
      <TmsRowVertical
        data={{
          one: "Temple Logo",
          two: <TmsImageUpload fileList={fileList} setFileList={setFileList} />,
          justify: "center",
          style: { width: "100%", padding: "10px 0px" },
        }}
      />
      <TmsRowVertical
        data={{
          one: "Display Name ",
          two: (
            <Input
              placeholder="Display Name "
              style={{ width: "250px" }}
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          ),
          justify: "center",
          style: { width: "100%", padding: "10px 0px" },
        }}
      />
      <TmsRowVertical
        data={{
          one: "Category",
          two: (
            <Select
              value={category}
              onChange={(e) => {
                setCategory(e);
              }}
              style={{ width: "250px" }}
            >
              <Option value="6A" key="6A">
                6A
              </Option>
              <Option value="6B" key="6B">
                6B
              </Option>
              <Option value="6C" key="6C">
                6C
              </Option>
            </Select>
          ),
          justify: "center",
          style: { width: "100%", padding: "10px 0px" },
        }}
      />
    </TmsModal>
  );
};
export default withRouter(TmsTMreateUserModal);
