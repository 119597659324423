import { Col, notification, Row } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import TmsDarshanamService from "../../../../services/tms-darshanam/tms-darshanam.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsCounterService from "../../../../services/tms-counter.service";
import TmsShiftService from "../../../../services/tms-shift.service";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";

const TmsReportDarshanamReport = ({ router }) => {
  const [loading, setLoading] = useState();
  const [excelLoading, setExcelLoading] = useState(false);

  const [bookingDetails, setBookingDetails] = useState([]);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [dateType, setDateType] = useState("booking");

  const [sortType, setSortType] = useState("ASC");
  const [bookingType, setBookingType] = useState("pos");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [darshanamType, setDarshanamType] = useState("");
  const [darshanamList, setDarshanamList] = useState([]);

  const [counter, setCounter] = useState("");
  const [counterList, setCounterList] = useState([]);

  const [shift, setShift] = useState("");
  const [shiftList, setShiftList] = useState([]);

  const [slot, setSLot] = useState("");
  const [slotList, setSlotList] = useState([]);

  const API = {
    online: TmsDarshanamService.darshanamReportOnline,
    pos: TmsDarshanamService.darshanamReport,
  };
  const fetchBookingDetails = async () => {
    setLoading(true);
    try {
      const data = await API[bookingType](
        "json",
        Number(tableCurrentPage),
        Number(tablePageSize),
        bookingFromDate,
        bookingToDate,
        dateType,
        sortType,
        counter,
        shift,
        slot,
        darshanamType
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setBookingDetails(
        data?.items?.map((item) => {
          return { key: item?.bookingId, ...item };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdDate") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  const fetchDarshanamList = async () => {
    try {
      let res = await TmsDarshanamService.getDarshanam(1, 100);
      setDarshanamList(res.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchCounterList = async () => {
    try {
      let res = await TmsCounterService.getAllCounter();
      setCounterList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchShiftList = async () => {
    try {
      let res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchDarshanamSlotList = async (id) => {
    try {
      let res = await TmsDarshanamService.getDarshanamSlots(id);
      setSlotList(res);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      let data = await API[bookingType](
        "json",
        Number(tableCurrentPage),
        Number(50000),
        bookingFromDate,
        bookingToDate,
        dateType,
        sortType,
        counter,
        shift,
        slot,
        darshanamType
      );

      data = data?.items?.reverse();
      let compiledDataOffile = [];
      let compiledDataOnline = [];
      if (bookingType === "online") {
        compiledDataOnline = data.map((item, i) => {
          return {
            "S.no": i + 1,
            "Darshanam Type ": item.darshanamName,
            "Booked on": moment(item.createdDate).format("DD-MM-YYYY HH:mm A"),
            "Booking phone": item.bookingPhoneNumber,
            "No of persons": item.noOfTickets,
            price: item.productPrice,
            "Total Amount": item?.price,
            Slot: `${moment(item.slotStartTime, "HH:mm:ss").format(
              "hh:mm A"
            )} - 
            ${moment(item.slotEndTime, "HH:mm:ss").format("hh:mm A")}`,
            "Performance Date": item.bookingDate
              ? moment(item.bookingDate).format("DD-MM-YYYY")
              : "-",
            "razorpay ID": item?.razorPayOrderId,
            "Devotee Details": item.personDetails
              .map(
                (items, j) => `${j + 1}. ${items?.personName} -
            ${moment(items?.dateOfBirth).format("DD-MM-YYYY")} ${
                  items?.gothram ?? ""
                } - ${items?.documentType}`
              )
              ?.join(","),
          };
        });
      } else {
        compiledDataOffile = data.map((item, i) => {
          return {
            "S.no": i + 1,
            "Darshanam Type ": item.darshanamName,
            "POS Name": item.counterName,
            "Shift ID": item.counterShiftTimingId,
            "Booking Id": item.bookingId,
            "Transaction Id": item?.categoryTransactionSequence
              ?.split("-")
              ?.pop(),
            "No of persons": item.noOfTickets,
            Amount: item.price,
            Slot: `${moment(item.slotStartTime, "HH:mm:ss").format(
              "hh:mm A"
            )} - 
          ${moment(item.slotEndTime, "HH:mm:ss").format("hh:mm A")}`,
            "Booked On": moment(item?.createdDate).format("DD-MM-YYYY HH:mm A"),

            "Performance Date": item.bookingDate
              ? moment(item.bookingDate).format("DD-MM-YYYY")
              : "-",
          };
        });
      }

      exportExcel(
        bookingType === "online" ? compiledDataOnline : compiledDataOffile,
        `darshanam-report-${bookingType}-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };

  useEffect(() => {
    fetchDarshanamList();
    fetchCounterList();
    fetchShiftList();
  }, []);

  useEffect(() => {
    fetchBookingDetails();
  }, [
    bookingFromDate,
    bookingToDate,
    dateType,
    sortType,
    tableCurrentPage,
    tablePageSize,
    slot,
    counter,
    shift,
    darshanamType,
    bookingType,
  ]);

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "POS Name",
      dataIndex: "counterName",
      key: "counterName",
    },
    {
      title: "Shift Id",
      dataIndex: "counterShiftTimingId",
      key: "counterShiftTimingId",
    },
    {
      title: "Booking Id",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Txn Id",
      dataIndex: "categoryTransactionSequence",
      render: (text) => text?.split("-")?.pop(),
    },
    {
      title: "S no",
      dataIndex: "dailyCategoryTransactionSequence",
      render: (text) => text?.split("-")?.pop(),
    },
    {
      title: "Darshanam Type",
      dataIndex: "darshanamName",
      key: "darshanamName",
    },
    {
      title: "No of Persons",
      dataIndex: "noOfTickets",
      key: "noOfTickets",
    },
    {
      title: "Amount",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return `Rs. ${text}`;
      },
      sorter: (a, b) => a.price > b.price,
    },

    {
      title: "Slot",
      dataIndex: "slotStartTime",
      key: "slotStartTime",
      render: (text, record) => {
        return `${moment(record.slotStartTime, "HH:mm:ss").format("hh:mm A")} - 
            ${moment(record.slotEndTime, "HH:mm:ss").format("hh:mm A")}`;
      },
    },
    {
      title: "Performance Date",
      dataIndex: "bookingDate",
      render: (record) => {
        return moment(record).format("DD-MM-YYYY");
      },
      sorter: (a, b) => a.bookingDate > b.bookingDate,
    },
    {
      title: "Booked On",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdDate > b.createdDate,
    },
  ];
  const onlineColumns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Darshanam Type",
      dataIndex: "darshanamName",
      key: "darshanamName",
    },
    {
      title: "Devotee Details",
      dataIndex: "personDetails",
      render: (record) => {
        return record
          ?.map((item) => {
            return item?.personName;
          })
          ?.join(",");
      },
      width: "120px",
    },
    {
      title: "No of Persons",
      dataIndex: "noOfTickets",
      key: "noOfTickets",
    },
    {
      title: "Price",
      dataIndex: "productPrice",
      key: "productPrice",
      render: (text) => {
        return `Rs. ${text}`;
      },
      sorter: (a, b) => a.price > b.price,
    },
    {
      title: "Total",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return `Rs. ${text}`;
      },
      // sorter: (a, b) => a.price > b.price,
    },

    {
      title: "Slot",
      dataIndex: "slotStartTime",
      key: "slotStartTime",
      render: (text, record) => {
        return `${moment(record.slotStartTime, "HH:mm:ss").format("hh:mm A")} - 
            ${moment(record.slotEndTime, "HH:mm:ss").format("hh:mm A")}`;
      },
    },
    {
      title: "Performance Date",
      dataIndex: "bookingDate",
      render: (record) => {
        return moment(record).format("DD-MM-YYYY");
      },
      sorter: (a, b) => a.bookingDate > b.bookingDate,
    },
    {
      title: "Booking Phone",
      dataIndex: "bookingPhoneNumber",
      key: "bookingPhoneNumber",
    },
    {
      title: "Booked on",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY HH:mm A");
      },
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdDate > b.createdDate,
    },
  ];

  return (
    <>
      <Row gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "pos" },
            { id: "online", name: "online" },
          ]}
          optional={true}
        />
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Darshanam Type"
          setValue={(e) => {
            setDarshanamType(e);
            if (e === "") {
              setSLot("");
              return;
            }
            fetchDarshanamSlotList(e);
          }}
          value={darshanamType}
          data={darshanamList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="POS"
          setValue={setCounter}
          value={counter}
          data={counterList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Shift"
          setValue={setShift}
          value={shift}
          data={shiftList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Slot"
          setValue={setSLot}
          value={slot}
          data={slotList}
        />

        <Col span={6}>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
        <TmsSelect
          type="col"
          span={3}
          label="Date Type"
          setValue={setDateType}
          value={dateType}
          data={[
            { id: "booking", name: "Performance" },
            { id: "payment", name: "Booking" },
          ]}
          optional={true}
        />
        <TmsExcelButton
          loading={excelLoading}
          handleExcelExport={handleExcelExport}
          top={false}
        />
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={bookingType === "online" ? onlineColumns : columns}
          dataSource={bookingDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>
    </>
  );
};

export default withRouter(TmsReportDarshanamReport);
