import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import { Badge, Button, Col, notification, Popconfirm, Row, Space } from "antd";
import moment from "moment";
import { BaseContext } from "../../../../contexts/BaseContext";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsSCCreateUserModal from "../../../../components/tms-service-management/tms-counter-management/tms-counter-create-modal";
import TmsCounterService from "../../../../services/tms-counter.service";
import tmsRouteMap from "../../../../constants/tms-route.map";
const TmsSmCounterManagement = ({ router }) => {
  const [loading, setLoading] = useState();
  const [showCreateCounterModal, setShowCreateCounterModal] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [editData, setEditData] = useState(null);
  const [updateFlag, setUpdateFlag] = useState();
  const [data, setData] = useState([]);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tablePageSize, setTabelPageSize] = useState(15);

  const { userDetails } = useContext(BaseContext);

  const handleModify = async (record) => {
    setEditData(record);
    setEditFlag(true);
    setShowCreateCounterModal(true);
  };
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);

  useEffect(() => {
    loadCounters();
  }, [updateFlag]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: "POS Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (text) => {
        return (
          text && (
            <div
              style={{
                width: "200px",
                whiteSpace: "initial",
                textTransform: "capitalize",
              }}
            >
              {text}
            </div>
          )
        );
      },
    },
    {
      title: "phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },

    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "enabled",
      key: "enabled",
      render: (text) => {
        return !text ? <Badge color="red" /> : <Badge color="green" />;
      },
    },
    {
      title: "Created By",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            {/* <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await TmsCounterService.deleteCounter(record.key);
                  notification.success({
                    message: "POS Deleted Successfully",
                  });
                } catch (error) {
                  catchErrorFunction(
                    error,
                    "Token Expired, redirecting to login page in 5 seconds",
                    router
                  );
                }
                loadCounters();
                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm> */}

            <a
              onClick={() => {
                handleModify(record);
              }}
            >
              Modify
            </a>
            <a
              onClick={() => {
                router.navigate(
                  `${tmsRouteMap.serviceManagement_counter_management_manage_shifts}?counterID=${record.key}&counterName=${record.name}`
                );
              }}
            >
              Manage Shift
            </a>
            {record.isReOpenable && (
              <Popconfirm
                title={"Are you sure to reopen the shift"}
                onConfirm={async () => {
                  try {
                    if (record.isReOpenable) {
                      await TmsCounterService.reOpenCurrentShift(record.key);
                      notification.success({
                        message: "Shift re-opened successfully",
                      });
                    }
                    setUpdateFlag(Math.random() * 1000 + 7);
                  } catch (error) {
                    catchErrorFunction(
                      error,
                      "Token Expired, redirecting to login page",
                      router
                    );
                  }
                }}
              >
                <a>Reopen Last Shift</a>
              </Popconfirm>
            )}
            {record.isClosable && (
              <Popconfirm
                title={"Are you sure to close the current shift"}
                onConfirm={async () => {
                  try {
                    if (record.isClosable) {
                      await TmsCounterService.closeCurrentShift(record.key);
                      notification.success({
                        message: "Shift closed successfully",
                      });
                    }
                    setUpdateFlag(Math.random() * 1000 + 7);
                  } catch (error) {
                    catchErrorFunction(
                      error,
                      "Token Expired, redirecting to login page",
                      router
                    );
                  }
                }}
              >
                <a>Close Shift</a>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const loadCounters = async () => {
    try {
      setLoading(true);
      const counters = await TmsCounterService.getAllCounter();
      setData(
        counters?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setTabelTotalSize(counters?.meta?.totalItems);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <Row style={{ width: "100%", marginBottom: "15px" }} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowCreateCounterModal(true);
            }}
            disabled={
              userDetails?.permissions?.includes("SUPER_ADMIN") ||
              userDetails?.permissions?.includes("ADMIN")
                ? false
                : true
            }
          >
            Add New POS
          </Button>
        </Col>
      </Row>
      <Row style={{ width: "100%" }}>
        <TmsTable
          dataSource={data}
          loading={loading}
          searchable={["display_name"]}
          columns={columns}
          handleTableChange={handleTableChange}
          expandable={{
            expandedRowRender: (record) => (
              <Row>
                <Col className="mr-10">
                  Created At: {moment(record.createdAt).format("YYYY-MM-DD")}
                </Col>
                <Col className="mr-10">
                  Updated At: {moment(record.updatedAt).format("YYYY-MM-DD")}
                </Col>
              </Row>
            ),
          }}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        />
      </Row>
      <TmsSCCreateUserModal
        showCreateCounterModal={showCreateCounterModal}
        setshowCreateCounterModal={setShowCreateCounterModal}
        setUpdateFlag={setUpdateFlag}
        editFlag={editFlag}
        setEditFlag={setEditFlag}
        editData={editData}
        setEditData={setEditData}
      />
    </div>
  );
};
export default withRouter(TmsSmCounterManagement);
