import tmsRouteMap from "./tms-route.map";
//----------------------constants-------------------------//
const service = {
  name: "Service Management",
  path: tmsRouteMap.serviceManagement,
};
const service_darshanam = {
  name: "Darshanam",
  path: tmsRouteMap.serviceManagement_darshanam,
};
const reports = { name: "Reports", path: tmsRouteMap.reports };
const service_sevas = {
  name: "Sevas",
  path: tmsRouteMap.serviceManagement_sevas,
};
const products = {
  name: "Products",
  path: tmsRouteMap.serviceManagement_products,
};
const donation = {
  name: "Donation",
  path: tmsRouteMap.serviceManagement_donation,
};
const prasadam = {
  name: "Prasadam",
  path: tmsRouteMap.serviceManagement_prasadam,
};
const tollgate = {
  name: "Toll Gate",
  path: tmsRouteMap.serviceManagement_tollgate,
};
const kalyankatta = {
  name: "Kalyanaka Katta",
  path: tmsRouteMap.serviceManagement_kalayanakatta,
};
const prasadamInventory = {
  name: "Prasadam Stock",
  path: tmsRouteMap.sm_prasadam_inventory,
};
const pos = { name: "POS", path: tmsRouteMap.pos };
const accommodation = {
  name: "Accommodation",
  path: tmsRouteMap.serviceManagement_accommodation,
};
const manual_entry = {
  name: "Manual Entry",
  path: tmsRouteMap.serviceManagement_manual_entry,
};
//-------------------------------------------------------//

const tmsBreadcrumbMap = {
  [tmsRouteMap.welcome]: [{ name: "Welcome", path: tmsRouteMap.welcome }],

  [tmsRouteMap.home]: [{ name: "Home", path: tmsRouteMap.home }],
  [tmsRouteMap.devotees]: [{ name: "Devotees", path: tmsRouteMap.devotees }],

  [tmsRouteMap.temple_management]: [
    { name: "Temple Management", path: tmsRouteMap.temple_management },
  ],
  [tmsRouteMap.temple_settings]: [
    { name: "Temple Settings", path: tmsRouteMap.temple_settings },
  ],

  [tmsRouteMap.pos]: [{ name: "POS", path: tmsRouteMap.pos }],

  // [tmsRouteMap.pos_parokshaseva]: [
  //   pos,
  //   { name: "Paroksha seva", path: tmsRouteMap.pos_parokshaseva },
  // ],

  [tmsRouteMap.pos_sevas]: [
    pos,
    { name: "Sevas", path: tmsRouteMap.pos_sevas },
  ],
  [tmsRouteMap.pos_darshanam]: [
    pos,
    { name: "Darshanam", path: tmsRouteMap.pos_darshanam },
  ],
  [tmsRouteMap.pos_donation]: [
    pos,
    { name: "Donation", path: tmsRouteMap.pos_donation },
  ],
  [tmsRouteMap.pos_petty_donation]: [
    pos,
    { name: "Petty Donation", path: tmsRouteMap.pos_petty_donation },
  ],
  [tmsRouteMap.pos_kalayanakatta]: [
    pos,
    { name: "Kalyanakatta", path: tmsRouteMap.pos_kalayanakatta },
  ],
  [tmsRouteMap.pos_prasadam]: [
    pos,
    { name: "Prasadam", path: tmsRouteMap.pos_prasadam },
  ],
  [tmsRouteMap.pos_products]: [
    pos,
    { name: "Products", path: tmsRouteMap.pos_products },
  ],
  [tmsRouteMap.pos_thulabharam]: [
    pos,
    { name: "Thulabharam", path: tmsRouteMap.pos_thulabharam },
  ],
  [tmsRouteMap.pos_accommodation]: [
    pos,
    { name: "Accommodation", path: tmsRouteMap.pos_accommodation },
  ],
  [tmsRouteMap.pos_acc_scan]: [
    pos,
    { name: "Accommodation Scan", path: tmsRouteMap.pos_acc_scan },
  ],
  [tmsRouteMap.pos_accommodation_dashboard]: [
    pos,
    {
      name: "Accommodation dashboard",
      path: tmsRouteMap.pos_accommodation_dashboard,
    },
  ],
  [tmsRouteMap.pos_accommodation_rooms]: [
    pos,
    { name: "Accommodation", path: tmsRouteMap.pos_accommodation },
    { name: "Rooms", path: tmsRouteMap.pos_accommodation_rooms },
  ],

  [tmsRouteMap.pos_tollgate]: [
    pos,
    { name: "Toll gate", path: tmsRouteMap.pos_tollgate },
  ],

  [tmsRouteMap.pos_check_in_scan]: [
    pos,
    { name: "Checkin Scan ", path: tmsRouteMap.pos_check_in_scan },
  ],
  [tmsRouteMap.pos_prasadm_scanner]: [
    pos,
    { name: "Prasadam Scanner", path: tmsRouteMap.pos_prasadm_scanner },
  ],
  [tmsRouteMap.pos_ticket_details]: [
    pos,
    { name: "Ticket Details ", path: tmsRouteMap.pos_ticket_details },
  ],

  [tmsRouteMap.serviceManagement]: [
    { name: "Service management", path: tmsRouteMap.serviceManagement },
  ],
  //----------------------------accommodation---------------------//

  [tmsRouteMap.serviceManagement_accommodation]: [
    service,
    {
      name: "Accommodation",
      path: tmsRouteMap.serviceManagement_accommodation,
    },
  ],
  [tmsRouteMap.sm_accommodation_and]: [
    service,
    accommodation,
    {
      name: "Add New Block",
      path: tmsRouteMap.sm_accommodation_and,
    },
  ],
  [tmsRouteMap.sm_accommodation_disable]: [
    service,
    accommodation,
    {
      name: "Disable Dates",
      path: tmsRouteMap.sm_accommodation_disable,
    },
  ],
  [tmsRouteMap.sm_acc_manage_room_categories]: [
    service,
    accommodation,
    {
      name: "Manage Room Categories",
      path: "",
    },
  ],
  [tmsRouteMap.sm_acc_mrc_manage_room]: [
    service,
    accommodation,
    {
      name: "Manage Room Categories",
      path: tmsRouteMap.sm_acc_manage_room_categories,
    },
    {
      name: "Manage Room",
      path: "",
    },
  ],
  [tmsRouteMap.sm_acc_mrc_manage_quota]: [
    service,
    accommodation,
    {
      name: "Manage Room Categories",
      path: tmsRouteMap.sm_acc_manage_room_categories,
    },
    {
      name: "Manage Quota",
      path: "",
    },
  ],
  [tmsRouteMap.sm_acc_manage_pos]: [
    service,
    accommodation,
    {
      name: "Manage POS",
      path: "",
    },
  ],
  //----------------------------accommodation---------------------//

  //----------------------------products - sm---------------------//

  [tmsRouteMap.serviceManagement_products]: [
    service,
    {
      name: "Products",
      path: tmsRouteMap.serviceManagement_products,
    },
  ],
  [tmsRouteMap.sm_products_and]: [
    service,
    products,
    {
      name: "Add New Product",
      path: tmsRouteMap.sm_products_and,
    },
  ],
  [tmsRouteMap.sm_products_manage_pos]: [
    service,
    products,
    {
      name: "Manage POS",
      path: tmsRouteMap.sm_products_manage_pos,
    },
  ],
  [tmsRouteMap.sm_products_manage_files]: [
    service,
    products,
    {
      name: "File Management",
      path: "",
    },
  ],
  [tmsRouteMap.sm_products_manage_files_add]: [
    service,
    products,
    {
      name: "File Management",
      path: "",
    },
  ],
  //----------------------------products - sm---------------------//
  [tmsRouteMap.serviceManagement_counter_management]: [
    service,
    {
      name: "POS Management",
      path: tmsRouteMap.serviceManagement_counter_management,
    },
  ],
  [tmsRouteMap.serviceManagement_counter_management_manage_shifts]: [
    service,
    {
      name: "POS Management",
      path: tmsRouteMap.serviceManagement_counter_management,
    },
    {
      name: "Manage Shifts",
      path: tmsRouteMap.serviceManagement_counter_management_manage_shifts,
    },
  ],

  [tmsRouteMap.serviceManagement_coupon_management]: [
    service,
    {
      name: "Coupon management",
      path: tmsRouteMap.serviceManagement_coupon_management,
    },
  ],
  //---------------------Service-Darshanam------------------------//

  [tmsRouteMap.serviceManagement_darshanam]: [
    service,
    { name: "Darshanam", path: tmsRouteMap.serviceManagement_darshanam },
  ],

  [tmsRouteMap.smd_darshanam_add_new_darshanam]: [
    service,
    service_darshanam,
    {
      name: "Add New Darshanam",
      path: tmsRouteMap.smd_darshanam_add_new_darshanam,
    },
  ],
  [tmsRouteMap.smd_darshanam_darshanam_booking_details]: [
    service,
    service_darshanam,
    {
      name: "Darshanam booking",
      path: tmsRouteMap.smd_darshanam_darshanam_booking_details,
    },
  ],
  [tmsRouteMap.smd_darshanam_manage_counters]: [
    service,
    service_darshanam,
    { name: "Manage Slots", path: tmsRouteMap.smd_darshanam_manage_slots },
    {
      name: "Manage Counters",
      path: tmsRouteMap.smd_darshanam_manage_counters,
    },
  ],
  [tmsRouteMap.smd_darshanam_manage_quota]: [
    service,
    service_darshanam,
    { name: "Manage Slots", path: tmsRouteMap.smd_darshanam_manage_slots },
    {
      name: "Manage Counters",
      path: "",
    },
  ],
  [tmsRouteMap.smd_darshanam_manage_slots]: [
    service,
    service_darshanam,
    { name: "Manage Slots", path: tmsRouteMap.smd_darshanam_manage_slots },
  ],
  [tmsRouteMap.smd_darshanam_disable_dates]: [
    service,
    service_darshanam,
    { name: "Disable Darshanam ", path: "" },
  ],
  //---------------------Service-Darshanam------------------------//
  //---------------------Service-Manual-Entry------------------------//
  [tmsRouteMap.serviceManagement_manual_entry]: [
    service,
    { name: "Manual Entry", path: tmsRouteMap.serviceManagement_manual_entry },
  ],
  [tmsRouteMap.serviceManagement_manual_entry_category]: [
    service,
    manual_entry,
    {
      name: "Manage Category",
      path: tmsRouteMap.serviceManagement_manual_entry_category,
    },
  ],
  [tmsRouteMap.pos_manual_entry]: [
    pos,
    { name: "Manual Entry", path: tmsRouteMap.pos_manual_entry },
  ],
  [tmsRouteMap.report_manual_entry]: [
    reports,
    { name: "Manual Entry", path: tmsRouteMap.report_manual_entry },
  ],
  //---------------------Service-Manual-Entry------------------------//
  //---------------------Service-Donation-------------------------//

  [tmsRouteMap.serviceManagement_donation]: [
    service,
    { name: "Donation", path: tmsRouteMap.serviceManagement_donation },
  ],
  [tmsRouteMap.sms_donation_booking_details]: [
    service,
    donation,
    {
      name: "Donation Booking Details",
      path: tmsRouteMap.sms_donation_booking_details,
    },
  ],
  [tmsRouteMap.sms_donation_donor_registartion]: [
    service,
    donation,
    {
      name: "Donar Registarion",
      path: tmsRouteMap.sms_donation_donor_registartion,
    },
  ],
  [tmsRouteMap.sms_donation_manage_counters]: [
    service,
    donation,
    {
      name: "Manage Donation Counters",
      path: tmsRouteMap.sms_donation_manage_counters,
    },
  ],
  [tmsRouteMap.sms_add_new_donation]: [
    service,
    donation,
    { name: "Add New Donation", path: tmsRouteMap.sms_add_new_donation },
  ],
  //---------------------Service-Donation-------------------------//

  //------------------Service kalayanakatta-----------//
  [tmsRouteMap.serviceManagement_kalayanakatta]: [
    service,
    {
      name: "Kalyana katta",
      path: tmsRouteMap.serviceManagement_kalayanakatta,
    },
  ],
  [tmsRouteMap.sm_kalyanakatta_manage_tonsure]: [
    service,
    kalyankatta,
    {
      name: "Manage POS",
      path: tmsRouteMap.sm_kalyanakatta_manage_tonsure,
    },
  ],
  [tmsRouteMap.sm_kalyanakatta_manage_performer]: [
    service,
    kalyankatta,
    {
      name: "Manage Performer",
      path: tmsRouteMap.sm_kalyanakatta_manage_performer,
    },
  ],
  [tmsRouteMap.sm_kalyanakatta_add_new_kalyanakatta]: [
    service,
    kalyankatta,
    {
      name: "Add New Kalyanakatta",
      path: tmsRouteMap.sm_kalyanakatta_add_new_kalyanakatta,
    },
  ],
  //------------------Service Kalyanakatta------------//
  [tmsRouteMap.serviceManagement_parokshaseva]: [
    service,
    { name: "Paroksha seva", path: tmsRouteMap.serviceManagement_parokshaseva },
  ],
  //---------------------Service-Prasadam-------------------------//

  [tmsRouteMap.serviceManagement_prasadam]: [
    service,
    { name: "Prasadam", path: tmsRouteMap.serviceManagement_prasadam },
  ],
  [tmsRouteMap.smd_prasadam_booking_details]: [
    service,
    prasadam,
    {
      name: "Prasadam Booking Details",
      path: tmsRouteMap.smd_prasadam_booking_details,
    },
  ],
  [tmsRouteMap.smd_prasadam_manage_counter]: [
    service,
    prasadam,
    {
      name: "Manage Prasadam Counters",
      path: tmsRouteMap.smd_prasadam_manage_counter,
    },
  ],
  [tmsRouteMap.smd_prasadam_add_new_prasadam]: [
    service,
    prasadam,
    {
      name: "Add New Prasadam",
      path: tmsRouteMap.smd_prasadam_add_new_prasadam,
    },
  ],
  [tmsRouteMap.sm_prasadam_inventory]: [
    service,
    { name: "Prasadam Inventory", path: tmsRouteMap.sm_prasadam_inventory },
  ],
  [tmsRouteMap.sm_prasadam_inventory_add_new]: [
    service,
    prasadamInventory,
    {
      name: "Add New ",
      path: tmsRouteMap.sm_prasadam_inventory_add_new,
    },
  ],
  [tmsRouteMap.sm_prasadam_inventory_stock]: [
    service,
    prasadamInventory,
    {
      name: "Stock Level",
      path: tmsRouteMap.sm_prasadam_inventory_stock,
    },
  ],

  //---------------------Service-Prasadam-------------------------//

  //---------------------Service-Sevas------------------------//
  [tmsRouteMap.serviceManagement_sevas]: [
    service,
    {
      name: "Sevas",
      path: tmsRouteMap.serviceManagement_sevas,
    },
  ],

  [tmsRouteMap.sms_add_new_seva]: [
    service,
    service_sevas,
    {
      name: "Add new Sevas",
      path: tmsRouteMap.sms_add_new_seva,
    },
  ],
  [tmsRouteMap.sms_seva_booking_details]: [
    service,
    service_sevas,
    {
      name: "Seva Booking Details",
      path: tmsRouteMap.sms_seva_booking_details,
    },
  ],
  [tmsRouteMap.sms_sevas_manage_slots]: [
    service,
    service_sevas,
    {
      name: "Manage Slots",
      path: tmsRouteMap.sms_sevas_manage_slots,
    },
  ],
  [tmsRouteMap.sms_sevas_manage_counters]: [
    service,
    service_sevas,
    {
      name: "Manage Slots",
      path: tmsRouteMap.sms_sevas_manage_slots,
    },
    {
      name: "Manage Counters",
      path: tmsRouteMap.sms_sevas_manage_counters,
    },
  ],
  [tmsRouteMap.sms_sevas_manage_quota]: [
    service,
    service_sevas,
    {
      name: "Manage Slots",
      path: tmsRouteMap.sms_sevas_manage_slots,
    },
    {
      name: "Manage Quota",
      path: "",
    },
  ],
  //---------------------Service-Sevas------------------------//

  [tmsRouteMap.serviceManagement_publications]: [
    service,
    { name: "Publications", path: tmsRouteMap.serviceManagement_publications },
  ],
  [tmsRouteMap.serviceManagement_shift_management]: [
    service,
    {
      name: "Shift management",
      path: tmsRouteMap.serviceManagement_shift_management,
    },
  ],
  [tmsRouteMap.serviceManagement_thulabharam]: [
    service,
    { name: "Thulabharam", path: tmsRouteMap.serviceManagement_thulabharam },
  ],
  [tmsRouteMap.serviceManagement_counter_management]: [
    service,
    {
      name: "POS management",
      path: tmsRouteMap.serviceManagement_counter_management,
    },
  ],
  //----------------------Services Tollgate----------------------//
  [tmsRouteMap.serviceManagement_tollgate]: [
    service,
    {
      name: "Toll Gate",
      path: tmsRouteMap.serviceManagement_tollgate,
    },
  ],
  [tmsRouteMap.sm_tollgate_add_new_tollgate]: [
    service,
    tollgate,
    {
      name: "Add new Toll Gate",
      path: tmsRouteMap.sm_tollgate_add_new_tollgate,
    },
  ],
  [tmsRouteMap.sm_tollgate_booking_details]: [
    service,
    tollgate,
    {
      name: "Toll Gate Booking Details",
      path: tmsRouteMap.sm_tollgate_booking_details,
    },
  ],

  [tmsRouteMap.sm_tollgate_manage_counter]: [
    service,
    tollgate,
    {
      name: "(Slots)-Manage Counters",
      path: tmsRouteMap.sm_tollgate_manage_counter,
    },
  ],
  //--------------------------------------------------------------//

  [tmsRouteMap.orders]: [{ name: "Orders", path: tmsRouteMap.orders }],
  [tmsRouteMap.reports]: [{ name: "Reports", path: tmsRouteMap.reports }],
  [tmsRouteMap.reports_shift_report]: [
    reports,
    { name: "Shift Report", path: tmsRouteMap.reports_shift_report },
  ],
  [tmsRouteMap.reports_view_shift_transactions]: [
    reports,
    {
      name: "Shift Transactions",
      path: tmsRouteMap.reports_view_shift_transactions,
    },
  ],
  [tmsRouteMap.reports_consolidated_reports]: [
    reports,
    {
      name: "Consolidated Report",
      path: tmsRouteMap.reports_consolidated_reports,
    },
  ],
  [tmsRouteMap.reports_view_shift_scan_logs]: [
    reports,
    {
      name: "Scan Log Transactions",
      path: tmsRouteMap.reports_view_shift_scan_logs,
    },
  ],
  [tmsRouteMap.reports_scan_logs]: [
    reports,
    {
      name: "Scan Logs",
      path: tmsRouteMap.reports_scan_logs,
    },
  ],
  //---------------------reports-darshanam-----------//
  [tmsRouteMap.reports_darshanam]: [
    reports,
    { name: "Darshanam Report", path: tmsRouteMap.reports_darshanam },
  ],
  [tmsRouteMap.reports_darshanam_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_darshanam_summary_report,
    },
  ],
  //---------------------reports-darshanam-----------//
  //---------------------reports-seva-----------//
  [tmsRouteMap.reports_seva]: [
    reports,
    { name: "Seva Report", path: tmsRouteMap.reports_seva },
  ],
  [tmsRouteMap.reports_seva_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_seva_summary_report,
    },
  ],
  //---------------------reports-seva-----------//

  //---------------------reports-prasadam-----------//
  [tmsRouteMap.reports_prasadam]: [
    reports,
    { name: "Prasadam Report", path: tmsRouteMap.reports_prasadam },
  ],
  [tmsRouteMap.reports_prasadam_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_prasadam_summary_report,
    },
  ],
  //---------------------reports-prasadam-----------//

  //---------------------reports-donation-----------//
  [tmsRouteMap.reports_donation]: [
    reports,
    { name: "Donation Report", path: tmsRouteMap.reports_donation },
  ],
  [tmsRouteMap.reports_donation_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_donation_summary_report,
    },
  ],
  //---------------------reports-donation-----------//

  //---------------------reports-thulabharam-----------//
  [tmsRouteMap.reports_thulabharam]: [
    reports,
    { name: "Thulabahram Report", path: tmsRouteMap.reports_thulabharam },
  ],
  [tmsRouteMap.reports_thulabharam_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_thulabharam_summary_report,
    },
  ],
  //---------------------reports-thulabharam-----------//

  //---------------------reports-accommodation-----------//
  [tmsRouteMap.reports_accommodation]: [
    reports,
    { name: "Accommodation Report", path: tmsRouteMap.reports_thulabharam },
  ],
  [tmsRouteMap.reports_accommodation_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_accommodation_summary_report,
    },
  ],

  //---------------------reports-accommodation-----------//

  [tmsRouteMap.userManagement]: [
    { name: "User Management", path: tmsRouteMap.userManagement },
  ],
  [tmsRouteMap.userManagement_manage_users]: [
    { name: "User Management", path: tmsRouteMap.userManagement },
    { name: "Manage Users", path: tmsRouteMap.userManagement_manage_users },
  ],
  [tmsRouteMap.userManagement_user_details]: [
    { name: "User Management", path: tmsRouteMap.userManagement },
    { name: "User Details", path: tmsRouteMap.userManagement_user_details },
  ],
  [tmsRouteMap.support]: [{ name: "Support", path: tmsRouteMap.support }],
  [tmsRouteMap.calender]: [{ name: "Calender", path: tmsRouteMap.calender }],
  [tmsRouteMap.bookings]: [{ name: "Bookings", path: tmsRouteMap.bookings }],
};

export default tmsBreadcrumbMap;
