import {Card, Col, Divider, Row, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {
  MailOutlined,
  PhoneOutlined,
  CalendarOutlined,
  HomeOutlined,
  LoginOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import moment from "moment";
import {tms_profile_pic_holder} from "../../assets/images/tms-image-list";

const TmsUserCard = ({userLoading, data, cardwidth}) => {
  const [userAddress, setUserAddress] = useState("");

  useEffect(() => {
    const addressArr = [];
    data?.address1 && addressArr.push(data?.address1);
    data?.address2 && addressArr.push(data?.address2);
    data?.city && addressArr.push(data?.city);
    data?.state && addressArr.push(data?.state);
    data?.country && addressArr.push(data?.country);
    data?.pincode && addressArr.push(data?.pincode);
    setUserAddress(addressArr.join(", "));
  }, [data]);

  return (
    <Row>
      <Col span={24}>
        <Card
          style={{marginBottom: 24, width: `${cardwidth}`}}
          loading={userLoading}
        >
          {data ? (
            <div>
              <div style={{width: "50%", float: "left"}}>
                <img
                  src={tms_profile_pic_holder}
                  alt="profile"
                  style={{
                    height: "180px",
                    paddingBottom: "30px",
                    float: "left",
                  }}
                ></img>
              </div>

              <div
                style={{
                  width: "50%",
                  textAlign: "left",
                  float: "right",
                  margin: "24px 0px",
                }}
              >
                <div
                  style={{
                    textTransform: "capitalize",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {data?.display_name}{" "}
                  {data?.registered ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  ) : (
                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                  )}
                </div>
                ({data?.role})
                <div
                  style={{
                    textTransform: "capitalize",
                    fontSize: "16px",
                    fontWeight: "lighter",
                  }}
                >
                  {data?.firstName} {data?.lastName}
                </div>
                <div
                  style={{
                    textTransform: "capitalize",
                    fontSize: "16px",
                  }}
                >
                  {data?.gothram}
                </div>
              </div>
              <Divider dashed />
              <div style={{textAlign: "left"}}>
                <Row gutter={[10, 10]}>
                  <Col>
                    <PhoneOutlined />
                  </Col>
                  <Col>
                    <Typography.Paragraph copyable={data?.phone ? true : false}>
                      {data?.phone ? data?.phone : "-"}
                    </Typography.Paragraph>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MailOutlined />
                  </Col>
                  <Col>
                    <Typography.Paragraph copyable={data.email ? true : false}>
                      {data?.email ? data.email : "-"}
                    </Typography.Paragraph>
                  </Col>
                </Row>
                <Row gutter={[10, 10]}>
                  <Col>
                    <CalendarOutlined />
                  </Col>
                  <Col>{data?.dateOfBirth ? data?.dateOfBirth : "-"}</Col>
                </Row>
                <Row gutter={[10, 10]}>
                  <Col>
                    <HomeOutlined />
                  </Col>
                  <Col>{userAddress ? userAddress : "-"}</Col>
                </Row>
                <Row gutter={[10, 10]}>
                  <Col>
                    <LoginOutlined />
                  </Col>
                  <Col>
                    {data?.lastLogoutAt &&
                      moment(data?.lastLogoutAt)
                        .utcOffset("+5:30")
                        .format("YYYY-MM-DD hh:mm A")}
                  </Col>
                </Row>
                <Row gutter={[10, 10]}>
                  <Col>Created On:</Col>
                  <Col>
                    {data?.createdAt &&
                      moment(data?.createdAt)
                        .utcOffset("+5:30")
                        .format("YYYY-MM-DD hh:mm A")}
                  </Col>
                </Row>
                <Row gutter={[10, 10]}>
                  <Col>Updated On:</Col>
                  <Col>
                    {data?.updatedAt &&
                      moment(data?.updatedAt)
                        .utcOffset("+5:30")
                        .format("YYYY-MM-DD hh:mm A")}
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            "loading..."
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default TmsUserCard;
