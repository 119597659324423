// import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";
const TmsDashboardService = {
  getCollectionData: async (counter, fromDate, toDate, dateType, order) => {
    const resp = await ReportsAxios.get(
      `v1/dashboard/tickets-and-collection-count/${counter}?page=1&limit=100&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${order}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getIndividualCollectionData: async ({
    counter,
    fromDate,
    toDate,
    dateType,
    order,
  }) => {
    const resp = await ReportsAxios.get(
      `v1/dashboard/consolidated-report/${counter}?page=1&limit=100&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${order}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getAllTemplesIndividualCollectionData: async ({
    counter,
    fromDate,
    toDate,
    dateType,
    order,
  }) => {
    const resp = await ReportsAxios.get(
      `v1/dashboard/temple-consolidated-report/${counter}?page=1&limit=100&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${order}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,

          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
};
export default TmsDashboardService;
