/* eslint-disable no-undef */
import { notification } from "antd";
import moment from "moment";
import numberToWords from "../tms-number-to-words/tms-number-to-words";

const postBookingDataFunction = (input) => {
  const arrayData = [...input];
  let array = [];
  let type = "";

  const handleDonationReprint = () => {
    type = "donation";
    let schemaBody = [];
    arrayData?.map((item) => {
      schemaBody = [
        ...schemaBody,
        [
          "header",
          item?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.dailyCategoryTransactionSequence?.split("-")?.pop(),
          item?.ticketId,
        ],

        ["Ticket Id", item?.ticketId, { margin: "0" }],
        [
          "Donation",
          item?.counter_donation?.donationName,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "Devotee Name",
          item?.counter_donation?.devoteeName
            ? item?.counter_donation?.contact
              ? `${item?.counter_donation?.devoteeName
                  ?.split(",")[0]
                  .trim()} | ${item?.counter_donation?.contact}`
              : item?.counter_donation?.devoteeName?.split(",")[0].trim()
            : item?.counter_donation?.contact ?? "NA",
          { margin: "0" },
        ],
        [
          "In Behalf of",
          `${item?.counter_donation?.inTheNameOf?.split(",")[0].trim()}
          `,
          { margin: "0" },
        ],
        ["Gothram", `${item?.counter_donation?.gothram}`, { margin: "0" }],
        ["Occasion", `${item?.counter_donation?.occasion}`, { margin: "0" }],
        [
          "Address",
          `${item?.counter_donation?.address}, ${item?.counter_donation?.city}, ${item?.counter_donation?.state}-${item?.counter_donation?.pinCode}`,
          { margin: "0" },
        ],

        [
          "Performance Date",
          `${
            item?.counter_donation?.calendarType === "english"
              ? !item?.counter_donation?.month
                ? `${moment(item?.counter_donation?.bookingDate).format(
                    "DD-MM-YYYY"
                  )}`
                : `${item?.counter_donation?.date}-${
                    item?.counter_donation?.month
                  }-${moment().format("YYYY")}`
              : `${item?.counter_donation?.masam} - 
                  ${item?.counter_donation?.paksham} - 
                  ${item?.counter_donation.tithi}
                `
          }`,
          { margin: "0" },
        ],
        ["Booking Date", moment().format("DD-MM-YYYY"), { margin: "0" }],
        [
          "ID proof",
          item?.counter_donation?.panCardId
            ? `PAN-${item?.counter_donation?.panCardId}`
            : item?.counter_donation?.documentId
            ? item?.counter_donation?.documentId
            : null,
          { margin: "0" },
        ],
        [
          "Ticket Price",
          `Rs. ${item?.counter_donation?.amount}.00`,
          { margin: "0" },
        ],
        [
          "Total Amount",
          `Rs. ${item?.counter_donation?.amount}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(item?.counter_donation?.amount)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.ticketId,
          {},
          {},
          {},
          null,
          item?.counter_donation?.createdAt,
        ],
      ];
    });
    array = [...schemaBody];
  };

  const handleSevaReprint = () => {
    type = "seva";
    let temple = JSON.parse(localStorage.getItem("templeDetails"));
    let schemaBody = [];
    arrayData?.map((item) => {
      schemaBody = [
        ...schemaBody,
        [
          "header",
          item?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.dailyCategoryTransactionSequence?.split("-")?.pop(),
          item?.ticketId,
          null,
        ],

        ["Ticket Id", item?.ticketId, { margin: "0" }],
        [
          "Seva",
          item?.counter_seva?.sevaName,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "Date",
          `${moment(item?.counter_seva?.bookingDate, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          )}`,
          { margin: "0" },
        ],
        [
          "Time Slot",
          `${moment(item?.counter_seva?.slotStartTime, "HH:mm:ss").format(
            "hh:mm A"
          )} -
        ${moment(item?.counter_seva?.slotEndTime, "HH:mm:ss").format(
          "hh:mm A"
        )}`,
          { margin: "0" },
        ],
        [
          "Devotee Name",
          item?.counter_seva?.devoteeName
            ? item?.counter_seva?.contact
              ? `${item?.counter_seva?.devoteeName?.split(",")[0].trim()} | ${
                  item?.counter_seva?.contact
                }`
              : item?.counter_seva?.devoteeName?.split(",")[0].trim()
            : item?.counter_seva?.contact ?? "NA",
          { margin: "0" },
        ],
        ["ID Proof No", item?.counter_seva?.idProofNumber, { margin: "0" }],
        [
          "No of People",
          ["APEDP", "SBMSD"].includes(temple?.shortName)
            ? item?.counter_seva?.noOfPeople === 1
              ? "Single"
              : item?.counter_seva?.noOfPeople === 2
              ? "Husband & Wife"
              : item?.counter_seva?.noOfPeople
            : item?.counter_seva?.noOfPeople,
          { margin: "0" },
        ],

        [
          "Ticket Price",
          `Rs. ${item?.counter_seva?.price}.00`,
          { margin: "0" },
        ],
        [
          "Total Amount",
          `Rs. ${item?.counter_seva?.price}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(item?.counter_seva?.price)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.ticketId,
          {},
          item?.counter_seva?.sevaName,
          `<p style="margin:0">TI No:${item?.categoryTransactionSequence
            ?.split("-")
            ?.pop()}</p><p style="margin:0">S No:${item?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop()}</p><p style="margin:0">${moment(
            item?.counter_seva?.bookingDate,
            "YYYY-MM-DD"
          ).format(
            "DD-MM-YYYY"
          )}</p><p style="font-weight:bold;font-size:22px;margin:0">Rs. ${
            item?.counter_seva?.price
          }.00</p> `,
          null,
          item?.counter_seva?.createdAt,
        ],
      ];
    });
    array = [...schemaBody];
  };

  const handleDarshanamReprint = () => {
    type = "darshanam";
    let schemaBody = [];
    const spcl = [
      "pratyekhadarshnam500",
      "pratyekhadarshnam300",
      "pratyekhadarshanam500",
      "pratyekhadarshanam300",
    ];
    arrayData?.forEach((item) => {
      schemaBody = [
        ...schemaBody,
        [
          "header",
          item?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.dailyCategoryTransactionSequence?.split("-")?.pop(),
          item?.ticketId,
          spcl.includes(
            item?.counter_darshanam?.darshanamName
              .toLowerCase()
              ?.replaceAll(" ", "")
              ?.trim()
          )
            ? "ENTRY ALLOWED THROUGH VIP GATE"
            : null,
        ],
        ["Ticket Id", item?.ticketId, { margin: "0" }],
        [
          "Darshanam",
          item?.counter_darshanam?.darshanamName,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "Date",
          `${moment(item?.counter_darshanam?.bookingDate, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          )}`,
          { margin: "0" },
        ],
        [
          "Time Slot",
          `${moment(item?.counter_darshanam?.slotStartTime, "HH:mm:ss").format(
            "hh:mm A"
          )} -
        ${moment(item?.counter_darshanam?.slotEndTime, "HH:mm:ss").format(
          "hh:mm A"
        )}`,
          { margin: "0" },
        ],

        ["Ticket Count", item?.counter_darshanam?.noOfTickets, { margin: "0" }],

        [
          "Ticket Price",
          `Rs. ${
            item?.counter_darshanam?.price /
            item?.counter_darshanam?.noOfTickets
          }.00`,
          { margin: "0" },
        ],
        [
          "Total Amount",
          `Rs. ${item?.counter_darshanam?.price}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(item?.counter_darshanam?.price)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.ticketId,
          {},
          item?.counter_darshanam?.darshanamName,
          `<p style="margin:0">TI No:${item?.categoryTransactionSequence
            ?.split("-")
            ?.pop()}</p><p style="margin:0">S No:${item?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop()}</p><p style="margin:0">${moment(
            item?.counter_darshanam?.bookingDate,
            "YYYY-MM-DD"
          ).format(
            "DD-MM-YYYY"
          )}</p><p style="font-weight:bold;font-size:22px;margin:0">Rs. ${
            item?.counter_darshanam?.price
          }.00</p> `,
        ],
      ];
    });
    array = [...schemaBody];
  };

  const handlePrasadamReprint = () => {
    type = "prasadam";
    let totalPrice = 0;
    let schemaBody = [];
    let firstItem = arrayData[0];
    arrayData?.forEach((item) => {
      totalPrice += item.counter_prasadam.price;

      schemaBody = [
        ...schemaBody,
        [
          "",
          `<tr style="text-transform:capitalize;"><td style="border:1px solid black">${item.counter_prasadam.productName}</td><td class="col-2" style="border:1px solid black">${item.counter_prasadam.noOfItems}</td><td style="border:1px solid black">Rs. ${item.counter_prasadam.price}.00</td></tr>`,
          {
            display: "none",
          },
        ],
      ];
    });
    let schemaHeader = [
      [
        "header",
        firstItem?.categoryTransactionSequence?.split("-")?.pop(),
        {},
        firstItem?.dailyCategoryTransactionSequence?.split("-")?.pop(),
        firstItem?.ticketId,
      ],
      [
        "Date",
        moment(firstItem?.prasdamBooking?.bookingDate).format("DD-MM-YYYY"),
        { margin: "0" },
      ],
      [
        "Ticket Id",
        `${
          firstItem?.ticketId?.split("-")[1]
        }-${firstItem?.dailyCategoryTransactionSequence?.split("-")?.pop()}`,
        { margin: "0" },
      ],
      [
        "",
        `<tr style="text-transform:capitalize;font-weight:bold;"><td>Item</td><td>Qty</td><td>Total</td></tr>`,
        {
          display: "none",
        },
      ],
    ];
    let schemaFooter = [
      [
        "Total Amount",
        `Rs. ${totalPrice}.00 `,
        { margin: "0", fontSize: "22px", fontWeight: "800" },
      ],
      [
        "",
        `(${numberToWords(totalPrice)} Rupees)`,
        { margin: "0", fontWeight: "bold" },
      ],
      ["footer", firstItem?.ticketId],
    ];
    array = [...schemaHeader, ...schemaBody, ...schemaFooter];
  };

  const handleKalyanakattaReprint = () => {
    type = "kalyanakatta";
    let schemaBody = [];
    arrayData?.forEach((item) => {
      schemaBody = [
        ...schemaBody,
        [
          "header",
          item?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.dailyCategoryTransactionSequence?.split("-")?.pop(),
          item?.ticketId,
          item?.counter_kalyanakatta?.performerSittingNumber,
        ],
        ["Ticket Id", item?.ticketId, { margin: "0" }],
        [
          "Type",
          item?.counter_kalyanakatta?.kalyanakattaName,
          {
            margin: "0",
            fontWeight: "bold",
          },
        ],
        [
          "Performer",
          item?.counter_kalyanakatta?.performerName
            ? item?.counter_kalyanakatta?.performerName
            : "null",
          {
            margin: "0",
          },
        ],
        [
          "Ticket Count",
          item?.counter_kalyanakatta?.noOfItems,
          {
            margin: "0",
          },
        ],
        [
          `Price`,
          `Rs. ${item?.counter_kalyanakatta?.price}.00`,
          { margin: "0" },
        ],
        [
          "",
          `(${numberToWords(item?.kalyanakattaBooking?.price)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.ticketId,
          {},
          {},
          {},
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.createdAt,
        ],
      ];
      array = [...schemaBody];
    });
  };

  const handleTollgateReprint = () => {
    type = "tollgate";
  };
  const handleThulabharamReprint = () => {
    type = "thulabharam";
  };

  if (arrayData[0]?.productType === "counter_donation") {
    handleDonationReprint();
  } else if (arrayData[0]?.productType === "counter_seva") {
    handleSevaReprint();
  } else if (arrayData[0]?.productType === "counter_darshanam") {
    handleDarshanamReprint();
  } else if (arrayData[0]?.productType === "counter_prasadam") {
    handlePrasadamReprint();
  } else if (arrayData[0]?.productType === "counter_kalyanakatta") {
    handleKalyanakattaReprint();
  } else if (arrayData[0]?.productType === "counter_thulabharam") {
    handleThulabharamReprint();
  } else if (arrayData[0]?.productType === "counter_tollgate") {
    handleTollgateReprint();
  } else {
    notification.error({
      message:
        "Print Option is only availbale for Donation,Darshanam,Seva,Thulabharam,Toll Gate,Prasadam",
    });
  }
  return { array: array, type: type, externalPrint: true };
};

export default postBookingDataFunction;
