import {
  Button,
  Calendar,
  Col,
  Drawer,
  Input,
  notification,
  Row,
  Select,
} from "antd";
import moment from "moment/moment";
import React, { useState } from "react";
import "../../assets/css/tms-custom-elements/tms-custom-elements.scss";
import TmsDarshanamService from "../../services/tms-darshanam/tms-darshanam.service";
import TmsDatePickerRange from "./tms-datepicker-range";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import { useEffect } from "react";
import tmsRouteMap from "../../constants/tms-route.map";
import TmsSevasService from "../../services/tms-sevas.service";
import TmsStringUtils from "../../utils/tms-string-utils/tms-string-utils";
import TmsAccommodationService from "../../services/tms-accommodation.service";
const TmsCalender = ({ router }) => {
  const [assignType, setAssignType] = useState("Single");
  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [showDrawer, setShowDrawer] = useState(false);
  const [quota, setQuota] = useState(0);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [editvalue, setEditValue] = useState(null);
  const [quotaLoading, setQuotaLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [quotaData, setQuotaData] = useState([]);
  const [sevaType, setSevaType] = useState("");
  const [quotaType, setQuotaType] = useState("online");
  const [maxQuota, setMaxQuota] = useState("");

  const sevaTypeServices = {
    Sevas: TmsSevasService,
    Darshanam: TmsDarshanamService,
    Accommodation: TmsAccommodationService,
  };

  const dateCellRender = (value) => {
    let data = quotaData ?? [];
    let obj = data?.find((item) => value.format("YYYY-MM-DD") === item.date);
    return (
      <ul
        className="events"
        onClick={() => {
          setShowDrawer(true);
          setEditValue(obj);
          setEditFlag(true);
          // setQuota(obj?.quantity);
        }}
      >
        {value.month() !== moment(selectedDate)?.month() ? (
          ""
        ) : (
          <>
            <span>
              Quota:{" "}
              <span style={{ color: "red", fontWeight: "600" }}>
                {obj?.quantity ?? "-"}
              </span>
            </span>
            <br />
            <span>
              Available:
              <span style={{ color: "green", fontWeight: "600" }}>
                {obj?.availableQuantity ?? "-"}
              </span>{" "}
            </span>
          </>
        )}
      </ul>
    );
  };

  const handleSubmit = async () => {
    if (quota === "") {
      notification?.error({ message: "Please enter quota to be added." });
      setQuotaLoading(true);
      return;
    }
    if (assignType === "Batch") {
      if (to === "" || from === "") {
        notification?.error({ message: "Please enter valid dates." });
        setQuotaLoading(true);
        return;
      }
    }

    try {
      let body = {
        dates:
          assignType === "Single"
            ? [moment(selectedDate).format("YYYY-MM-DD")]
            : TmsStringUtils.enumerateDaysBetweenDates(
                moment(from).format("YYYY-MM-DD"),
                moment(to).format("YYYY-MM-DD")
              ),
        quantity: Number(quota),
      };
      if (router.location?.pathname.includes("accommodation")) {
        body.channel = quotaType;

        body.accBlockCategoryIds = [
          Number(
            new URLSearchParams(router?.location?.search).get("categoryid")
          ),
        ];
      } else {
        body.productSlotIds = [
          Number(new URLSearchParams(router?.location?.search).get("slotid")),
        ];
      }
      await sevaTypeServices[
        router.location?.pathname.includes("sevas")
          ? "Sevas"
          : router.location?.pathname.includes("darshanam")
          ? "Darshanam"
          : "Accommodation"
      ].updateQuotaData(body);
      notification.success({ message: "Quota Updated successfully" });

      setQuotaLoading(false);
      clearFunction();
    } catch (e) {
      catchErrorFunction(e, "Token expired! Please login again", router);
      setQuotaLoading(false);
    }
  };

  const getQuotaData = async () => {
    setLoading(true);
    try {
      let body = {
        from: moment(selectedDate).startOf("month").format("YYYY-MM-DD"),
        to: moment(selectedDate).endOf("month").format("YYYY-MM-DD"),
      };
      if (router.location?.pathname.includes("accommodation")) {
        body.accBlockId = new URLSearchParams(router?.location?.search).get(
          "id"
        );
        body.accBlockCategoryId = new URLSearchParams(
          router?.location?.search
        ).get("categoryid");
        body.channel = quotaType;
      } else {
        body.productIds = [
          new URLSearchParams(router?.location?.search).get("slotid"),
        ];
      }

      const res = await sevaTypeServices[
        router.location?.pathname.includes("sevas")
          ? "Sevas"
          : router.location?.pathname.includes("darshanam")
          ? "Darshanam"
          : "Accommodation"
      ].getQuotaData(body);
      if (router.location?.pathname.includes("accommodation")) {
        const res2 = await TmsAccommodationService.fetchRoomsByBlockCategoryId(
          new URLSearchParams(router?.location?.search).get("categoryid")
        );
        setMaxQuota(res2);
      }
      setQuotaData(res);
      setLoading(false);
    } catch (e) {
      catchErrorFunction(e, "Token expired! Please login again!", router);
      setLoading(false);
    }
  };

  const clearFunction = () => {
    setQuota("");
    setSelectedDate(moment());
    setEditValue("");
    setShowDrawer(false);
    setEditFlag(false);
    setAssignType("Single");
  };

  useEffect(() => {
    let id = new URLSearchParams(router?.location?.search).get("id");

    if (!id) {
      if (router.location?.pathname.includes("sevas")) {
        router.navigate(tmsRouteMap?.serviceManagement_sevas);
      } else if (router.location?.pathname.includes("darshanam")) {
        router.navigate(tmsRouteMap?.serviceManagement_darshanam);
      } else if (router.location?.pathname.includes("accommodation")) {
        router.navigate(tmsRouteMap?.serviceManagement_accommodation);
      } else {
        router.navigate(tmsRouteMap?.serviceManagement);
      }
    } else {
      if (router.location?.pathname.includes("sevas")) {
        setSevaType("Sevas");
      } else if (router.location?.pathname.includes("darshanam")) {
        setSevaType("Darshanam");
      } else {
        setSevaType("Accommodation");
      }
    }
  }, [router.location?.pathname]);

  useEffect(() => {
    !editFlag && sevaType !== "" ? getQuotaData() : "";
  }, [selectedDate, sevaType, editFlag, quotaType]);

  return (
    <div>
      <div>
        <Row gutter={[20, 20]}>
          {router.location?.pathname.includes("accommodation") && (
            <Col span={2}>
              <div
                style={{
                  border: "1px solid #cbcbcb",
                  height: "100%",
                  padding: "0px 10px",
                }}
              >
                <h4 style={{ marginBottom: "0" }}>Total Rooms</h4>
                <h2 style={{ marginBottom: "0px" }}>{maxQuota?.totalCount}</h2>
              </div>
            </Col>
          )}
          <Col span={4}>
            <Row className="cal-select">Assign Type</Row>
            <Select
              width="100%"
              style={{ width: "100%" }}
              value={assignType}
              placeholder="Please select a POS"
              onChange={(e) => {
                setAssignType(e);
                setQuota("");
              }}
            >
              <Select.Option key={"Batch"} value={"Batch"}>
                {"Batch"}{" "}
              </Select.Option>
              <Select.Option key={"Single"} value={"Single"}>
                {"Single"}{" "}
              </Select.Option>
            </Select>
          </Col>
          {router.location?.pathname.includes("accommodation") && (
            <Col span={4}>
              <Row className="cal-select">Quota Type</Row>
              <Select
                width="100%"
                style={{ width: "100%" }}
                value={quotaType}
                placeholder="Please select a POS"
                onChange={(e) => {
                  setQuotaType(e);
                  setQuota(0);
                }}
              >
                <Select.Option key={"online"} value={"online"}>
                  {"Online"}{" "}
                </Select.Option>
                <Select.Option
                  key={"advance_counter"}
                  value={"advance_counter"}
                >
                  {"Advance Counter"}{" "}
                </Select.Option>
              </Select>
            </Col>
          )}

          {assignType === "Batch" && (
            <>
              <Col span={7}>
                <TmsDatePickerRange
                  label="Select Batch Dates"
                  to={to}
                  from={from}
                  setFrom={setFrom}
                  setTo={setTo}
                />
              </Col>
              <Col span={4}>
                <Row className="cal-select">Quota</Row>
                <Row>
                  <Input
                    type="number"
                    defaultValue={quota}
                    value={quota}
                    style={{ width: "100%" }}
                    placeholder="Enter Quota"
                    onChange={(e) => {
                      setQuota(Number(e.target.value));
                    }}
                  ></Input>
                </Row>
              </Col>

              <Col span={3}>
                <Row className="cal-select">Submit</Row>
                <Row>
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    loading={quotaLoading}
                  >
                    Submit
                  </Button>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </div>
      <br />

      {assignType !== "Batch" && loading === false ? (
        <div className="tms-calender">
          <Calendar
            className="calender-inner"
            dateCellRender={dateCellRender}
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e);
            }}
          />
        </div>
      ) : (
        ""
      )}

      <Drawer
        centered={true}
        width="21%"
        closable={false}
        visible={showDrawer}
        title={"Edit Quota"}
        onOk={handleSubmit}
        footer={[
          <Button
            key="back"
            className="mr-10"
            onClick={() => {
              clearFunction();
            }}
          >
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={quotaLoading}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>,
        ]}
        onClose={() => {
          clearFunction();
        }}
      >
        <Row justify="center">
          <b>Date:</b>{" "}
        </Row>

        <Row justify="center">
          {moment(selectedDate ?? editvalue?.date).format("DD-MM-YYYY")}
        </Row>

        <br />
        <Row justify="center">
          <b>Previous Quota:</b>{" "}
        </Row>
        <Row justify="center">
          {" "}
          <span style={{ marginBottom: "10px" }}>
            {editvalue?.quantity ?? 0}
          </span>
        </Row>

        <Row justify="center" style={{ marginBottom: "10px" }}>
          <b>Quota:</b>{" "}
        </Row>
        <Row justify="center" style={{ marginBottom: "10px" }}>
          <b>
            Quota:{" "}
            {router.location?.pathname.includes("accommodation") &&
              `(Total Rooms - ${maxQuota?.totalCount})`}
          </b>{" "}
        </Row>
        <Row width="100%">
          <Input
            type="number"
            defaultValue={quota}
            value={quota}
            style={{ width: "100%", marginLeft: "10px" }}
            placeholder="Enter Quota"
            onChange={(e) => {
              setQuota(e.target.value);
            }}
          ></Input>
        </Row>
        <Row justify="center">
          Updated Quota:{" "}
          <b style={{ color: "green" }}>
            {Number(quota ?? 0) + Number(editvalue?.quantity ?? 0)}
          </b>
        </Row>
      </Drawer>
    </div>
  );
};

export default withRouter(TmsCalender);
