import { Badge, Popconfirm, Space } from "antd";
import moment from "moment";
import React from "react";
import tmsRouteMap from "./tms-route.map";

const tmsColumsFunction = (type, handleDelete, handleModify, router) => {
  const tmsCommonSlotsTableColumns = {
    "darshanam-counters": [
      { title: "id", dataIndex: "key", key: "key" },
      {
        title: "POS Name",
        dataIndex: "counter",
        render: (record) => {
          return record?.name;
        },
      },
      {
        title: "POS ID",
        dataIndex: "counterId",
        key: "counterId",
      },
      {
        title: "Max bookings (Day)",
        dataIndex: "maxCounterBookingsPerDay",
        key: "maxCounterBookingsPerDay",
      },
      {
        title: "Action",
        fixed: "right",
        responsive: ["md", "lg", "xl", "xsl"],
        render: (record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title="Are you sure delete this user?"
                onConfirm={async () => {
                  handleDelete(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a>Remove</a>
              </Popconfirm>{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleModify(record);
                }}
              >
                Edit
              </a>
            </Space>
          );
        },
      },
    ],
    "darshanam-slots": [
      { title: "id", dataIndex: "key", key: "key" },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Start Time",
        dataIndex: "startTime",
        key: "startTime",
        render: (record) => {
          return moment(record, "HH:mm:ss Z").format("hh:mm A");
        },
      },
      {
        title: "End Time",
        dataIndex: "endTime",
        key: "endTime",
        render: (record) => {
          return moment(record, "HH:mm:ss Z").format("hh:mm A");
        },
      },
      {
        title: "Status",
        dataIndex: "enabled",
        key: "enabled",
        render: (text) => {
          return !text ? <Badge color="red" /> : <Badge color="green" />;
        },
      },
      {
        title: "Max Online bookings (Day)",
        render: (record) => {
          return (
            <>
              {record?.maxOnlineBookingsPerDay} -{" "}
              <a
                onClick={() => {
                  router.navigate(
                    `${tmsRouteMap.smd_darshanam_manage_quota}?id=${record.productId}&slotid=${record.id}`
                  );
                }}
              >
                Manage Quota
              </a>
            </>
          );
        },
      },
      {
        title: "Action",
        fixed: "right",
        responsive: ["md", "lg", "xl", "xsl"],
        render: (record) => {
          return (
            <Space size="middle">
              <a
                onClick={() => {
                  router.navigate(
                    `${tmsRouteMap.smd_darshanam_manage_counters}?id=${record.productId}&slotid=${record.id}`
                  );
                }}
              >
                Manage Counters
              </a>
              {/* <Popconfirm
                title="Are you sure delete this user?"
                onConfirm={async () => {
                  handleDelete(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a>Remove</a>
              </Popconfirm>{" "} */}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleModify(record);
                }}
              >
                Edit
              </a>
            </Space>
          );
        },
      },
    ],
    "seva-counters": [
      { title: "id", dataIndex: "key", key: "key" },
      {
        title: "POS Name",
        dataIndex: "counter",
        render: (record) => {
          return record?.name;
        },
      },
      {
        title: "POS ID",
        dataIndex: "counterId",
        key: "counterId",
      },
      {
        title: "Max bookings (Day)",
        dataIndex: "maxCounterBookingsPerDay",
        key: "maxCounterBookingsPerDay",
      },
      {
        title: "Action",
        fixed: "right",
        responsive: ["md", "lg", "xl", "xsl"],
        render: (record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title="Are you sure delete this user?"
                onConfirm={async () => {
                  handleDelete(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a>Remove</a>
              </Popconfirm>{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleModify(record);
                }}
              >
                Edit
              </a>
            </Space>
          );
        },
      },
    ],
    "seva-slots": [
      { title: "id", dataIndex: "key", key: "key" },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Start Time",
        dataIndex: "startTime",
        key: "startTime",
        render: (record) => {
          return moment(record, "HH:mm:ss Z").format("hh:mm A");
        },
      },
      {
        title: "End Time",
        dataIndex: "endTime",
        key: "endTime",
        render: (record) => {
          return moment(record, "HH:mm:ss Z").format("hh:mm A");
        },
      },
      {
        title: "Status",
        dataIndex: "enabled",
        key: "enabled",
        render: (text) => {
          return !text ? <Badge color="red" /> : <Badge color="green" />;
        },
      },
      {
        title: "Max Online bookings (Day)",
        render: (record) => {
          return (
            <>
              {record?.maxOnlineBookingsPerDay} -{" "}
              <a
                onClick={() => {
                  router.navigate(
                    `${tmsRouteMap.sms_sevas_manage_quota}?id=${record.productId}&slotid=${record.id}`
                  );
                }}
              >
                Manage Quota
              </a>
            </>
          );
        },
      },
      {
        title: "Action",
        fixed: "right",
        responsive: ["md", "lg", "xl", "xsl"],
        render: (record) => {
          return (
            <Space size="middle">
              <a
                onClick={() => {
                  router.navigate(
                    `${tmsRouteMap.sms_sevas_manage_counters}?id=${record.productId}&slotid=${record.id}`
                  );
                }}
              >
                Manage Counters
              </a>
              {/* <Popconfirm
                title="Are you sure delete this user?"
                onConfirm={async () => {
                  handleDelete(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a>Remove</a>
              </Popconfirm>{" "} */}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleModify(record);
                }}
              >
                Edit
              </a>
            </Space>
          );
        },
      },
    ],
    "donation-counters": [
      { title: "id", dataIndex: "key", key: "key" },
      {
        title: "POS Name",
        dataIndex: "counter",
        render: (record) => {
          return record?.name;
        },
      },
      {
        title: "POS ID",
        dataIndex: "counterId",
        key: "counterId",
      },
      {
        title: "Action",
        fixed: "right",
        responsive: ["md", "lg", "xl", "xsl"],
        render: (record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title="Are you sure delete this user?"
                onConfirm={async () => {
                  handleDelete(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a>Remove</a>
              </Popconfirm>{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleModify(record);
                }}
              >
                Edit
              </a>
            </Space>
          );
        },
      },
    ],
    "prasadam-counters": [
      { title: "id", dataIndex: "key", key: "key" },
      {
        title: "Prasadam Name",
        dataIndex: "counter",
        render: (record) => {
          return record?.name;
        },
      },
      {
        title: "POS ID",
        dataIndex: "counterId",
        key: "counterId",
      },
      {
        title: "Action",
        fixed: "right",
        responsive: ["md", "lg", "xl", "xsl"],
        render: (record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title="Are you sure delete this user?"
                onConfirm={async () => {
                  handleDelete(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a>Remove</a>
              </Popconfirm>{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleModify(record);
                }}
              >
                Edit
              </a>
            </Space>
          );
        },
      },
    ],
    "thulabharam-counters": [
      { title: "id", dataIndex: "key", key: "key" },
      {
        title: "Thulabharam Name",
        dataIndex: "counter",
        render: (record) => {
          return record?.name;
        },
      },
      {
        title: "POS ID",
        dataIndex: "counterId",
        key: "counterId",
      },
      {
        title: "Action",
        fixed: "right",
        responsive: ["md", "lg", "xl", "xsl"],
        render: (record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title="Are you sure delete this user?"
                onConfirm={async () => {
                  handleDelete(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a>Remove</a>
              </Popconfirm>{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleModify(record);
                }}
              >
                Edit
              </a>
            </Space>
          );
        },
      },
    ],
    "tollgate-counters": [
      { title: "id", dataIndex: "key", key: "key" },
      {
        title: "Toll Name",
        dataIndex: "counter",
        render: (record) => {
          return record?.name;
        },
      },
      {
        title: "POS ID",
        dataIndex: "counterId",
        key: "counterId",
      },
      {
        title: "Action",
        fixed: "right",
        responsive: ["md", "lg", "xl", "xsl"],
        render: (record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title="Are you sure delete this user?"
                onConfirm={async () => {
                  handleDelete(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a>Remove</a>
              </Popconfirm>{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleModify(record);
                }}
              >
                Edit
              </a>
            </Space>
          );
        },
      },
    ],
    "manage-acc-pos": [
      { title: "id", dataIndex: "key", key: "key" },
      {
        title: "POS Name",
        dataIndex: "counter",
        render: (record) => {
          return record?.name;
        },
      },
      {
        title: "POS ID",
        dataIndex: "counterId",
        key: "counterId",
      },

      {
        title: "Action",
        fixed: "right",
        responsive: ["md", "lg", "xl", "xsl"],
        render: (record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title="Are you sure delete this counter?"
                onConfirm={async () => {
                  handleDelete(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a>Remove</a>
              </Popconfirm>{" "}
            </Space>
          );
        },
      },
    ],
    "kalyankatta-tonsure": [
      { title: "id", dataIndex: "key", key: "key" },
      {
        title: "Kalyanakatta Name",
        dataIndex: "counter",
        render: (record) => {
          return record?.name;
        },
      },
      {
        title: "POS ID",
        dataIndex: "counterId",
        key: "counterId",
      },
      {
        title: "Action",
        fixed: "right",
        responsive: ["md", "lg", "xl", "xsl"],
        render: (record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title="Are you sure delete this counter?"
                onConfirm={async () => {
                  handleDelete(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a>Remove</a>
              </Popconfirm>{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleModify(record);
                }}
              >
                Edit
              </a>
            </Space>
          );
        },
      },
    ],
    "products-counters": [
      { title: "id", dataIndex: "key", key: "key" },
      {
        title: "Product Name",
        dataIndex: "counter",
        render: (record) => {
          return record?.name;
        },
      },
      {
        title: "POS ID",
        dataIndex: "counterId",
        key: "counterId",
      },
      {
        title: "Action",
        fixed: "right",
        responsive: ["md", "lg", "xl", "xsl"],
        render: (record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title="Are you sure delete this user?"
                onConfirm={async () => {
                  handleDelete(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a>Remove</a>
              </Popconfirm>{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleModify(record);
                }}
              >
                Edit
              </a>
            </Space>
          );
        },
      },
    ],
  };
  return tmsCommonSlotsTableColumns[type];
};

export default tmsColumsFunction;
