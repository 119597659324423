import React, { useState } from "react";
import TmsLoginStepOne from "../../components/tms-login/tms-login-step-one";
import TmsLoginStepTwo from "../../components/tms-login/tms-login-step-two";
import "../../assets/css/tms-login/tms-login.scss";
import { tmsLogo } from "../../assets/images/tms-image-list";
import TmsCarousel from "../../components/tms-customs/tms-carousel";
import TmsLoginStepThree from "../../components/tms-login/tms-login-step-three";

const TmsLogin = () => {
  const [page, setPage] = useState(1);
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");

  const getLoginPage = () => {
    switch (page) {
      case 1:
        return (
          <TmsLoginStepOne
            userName={userName}
            setUsername={setUsername}
            setPassword={setPassword}
            password={password}
            setPage={setPage}
          />
        );
      case 2:
        return (
          <TmsLoginStepTwo
            setPage={setPage}
            setPhone={setPhone}
            phone={phone}
          />
        );
      case 3:
        return (
          <TmsLoginStepThree
            setPage={setPage}
            phone={phone}
            setPhone={setPhone}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="tms-login-outer-wrapper">
      <div className="tms-login-left">
        <TmsCarousel />
      </div>
      <div className="tms-login-right">
        <div className="tms-login-top">
          {" "}
          <img src={tmsLogo} className="tms-login-logo" />
          <h2 className="tms-login-h2">Devasthanam Admin Panel</h2>
          {getLoginPage()}
        </div>
        <div className="tms-reserved">
          <p className="tms-r-p">
            © {new Date().getFullYear()} All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};
export default TmsLogin;
