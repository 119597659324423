import React, { useState, useContext } from "react";
import QRCode from "qrcode.react";
import { tmsLogo } from "../../../assets/images/tms-image-list";
import { Divider } from "antd";
import moment from "moment";
import { BaseContext } from "../../../contexts/BaseContext";

const DarshanamTicket = ({ from, data, type }) => {
  const { userDetails, shiftData } = useContext(BaseContext);
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );
  return (
    <>
      <div>
        {data?.map((item) => {
          return (
            <div key={item[0] + "table"}>
              {(() => {
                if (item[0] === "header") {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <b style={{ margin: "0" }}>{`TI No : ${item[1]}`}</b>

                        <b style={{ margin: "0" }}>{`S No : ${item[3]}`}</b>
                      </div>
                      <div style={{ textAlign: "center", fontSize: "14px" }}>
                        <div>
                          <img
                            alt="logo"
                            style={{
                              width: "20%",
                              justifyContent: "center",
                              margin: "auto",
                              filter: "grayscale(1)",
                            }}
                            src={
                              templeDetails?.logo
                                ? templeDetails?.logo
                                : tmsLogo
                            }
                          />
                        </div>
                        <div
                          style={{
                            justifyContent: "center",
                            margin: "0",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          <p>{templeDetails?.name}</p>
                        </div>
                        {type && (
                          <div
                            style={{
                              justifyContent: "center",
                              margin: "0",
                              fontWeight: "bold",
                              textAlign: "center",
                              textTransform: "capitalize",
                            }}
                          >
                            <p style={{ margin: "0" }}>
                              {type} {from}
                            </p>
                          </div>
                        )}
                        <div
                          style={{
                            display: `${
                              item[5] === "null" || item[5] === "" || !item[5]
                                ? "none"
                                : ""
                            }`,
                            width: "5%",
                            margin: "auto",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "17px",
                              textAlign: "center",
                              fontWeight: "bold",
                              height: "38px",
                              width: "38px",
                              alignItems: "center",
                              display: "grid",
                              border: "2px solid black",
                              borderRadius: "20px",
                              margin: "0 0 3px",
                            }}
                          >
                            {item[5]}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                }
              })()}

              <div
                style={{
                  display: `${
                    item[1] === "null" ||
                    item[0] === "header" ||
                    item[0] === "footer"
                      ? "none"
                      : "flex"
                  }`,
                }}
              >
                <div
                  style={{
                    width: "40%",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  <p style={{ margin: "0" }}>{item[0]}</p>
                </div>
                <div
                  style={{
                    width: "60%",
                    overflowWrap: "break-word",
                    textTransform: "capitalize",
                    display: "inline-flex",
                  }}
                >
                  <p style={{ paddingRight: "10px", margin: "0" }}>
                    {item[0] !== "" && ":"}
                  </p>
                  <p style={item[2]}>{item[1]}</p>
                </div>
              </div>
              {(() => {
                if (item[0] === "footer") {
                  return (
                    <>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        <QRCode
                          size="75"
                          fontSize="15px"
                          value={item[1] ? item[1] : "transactionId=127"}
                        />
                      </div>
                      <Divider dashed />

                      <div
                        style={{
                          fontSize: "14px",
                          paddingBottom: "10px",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "100%", fontWeight: "bold" }}>
                          Note :{" "}
                        </div>
                        <div style={{ width: "100%" }}>
                          <p>
                            1.Sevadaris Must wear traditional clothes. Pancha &
                            Kanduva for male and saree for female.No other wear
                            is allowed.
                          </p>
                          <p>
                            2. Kindly carry your Aadhar card Xerox or Original
                            in the Darshanam Queue.
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "grid",
                          fontSize: "12px",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ width: "100%", display: "inline-flex" }}>
                          <p style={{ marginRight: "10px", marginBottom: "0" }}>
                            User :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              textTransform: "capitalize",
                              marginBottom: "0",
                            }}
                          >
                            {userDetails?.user?.displayName ??
                              userDetails?.user?.phone}
                          </p>
                          <p style={{ marginRight: "10px", marginBottom: "0" }}>
                            POS :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              textTransform: "capitalize",
                              marginBottom: "0",
                            }}
                          >
                            {shiftData?.counter?.name}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          justifyContent: "center",
                          margin: "auto",
                          width: "100%",
                          display: "flex",
                          fontSize: "12px",
                        }}
                      >
                        <p style={{ marginRight: "10px" }}>Time :</p>
                        <p style={{ fontWeight: "bold" }}>
                          {moment()
                            .utcOffset("+05:30")
                            .format("DD-MM-YYYY hh:mm:ss A")}
                        </p>
                      </div>
                    </>
                  );
                }
              })()}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DarshanamTicket;
