import { Button, Col, Row } from "antd";
import React from "react";
import { DownloadOutlined } from "@ant-design/icons";

const TmsExcelButton = ({ loading, handleExcelExport, top }) => {
  return (
    <Col>
      {!top && (
        <Button loading={loading} type="primary" onClick={handleExcelExport}>
          <DownloadOutlined /> Excel
        </Button>
      )}
      {top && (
        <>
          <Row
            justify="start"
            style={{ marginBottom: "5px", color: "transparent" }}
          >
            .
          </Row>
          <Row justify="start">
            <Button
              loading={loading}
              type="primary"
              onClick={handleExcelExport}
            >
              <DownloadOutlined /> Excel
            </Button>
          </Row>
        </>
      )}
    </Col>
  );
};
export default TmsExcelButton;
