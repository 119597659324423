import { Button, Card, Checkbox, Col, Input, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import catchErrorFunction from "../../../../../../../utils/tms-catch-error/tms-catch-error";
import TmsAccommodationService from "../../../../../../../services/tms-accommodation.service";
import TextArea from "antd/lib/input/TextArea";

const TmsAccIpMcrManageRoomAddNew = ({
  router,
  editFlag,
  roomId,
  setEditFlag,
  viewAccommodationData,
  settmsModalVisible,
}) => {
  const [loading, setLoading] = useState(false);
  const [roomNumber, setRoomNumber] = useState("");
  const [description, setDescription] = useState("");
  const [enabled, setEnabled] = useState(false);

  const handleSumit = async () => {
    setLoading(true);
    if (roomNumber === null || roomNumber === "" || description === "") {
      notification.error({ message: "Fill all the fields" });
      setLoading(false);

      return;
    }
    let body = {
      accBlockCategoryId: Number(
        new URLSearchParams(router?.location.search).get("categoryid")
      ),
      roomNumber: roomNumber,
      description: description,
      enabled: enabled,
    };
    try {
      if (editFlag) {
        await TmsAccommodationService.editBlockCategoryRoom(roomId, body);
        notification.success({
          message: "Accommodation Block Category Edited Successful",
        });
        setEditFlag(false);
      } else {
        await TmsAccommodationService.createNewBlockCategoryRoom(body);
        notification.success({
          message: "Accommodation Block Category Successful",
        });
        clearFunction();
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
    setLoading(false);
  };

  const clearFunction = () => {
    setRoomNumber("");
    setDescription("");
    setEnabled("");
  };

  useEffect(() => {
    if (editFlag) {
      setRoomNumber(viewAccommodationData.roomNumber);
      setEnabled(viewAccommodationData.enabled);
      setDescription(viewAccommodationData.description);
    }
  }, [viewAccommodationData]);

  return (
    <Row>
      <Card style={{ minWidth: "700px" }}>
        <Row className="formRow">
          <label className="formLabel">Room Number</label>
          <Input
            className="formInput"
            type="text"
            onKeyDown={(e) =>
              ["+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            onChange={(e) => setRoomNumber(e.target.value)}
            value={roomNumber}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel">Description</label>
          <TextArea
            className="formInput"
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Row>
        <Row className="formRow ml-20" justify="center">
          <label style={{ paddingRight: "10px" }}>Open for Bookings </label>
          <Checkbox
            style={{ paddingRight: "10px" }}
            checked={enabled}
            onChange={(e) => setEnabled(e.target.checked)}
          />
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              ghost
              loading={loading}
              onClick={() => {
                editFlag ? settmsModalVisible(false) : clearFunction();
              }}
            >
              Reset
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              loading={loading}
              onClick={() => {
                handleSumit();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsAccIpMcrManageRoomAddNew);
