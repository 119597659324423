import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Divider,
  Modal,
  Card,
  Radio,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Select } from "antd";
import moment from "moment";
import { FormatNumberINRCurrency } from "../../../utils/tms-currency-formatter/tms-curreny-formatter";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsSevasService from "../../../services/tms-sevas.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import TmsStringUtils from "../../../utils/tms-string-utils/tms-string-utils";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import { useContext } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import TmsCalenderTithi from "../../../components/tms-customs/tms-calender-tithi";
import TmsCalenderMasam from "../../../components/tms-customs/tms-calender-masam";
const { Option } = Select;
const TmsPosPratyakshaSeva = ({ router }) => {
  const { shiftData } = useContext(BaseContext);
  let temple = JSON.parse(localStorage.getItem("templeDetails"));
  const [cashRemarks, setCashRemarks] = useState("");
  const [externalPrint, setExternalPrint] = useState(false);
  const [price, setPrice] = useState();
  const ref = useRef(null);
  const [sevaList, setSevaList] = useState([]);
  const [slot, setSlot] = useState("");
  const [slotLoading, setSlotLoading] = useState(false);
  const [slotsData, setSlotsData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [phone, setPhone] = useState("");
  const [gothram, setGothram] = useState("");
  const [age, setAge] = useState("");
  const [address, setAddress] = useState("");
  const [payment, setPayment] = useState("CASH");
  const [idProofType, setIdProofType] = useState("");
  const [idProofNumber, setIdProofNumber] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cashReference, setCashReference] = useState("");
  const labelStyles = { float: "left", fontSize: "17px", marginBottom: "5px" };
  const [devoteeName, setDevoteeName] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [sevaType, setSevaType] = useState("pratyakshaSeva");
  const [selectedSevaId, setselectedSevaId] = useState();
  const [array, setArray] = useState([]);
  const [noOfPeople, setNoOfPeople] = useState(1);
  const [ticketType, setTicketType] = useState();
  const [calenderType, setCalenderType] = useState("english");
  const [saswathaTithi, setSaswathaTithi] = useState("");
  const [saswathaPaksham, setSaswathaPaksham] = useState("");
  const [saswathaMasam, setSaswathaMasam] = useState("");

  const formatSevaData = (data) => {
    let schemaBody = [];
    data?.cartItems?.seva?.map((item) => {
      setTicketType(item?.productDetails?.productMetadata?.ticketType);
      const generalBody = [
        [
          "Calender",
          item?.sevaBooking?.calendarType ?? "null",
          { margin: "0" },
        ],
        [
          item?.productDetails?.sevaType === "saswataSeva"
            ? "Booked On"
            : "Date",
          item?.productDetails?.sevaType === "saswataSeva"
            ? moment(item?.sevaBooking?.createdAt).format("DD-MM-YYYY")
            : moment(item?.sevaBooking?.bookingDate).format("DD-MM-YYYY"),
          { margin: "0" },
        ],
        [
          "Performace Date",
          item?.productDetails?.sevaType === "saswataSeva"
            ? item?.sevaBooking?.calendarType === "telugu"
              ? `${item?.sevaBooking?.masam} ${item?.sevaBooking?.paksham} ${item?.sevaBooking?.tithi}`
              : moment(item?.sevaBooking?.bookingDate).format("DD-MM-YYYY")
            : "null",
          { margin: "0" },
        ],
        [
          "Time Slot",
          `${moment(
            item?.productDetails?.productSlotStartTime,
            "HH:mm:ss"
          ).format("hh:mm A")} -
        ${moment(item?.productDetails?.productSlotEndTime, "HH:mm:ss").format(
          "hh:mm A"
        )}`,
          { margin: "0" },
        ],
        [
          "Devotee Name",
          item?.sevaBooking?.devoteeName
            ? item?.sevaBooking?.contact
              ? `${item?.sevaBooking?.devoteeName?.split(",")[0].trim()} | ${
                  item?.sevaBooking?.contact
                }`
              : item?.sevaBooking?.devoteeName?.split(",")[0].trim()
            : item?.sevaBooking?.contact ?? "NA",
          { margin: "0" },
        ],
        [
          "ID Proof No",
          item?.sevaBooking?.idProofNumber
            ?.toString()
            ?.replace(/.(?=.{4,}$)/g, "X"),
          { margin: "0" },
        ],
        ["Gothram", item?.sevaBooking?.gothram ?? "null", { margin: "0" }],
        [
          "No of People",
          ["SBMSD"].includes(temple?.shortName)
            ? item?.productDetails?.productNoOfPeople === 1
              ? "Single"
              : item?.productDetails?.productNoOfPeople === 2
              ? "Husband & Wife"
              : item?.productDetails?.productNoOfPeople
            : item?.productDetails?.productNoOfPeople,

          { margin: "0" },
        ],
        [
          "Ticket Price",
          item?.productDetails?.productName === "AC vratam 2000" &&
          JSON.parse(localStorage.getItem("templeDetails"))?.shortName ===
            "SVVSSD"
            ? `Rs. 1500.00 + 500.00 (A/C Hall)`
            : `Rs. ${item?.productDetails?.productActualPrice}.00`,
          { margin: "0" },
        ],
        [
          "Total Amount",
          `Rs. ${item?.sevaBooking?.price}.00 `,
          { margin: "0", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(item?.sevaBooking?.price)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          item?.productDetails?.productName,
          `<p style="margin:0">TI No:${item?.counterCartItem?.categoryTransactionSequence
            ?.split("-")
            ?.pop()}</p><p style="margin:0">S No:${item?.counterCartItem?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop()}</p><p style="margin:0">${moment(
            item?.sevaBooking?.bookingDate
          ).format(
            "DD-MM-YYYY"
          )}</p><p style="font-weight:bold;font-size:22px;margin:0">Rs. ${
            item?.sevaBooking?.price
          }.00</p> `,
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.counterCartItem?.createdAt,
        ],
      ];
      const miniTicketBody = [
        [
          "Amount",
          `Rs. ${item?.sevaBooking?.price}.00 `,
          { margin: "0", fontWeight: "800" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          null,
          item?.counterCartItem?.createdAt,
        ],
      ];
      const basicBody = [
        [
          "header",
          item?.counterCartItem?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.counterCartItem?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop(),
          item?.counterCartItem?.ticketId,
          null,
        ],
        [
          "Ticket Id",
          item?.productDetails?.productMetadata?.ticketType === "mini_ticket"
            ? item?.counterCartItem?.ticketId?.split("-")?.pop()
            : item?.counterCartItem?.ticketId,
          { margin: "0" },
        ],
        [
          "Seva",
          item?.productDetails?.productName,
          { margin: "0", fontWeight: "bold" },
        ],
      ];
      if (item?.productDetails?.productMetadata?.ticketType === "mini_ticket") {
        schemaBody = [...schemaBody, ...basicBody, ...miniTicketBody];
      } else {
        schemaBody = [...schemaBody, ...basicBody, ...generalBody];
      }
    });
    setArray([...schemaBody]);
  };

  useEffect(() => {
    if (selectedSevaId)
      getSlots(selectedSevaId, moment(new Date()).format("YYYY-MM-DD"));
  }, [sevaType, selectedSevaId]);

  useEffect(() => {
    if (sevaType && shiftData?.counterId) getSevasList();
  }, [sevaType, shiftData]);

  useEffect(() => {
    setStartDate("");
    if (sevaType || selectedSevaId) setStartDate(moment(new Date()));
  }, []);

  const getSevasList = async () => {
    setButtonLoading(true);
    try {
      const data = await TmsSevasService.getSevasByCounter(
        shiftData?.counterId
      );

      setSevaList(
        data.filter((item) => item.counterEnabled && item.sevaType === sevaType)
      );
      if (data?.length === 1) {
        setselectedSevaId(data[0]?.id);
      } else {
        setselectedSevaId("");
      }
      setSlotsData([]);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setButtonLoading(false);
  };

  const getSlots = async (sevaId, date) => {
    setSlotLoading(true);
    try {
      setSlot("");
      setSlotsData([]);
      let res = await TmsSevasService.fetchSevaBookingSlots(sevaId, date);

      // console.log(res);
      setSlotsData(
        res
          ?.sort((a, b) => a.startTime.localeCompare(b.startTime))
          ?.map((item) => {
            return {
              key: item.productSlotId,
              ...item,
            };
          })
      );
      if (res?.length > 0) {
        setSlot(res[0]?.productSlotId);
        setPrice(res[0]?.product?.price);
        setTimeout(() => {
          focus();
        }, 300);
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );

      setSlotLoading(false);
      setSlot("");
      setSlotsData([]);
    }
    setSlotLoading(false);
  };
  const focus = () => {
    try {
      ref?.current?.focus?.();
      ref?.current?.focus?.();
    } catch (e) {
      //
    }
  };
  const getSlotsDetails = () => {
    return slotsData?.map((item) => {
      return (
        <Col
          style={{
            float: "left",
          }}
          key={item?.key}
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={6}
          xsl={6}
        >
          <Card
            hoverable
            onClick={() => {
              setSlot(item?.productSlotId);
              setPrice(item?.product?.price);
              focus();
            }}
          >
            <Row>
              <Col style={{ width: "80%" }}>
                <Row
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >{`${moment(item?.startTime, "HH:mm:ss").format(
                  "hh:mm A"
                )} - ${moment(item?.endTime, "HH:mm:ss").format(
                  "hh:mm A"
                )}`}</Row>
                <Row
                  style={{ fontWeight: "600" }}
                >{`Available Tickets - ${item?.availableBookingCount}`}</Row>
              </Col>
              <Col style={{ width: "20%", textAlign: "right" }}>
                <Radio
                  defaultChecked={false}
                  value={item?.productSlotId}
                  checked={item?.productSlotId === slot ? true : false}
                  id={item?.productSlotId}
                  onClick={() => {
                    if (1 > item?.availableBookingCount) {
                      return;
                    }
                    setSlot(item);
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      );
    });
  };

  const handleSubmit = async () => {
    if (buttonLoading) {
      return;
    }
    setButtonLoading(true);
    if (noOfPeople === "") {
      notification.info({
        message: "No of people cannot be empty!!",
      });
      setButtonLoading(false);
      return;
    }
    if (slot === "" || !slot) {
      let error = "Please enter valid ";
      slot === "" || !slot ? (error += "slot") : "";
      notification.error({ message: "Enter a name" });
      setButtonLoading(false);
      return;
    }

    if (phone !== "") {
      console.log(phone);
      if (phone.length !== 10) {
        notification.error({ message: "Phone number must be 10 digits" });
        setButtonLoading(false);
        return;
      }
    }

    if (idProofType !== "") {
      if (idProofType === "pan") {
        let txt = idProofNumber.toUpperCase();
        let regex = /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
        let pan = {
          C: "Company",
          P: "Personal",
          H: "Hindu Undivided Family (HUF)",
          F: "Firm",
          A: "Association of Persons (AOP)",
          T: "AOP (Trust)",
          B: "Body of Individuals (BOI)",
          L: "Local Authority",
          J: "Artificial Juridical Person",
          G: "Govt",
        };
        pan = pan[txt[3]];
        if (regex.test(txt)) {
          if (pan !== "Personal") {
            notification.error({ message: "Invalid Pan Card Number." });
            setButtonLoading(false);
            return;
          }
        } else {
          notification.error({ message: "Invalid Pan Card Number." });
          setButtonLoading(false);
          return;
        }
      } else if (idProofType === "voter") {
        let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
        if (!regex.test(idProofNumber.toUpperCase())) {
          notification.error({ message: "Invalid Voter Card Number." });
          setButtonLoading(false);
          return;
        }
      } else if (idProofType === "aadhar") {
        let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
        if (!regex.test(idProofNumber.toUpperCase())) {
          notification.error({ message: "Invalid Aadhar Card Number." });
          setButtonLoading(false);
          return;
        }
      }
    }
    if (sevaType === "saswataSeva") {
      if (calenderType === "telugu") {
        if (
          saswathaMasam === "" ||
          saswathaPaksham === "" ||
          saswathaTithi === ""
        ) {
          notification.error({
            message:
              "Masam, Tithi , Paksham must be entered for saswatha seva when you select telugu calender",
          });
          setButtonLoading(false);
          return;
        }
      }
    }
    try {
      let innerBody = {
        sevaSlotId: Number(slot),
        bookingDate: moment(startDate).format("YYYY-MM-DD"),
      };
      if (devoteeName !== "") {
        innerBody.devoteeName = devoteeName;
      }
      if (phone !== "") {
        innerBody.contact = `+91${phone}`;
      }
      if (idProofType !== "") {
        if (idProofNumber !== "") innerBody.idProofNumber = idProofNumber;
        innerBody.idProofType = idProofType;
      }

      if (address !== "") {
        innerBody.address = address;
      }
      if (age !== "") {
        innerBody.dob = moment()?.subtract(age, "years")?.format("YYYY-MM-DD");
      }
      if (gothram !== "") {
        innerBody.gothram = gothram;
      }
      let multiple = [];
      for (let i = 1; i <= noOfPeople; i++) {
        multiple.push(innerBody);
      }
      if (sevaType === "saswataSeva") {
        if (calenderType !== "english") {
          innerBody.masam = saswathaMasam;
          innerBody.paksham = saswathaPaksham;
          innerBody.tithi = saswathaTithi;
          innerBody.calendarType = "telugu";
        } else {
          innerBody.calendarType = "english";
        }
      }
      let body = {
        sevaBookings: multiple,
        paymentType: payment,
        multiTicketsLinked: false,
      };
      if (cashReference !== "") {
        body.cashReference = cashReference;
      }
      if (cashRemarks !== "") {
        body.cashRemarks = cashRemarks;
      }

      let res = await TmsSevasService.sevasBooking(body);
      notification.success({ message: "Success" });
      formatSevaData(res);
      // clearFields();
      setIsModalVisible(true);
      setExternalPrint(true);
      setNoOfPeople("");
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setButtonLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "white",
          padding: "30px 50px",
          textAlign: "left",
        }}
      >
        <Row justify="space-between">
          <Col style={{ width: "22%", justifyContent: "start" }}>
            <label style={labelStyles}>
              Seva
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <br />
            <Select
              size="medium"
              style={{ width: "100%" }}
              showSearch
              placeholder="Select a Seva Type"
              onChange={(e) => {
                setSevaType(e);
              }}
              value={sevaType}
            >
              <Select.Option value="pratyakshaSeva">
                Pratyaksha Seva
              </Select.Option>
              <Select.Option value="parokshaSeva">Paroksha Seva</Select.Option>
              <Select.Option value="saswataSeva">Saswata Seva</Select.Option>
            </Select>
          </Col>
          <Col style={{ width: "30%", justifyContent: "start" }}>
            <label style={labelStyles}>
              Seva Name
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <br />
            <Select
              size="medium"
              style={{ width: "100%" }}
              showSearch
              placeholder="Select a Seva Name"
              optionFilterProp="children"
              value={selectedSevaId}
              onChange={(e) => {
                let temp = sevaList?.find((a) => a.id === e);
                setselectedSevaId(temp?.id);
                setSlotsData([]);
                setSlot("");
                setStartDate(moment(new Date()));
              }}
            >
              {sevaList?.map((item, i) => {
                if (item.counterEnabled) {
                  return (
                    <Select.Option
                      key={item.name + "seva-list" + i}
                      value={item.id}
                    >
                      {item.name} - Rs.
                      {item?.price}
                    </Select.Option>
                  );
                }
              })}
            </Select>
          </Col>
          <Col
            style={{
              width: "22%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col style={{ width: "100%", justifyContent: "start" }}>
              <label style={labelStyles}>
                Booking Date
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />
              <DatePicker
                value={startDate}
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                disabledDate={(d) =>
                  !d || d.isBefore(TmsStringUtils.addDays(new Date(), -1))
                }
                onChange={(date) => {
                  if (selectedSevaId === "") {
                    notification.error({
                      message: "Please select a Seva before selecting a date",
                    });
                    return;
                  }
                  getSlots(selectedSevaId, moment(date).format("YYYY-MM-DD"));
                  setStartDate(date);
                }}
              />
            </Col>
          </Col>

          <Col
            style={{
              width: "22%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col style={{ width: "100%", justifyContent: "start" }}>
              <label style={labelStyles}>
                No of tickets
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />
              <Input
                style={{ width: "100%" }}
                onWheel={(event) => event.currentTarget.blur()}
                type="number"
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault();
                }}
                min="0"
                max="5"
                maxLength="1"
                key="edit"
                placeholder="0"
                value={noOfPeople}
                controls={false}
                step="false"
                onChange={(event) => {
                  if (event.target.value > 5) {
                    return;
                  } else {
                    setNoOfPeople(event.target.value);
                  }
                }}
              />
            </Col>
          </Col>
        </Row>
        <br />
        <Divider />
        {slotsData?.length !== 0 && (
          <div style={{ position: "relative" }}>
            <Row
              gutter={[20, 20]}
              style={{ background: "#EFEFEF", padding: "20px" }}
            >
              {getSlotsDetails()}
            </Row>
          </div>
        )}

        <br />
        <Row style={{ display: "flex", flexDirection: "column" }}>
          {slotsData?.length !== 0 ? (
            <>
              {slot !== "" && (
                <>
                  <Row justify="space-between">
                    <Col style={{ width: "48%", justifyContent: "start" }}>
                      <label style={labelStyles}>Select ID Proof </label>
                      <br />
                      <Select
                        size="medium"
                        style={{ width: "100%" }}
                        placeholder="Select a ID"
                        optionFilterProp="children"
                        value={idProofType}
                        onChange={(e) => {
                          setIdProofType(e);
                          setIdProofNumber("");
                        }}
                      >
                        {/* <Select.Option key="pan" value="pan">
                          PAN
                        </Select.Option> */}
                        <Select.Option key="aadhar" value="aadhar">
                          AADHAR
                        </Select.Option>
                        {/* <Select.Option key="voter" value="voter">
                          Voter ID
                        </Select.Option> */}
                      </Select>
                    </Col>
                    <Col style={{ width: "48%" }}>
                      <label style={labelStyles}>Enter ID Proof </label>
                      <br />
                      <Input
                        size="medium"
                        type="text"
                        placeholder="Enter ID Proof"
                        style={{ width: "100%" }}
                        value={idProofNumber}
                        onChange={(e) => {
                          setIdProofNumber(`${e.target.value?.toUpperCase()}`);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row justify="space-between">
                    <Col style={{ width: "48%", justifyContent: "start" }}>
                      <label style={labelStyles}>Devotee Name</label>
                      <br />
                      <Input
                        type="text"
                        placeholder="Enter a Name"
                        value={devoteeName}
                        onChange={(e) => {
                          setDevoteeName(e.target.value);
                        }}
                      />
                    </Col>
                    <Col style={{ width: "48%" }}>
                      <label style={labelStyles}>Mobile Number</label>
                      <br />
                      <Input
                        size="medium"
                        type="number"
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-", "."].includes(e.key) &&
                          e.preventDefault()
                        }
                        onWheel={(event) => event.currentTarget.blur()}
                        maxLength={10}
                        addonBefore={"+91"}
                        placeholder="Please Enter Mobile Number"
                        style={{ width: "100%" }}
                        value={phone}
                        onChange={(e) => {
                          if (e.target.value.length > 10) {
                            return;
                          }
                          setPhone(`${e.target.value}`);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row justify="space-between">
                    <Col style={{ width: "48%", justifyContent: "start" }}>
                      <label style={labelStyles}>Age </label>
                      <br />
                      <Input
                        size="medium"
                        type="number"
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-", "."].includes(e.key) &&
                          e.preventDefault()
                        }
                        onWheel={(event) => event.currentTarget.blur()}
                        maxLength={3}
                        placeholder="Please Enter Age"
                        style={{ width: "100%" }}
                        value={age}
                        onChange={(e) => {
                          if (e.target.value > 120) {
                            return;
                          }
                          setAge(`${e.target.value}`);
                        }}
                      />
                    </Col>
                    <Col style={{ width: "48%" }}>
                      <label style={labelStyles}>Gothram</label>
                      <br />
                      <Input
                        type="text"
                        placeholder="Enter a Gothram"
                        value={gothram}
                        onChange={(e) => {
                          setGothram(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  {sevaType === "saswataSeva" && (
                    <>
                      <Row justify="space-between">
                        <Col style={{ width: "48%", justifyContent: "start" }}>
                          <label style={labelStyles}>Calender Type </label>
                          <br />
                          <Select
                            size="medium"
                            style={{ width: "100%" }}
                            placeholder="Select Calender type"
                            optionFilterProp="children"
                            value={calenderType}
                            onChange={(e) => {
                              setCalenderType(e);
                            }}
                          >
                            <Select.Option key="telugu" value="telugu">
                              Telugu
                            </Select.Option>
                            <Select.Option key="english" value="english">
                              English
                            </Select.Option>
                          </Select>
                        </Col>
                        {calenderType === "telugu" && (
                          <TmsCalenderMasam
                            masam={saswathaMasam}
                            setMasam={setSaswathaMasam}
                            labelStyles={labelStyles}
                            percentage={"48%"}
                          />
                        )}
                      </Row>
                      {calenderType === "telugu" && (
                        <>
                          <Row justify="space-between">
                            <Col
                              style={{ width: "48%", justifyContent: "start" }}
                            >
                              <label style={labelStyles}>
                                Paksham
                                <span
                                  style={{ color: "red", marginLeft: "3px" }}
                                >
                                  *
                                </span>
                              </label>
                              <br />
                              <Select
                                size="medium"
                                style={{ width: "100%" }}
                                placeholder="Select a Paksham"
                                optionFilterProp="children"
                                value={saswathaPaksham}
                                onChange={(e) => {
                                  setSaswathaPaksham(e);
                                }}
                              >
                                <Option key={"Shukla"} value={"Shukla"}>
                                  Shukla
                                </Option>
                                <Option key={"Krishna"} value={"Krishna"}>
                                  Krishna
                                </Option>
                              </Select>
                            </Col>

                            <TmsCalenderTithi
                              labelStyles={labelStyles}
                              paksham={saswathaPaksham}
                              tithi={saswathaTithi}
                              setTithi={setSaswathaTithi}
                            />
                          </Row>
                          <br />
                        </>
                      )}
                    </>
                  )}{" "}
                  <Row justify="space-between">
                    <Col style={{ width: "48%", justifyContent: "start" }}>
                      <label style={labelStyles}>Address </label>
                      <br />
                      <Input
                        size="medium"
                        type="text"
                        placeholder="Please Enter Address"
                        style={{ width: "100%" }}
                        value={address}
                        onKeyDown={(e) =>
                          ["#", "+"].includes(e.key) && e.preventDefault()
                        }
                        onChange={(e) => {
                          setAddress(`${e.target.value}`);
                        }}
                      />
                    </Col>
                    <Col style={{ width: "48%", justifyContent: "start" }}>
                      <label style={labelStyles}>
                        Select Payment Type
                        <span style={{ color: "red", marginLeft: "3px" }}>
                          *
                        </span>
                      </label>
                      <br />
                      <Select
                        size="medium"
                        style={{ width: "100%" }}
                        placeholder="Select payment type"
                        optionFilterProp="children"
                        value={payment}
                        onChange={(e) => {
                          setPayment(e);
                        }}
                      >
                        <Select.Option key="CASH" value="CASH">
                          Cash
                        </Select.Option>
                        <Select.Option key="UPI" value="UPI">
                          UPI
                        </Select.Option>
                        <Select.Option key="CARD" value="CARD">
                          Card
                        </Select.Option>
                        <Select.Option key="CHEQUE" value="CHEQUE">
                          Cheque
                        </Select.Option>
                        <Select.Option key="DD" value="DD">
                          DD
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col style={{ width: "48%", marginTop: "20px" }}>
                      <label style={labelStyles}>
                        Enter Reference (UPI Payment ID, Cheque ID, DD ID)
                      </label>
                      <br />
                      <Input
                        size="medium"
                        type="text"
                        placeholder="Enter Reference ID"
                        style={{ width: "100%" }}
                        value={cashReference}
                        onChange={(e) => {
                          setCashReference(`${e.target.value}`);
                        }}
                      />
                    </Col>
                    <Col style={{ width: "48%", marginTop: "20px" }}>
                      <label style={labelStyles}>Enter Payment Remarks</label>
                      <br />
                      <Input
                        size="medium"
                        type="text"
                        placeholder="Enter Payment Remarks"
                        style={{ width: "100%" }}
                        value={cashRemarks}
                        onChange={(e) => {
                          setCashRemarks(`${e.target.value}`);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                </>
              )}
            </>
          ) : (
            <>
              {!selectedSevaId && !slotLoading ? (
                <>
                  <hr></hr>
                  <div style={{ position: "relative", color: "red" }}>
                    Please Select Seva Name
                  </div>
                </>
              ) : (
                <>
                  {startDate && selectedSevaId && !slotLoading ? (
                    <>
                      <hr></hr>
                      <div style={{ position: "relative", color: "red" }}>
                        No Slots available on{" "}
                        {moment(startDate).format("DD-MM-YYYY")}
                      </div>
                    </>
                  ) : (
                    startDate && (
                      <>
                        <hr></hr>
                        <div style={{ position: "relative" }}>
                          Fetching slots...Please wait
                        </div>
                      </>
                    )
                  )}
                </>
              )}
            </>
          )}
        </Row>
      </div>
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "#FFFCDC",
          padding: "10px 50px",
        }}
      >
        {slot !== "" && (
          <>
            <Row
              style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "500",
              }}
            >
              <Col
                style={{
                  width: "25%",
                  fontWeight: "bold",
                  fontSize: "23px",
                  textAlign: "left",
                }}
              >
                Total Amount :
              </Col>
              <Col
                style={{
                  width: "75%",
                  textAlign: "right",
                  fontSize: "22px",
                  fontWeight: "600",
                }}
              >
                {FormatNumberINRCurrency(
                  noOfPeople ? noOfPeople * price : price
                )}
              </Col>
            </Row>
          </>
        )}
      </div>
      <br />
      <br />
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
        }}
      >
        <Row style={{ justifyContent: "end", width: "100%" }}>
          <Col
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              ref={ref}
              type="primary"
              style={{
                width: "100%",
                background: "#E11513",
                borderColor: "#E11513",
                height: "42px",
                borderRadius: "3px",
              }}
              onClick={() => {
                buttonLoading === false && handleSubmit();
              }}
              loading={buttonLoading}
            >
              Book Now <RightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type="seva"
          from="Counter"
          data={array}
          setData={setArray}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setButtonLoading}
          setPrintTicketModal={setIsModalVisible}
          ticketType={ticketType}
        />
      </Modal>
    </div>
  );
};
export default withRouter(TmsPosPratyakshaSeva);
