import Axios from "../utils/tms-axios/tms-axios";

const TmsCartService = {
  allPurchases: async (
    fromDate,
    toDate,
    paymentId,
    ticketId,
    paymentStatus,
    cartId,
    userId,
    page,
    pageSize,
    sortBy,
    sortType
  ) => {
    const query = {
      fromDate,
      toDate,
      paymentId,
      ticketId,
      paymentStatus,
      cartId,
      userId,
      page,
      limit: pageSize,
      sortBy,
      sortType,
    };
    Object.keys(query).forEach((key) => {
      if (query[key] === undefined || !query[key]) {
        delete query[key];
      }
    });
    const resp = await Axios.get(`v1/cart/all-purchases`, {
      params: query,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  getAdminTicketDetails: async (ticketId) => {
    const resp = await Axios.get(
      `v1/scan-ticket/ticket-details/${ticketId}`,

      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getNamesAPI: async () => {
    const resp = await Axios.get(
      `/v1/pre-assigned-tickets/all?page=1&limit=200`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  scanTicket: async (ticketId) => {
    const resp = await Axios.post(
      `v1/scan-ticket/by-ticket-id/${ticketId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  getUserBookingHistory: async (userId, paymentStatus, page, pageSize) => {
    const resp = await Axios.get(
      `v1/cart/admin/user/booking-history?userId=${userId}&paymentStatus=${paymentStatus}&page=${page}&limit=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  sendreprintOTP: async (counterCartItemId) => {
    const resp = await Axios.post(
      `v1/ticket-print/reprint-otp/${counterCartItemId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  reprint: async (counterCartItemId, otp) => {
    const resp = await Axios.post(
      `v1/ticket-print/reprint`,
      {
        counterCartItemId: counterCartItemId,
        OTP: otp,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
};

export default TmsCartService;
