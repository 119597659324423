import React from "react";
import { Route, Routes } from "react-router";
import tmsRouteMap from "../constants/tms-route.map";
import TmsSmCounponManagement from "../views/tms-service-management/tms-service-management-pages/tms-coupon-management/tms-sm-coupon-management";
// import TmsSmAccommodation from "../views/tms-service-management/tms-service-management-pages/tms-sm-accommodation";
import TmsSmDarshanam from "../views/tms-service-management/tms-service-management-pages/tms-sm-darshanam/tms-sm-darshanam";
import TmsSmDonation from "../views/tms-service-management/tms-service-management-pages/tms-sm-donation/tms-sm-donation";
import TmsSmKalayanakatta from "../views/tms-service-management/tms-service-management-pages/tms-sm-kalayanakatta/tms-sm-kalayanakatta";
// import TmsSmParokshaSeva from "../views/tms-service-management/tms-service-management-pages/tms-sm-paroksha-seva";
import TmsSmPrasadam from "../views/tms-service-management/tms-service-management-pages/tms-sm-prasadam/tms-sm-prasadam";
import TmsSmSevas from "../views/tms-service-management/tms-service-management-pages/tms-sm-sevas/tms-sm-sevas";
import TmsSmShiftManagement from "../views/tms-service-management/tms-service-management-pages/tms-sm-shift-management/tms-sm-shift-management";
import TmsSmThulabharam from "../views/tms-service-management/tms-service-management-pages/tms-sm-thulabharam/tms-sm-thulabharam";
import TmsSmTollgate from "../views/tms-service-management/tms-service-management-pages/tms-sm-tollgate/tms-sm-tollgate";
import TmsServiceManagementHome from "../views/tms-service-management/tms-service-management-pages/tms-sm-home";
import { withRouter } from "../utils/tms-with-router/tms-with-router";
import TmsSmCounterManagementHome from "../views/tms-service-management/tms-service-management-pages/tms-sm-counter-management/tms-sm-counter-management-home";
import TmsSmPrasadamInventory from "../views/tms-service-management/tms-service-management-pages/tms-sm-prasadam-inventory/tms-sm-prasadam-inventory";
import TmsSmAccommodation from "../views/tms-service-management/tms-service-management-pages/tms-sm-accommodation/tms-sm-accommodation";
import TmsSmFootfall from "../views/tms-service-management/tms-service-management-pages/tms-sm-footfall/tms-sm-footfall";
// import TmsManualEntry from "../views/tms-service-management/tms-service-management-pages/tms-sm-manual-entry/tms-sm-manual-entry";
import TmsManualEntryRoutes from "./tms-manual-entry-routes";
import TmsSmProducts from "../views/tms-service-management/tms-service-management-pages/tms-sm-products/tms-sm-products";
const TmsServiceRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path={
          tmsRouteMap.serviceManagement_accommodation?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsSmAccommodation />}
      />
      <Route
        exact
        path={
          tmsRouteMap.serviceManagement_counter_management?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsSmCounterManagementHome />}
      />
      <Route
        exact
        path={tmsRouteMap.serviceManagement_coupon_management?.replace(
          "/service-management",
          ""
        )}
        element={<TmsSmCounponManagement />}
      />
      <Route
        exact
        path={
          tmsRouteMap.serviceManagement_darshanam?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsSmDarshanam />}
      />
      <Route
        exact
        path={
          tmsRouteMap.serviceManagement_donation?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsSmDonation />}
      />
      <Route
        exact
        path={
          tmsRouteMap.serviceManagement_kalayanakatta?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsSmKalayanakatta />}
      />
      {/* <Route
        exact
        path={tmsRouteMap.serviceManagement_parokshaseva?.replace(
          "/service-management",
          ""
        )}
        element={<TmsSmParokshaSeva />}
      /> */}
      <Route
        exact
        path={
          tmsRouteMap.serviceManagement_prasadam?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsSmPrasadam />}
      />
      <Route
        exact
        path={
          tmsRouteMap.sm_prasadam_inventory?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsSmPrasadamInventory />}
      />
      <Route
        exact
        path={
          tmsRouteMap.serviceManagement_sevas?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsSmSevas />}
      />
      <Route
        exact
        path={
          tmsRouteMap.serviceManagement_products?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsSmProducts />}
      />
      <Route
        exact
        path={
          tmsRouteMap.serviceManagement_shift_management?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsSmShiftManagement />}
      />
      <Route
        exact
        path={
          tmsRouteMap.serviceManagement_thulabharam?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsSmThulabharam />}
      />
      <Route
        exact
        path={
          tmsRouteMap.serviceManagement_footfall?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsSmFootfall />}
      />
      <Route
        exact
        path={
          tmsRouteMap.serviceManagement_tollgate?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsSmTollgate />}
      />
      <Route
        exact
        path={
          tmsRouteMap.serviceManagement_manual_entry?.replace(
            "/service-management",
            ""
          ) + "/*"
        }
        element={<TmsManualEntryRoutes />}
      />
      <Route path={"/"} element={<TmsServiceManagementHome />} />
      <Route path="*" element={<div>Not FOund</div>} />
    </Routes>
  );
};
export default withRouter(TmsServiceRoutes);
