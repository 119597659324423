import { Button, Divider, Input, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
// import tmsRouteMap from "../../constants/tms-route.map";
import { BaseContext } from "../../contexts/BaseContext";
import TmsUserService from "../../services/tms-user/tms-user.service";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import TmsCheckBoxList from "../tms-customs/tms-checkbox-list";
import TmsModal from "../tms-customs/tms-modal";
import TmsRowVertical from "../tms-customs/tms-row-vertical";
const TmsUMAssignModal = ({
  setUpdateFlag,
  showAssignModal,
  setShowAssignModal,
  assignPermissionsFlag,
  editPermissionsFlag,
  setEditPermissionsFlag,
  setAssignPermissionsFlag,
  editData,
  router,
}) => {
  const [userPhone, setUserPhone] = useState();
  const [loading, setLoading] = useState(false);
  const [permissionsData, setPermissionsData] = useState([]);
  const [userPermissionData, setUserPermissionData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { userDetails } = useContext(BaseContext);
  useEffect(() => {
    if (assignPermissionsFlag === true || editPermissionsFlag === true) {
      fetchAllPermissions();
    }
  }, [assignPermissionsFlag, editPermissionsFlag]);

  useEffect(() => {
    if (editPermissionsFlag) {
      setUserPhone(editData.phone);
      fetchUsersPermissionsByID(editData?.userId);
    }
  }, [editPermissionsFlag]);

  const handleAssignUser = async () => {
    setButtonLoading(true);
    if (userPhone === "") {
      notification.error({ message: "Please Enter Number" });
      setButtonLoading(false);
      return;
    }
    if (userPhone.length !== 10) {
      notification.error({ message: "Phone number must be 10 digits" });
      setButtonLoading(false);
      return;
    }
    try {
      await TmsUserService.updatePermissions(
        userPermissionData,
        assignPermissionsFlag ? `+91${userPhone}` : editData?.userId,
        assignPermissionsFlag
      );
      setEditPermissionsFlag(false);
      setUserPhone("");
      setPermissionsData([]);
      setUserPermissionData([]);
      setButtonLoading(false);
      setAssignPermissionsFlag(false);
      setUpdateFlag(Math.random() * 100 + 8);
      setShowAssignModal(false);
    } catch (error) {
      setButtonLoading(false);
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
  };
  const fetchAllPermissions = async () => {
    setLoading(true);
    try {
      const response = await TmsUserService.userPermissions();
      setPermissionsData(response);
    } catch (error) {
      // console.log(error.response);
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };
  const updateSelectedRoles = (role) => {
    let newRoles = [];
    if (userPermissionData?.includes(role)) {
      newRoles = userPermissionData.filter((e) => e !== role);
    } else {
      newRoles = [...userPermissionData, role];
    }
    setUserPermissionData(newRoles);
  };
  const handleClose = () => {
    setUserPhone("");
    setUserPermissionData([]);
    setShowAssignModal(false);
    setEditPermissionsFlag(false);
    setAssignPermissionsFlag(false);
  };
  const fetchUsersPermissionsByID = async (userId) => {
    setLoading(true);
    try {
      const response = await TmsUserService.getUserPermissionsById(userId);
      setUserPermissionData(response?.permissions);
    } catch (error) {
      // console.log(error.response);
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  return (
    <TmsModal
      width={"70%"}
      visible={showAssignModal}
      title={
        editPermissionsFlag
          ? "Edit User Permissions"
          : "Assign User Permissions"
      }
      onOk={handleAssignUser}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={buttonLoading}
          onClick={() => {
            handleAssignUser();
          }}
        >
          Submit
        </Button>,
      ]}
    >
      {loading ? (
        "loading"
      ) : (
        <>
          <TmsRowVertical
            data={{
              one: "Phone",
              two: (
                <Input
                  type="number"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  maxLength={10}
                  addonBefore={"+91"}
                  placeholder="Please Enter Mobile Number"
                  style={{ width: "100%" }}
                  value={userPhone}
                  onChange={(e) => {
                    if (e.target.value.length > 10) {
                      return;
                    }
                    setUserPhone(`${e.target.value}`);
                  }}
                  disabled={editPermissionsFlag ? true : false}
                />
              ),
              justify: "start",
              style: { width: "100%", padding: "10px 0px" },
            }}
          />
          <Divider style={{ margin: "10px 0px" }} />
          <TmsCheckBoxList
            data={permissionsData}
            userDetails={userDetails}
            selectedData={userPermissionData}
            name="Permissions"
            updateItems={updateSelectedRoles}
            gutter={[20, 20]}
            style={{ width: "100%", padding: "10px 0px" }}
          />
        </>
      )}
    </TmsModal>
  );
};
export default withRouter(TmsUMAssignModal);
