import { Card, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import TmsDatePickerRange from "../../components/tms-customs/tms-datepicker-range";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import tmsDashboardMap from "../../constants/tms-dashboard.map";
import {
  FormatNumberINRCurrency,
  FormatNumberINRCurrencyDecimalSeperator,
} from "../../utils/tms-currency-formatter/tms-curreny-formatter";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import { tms_icon_performance_scan } from "../../assets/images/tms-image-list";
import "../../assets/css/tms-dashboard/tms-dashboard.scss";
import TmsDashboardService from "../../services/tms-dashboard.service";
import TmsChart from "../../components/tms-dashboard/tms-chart";
import { useContext } from "react";
import { BaseContext } from "../../contexts/BaseContext";

const Option = Select;

const TmsHome = ({ router }) => {
  const { userDetails } = useContext(BaseContext);
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );
  const [from, setFrom] = useState(moment());
  const [to, setTo] = useState(moment());
  const [order] = useState("DESC");
  const [bookingType, setBookingType] = useState("payment");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData] = useState(null);
  const [dataType, setDataType] = useState("counter");
  const [daySelect, setDaySelect] = useState("1");
  const [heading, setHeading] = useState("Total Collection");
  const [visible, setVisible] = useState(false);
  const [individualData, setIndividualData] = useState({});
  const [cardData, setCardData] = useState({});
  const [individualDataLoading, setIndividualDataLoading] = useState(false);

  const [formatData, setFormatData] = useState([
    {
      bookingType: "Loading Data",
      collection: 0,
      total: 0,
    },
  ]);

  const getCollectionData = async () => {
    try {
      const collectionData = await TmsDashboardService.getCollectionData(
        dataType,
        moment(from).format("YYYY-MM-DD"),
        moment(to).format("YYYY-MM-DD"),
        bookingType,
        order
      );
      setData(collectionData.data);
      setMetaData(collectionData.visitorsCount);
      setLoading(false);
      let formattedArray = [];
      for (const key in collectionData?.data) {
        if (key !== "total") {
          formattedArray.push({
            bookingType: tmsDashboardMap.find((e) => e.key === key)?.name,
            collection: collectionData?.data[key].totalCollection ?? 0,
            total: collectionData?.data[key]?.totalTickets ?? 0,
          });
        }
      }
      setFormatData(formattedArray);
      setHeading("Devasthanam's Renvenue");
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Login Again", router);
      setLoading(false);
    }
  };
  const getIndividualData = async (name, key) => {
    setIndividualDataLoading(true);
    try {
      let data = {};
      const body = {
        counter: dataType,
        fromDate: moment(from).format("YYYY-MM-DD"),
        toDate: moment(to).format("YYYY-MM-DD"),
        dateType: bookingType,
        order,
      };
      if (
        templeDetails.shortName === "APEDP" &&
        userDetails?.permissions.find((item) =>
          ["DASHBOARD_READ", "SUPER_ADMIN"].includes(item)
        )
      ) {
        setVisible(true);
        data = await TmsDashboardService.getAllTemplesIndividualCollectionData(
          body
        );
        setIndividualData(data);
        let formattedArray = [];

        if (key === "total") {
          data?.templeData?.slice(0, 9)?.forEach((element) => {
            formattedArray.push({
              bookingType: element.templeShortName ?? element?.templeName,
              collection: element.totalCollection ?? 0,
              total: element?.totalTickets ?? 0,
            });
          });
        } else {
          data?.data[key]?.slice(0, 9)?.forEach((element) => {
            formattedArray.push({
              bookingType: element.templeShortName ?? element?.templeName,
              collection: element.totalCollection ?? 0,
              total: element?.totalTickets ?? 0,
            });
          });
        }

        setFormatData(formattedArray);
        setHeading(`${name} - All Temples`);
      } else {
        data = await TmsDashboardService.getIndividualCollectionData(body);
        setIndividualData(data);
        let formattedArray = [];
        data?.data[key]?.slice(0, 9)?.forEach((element) => {
          formattedArray.push({
            bookingType: element.productName,
            collection: element.totalCollection ?? 0,
            total: element?.totalTickets ?? 0,
          });
        });
        setFormatData(formattedArray);
        `${name}`;
      }
      setCardData({ name, key });
      setVisible(true);
      setIndividualDataLoading(false);
    } catch (e) {
      catchErrorFunction(e, "Token expired!! Please login again.", router);
      setIndividualData(false);
      setIndividualDataLoading(false);
      setVisible(false);
    }
  };
  useEffect(() => {
    getCollectionData();
  }, [from, to, bookingType, order, dataType]);
  return loading ? (
    "loading"
  ) : (
    <div>
      <div className="tms-d-row-one">
        <div className="tms-dashboard-heading">Dashboard</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Select
            className="br-25"
            defaultValue={daySelect}
            value={daySelect}
            style={{ marginRight: "10px", borderRadius: "0.25rem" }}
            onChange={(e) => {
              setDaySelect(e);
              if (e === "7") {
                setFrom(moment().subtract(7, "day").format("YYYY-MM-DD"));
              } else if (e === "15") {
                setFrom(moment().subtract(15, "day").format("YYYY-MM-DD"));
              } else if (e === "30") {
                setFrom(moment().subtract(30, "day").format("YYYY-MM-DD"));
              } else if (e === "1") {
                // setFrom(moment().subtract(30, "day").format("YYYY-MM-DD"));
              }
            }}
          >
            <Option value="7" key="7">
              Last 7 days
            </Option>
            <Option value="15" key="15">
              Last 15 days
            </Option>{" "}
            <Option value="30" key="30">
              Last 30 days
            </Option>
            <Option value="1" key="1">
              Select Days
            </Option>
          </Select>

          <TmsDatePickerRange
            className="br-25"
            label=""
            to={to}
            from={from}
            setFrom={setFrom}
            setTo={setTo}
          />
          <Select
            className="br-25"
            defaultValue={"counter"}
            value={dataType}
            style={{ marginLeft: "10px" }}
            onChange={(e) => {
              setDataType(e);
            }}
          >
            <Option value="counter" key="counter">
              POS
            </Option>
            <Option value="online" key="online">
              Online
            </Option>
            <Option value="both" key="both">
              All
            </Option>
          </Select>
          {/* <Select
            defaultValue={"DESC"}
            value={order}
            style={{ marginLeft: "10px" }}
            onChange={(e) => {
              setOrder(e);
            }}
          >
            <Option value="ASC" key="ASC">
              Ascending
            </Option>
            <Option value="DESC" key="DESC">
              Descending
            </Option>
          </Select> */}
          <Select
            className="br-25"
            labelInValue="sdsd"
            defaultValue={bookingType}
            value={bookingType}
            style={{ marginLeft: "10px" }}
            onChange={(e) => {
              setBookingType(e.value);
            }}
          >
            <Option value="payment" key="payment">
              Booking
            </Option>
            <Option value="booking" key="booking">
              Performance
            </Option>
          </Select>
        </div>
      </div>
      <br />
      <div className="tms-d-row-two">
        {tmsDashboardMap?.map((item, i) => {
          return (
            <Card
              className={"id-elements"}
              key={"elemets" + i}
              style={{ backgroundColor: item?.background }}
              onClick={() => {
                if (item?.name === "Total Collection") {
                  if (templeDetails?.shortName === "APEDP") {
                    if (
                      !userDetails?.permissions.find((item) =>
                        ["SUPER_ADMIN"].includes(item)
                      )
                    ) {
                      getCollectionData();
                      return;
                    }
                  } else {
                    getCollectionData();
                    return;
                  }
                }
                if (["Foot Fall", "Registrations"].includes(item?.name)) return;
                getIndividualData(item?.name, item?.key);
              }}
            >
              <div
                className={
                  item?.bookings
                    ? "id-elements-top id-100"
                    : "id-elements-top id-80"
                }
                style={{
                  backgroundColor: item?.background,
                  fontWeight: "600",
                }}
              >
                <div>
                  <p>{item?.name}</p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                    }}
                  >
                    {item?.name !== "Foot Fall"
                      ? FormatNumberINRCurrencyDecimalSeperator(
                          data[
                            dataType === "online" ? item?.onlinekey : item?.key
                          ]?.totalCollection ?? 0
                        )
                      : dataType === "online"
                      ? metaData?.online
                      : metaData?.offline}
                  </p>
                </div>
                <div className="bottom">
                  {item.logo && (
                    <img
                      src={item.logo ?? ""}
                      alt={tms_icon_performance_scan}
                    />
                  )}
                </div>
              </div>
              {item.bookings && (
                <div
                  className="id-elements-bottom"
                  style={{
                    marginTop: item?.name === "Foot Fall" ? "5px" : "",
                  }}
                >
                  <div className="top">
                    {item?.name !== "Foot Fall"
                      ? item?.name === "Publications"
                        ? "Coming Soon"
                        : "No of Bookings"
                      : "Footfall Count"}{" "}
                    <br />{" "}
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        lineHeight: "20px",
                      }}
                    >
                      {item?.name !== "Foot Fall"
                        ? data[
                            dataType === "online" ? item?.onlinekey : item?.key
                          ]?.totalTickets ?? 0
                        : ""}
                    </span>
                  </div>
                </div>
              )}
            </Card>
          );
        })}
      </div>
      <div className="sd-graph">
        {/*---Graph chart---*/}
        <div className="sd-graph-heading">{heading}</div>
        <br /> <TmsChart dataCollection={formatData} />{" "}
      </div>
      <div style={{ padding: "20px 0px" }}>
        <Table
          style={{ width: "100%", textTransform: "capitalize" }}
          columns={[
            {
              title: "S.no",
              key: "sno",
              width: "20px",
              render: (text, object, index) => index + 1,
            },
            {
              title: "Name",
              render: (record) => {
                return record.productName ?? record.templeName;
              },
            },

            {
              title: "Total Tickets",
              key: "totalTickets",
              dataIndex: "totalTickets",
            },
            {
              title: "Total Collection",
              key: "totalCollection",
              dataIndex: "totalCollection",
              render: FormatNumberINRCurrency,
            },
          ]}
          dataSource={
            cardData.key !== "total"
              ? individualData?.data?.[cardData?.key]
              : individualData?.templeData
          }
          size="small"
          loading={individualDataLoading}
          scroll={{ x: true }}
          pagination={false}
        ></Table>
      </div>
      {/* <Modal
        width="50%"
        visible={visible}
        title={`Temples Collection - ${cardData?.name ?? ""}`}
        onOk={() => {
          setVisible(false);
          setIndividualData({});
        }}
        onCancel={() => {
          setVisible(false);
          setIndividualData({});
        }}
        footer={null}
      >
        <Table
          style={{ width: "100%", textTransform: "capitalize" }}
          columns={[
            {
              title: "S.no",
              key: "sno",
              width: "20px",
              render: (text, object, index) => index + 1,
            },
            {
              title: "Temple Name",
              key: "templeName",
              dataIndex: "templeName",
            },

            {
              title: "Total Tickets",
              key: "totalTickets",
              dataIndex: "totalTickets",
            },
            {
              title: "Total Collection",
              key: "totalCollection",
              dataIndex: "totalCollection",
              render: FormatNumberINRCurrency,
            },
          ]}
          dataSource={individualData?.data?.[cardData?.key]}
          size="small"
          loading={individualDataLoading}
          scroll={{ x: true }}
          pagination={false}
        ></Table>
        <br />
        {(() => {
          let total = 0;
          let tickets = 0;
          console.log(individualData?.data?.[cardData?.key]);
          individualData?.data?.[cardData?.key]?.forEach((item) => {
            total += item.totalCollection;
            tickets += item.totalTickets;
          });
          return (
            <div
              key="totalC"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p key="totalT">
                Total Tickets: <b>{tickets}</b>
              </p>
              <p>
                Total Collection: <b>Rs.{total}.00</b>
              </p>
            </div>
          );
        })()}
      </Modal>{" "} */}
    </div>
  );
};
export default withRouter(TmsHome);
