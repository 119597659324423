import { Button, Col, Input, notification, Row } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import Highlighter from "react-highlight-words";
import TmsSevasService from "../../../../services/tms-sevas.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsCounterService from "../../../../services/tms-counter.service";
import TmsShiftService from "../../../../services/tms-shift.service";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import { SearchOutlined } from "@ant-design/icons";

import "../../../../assets/css/tms-reports/tms-reports.scss";
const TmsReportSevaReport = ({ router }) => {
  const [loading, setLoading] = useState();
  const [bookingDetails, setBookingDetails] = useState([]);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [dateType, setDateType] = useState("booking");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [setSortType] = useState("DESC");
  const [bookingType, setBookingType] = useState("pos");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [sevaType, setSevaType] = useState("pratyakshaSeva");
  const [sevaList, setSevaList] = useState([]);
  const [sevaId, setSevaId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("success");
  const [counter, setCounter] = useState("");
  const [counterList, setCounterList] = useState([]);

  const [shift, setShift] = useState("");
  const [shiftList, setShiftList] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const [slot, setSLot] = useState("");
  const [slotList, setSlotList] = useState([]);

  const API = {
    online: TmsSevasService.sevaReportOnline,
    pos: TmsSevasService.sevaReport,
  };

  const fetchBookingDetails = async () => {
    setLoading(true);
    let body = handleBody("fetch");
    try {
      const data = await API[bookingType](body);
      setTabelTotalSize(data?.meta?.totalItems);
      setBookingDetails(
        data?.items?.map((item) => {
          return { key: item?.bookingId, ...item };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdDate") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      const body = handleBody("excel");
      let data = await API[bookingType](body);
      data = data?.items?.reverse();
      let compiledDataOffile = [];
      let compiledDataOnline = [];
      if (bookingType === "online") {
        compiledDataOnline = data.map((item, i) => {
          return {
            "S.no": i + 1,
            "Seva Type ": item.sevaName,
            "Booked on": moment(item.createdDate).format("DD-MM-YYYY HH:mm A"),
            "Booking phone": item.bookingPhoneNumber,

            "No of persons": item.noOfTickets,
            price: item.productPrice,
            "Total Amount": item?.price,
            Slot: `${moment(item.slotStartTime, "HH:mm:ss").format(
              "hh:mm A"
            )} - 
            ${moment(item.slotEndTime, "HH:mm:ss").format("hh:mm A")}`,
            "Performance Date": item.bookingDate
              ? moment(item.bookingDate).format("DD-MM-YYYY")
              : "-",
            "razorpay ID": item?.razorPayOrderId,
            "Devotee Details": item.personDetails
              .map((items, j) => `${j + 1}. ${items?.personName}`)
              ?.join("\n"),
            Gothram: item.gothram,
            address: `${item.address ?? "-"} ${item.address2 ?? ""} ${
              item?.city ?? ""
            } ${item?.state ?? ""} ${item?.country ?? ""} ${
              item?.pinCode ?? ""
            }`,

            "Payment Status": item?.onlinePaymentStatus,
          };
        });
      } else {
        compiledDataOffile = data.map((item, i) => {
          return {
            "S.no": i + 1,
            "Seva Type ": item.sevaName,
            "POS Name": item.counterName,
            "Shift ID": item.counterShiftTimingId,
            "Booking Id": item.bookingId,
            "Transaction Id": item?.categoryTransactionSequence
              ?.split("-")
              ?.pop(),
            Amount: item.price,
            "Ticket Count": item?.noOfPeople,
            Slot: `${moment(item.slotStartTime, "HH:mm:ss").format(
              "hh:mm A"
            )} - 
            ${moment(item.slotEndTime, "HH:mm:ss").format("hh:mm A")}`,
            "Booked on": item.createdDate
              ? moment(item.createdDate).format("DD-MM-YYYY")
              : "-",
            "Performance Date": item.bookingDate
              ? moment(item.bookingDate).format("DD-MM-YYYY")
              : "-",
            "Devotee Name": item?.devoteeName ?? "-",
            Gothram: item?.gothram ?? "-",
            "Date of Birth": item?.dob
              ? moment(item?.dob).format("DD-MM-YYYY")
              : "-",
            Address: item?.address ?? "-",
            Contact: item?.contact ?? "-",
          };
        });
      }

      exportExcel(
        bookingType === "online" ? compiledDataOnline : compiledDataOffile,
        `seva-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };

  const fetchSevaList = async () => {
    try {
      let data = [];
      setSevaList([]);
      setSevaId("");
      setSLot("");
      setSlotList([]);
      if (
        ["parokshaSeva", "pratyakshaSeva", "saswataSeva"]?.includes(sevaType)
      ) {
        data = await TmsSevasService.getSevasByType(sevaType, 1, 300);
      } else {
        data = await TmsSevasService.getSevas(1, 300);
      }

      setTabelTotalSize(data?.meta?.totalItems);
      setSevaList(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchCounterList = async () => {
    try {
      let res = await TmsCounterService.getAllCounter();
      setCounterList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchShiftList = async () => {
    try {
      let res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchSevaSlotList = async () => {
    try {
      setSLot("");
      setSlotList([]);
      const res = await TmsSevasService.getSevaSlots(sevaId);
      // console.log(res);
      setSlotList(
        res?.map((item) => {
          return {
            key: item.productSlotId,
            ...item,
          };
        })
      );
      if (res?.length > 0) {
        setSLot("");
        res[0]?.productSlotId;
      }
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };

  useEffect(() => {
    if (bookingType === "pos") {
      fetchCounterList();
      fetchShiftList();
    }
  }, []);

  useEffect(() => {
    if (sevaId !== "") {
      fetchSevaSlotList();
    }
  }, [sevaId]);

  useEffect(() => {
    if (sevaType !== "") {
      fetchSevaList();
    }
  }, [sevaType]);

  useEffect(() => {
    fetchBookingDetails();
  }, [
    tableCurrentPage,
    tablePageSize,
    bookingFromDate,
    bookingToDate,
    sevaType,
    sevaId,
    slot,
    counter,
    shift,
    paymentStatus,
    dateType,
    bookingType,
  ]);

  const handleBody = (type) => {
    let body = {
      format: "json",
      tableCurrentPage: Number(tableCurrentPage),
      tablePageSize: type === "excel" ? 70000 : Number(tablePageSize),
      fromDate: bookingFromDate,
      toDate: bookingToDate,
      sevaType,
      sevaId: Number(sevaId),
      slotId: slot,
      dateType: dateType,
    };
    if (bookingType === "pos") {
      body.counterId = Number(counter);
      body.shiftId = shift;
      return body;
    } else {
      body.paymentStatus = paymentStatus;
      return body;
    }
  };

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "POS Name",
      dataIndex: "counterName",
      key: "counterName",
    },
    {
      title: "Shift Id",
      dataIndex: "counterShiftTimingId",
      key: "counterShiftTimingId",
    },
    {
      title: "Booking Id",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Txn Id",
      dataIndex: "categoryTransactionSequence",
      render: (text) => text?.split("-")?.pop(),
    },
    {
      title: "S no",
      dataIndex: "dailyCategoryTransactionSequence",
      render: (text) => text?.split("-")?.pop(),
    },
    {
      title: "Seva Type",
      dataIndex: "sevaName",
      key: "sevaName",
      ...getColumnSearchProps("sevaName"),
    },
    {
      title: "Amount",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return `Rs. ${text}`;
      },
      sorter: (a, b) => a.price > b.price,
    },
    {
      title: "User Phone",
      dataIndex: "contact",
      key: "contact",
      render: (record) => {
        return record && record !== null ? record : "-";
      },
    },
    {
      title: "Slot",
      dataIndex: "slotStartTime",
      key: "slotStartTime",
      render: (text, record) => {
        return `${moment(record.slotStartTime, "HH:mm:ss").format("hh:mm A")} - 
            ${moment(record.slotEndTime, "HH:mm:ss").format("hh:mm A")}`;
      },
    },
    {
      title: "Performance Date",
      dataIndex: "bookingDate",
      render: (record) => {
        return moment(record).format("DD-MM-YYYY");
      },
      sorter: (a, b) => a.bookingDate > b.bookingDate,
    },
    {
      title: "Booked Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY HH:mm:ss");
      },
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdDate > b.createdDate,
    },
  ];
  const onlineColumns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Seva Type",
      dataIndex: "sevaName",
      key: "sevaName",
    },
    {
      title: "Devotee Details",
      dataIndex: "personDetails",
      render: (record) => {
        return record
          ?.map((item) => {
            return item?.personName;
          })
          ?.join(",");
      },
      width: "120px",
    },
    {
      title: "No of Persons",
      dataIndex: "noOfTickets",
      key: "noOfTickets",
    },
    {
      title: "Price",
      dataIndex: "productPrice",
      key: "productPrice",
      render: (text) => {
        return `Rs. ${text}`;
      },
      sorter: (a, b) => a.price > b.price,
    },
    {
      title: "Total",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return `Rs. ${text}`;
      },
      // sorter: (a, b) => a.price > b.price,
    },

    {
      title: "Slot",
      dataIndex: "slotStartTime",
      key: "slotStartTime",
      render: (text, record) => {
        return `${moment(record.slotStartTime, "HH:mm:ss").format("hh:mm A")} - 
            ${moment(record.slotEndTime, "HH:mm:ss").format("hh:mm A")}`;
      },
    },
    {
      title: "Performance Date",
      dataIndex: "bookingDate",
      render: (record) => {
        return moment(record).format("DD-MM-YYYY");
      },
      sorter: (a, b) => a.bookingDate > b.bookingDate,
    },
    {
      title: "Booking ID",
      dataIndex: "bookingId",
      key: "bookingId",
    },
    {
      title: "Booking Phone",
      dataIndex: "bookingPhoneNumber",
      key: "bookingPhoneNumber",
    },
    {
      title: "Booked on",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY HH:mm A");
      },
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdDate > b.createdDate,
    },
  ];

  return (
    <div>
      <Row
        gutter={[20, 20]}
        style={{ paddingTop: "15px", position: "relative" }}
      >
        <Col span={6}>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "pos" },
            { id: "online", name: "online" },
          ]}
          optional={true}
        />

        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Seva Type"
          setValue={setSevaType}
          value={sevaType}
          data={[
            { id: "parokshaSeva", name: "Paroksha Pooja" },
            { id: "pratyakshaSeva", name: "Pratyaksha Pooja" },
            // { id: "saswataSeva", name: "Saswata Seva" },
          ]}
        />

        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Seva Name"
          setValue={(e) => {
            setSevaId(e);
            if (e === "") {
              setSLot("");
              return;
            }
          }}
          value={sevaId}
          data={sevaList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Slot"
          setValue={setSLot}
          value={slot}
          data={slotList}
        />

        {bookingType === "online" && (
          <>
            {" "}
            <TmsSelect
              type="col"
              span={3}
              selectClass="w-100"
              label="Payment Status"
              setValue={setPaymentStatus}
              value={paymentStatus}
              data={[
                { id: "created", name: "created" },
                { id: "success", name: "success" },
                { id: "failure", name: "failure" },
                { id: "canceled", name: "canceled" },
              ]}
            />
          </>
        )}
        {bookingType === "pos" && (
          <>
            {" "}
            <TmsSelect
              type="col"
              span={3}
              label="POS"
              setValue={setCounter}
              value={counter}
              data={counterList}
            />
            <TmsSelect
              type="col"
              span={3}
              label="Shift"
              setValue={setShift}
              value={shift}
              data={shiftList}
            />
          </>
        )}
        <TmsSelect
          type="col"
          span={3}
          label="Date Type"
          setValue={setDateType}
          value={dateType}
          data={[
            { id: "booking", name: "Performance" },
            { id: "payment", name: "Booking" },
          ]}
          optional={true}
        />

        <div style={{ position: "absolute", right: "0px", marginTop: "-20px" }}>
          <TmsExcelButton
            span={24}
            loading={excelLoading}
            handleExcelExport={handleExcelExport}
            top={false}
          />
        </div>

        {/* <TmsSelect
          type="col"
          span={3}
          label="Date Type"
          setValue={setDateType}
          value={dateType}
          data={[
            { id: "booking", name: "Booking" },
            { id: "payment", name: "Payment" },
          ]}
        /> */}
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={bookingType === "online" ? onlineColumns : columns}
          dataSource={bookingDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>
    </div>
  );
};

export default withRouter(TmsReportSevaReport);
