import {
  Button,
  Drawer,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import moment from "moment";
import TmsDatePickerRange from "../../../components/tms-customs/tms-datepicker-range";
import TmsCouponService from "../../../services/tms-coupon-service";

const TmsCMNewCouponDrawer = ({
  showCouponDrawer,
  setShowCouponDrawer,
  setUpdateFlag,
  editFlag,
  setEditFlag,
  editData,
  router,
}) => {
  const [coupounType, setCouponType] = useState("once_a_user");
  const [from, setFrom] = useState(moment(new Date()).format(`YYYY-MM-DD`));
  const [to, setTo] = useState(moment(new Date()).format(`YYYY-MM-DD`));
  const [percentage, setPercentage] = useState(25);
  const [coupounCode, setCouponCode] = useState("");
  const [coupounMaxAmount, setCoupounMaxAmount] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editFlag && editData) {
      setCouponCode(editData?.couponCode);
      setCouponType(editData?.type);
      setFrom(editData?.validFrom);
      setTo(editData?.validTo);
      setPercentage(editData?.maxOfferPercentage);
      setCoupounMaxAmount(editData?.maxOfferAmount);
    }
  }, [editFlag]);

  const handleCreateCoupon = async () => {
    if (!coupounCode || coupounCode === "") {
      return notification.error({ message: `Coupon Code is required` });
    }
    if (!to || !from || to === "" || from === "") {
      return notification.error({
        message: `Make sure start and end dates are valid`,
      });
    }

    setLoading(true);
    try {
      let body = {
        type: coupounType,
        couponCode: coupounCode,
        maxOfferPercentage: percentage,
        maxOfferAmount: coupounMaxAmount,
        validFrom: from,
        validTo: to,
      };
      if (editFlag) {
        await TmsCouponService.editCoupon(body, editData?.key);
        notification.success({
          message: `Coupon updated successfully`,
        });
        clearFields();
        setUpdateFlag(Math.random() * 100 + 9);
      } else {
        await TmsCouponService.createNewCoupon(body);
        notification.success({ message: `Coupon created successfully` });
        setUpdateFlag(Math.random() * 1000 + 9);
      }
      handleCancel();
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  const clearFields = () => {
    setCouponCode("");
    setCouponType("");
    setPercentage(20);
    setCoupounMaxAmount("");
    setFrom(moment(new Date()).format(`YYYY-MM-DD`));
    setTo(moment(new Date()).format(`YYYY-MM-DD`)); // setblockID(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowCouponDrawer(false);
    setEditFlag(false);
  };

  return (
    <Drawer
      centered={true}
      width="30%"
      closable={false}
      visible={showCouponDrawer}
      title={editFlag ? `Edit Coupon - ${editData?.name}` : "Create Coupon"}
      onOk={handleCreateCoupon}
      footer={[
        <Button
          key="back"
          className="mr-10"
          onClick={() => {
            handleCancel();
          }}
        >
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            handleCreateCoupon();
          }}
        >
          Submit
        </Button>,
      ]}
      onClose={() => {
        handleCancel();
      }}
    >
      <Row>
        Coupon Code :{" "}
        <Input
          value={coupounCode}
          onChange={(e) => setCouponCode(e.target.value?.toUpperCase())}
          placeholder="Enter Coupon code"
        ></Input>
      </Row>
      <Row style={{ width: "100%", padding: "10px 0px" }}>
        Coupon Type :{" "}
        <Select
          width="100%"
          style={{ width: "100%" }}
          value={coupounType}
          defaultValue={coupounType}
          placeholder="Please select a Counter"
          onChange={(e) => {
            setCouponType(e);
          }}
        >
          <Select.Option
            key={"DONAR_ACCOMMODATION"}
            value={"DONAR_ACCOMMODATION"}
          >
            Donor Accommodation
          </Select.Option>
          <Select.Option key={"ONCE_A_USER"} value={"ONCE_A_USER"}>
            Once a User
          </Select.Option>
        </Select>
      </Row>
      <Row width="100%">
        Discount in % :{" "}
        <InputNumber
          defaultValue={percentage}
          value={percentage}
          min={0}
          max={101}
          style={{ width: "100%" }}
          placeholder="Enter Discount Percentage"
          onChange={(e) => {
            if (e < 101) {
              setPercentage(e);
            }
          }}
        ></InputNumber>
      </Row>
      <Row style={{ width: "100%", padding: "10px 0px" }}>
        Max Discount in Rs.:{" "}
        <InputNumber
          defaultValue={coupounMaxAmount}
          value={coupounMaxAmount}
          min={0}
          style={{ width: "100%" }}
          placeholder="Enter max Discount on Coupon"
          onChange={(e) => {
            setCoupounMaxAmount(e);
          }}
        ></InputNumber>
      </Row>
      <Row style={{ width: "100%" }}>
        <TmsDatePickerRange
          label="Select Duration of Coupon"
          to={to}
          from={from}
          setFrom={setFrom}
          setTo={setTo}
        />
      </Row>
    </Drawer>
  );
};
export default withRouter(TmsCMNewCouponDrawer);
