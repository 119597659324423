import { Row, Tabs, Button, Col, Select, Input, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import TmsUserCard from "../../../components/tms-user-management/tms-user-card";
// import {BaseContext} from "../../../contexts/BaseContext";
import TmsUserService from "../../../services/tms-user/tms-user.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
const TmsUmInnerPagesUsersDetails = ({ router }) => {
  // const {userDetails} = useContext(BaseContext);

  const inputEl = useRef();

  const [queryBy, setQueryBy] = useState("userId");
  const [userId, setUserId] = useState("");
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const setInputFocus = () => {
    inputEl?.current?.input?.focus();
  };

  const selectInput = () => {
    inputEl?.current?.input?.select();
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  useEffect(() => {
    const id = new URLSearchParams(router?.location?.search).get("userId");
    if (id) {
      setUserId(id);
      setQueryBy("userId");
      submit(id);
    }
  }, [router?.location?.search]);

  const submit = async (_userId = undefined) => {
    setLoading(true);
    setData(undefined);
    try {
      if (!userId && !_userId) {
        return notification.error({ message: "Please enter a value." });
      }
      const temp = _userId ?? userId;
      const data = await TmsUserService.userDetails(queryBy, temp);
      // console.log(data);
      if (data) {
        setData(data);
      } else {
        notification.error({ message: "No such user." });
      }
      selectInput();
      setInputFocus();
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    setInputFocus();
  }, []);

  return (
    <>
      <Row justify="start" gutter={[20, 20]}>
        <Col>
          <Select
            onChange={(e) => {
              setQueryBy(e);
            }}
            value={queryBy}
            style={{ width: "100px" }}
          >
            <Select.Option value="email"> Email </Select.Option>
            <Select.Option value="phone"> Phone </Select.Option>
            <Select.Option value="userId"> User ID </Select.Option>
          </Select>
        </Col>
        <Col>
          <Input
            ref={inputEl}
            value={userId}
            onChange={(e) => {
              setUserId(e.target.value);
            }}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              submit();
            }}
          >
            GO!
          </Button>
        </Col>
      </Row>
      <br />
      <Tabs className="tms-tabs-inner-scroll">
        <Tabs.TabPane tab="User Details" key="1">
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={24} lg={7}>
              {
                data && <TmsUserCard userLoading={loading} data={data} />

                // <Row justify='center' gutter={[10,0]}>
                //   <Col>{item}</Col>
                //   <Col>{data[item]}</Col>
                // </Row>
              }
            </Col>
            <Col xs={24} sm={24} md={24} lg={17}>
              {/* {data?.id && (
                <UserOrderStats
                  userId={data?.id}
                  cardsmall={24}
                  cardmid={8}
                  cardlarge={8}
                  graphsmall={24}
                  graphlarge={12}
                  cardheight={'230px'}
                />
              )} */}
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="User Booking History" key="2">
          {/* {data?.id && <UserBookingHistoryTable userId={data?.id} />} */}
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="User Order Stats" key="3">
          {data?.id && (
            <UserOrderStats
              userId={data?.id}
              cardsmall={24}
              cardmid={8}
              cardlarge={6}
              graphsmall={24}
              graphlarge={12}
            />
          )}
        </Tabs.TabPane> */}
        <Tabs.TabPane tab="User Auth Logs" key="4">
          {/* {data?.phone && <UserAuthLogs phone={data?.phone} />} */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="User Action Logs" key="5">
          {/* {data?.id && <UserActionLogs userId={data?.id} />} */}
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};
export default withRouter(TmsUmInnerPagesUsersDetails);
