import { Col, notification, Row } from "antd";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsCounterService from "../../../../services/tms-counter.service";
import TmsShiftService from "../../../../services/tms-shift.service";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import { FormatNumberINRCurrency } from "../../../../utils/tms-currency-formatter/tms-curreny-formatter";
import "../../../../assets/css/tms-reports/tms-reports.scss";
import { BaseContext } from "../../../../contexts/BaseContext";
import TmsAccommodationService from "../../../../services/tms-accommodation.service";

const TmsReportAccommodationReport = ({ router }) => {
  const [loading, setLoading] = useState();
  const [bookingDetails, setBookingDetails] = useState([]);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [dateType, setDateType] = useState("booking");
  const [sortType, setSortType] = useState("DESC");
  const [bookingType, setBookingType] = useState("pos");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [roomType, setRoomType] = useState("");
  const [blocksList, setblocksList] = useState([]);
  const [blockId, setBlockId] = useState("");
  const { roomMapData } = useContext(BaseContext);

  const [counter, setCounter] = useState("");
  const [counterList, setCounterList] = useState([]);
  const [sortBy, setSortBy] = useState("bookingDate");
  const [shift, setShift] = useState("");
  const [shiftList, setShiftList] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [roomList, setRoomList] = useState([]);

  const API = {
    online: TmsAccommodationService.accommodationReportOnline,
    pos: TmsAccommodationService.accommodationReport,
    advance: TmsAccommodationService.accommodationReportAdvance,
  };

  const fetchBookingDetails = async () => {
    setLoading(true);
    try {
      const data = await API[bookingType](
        "json",
        Number(tableCurrentPage),
        Number(tablePageSize),
        bookingFromDate,
        bookingToDate,
        roomType,
        Number(blockId),
        selectedRoom,
        Number(counter),
        shift,
        dateType,
        sortType,
        sortBy
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setBookingDetails(
        data?.items?.map((item) => {
          return bookingType === "advance"
            ? { key: item?.id, ...item }
            : { key: item?.bookingId, ...item };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdDate") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      let data = await API[bookingType](
        "json",
        Number(tableCurrentPage),
        Number(50000),
        bookingFromDate,
        bookingToDate,
        roomType,
        Number(blockId),
        selectedRoom,
        Number(counter),
        shift,
        dateType,
        sortType,
        sortBy
      );

      // data = data?.items?.reverse();
      let compiledDataOffile = [];
      let compiledDataOnline = [];
      let compiledDataOffileAdv = [];
      if (bookingType === "online") {
        compiledDataOnline = data.items.map((item, i) => {
          return {
            "S.no": i + 1,
            "Booking Id": item?.bookingId,
            "Transaction Id": item?.paymentId,
            "Room Type ": item?.roomType,
            "Block Name": item?.blockName,
            "Room Number": item?.roomNumber,
            "Devotee Name": `${item?.firstName} ${item?.lastName}`,
            "Devotee DOB": moment(item?.dob)?.format("DD-MM-YYYY"),
            "Aadhar No": item?.documentId,
            Contact: item?.contact ?? "-",
            Email: item?.email ?? "-",
            address: `${item?.address ?? "-"} ${item?.address2 ?? ""} ${
              item?.city ?? ""
            } ${item?.state ?? ""} ${item?.country ?? ""} ${
              item?.pinCode ?? ""
            }`,
            "Check In Date": item?.bookingDate
              ? moment(item?.checkIn)?.format("DD-MM-YYYY HH:mm A")
              : "-",
            "Check Out Date": item?.checkOut
              ? moment(item?.checkOut)?.format("DD-MM-YYYY HH:mm A")
              : "-",
            "Booking Date": item?.bookingDate
              ? moment(item?.bookingDate)?.format("DD-MM-YYYY HH:mm A")
              : "-",
            "Room Price": item?.price,
            Gst: item?.gst,
            "Total Amount": `${
              (item?.depositAmount ?? 0) +
              item?.gst +
              item?.price +
              item?.depositAmount
            }`,
          };
        });
      } else if (bookingType === "pos") {
        compiledDataOffile = data.items.map((item, i) => {
          return {
            "S.no": i + 1,
            "POS Name": item?.counterName,
            "Shift ID": item?.counterShiftTimingId,
            "Booking Id": item?.bookingId,
            "Transaction Id": `${
              item?.categoryTransactionSequence?.split("-")[3]
            }-${item?.categoryTransactionSequence?.split("-")[4]}`,
            "Room Type ": item?.roomType,
            "Block Name": item?.blockName,
            "Room Number": item?.roomNumber,
            "Devotee Name": item?.devoteeName,
            "Devotee DOB": moment(item?.dob)?.format("DD-MM-YYYY"),
            "Aadhar No": item?.documentId,
            Contact: item?.contact ?? "-",
            Email: item?.email ?? "-",
            Address: `${item?.address ?? ""} ${item?.address2 ?? ""} ${
              item?.city ?? ""
            } ${item?.state ?? ""} ${item?.country ?? ""} ${
              item?.pinCode ?? ""
            }`,
            "Check In Date": item?.checkIn
              ? moment(item?.checkIn)?.format("DD-MM-YYYY HH:mm A")
              : "-",
            "Check Out Date": item?.checkOut
              ? moment(item?.checkOut)?.format("DD-MM-YYYY HH:mm A")
              : "-",
            "Booking Date": item?.bookingDate
              ? moment(item?.bookingDate)?.format("DD-MM-YYYY HH:mm A")
              : "-",
            "Room Price": item?.price,
            Gst: item?.gst,
            "Total Amount": `${
              (item?.depositAmount ?? 0) +
              item?.gst +
              item?.price +
              item?.depositAmount
            }`,
          };
        });
      } else {
        compiledDataOffileAdv = data.items.map((item, i) => {
          return {
            "S.no": i + 1,
            "POS Name": item?.counter?.name,
            "Shift ID":
              item?.advanceCounterCartItem?.counterCart?.counterShiftLoginLog
                ?.counterShiftTimingId,
            "Booking Id": item?.id,
            "Transaction Id": `${
              item?.advanceCounterCartItem?.categoryTransactionSequence?.split(
                "-"
              )[3]
            }-${
              item?.advanceCounterCartItem?.categoryTransactionSequence?.split(
                "-"
              )[4]
            }`,
            "Room Type ": item?.accBlockCategory?.roomType,
            "Block Name": item?.accBlockCategory?.accBlock?.name,
            "Room Number": item?.accPreAssignedRoom?.roomNumber ?? "-",
            "Devotee Name": item?.devoteeName,
            "Devotee DOB": moment(item?.dob)?.format("DD-MM-YYYY"),
            "Aadhar No": item?.documentId,
            Contact: item?.contact ?? "-",
            Email: item?.email ?? "-",
            Address: `${item?.address ?? ""} ${item?.address2 ?? ""} ${
              item?.city ?? ""
            } ${item?.state ?? ""} ${item?.country ?? ""} ${
              item?.pinCode ?? ""
            }`,
            "Booking Date": item?.bookingDate
              ? moment(item?.bookingDate)?.format("DD-MM-YYYY HH:mm A")
              : "-",
            "Room Price": item?.price,
            Gst: item?.gst,
            "Advance Booking Charge": item?.advanceBookingCharge,
            "Total Amount": `${
              (item?.depositAmount ?? 0) +
              item?.gst +
              item?.price +
              item?.advanceBookingCharge
            }`,
          };
        });
      }

      exportExcel(
        bookingType === "online"
          ? compiledDataOnline
          : bookingType === "pos"
          ? compiledDataOffile
          : compiledDataOffileAdv,
        `accommodation-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };
  const fetchblocksList = async () => {
    try {
      let data = [];
      setblocksList([]);
      setBlockId("");
      // setSelectedRoom("");
      // setRoomList([]);
      {
        data = await TmsAccommodationService.getAllBlocks(1, 200);
      }

      setTabelTotalSize(data?.meta?.totalItems);
      setblocksList(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchCounterList = async () => {
    try {
      let res = await TmsCounterService.getAllCounter();
      setCounterList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchShiftList = async () => {
    try {
      let res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchRoomList = async () => {
    try {
      setSelectedRoom("");
      setRoomList([]);
      const res = await TmsAccommodationService.getRoomsByBlockId(blockId);
      // console.log(res);
      setRoomList(
        res?.map((item) => {
          return {
            key: item.id,
            name: `${item.description} - ${item.roomNumber}`,
            ...item,
          };
        })
      );
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };

  useEffect(() => {
    fetchCounterList();
    fetchShiftList();
  }, []);

  useEffect(() => {
    if (blockId !== "") {
      fetchRoomList();
    }
  }, [blockId]);

  useEffect(() => {
    // if (roomType !== "") {
    fetchblocksList();
    // }
  }, [roomType]);

  useEffect(() => {
    fetchBookingDetails();
  }, [
    tableCurrentPage,
    tablePageSize,
    bookingFromDate,
    bookingToDate,
    roomType,
    blockId,
    selectedRoom,
    counter,
    shift,
    dateType,
    sortType,
    bookingType,
    sortBy,
  ]);

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },

    {
      title: "Devotee Name",
      render: (record) => {
        return `${
          bookingType === "online"
            ? `${record?.firstName} ${record?.lastName}`
            : record?.devoteeName
        }`;
      },
    },

    {
      title: "Room Type",
      dataIndex: bookingType === "advance" ? "accBlockCategory" : "roomType",
      key: bookingType === "advance" ? "accBlockCategory" : "roomType",
      render: (record) => {
        return `${
          bookingType === "advance"
            ? record?.roomType?.replace("_", " ")
            : record?.replace("_", " ")
        }`;
      },
    },
    {
      title: "Block Name",
      dataIndex: bookingType === "advance" ? "accBlockCategory" : "blockName",
      key: "blockName",
      render: (record) => {
        return `${bookingType === "advance" ? record?.accBlock?.name : record}`;
      },
    },
    {
      title: "Room Number",
      dataIndex:
        bookingType === "advance" ? "accPreAssignedRoom" : "roomNumber",
      render: (record) => {
        return `${
          bookingType === "advance" ? record?.roomNumber ?? "-" : record ?? "-"
        }`;
      },
    },

    {
      title: "Check In Date",
      dataIndex: "checkIn",
      render: (record) => {
        return record ? moment(record).format("DD-MM-YYYY HH:mm A") : "Not yet";
      },
      sorter: (a, b) => a.checkIn > b.checkIn,
      hidden: bookingType === "advance",
    },
    {
      title: "Check out Date",
      dataIndex: "checkOut",
      render: (record) => {
        return record ? moment(record).format("DD-MM-YYYY HH:mm A") : "Not Yet";
      },
      sorter: (a, b) => a.checkOut > b.checkOut,
      hidden: bookingType === "advance",
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },

    {
      title: "Total Amount",
      render: (text) => {
        return `Rs. ${
          (text?.gst ?? 0) + (text?.price ?? 0) + (text?.depositAmount ?? 0)
        }`;
      },
    },
  ].filter((item) => !item.hidden);

  return (
    <div>
      <Row gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "POS" },
            { id: "online", name: "Online" },
            { id: "advance", name: "POS Advance" },
          ]}
          optional={true}
        />
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Room Type"
          setValue={setRoomType}
          value={roomType}
          data={roomMapData}
        />
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Block Name"
          setValue={(e) => {
            setBlockId(e);
            if (e === "") {
              setSelectedRoom("");
              return;
            }
          }}
          value={blockId}
          data={blocksList}
        />
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Room Number"
          setValue={setSelectedRoom}
          value={selectedRoom}
          data={roomList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="POS"
          setValue={setCounter}
          value={counter}
          data={counterList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Shift"
          setValue={setShift}
          value={shift}
          data={shiftList}
        />
        <Col span={6}>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
      </Row>
      <br />
      <Row gutter={[20, 20]}>
        <Col span={3}>
          <TmsSelect
            type="col"
            label="Sort By"
            setValue={setSortBy}
            value={sortBy}
            data={[
              { id: "bookingDate", name: "Booking Date" },
              { id: "checkInDate", name: "Check In" },
              { id: "checkOutDate", name: "Check Out" },
            ]}
            optional={true}
          />
        </Col>
        <Col span={3} style={{ padding: "0" }}>
          <TmsSelect
            type="col"
            label="Date Type"
            setValue={setDateType}
            value={dateType}
            data={[
              { id: "booking", name: "Booking" },
              { id: "payment", name: "Payment" },
            ]}
          />
        </Col>
        <Col style={{ padding: "0" }}>
          <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
          <TmsExcelButton
            loading={excelLoading}
            handleExcelExport={handleExcelExport}
            top={false}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={columns}
          dataSource={bookingDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          fontSize={true}
          expandable={{
            expandedRowRender: (record) => (
              <Row justify="space-evenly">
                <Col className="mr-10">
                  <b>Booking ID:</b> {record.key}
                </Col>
                |
                <Col className="mr-10">
                  <b> Devotee Name</b> :
                  {bookingType === "online"
                    ? `${record?.firstName} ${record?.lastName}`
                    : record?.devoteeName}
                </Col>
                |
                <Col className="mr-10">
                  <b> Room Price</b>: {FormatNumberINRCurrency(record.price)}
                </Col>
                |
                <Col className="mr-10">
                  <b> GST</b>: {FormatNumberINRCurrency(record.gst)}
                </Col>
                |
                <Col className="mr-10">
                  <b> User phone</b>:
                  {record.contact !== null ? record.contact : "-"}
                </Col>
              </Row>
            ),
          }}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>
    </div>
  );
};

export default withRouter(TmsReportAccommodationReport);
